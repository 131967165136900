import { useMemo } from 'react';
import { useAppSelector } from './use-store';
import { FETCH_STATUS } from '~/utils/constants';

const usePayPerPost = () => {
  const realtimeData = useAppSelector((state) => state.realtimeData);
  const isFreePlan = useMemo(() => {
    if (
      realtimeData.packageByCompanyId.fetchStatus !== FETCH_STATUS.RESOLVED &&
      realtimeData.company.fetchStatus !== FETCH_STATUS.RESOLVED
    )
      return;

    return !realtimeData.packageByCompanyId.data?.isPremium;
  }, [realtimeData]);

  return { isFreePlan };
};

export default usePayPerPost;
