import { Image, Typography } from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { useFeatureFlag } from '~/hooks/firebase/use-feature-flag';
import { closeDialog, setDialog } from '~/store/views/dialogs-slice';
import {
  EpCompanyVerificationStatus,
  EpUserSubscriptionPlanTypeEnum,
  JobPublished,
} from '~/types/graphql/graphql';
import * as mpe from '~/utils/mixpanel';

export const useVacancyPublishLimitDialog = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { realtimeData } = useAppSelector((state) => state);
  const isFreePlanActive = useFeatureFlag('free_plan');

  const realtimeDataCompany = realtimeData.company;

  const isPendingOrVerified = [
    EpCompanyVerificationStatus.Pending,
    EpCompanyVerificationStatus.Verified,
  ].includes(
    realtimeDataCompany?.data?.company?.status as EpCompanyVerificationStatus,
  );

  const isFreePlan =
    realtimeDataCompany?.data?.package?.subscription ===
    EpUserSubscriptionPlanTypeEnum?.Fp;

  const isDisplay =
    isPendingOrVerified && isFreePlan && isFreePlanActive && false;

  const closeVacancyPublishLimitDialog = () =>
    dispatch(closeDialog('confirmDialogV2'));

  const openVacancyPublishLimitDialog = (
    jobPublished: JobPublished,
    vacancyTitle: string,
  ) => {
    if (!isDisplay) return;

    const constructMessage = () => {
      if (jobPublished?.hasExceeded) {
        return (
          <>
            <Typography>
              {t('vacancy:dialog.publishLimit.exceed.message1')}{' '}
              <strong>
                {vacancyTitle}{' '}
                {t('vacancy:dialog.publishLimit.exceed.message2')}
              </strong>{' '}
              {t('vacancy:dialog.publishLimit.exceed.message3')}
            </Typography>
            <Typography>
              {t('vacancy:dialog.publishLimit.exceed.message4')}
            </Typography>
          </>
        );
      } else if (
        !jobPublished?.hasExceeded &&
        jobPublished?.current === jobPublished?.max
      ) {
        return (
          <Typography>
            {t('vacancy:dialog.publishLimit.reach.message1')}{' '}
            <strong>{t('vacancy:dialog.publishLimit.reach.message2')}</strong>{' '}
            {t('vacancy:dialog.publishLimit.reach.message3')}
          </Typography>
        );
      }
    };

    dispatch(
      setDialog({
        confirmDialogV2: {
          open: true,
          content: {
            containerProps: {
              'data-test-id': 'ctVacancyPublishLimitDialog',
            },
            title: t('vacancy:dialog.publishLimit.title'),
            titleProps: {
              'data-test-id': 'lbVacancyPublishLimitDialogTitle',
            },
            body: (
              <>
                <Image
                  src="/illustrations/vacancy-publish-limit-illustration.webp"
                  alt="vacancy-publish-limit-illustration"
                  data-test-id="imgVacancyPublishLimitDialogIllustrations"
                />
                {constructMessage()}
              </>
            ),
            bodyProps: {
              'data-test-id': 'lbVacancyPublishLimitDialogMessage',
            },
            cancelProps: {
              children: t('common:later'),
              'data-test-id': 'btnVacancyPublishLimitDialogCancel',
            },
            acceptProps: {
              children: t('common:explorePackages'),
              'data-test-id': 'btnVacancyPublishLimitDialogAccept',
              onClick: () => {
                closeVacancyPublishLimitDialog();

                mpe.premiumOffering({
                  linkPosition: 'limitBasicVacancy',
                });

                dispatch(
                  setDialog({
                    subscriptionPlanDialog: {
                      open: true,
                      content: {
                        linkPosition: 'limitBasicVacancy',
                      },
                    },
                  }),
                );
              },
            },
            closeButtonProps: {
              'data-test-id': 'btnVacancyPublishLimitDialogClose',
            },
          },
        },
      }),
    );
  };

  return {
    openVacancyPublishLimitDialog,
    closeVacancyPublishLimitDialog,
  };
};
