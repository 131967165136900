import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CheckCircleIcon from '~/components/Icons/CheckCircleIcon';
import StopCircleIcon from '~/components/Icons/StopCircleIcon';
import {
  useOpenJobVacancy,
  useOnHoldJobVacancy,
  useCloseJobVacancy,
} from '~/hooks/use-job-vacancy-change-state';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { useVacancyPublishLimitDialog } from '~/hooks/vacancy/use-vacancy-publish-limit-dialog';
import { clearDialog, setDialog } from '~/store/views/dialogs-slice';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { setRealtimeDataCompany } from '~/store/features/realtime-data-slice';
import {
  VacancyChangeStateDialogAction,
  VacancyChangeStateDialogActionData,
  VacancyChangeStateDialogProps,
} from './VacancyChangeStateDialog.types';
import ConfirmDialogV1 from '~/components/Dialogs/ConfirmDialogV1';
import { VACANCY_UTM } from '~/utils/constants/vacancy';
import * as mpe from '~/utils/mixpanel';
import {
  EpJobVacancyState,
  EpOpenJobVacancyErrorEnum,
  EpOpenJobVacancyV3ErrorEnum,
} from '~/types/graphql/graphql';

const VacancyChangeStateDialog = ({
  content,
  ...props
}: VacancyChangeStateDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { auth, realtimeData } = useAppSelector((state) => state);

  const {
    handleOpenJobVacancy,
    handleOpenJobVacancyV3,
    isLoading: isOpenLoading,
  } = useOpenJobVacancy();
  const { handleOnHoldJobVacancy, isLoading: isOnHoldLoading } =
    useOnHoldJobVacancy();
  const { handleCloseJobVacancy, isLoading: isCloseLoading } =
    useCloseJobVacancy();
  const { openVacancyPublishLimitDialog } = useVacancyPublishLimitDialog();

  const handleClose = useCallback(() => {
    dispatch(clearDialog('vacancyChangeStateDialog'));
  }, []);

  const handleOpenVacancy = useCallback(async () => {
    if (!content) return;
    const {
      vacancyId,
      vacancyWebLink,
      vacancyCode,
      vacancyHighlighted,
      vacancyState,
      vacancyName,
      vacancyLocation,
      onCompleted,
    } = content;
    const res = await handleOpenJobVacancy(vacancyId);
    const openJobVacancy = res?.data?.openJobVacancy;

    // Handle on submit error
    if (openJobVacancy?.__typename === 'EpOpenJobVacancyError') {
      if (
        openJobVacancy?.typeError === EpOpenJobVacancyErrorEnum.DuplicateName
      ) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: t('business-error:duplicateName', {
              positionName: vacancyName,
              locationName: vacancyLocation,
            }),
            sx: {
              '.MuiPaper-root': {
                maxWidth: { xs: '100%', lg: 471 },
                'div > div:first-child': { pr: 0 },
                'div > div:last-child': { ml: 0 },
                '.SnackbarText': { pr: 0 },
              },
            },
          }),
        );
      }
      handleClose();
    }

    // Handle on submit success
    if (openJobVacancy?.__typename === 'EpJobVacancyGeneratedLink') {
      const jobPublished = openJobVacancy?.jobPublished;

      mpe.vacancyUpdateStatus({
        vacancyCode,
        currentVacancyStatus: vacancyState ?? '',
        newVacancyStatus: EpJobVacancyState.Open,
        isPromoted: vacancyHighlighted ?? false,
      });

      // Add setTimeout to prevent race condition on url params change
      setTimeout(() => {
        // Update realtimeData with data from mutation response to prevent delay
        if (auth?.companyCode && jobPublished) {
          dispatch(
            setRealtimeDataCompany({
              companyCode: auth?.companyCode,
              data: {
                ...realtimeData?.company?.data,
                jobPublished: {
                  current: jobPublished?.current,
                  max: jobPublished?.max,
                  hasExceeded: jobPublished?.hasExceeded,
                },
              },
            }),
          );
        }
      }, 100);

      onCompleted?.(handleClose, openJobVacancy);

      if (
        jobPublished &&
        !jobPublished?.hasExceeded &&
        jobPublished?.current === jobPublished?.max
      )
        openVacancyPublishLimitDialog(jobPublished, vacancyName);

      dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'success',
          message: t('vacancy-detail:alert.openJob'),
          closeable: false,
          actions: (onClose) => [
            {
              label: t('common:no'),
              onClick: onClose,
              color: 'text.secondary',
              buttonProps: {
                sx: { justifyContent: 'center', alignItems: 'center' },
              },
            },
            {
              label: t('common:look'),
              color: 'text.primary',
              buttonProps: {
                sx: { justifyContent: 'center', alignItems: 'center' },
              },
              onClick: () => {
                if (vacancyWebLink)
                  window.open(
                    `${vacancyWebLink}${VACANCY_UTM.PREVIEW}`,
                    '_blank',
                  );
              },
            },
          ],
        }),
      );

      // redirect to vacancy list page
      navigate('/vacancy?tab=OPEN');
    }
  }, [content, handleClose, handleOpenJobVacancy]);

  const handleOpenVacancyV3 = useCallback(async () => {
    if (!content) return;
    const {
      vacancyId,
      vacancyWebLink,
      vacancyCode,
      vacancyHighlighted,
      vacancyState,
      vacancyName,
      vacancyLocation,
      onCompleted,
    } = content;

    const res = await handleOpenJobVacancyV3(vacancyId);
    if (!res) return;

    const openJobVacancy = res?.openJobVacancyV3;
    // Handle on submit error
    if (openJobVacancy?.__typename === 'EpOpenJobVacancyV3Error') {
      if (
        openJobVacancy?.typeError === EpOpenJobVacancyV3ErrorEnum.DuplicateName
      ) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: t('business-error:duplicateName', {
              positionName: vacancyName,
              locationName: vacancyLocation,
            }),
            sx: {
              '.MuiPaper-root': {
                maxWidth: { xs: '100%', lg: 471 },
                'div > div:first-child': { pr: 0 },
                'div > div:last-child': { ml: 0 },
                '.SnackbarText': { pr: 0 },
              },
            },
          }),
        );
      }
      if (
        openJobVacancy?.typeError ===
          EpOpenJobVacancyV3ErrorEnum.AlreadyClosed ||
        openJobVacancy?.typeError === EpOpenJobVacancyV3ErrorEnum.NotFound
      ) {
        dispatch(
          setSnackbar({
            layout: 'dashboard',
            severity: 'error',
            message: openJobVacancy?.message,
          }),
        );
      }
      handleClose();
    }

    // Handle on submit success
    if (openJobVacancy?.__typename === 'EpJobVacancyV3Response') {
      mpe.vacancyUpdateStatus({
        vacancyCode,
        currentVacancyStatus: vacancyState ?? '',
        newVacancyStatus: EpJobVacancyState.Open,
        isPromoted: vacancyHighlighted ?? false,
      });

      onCompleted?.(handleClose, res);

      dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'success',
          message: t('vacancy-detail:alert.openJob'),
          closeable: false,
          actions: (onClose) => [
            {
              label: t('common:no'),
              onClick: onClose,
              color: 'text.secondary',
              buttonProps: {
                sx: { justifyContent: 'center', alignItems: 'center' },
              },
            },
            {
              label: t('common:look'),
              color: 'text.primary',
              buttonProps: {
                sx: { justifyContent: 'center', alignItems: 'center' },
              },
              onClick: () => {
                if (vacancyWebLink)
                  window.open(
                    `${vacancyWebLink}${VACANCY_UTM.PREVIEW}`,
                    '_blank',
                  );
              },
            },
          ],
        }),
      );

      // redirect to vacancy list page
      navigate('/vacancy?tab=OPEN');
    }
  }, [content, handleClose, handleOpenJobVacancy]);

  const handleOnHoldVacancy = useCallback(async () => {
    if (!content) return;
    const {
      vacancyId,
      vacancyCode,
      vacancyHighlighted,
      vacancyState,
      onCompleted,
    } = content;
    const data = await handleOnHoldJobVacancy(vacancyId);
    if (!data) return;

    mpe.vacancyUpdateStatus({
      vacancyCode,
      currentVacancyStatus: vacancyState ?? '',
      newVacancyStatus: EpJobVacancyState.OnHold,
      isPromoted: vacancyHighlighted ?? false,
    });

    onCompleted?.(handleClose, data);

    navigate('/vacancy?tab=ON_HOLD');

    dispatch(
      setSnackbar({
        layout: 'dashboard',
        severity: 'success',
        message: content?.vacancyHighlighted
          ? t('vacancy-detail:alert.onHoldPromotedJob')
          : t('vacancy-detail:alert.onHoldJob'),
      }),
    );
  }, [content, handleClose, handleOnHoldJobVacancy]);

  const handleCloseVacancy = useCallback(async () => {
    if (!content) return;
    const {
      vacancyId,
      vacancyCode,
      vacancyHighlighted,
      vacancyState,
      onCompleted,
    } = content;
    const data = await handleCloseJobVacancy(vacancyId);
    if (!data) return;

    mpe.vacancyUpdateStatus({
      vacancyCode,
      currentVacancyStatus: vacancyState ?? '',
      newVacancyStatus: EpJobVacancyState.Closed,
      isPromoted: vacancyHighlighted ?? false,
    });

    onCompleted?.(handleClose, data);

    navigate('/vacancy?tab=CLOSED');

    dispatch(
      setSnackbar({
        layout: 'dashboard',
        severity: 'success',
        message: content?.vacancyHighlighted
          ? t('vacancy-detail:alert.closePromotedJob')
          : t('vacancy-detail:alert.closeJob'),
      }),
    );

    dispatch(
      setDialog({
        vacancyClosedSurveyDialog: {
          open: true,
          content: {
            jobVacancyId: vacancyId,
            vacancyCode,
          },
        },
      }),
    );
  }, [content, handleClose, handleCloseJobVacancy]);

  const isLoading = isOpenLoading || isOnHoldLoading || isCloseLoading;
  const commonSubmitProps = {
    loading: isLoading,
  };

  const dataByAction = useMemo<
    Record<VacancyChangeStateDialogAction, VacancyChangeStateDialogActionData>
  >(() => {
    return {
      [EpJobVacancyState.Open]: {
        icon: <CheckCircleIcon />,
        title: t('vacancy-detail:dialog.open.title'),
        body: t('vacancy-detail:dialog.open.message'),
        submitProps: {
          onClick: handleOpenVacancyV3,
          color: 'primary',
          children: t('common:yesOpen'),
          'data-test-id': 'btnVacancyChangeStatusPopupConfirm',
          ...commonSubmitProps,
        },
      },
      [EpJobVacancyState.OnHold]: {
        icon: <StopCircleIcon />,
        title: content?.vacancyHighlighted
          ? t('vacancy-detail:dialog.onHoldHighlighted.title')
          : t('vacancy-detail:dialog.onHold.title'),
        body: content?.vacancyHighlighted
          ? t('vacancy-detail:dialog.onHoldHighlighted.message')
          : t('vacancy-detail:dialog.onHold.message'),
        submitProps: {
          onClick: handleOnHoldVacancy,
          color: 'error',
          children: t('common:yesOnHold'),
          'data-test-id': 'btnVacancyChangeStatusPopupConfirm',
          ...commonSubmitProps,
        },
      },
      [EpJobVacancyState.Closed]: {
        icon: <StopCircleIcon />,
        title: content?.vacancyHighlighted
          ? t('vacancy-detail:dialog.closeHighlighted.title')
          : t('vacancy-detail:dialog.close.title'),
        body: content?.vacancyHighlighted
          ? t('vacancy-detail:dialog.closeHighlighted.message')
          : t('vacancy-detail:dialog.close.message'),
        submitProps: {
          onClick: handleCloseVacancy,
          color: 'error',
          children: t('common:yesClose'),
          'data-test-id': 'btnVacancyChangeStatusPopupConfirm',
          ...commonSubmitProps,
        },
      },
    };
  }, [
    handleOpenVacancyV3,
    handleOpenVacancy,
    handleOnHoldVacancy,
    handleCloseVacancy,
    commonSubmitProps,
  ]);

  if (!content) return null;

  const data = dataByAction[content.action];

  return (
    <ConfirmDialogV1
      {...props}
      data-test-id="ctVacancyChangeStatusPopup"
      title={data.title}
      titleProps={{ 'data-test-id': 'lbVacancyChangeStatusPopupTitle' }}
      closeButtonProps={{
        'data-test-id': 'btnVacancyChangeStatusPopupClose',
        sx: {
          top: 21,
          right: 15,
          color: 'text.primary',
        },
      }}
      body={data.body}
      acceptProps={data.submitProps}
      cancelProps={{
        onClick: handleClose,
        children: t('common:cancelled'),
        'data-test-id': 'btnVacancyChangeStatusPopupCancel',
      }}
      PaperProps={{
        sx: {
          p: 3,
          maxWidth: 480,
          gap: 3,
          '& > div': { p: 0 },
          '& > div:last-of-type > button:last-of-type': { marginLeft: '12px' },
        },
      }}
    />
  );
};

export default VacancyChangeStateDialog;
