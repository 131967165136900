import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DropdownOption } from '~/types';
import { FETCH_STATUS } from '~/utils/constants';
import {
  EpEducationLevelFilterKeyValue,
  EpExperienceJobFunctions,
  EpExperienceYearDropdown,
  EpInterviewSessionDropdown,
  EpLocationAreas,
  EpUserSkills,
  GenderDropdownQuery,
  JobSpecializationRoleSkills,
  JobSpecializationRolesQuery,
  JobSpecializations,
} from '~/types/graphql/graphql';

type TCommonOptionsFilter = {
  page: number;
  keyword: string;
  positionName: string;
};

export type TCommonOptions = {
  locationAreas: Partial<EpLocationAreas> & {
    fetchStatus: FETCH_STATUS;
    data: DropdownOption[];
  };
  educationLevelFilter: Partial<EpEducationLevelFilterKeyValue> & {
    fetchStatus: FETCH_STATUS;
    data: DropdownOption[];
  };
  educationInstitutions: {
    fetchStatus: FETCH_STATUS;
    data: DropdownOption[];
    filter: {
      page: number;
      keyword: string;
    };
    hasNext: boolean;
  };
  educationPrograms: {
    fetchStatus: FETCH_STATUS;
    data: DropdownOption[];
    totalPage: number;
    hasNext: boolean;
    filter: {
      page: number;
      name: string;
      educationLevel: string;
    };
  };
  genderDropdown: Partial<GenderDropdownQuery> & {
    fetchStatus: FETCH_STATUS;
    data: DropdownOption[];
  };
  experienceYearDropdown: Partial<EpExperienceYearDropdown> & {
    fetchStatus: FETCH_STATUS;
    data: DropdownOption[];
  };
  experienceJobFunctions: Partial<EpExperienceJobFunctions> & {
    fetchStatus: FETCH_STATUS;
    data: DropdownOption[];
  };
  userSkills: Partial<EpUserSkills> & {
    fetchStatus: FETCH_STATUS;
    data: DropdownOption[];
  };
  jobSpecializations: Partial<JobSpecializations> & {
    fetchStatus: FETCH_STATUS;
    data: DropdownOption[];
  };
  jobSpecializationRoles: Partial<JobSpecializationRolesQuery> & {
    fetchStatus: FETCH_STATUS;
    data: DropdownOption[];
    totalPage: number;
    hasNext: boolean;
    filter: {
      page: number;
      keyword: string;
      positionName: string;
    };
  };
  jobSpecializationRoleSkills: Partial<JobSpecializationRoleSkills> & {
    fetchStatus: FETCH_STATUS;
    data: DropdownOption[];
    totalPage: number;
    hasNext: boolean;
    filter: {
      page: number;
      keyword: string;
    };
  };
  jobSpecializationRoleSkillSuggestions: Partial<JobSpecializationRoleSkills> & {
    fetchStatus: FETCH_STATUS;
    data: DropdownOption[];
  };
  documentsCertificates: {
    fetchStatus: FETCH_STATUS;
    data: DropdownOption[];
    hasNext: boolean;
    filter: {
      page: number;
      keyword: string;
    };
  };
  jobVacancyPositionNames: {
    fetchStatus: FETCH_STATUS;
    data: DropdownOption[];
    hasNext: boolean;
    filter: {
      page: number;
      keyword: string;
    };
  };
  invitationStatuses: {
    fetchStatus: FETCH_STATUS;
    data: DropdownOption[];
  };
  interviewSessions: {
    fetchStatus: FETCH_STATUS;
    data: DropdownOption<EpInterviewSessionDropdown>[];
  };
};

type MyIndexedObject<T> = Record<string, T>;

const initialState: TCommonOptions = {
  locationAreas: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
  },
  educationLevelFilter: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
  },
  educationInstitutions: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
    filter: {
      page: 0,
      keyword: '',
    },
    hasNext: false,
  },
  educationPrograms: {
    fetchStatus: FETCH_STATUS.IDLE,
    totalPage: 0,
    data: [],
    filter: {
      page: 0,
      name: '',
      educationLevel: '',
    },
    hasNext: false,
  },
  genderDropdown: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
  },
  experienceYearDropdown: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
  },
  experienceJobFunctions: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
  },
  userSkills: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
  },
  jobSpecializations: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
  },
  jobSpecializationRoles: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
    totalPage: 0,
    filter: {
      page: 0,
      keyword: '',
      positionName: '',
    },
    hasNext: false,
  },
  jobSpecializationRoleSkills: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
    totalPage: 0,
    filter: {
      page: 0,
      keyword: '',
    },
    hasNext: false,
  },
  jobSpecializationRoleSkillSuggestions: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
  },
  documentsCertificates: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
    filter: {
      page: 0,
      keyword: '',
    },
    hasNext: false,
  },
  jobVacancyPositionNames: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
    filter: {
      page: 0,
      keyword: '',
    },
    hasNext: false,
  },
  invitationStatuses: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
  },
  interviewSessions: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: [],
  },
};

export const commonOptionsSlice = createSlice({
  name: 'commonOptions',
  initialState,
  reducers: {
    setLocationAreas: (
      state,
      action: PayloadAction<Partial<TCommonOptions['locationAreas']>>,
    ) => {
      return {
        ...state,
        locationAreas: {
          ...state.locationAreas,
          ...action.payload,
        },
      };
    },
    setEducationLevelFilter: (
      state,
      action: PayloadAction<Partial<TCommonOptions['educationLevelFilter']>>,
    ) => {
      return {
        ...state,
        educationLevelFilter: {
          ...state.educationLevelFilter,
          ...action.payload,
        },
      };
    },
    setEducationInstitutions: (
      state,
      action: PayloadAction<Partial<TCommonOptions['educationInstitutions']>>,
    ) => {
      state.educationInstitutions = {
        ...state.educationInstitutions,
        ...action.payload,
      };
    },
    setEducationPrograms: (
      state,
      action: PayloadAction<Partial<TCommonOptions['educationPrograms']>>,
    ) => {
      state.educationPrograms = {
        ...state.educationPrograms,
        ...action.payload,
      };
    },
    setEducationProgramsFilter: (
      state,
      action: PayloadAction<
        Partial<TCommonOptions['educationPrograms']['filter']>
      >,
    ) => {
      return {
        ...state,
        educationPrograms: {
          ...state.educationPrograms,
          filter: {
            ...state.educationPrograms.filter,
            ...action.payload,
          },
        },
      };
    },
    setGenderDropdown: (
      state,
      action: PayloadAction<Partial<TCommonOptions['genderDropdown']>>,
    ) => {
      return {
        ...state,
        genderDropdown: {
          ...state.genderDropdown,
          ...action.payload,
        },
      };
    },
    setExperienceYearDropdown: (
      state,
      action: PayloadAction<Partial<TCommonOptions['experienceYearDropdown']>>,
    ) => {
      return {
        ...state,
        experienceYearDropdown: {
          ...state.experienceYearDropdown,
          ...action.payload,
        },
      };
    },
    setExperienceJobFunctions: (
      state,
      action: PayloadAction<Partial<TCommonOptions['experienceJobFunctions']>>,
    ) => {
      return {
        ...state,
        experienceJobFunctions: {
          ...state.experienceJobFunctions,
          ...action.payload,
        },
      };
    },
    setUserSkills: (
      state,
      action: PayloadAction<Partial<TCommonOptions['userSkills']>>,
    ) => {
      return {
        ...state,
        userSkills: {
          ...state.userSkills,
          ...action.payload,
        },
      };
    },
    setJobSpecializations: (
      state,
      action: PayloadAction<Partial<TCommonOptions['jobSpecializations']>>,
    ) => {
      return {
        ...state,
        jobSpecializations: {
          ...state.jobSpecializations,
          ...action.payload,
        },
      };
    },
    setJobSpecializationsSearch: (
      state,
      action: PayloadAction<Partial<TCommonOptions['jobSpecializations']>>,
    ) => {
      if (action.payload.data?.length) {
        const uniqueIds: MyIndexedObject<boolean> = {};
        const combinedJobSpecializations = [
          ...state.jobSpecializations.data,
          ...action.payload.data,
        ];
        const uniqueJobSpecializations = combinedJobSpecializations.filter(
          (obj) => {
            // Use the 'value' property as the key to check uniqueness
            if (obj?.value && !uniqueIds[obj?.value]) {
              uniqueIds[obj?.value] = true;
              return true;
            }
            return false;
          },
        );

        return {
          ...state,
          jobSpecializations: {
            ...state.jobSpecializations,
            ...action.payload,
            data: uniqueJobSpecializations,
          },
        };
      }
    },
    setJobSpecializationRoles: (
      state,
      action: PayloadAction<Partial<TCommonOptions['jobSpecializationRoles']>>,
    ) => {
      return {
        ...state,
        jobSpecializationRoles: {
          ...state.jobSpecializationRoles,
          ...action.payload,
        },
      };
    },
    setJobSpecializationRolesFilter: (
      state,
      action: PayloadAction<Partial<TCommonOptionsFilter>>,
    ) => {
      return {
        ...state,
        jobSpecializationRoles: {
          ...state.jobSpecializationRoles,
          filter: {
            ...state.jobSpecializationRoles.filter,
            ...action.payload,
          },
        },
      };
    },
    setJobSpecializationRolesMore: (
      state,
      action: PayloadAction<Partial<TCommonOptions['jobSpecializationRoles']>>,
    ) => {
      return {
        ...state,
        jobSpecializationRoles: {
          ...state.jobSpecializationRoles,
          ...action.payload,
          fetchStatus: FETCH_STATUS.RESOLVED,
          data: [
            ...state.jobSpecializationRoles.data,
            ...(action.payload.data || []),
          ],
        },
      };
    },
    setJobSpecializationRolesSearch: (
      state,
      action: PayloadAction<Partial<TCommonOptions['jobSpecializationRoles']>>,
    ) => {
      if (action.payload.data) {
        const uniqueIds: MyIndexedObject<boolean> = {};
        const combinedJobSpecializationRoles = [
          ...state.jobSpecializationRoles.data,
          ...action.payload.data,
        ];
        const uniqueJobSpecializationRoles =
          combinedJobSpecializationRoles.filter((obj) => {
            // Use the 'value' property as the key to check uniqueness
            if (obj?.value && !uniqueIds[obj?.value]) {
              uniqueIds[obj?.value] = true;
              return true;
            }
            return false;
          });

        return {
          ...state,
          jobSpecializationRoles: {
            ...state.jobSpecializationRoles,
            ...action.payload,
            data: uniqueJobSpecializationRoles,
          },
        };
      }
    },
    setJobSpecializationRoleSkills: (
      state,
      action: PayloadAction<
        Partial<TCommonOptions['jobSpecializationRoleSkills']>
      >,
    ) => {
      return {
        ...state,
        jobSpecializationRoleSkills: {
          ...state.jobSpecializationRoleSkills,
          ...action.payload,
        },
      };
    },
    setJobSpecializationRoleSkillsFilter: (
      state,
      action: PayloadAction<Partial<TCommonOptionsFilter>>,
    ) => {
      return {
        ...state,
        jobSpecializationRoleSkills: {
          ...state.jobSpecializationRoleSkills,
          filter: {
            ...state.jobSpecializationRoleSkills.filter,
            ...action.payload,
          },
        },
      };
    },
    setJobSpecializationRoleSkillSuggestions: (
      state,
      action: PayloadAction<
        Partial<TCommonOptions['jobSpecializationRoleSkillSuggestions']>
      >,
    ) => {
      return {
        ...state,
        jobSpecializationRoleSkillSuggestions: {
          ...state.jobSpecializationRoleSkillSuggestions,
          ...action.payload,
        },
      };
    },
    setDocumentsCertificates: (
      state,
      action: PayloadAction<Partial<TCommonOptions['documentsCertificates']>>,
    ) => {
      return {
        ...state,
        documentsCertificates: {
          ...state.documentsCertificates,
          ...action.payload,
        },
      };
    },
    setDocumentsCertificatesMore: (
      state,
      action: PayloadAction<TCommonOptions['documentsCertificates']>,
    ) => {
      const combinedDocumentsCertificates = [
        ...state.documentsCertificates.data,
        ...action.payload.data,
      ];

      return {
        ...state,
        documentsCertificates: {
          ...state.documentsCertificates,
          ...action.payload,
          data: combinedDocumentsCertificates,
        },
      };
    },
    setJobVacancyPositionNames: (
      state,
      action: PayloadAction<Partial<TCommonOptions['jobVacancyPositionNames']>>,
    ) => {
      return {
        ...state,
        jobVacancyPositionNames: {
          ...state.jobVacancyPositionNames,
          ...action.payload,
        },
      };
    },
    setJobVacancyPositionNamesMore: (
      state,
      action: PayloadAction<TCommonOptions['jobVacancyPositionNames']>,
    ) => {
      const combinedJobVacancyPositionNames = [
        ...state.jobVacancyPositionNames.data,
        ...action.payload.data,
      ];

      return {
        ...state,
        jobVacancyPositionNames: {
          ...state.jobVacancyPositionNames,
          ...action.payload,
          data: combinedJobVacancyPositionNames,
        },
      };
    },
    setInvitationStatuses: (
      state,
      action: PayloadAction<Partial<TCommonOptions['invitationStatuses']>>,
    ) => {
      return {
        ...state,
        invitationStatuses: {
          ...state.invitationStatuses,
          ...action.payload,
        },
      };
    },
    setInterviewSessions: (
      state,
      action: PayloadAction<Partial<TCommonOptions['interviewSessions']>>,
    ) => {
      return {
        ...state,
        interviewSessions: {
          ...state.interviewSessions,
          ...action.payload,
        },
      };
    },
    clearJobSpecializationRole: (state) => {
      return {
        ...state,
        jobSpecializationRoles: initialState.jobSpecializationRoles,
      };
    },
    clearEducationPrograms: (state) => {
      return {
        ...state,
        educationPrograms: initialState.educationPrograms,
      };
    },
    clearDocumentsCertificates: (state) => {
      return {
        ...state,
        documentsCertificates: initialState.documentsCertificates,
      };
    },
    clearProfileFilterDropdown: (state) => {
      return {
        ...state,
        locationAreas: {
          ...initialState.locationAreas,
        },
        educationLevelFilter: {
          ...initialState.educationLevelFilter,
        },
        genderDropdown: {
          ...initialState.genderDropdown,
        },
        experienceYearDropdown: {
          ...initialState.experienceYearDropdown,
        },
      };
    },
  },
});

export const {
  setLocationAreas,
  setEducationLevelFilter,
  setEducationInstitutions,
  setEducationPrograms,
  setEducationProgramsFilter,
  setGenderDropdown,
  setExperienceYearDropdown,
  setExperienceJobFunctions,
  setUserSkills,
  setJobSpecializations,
  setJobSpecializationsSearch,
  setJobSpecializationRoles,
  setJobSpecializationRolesFilter,
  setJobSpecializationRolesMore,
  setJobSpecializationRolesSearch,
  setJobSpecializationRoleSkills,
  setJobSpecializationRoleSkillsFilter,
  setJobSpecializationRoleSkillSuggestions,
  setDocumentsCertificates,
  setDocumentsCertificatesMore,
  clearEducationPrograms,
  clearJobSpecializationRole,
  clearDocumentsCertificates,
  clearProfileFilterDropdown,
  setJobVacancyPositionNames,
  setJobVacancyPositionNamesMore,
  setInvitationStatuses,
  setInterviewSessions,
} = commonOptionsSlice.actions;

export default commonOptionsSlice.reducer;
