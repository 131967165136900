import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  VacancyHighlightDialogContent,
  VacancyHighlightDialogAction,
} from '~/components/GlobalDialogs/VacancyHighlightDialog';
import {
  MUTATION_ACTIVATE_JOB_VACANCY_HIGHLIGHT_V2,
  MUTATION_DEACTIVATE_JOB_VACANCY_HIGHLIGHT_V2,
} from '~/graphql/vacancy';
import { setDialog } from '~/store/views/dialogs-slice';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { Nullable } from '~/types';
import {
  ActivateJobVacancyHighlightV2Mutation,
  ActivateJobVacancyHighlightV2MutationVariables,
  DeactivateJobVacancyHighlightV2Mutation,
  DeactivateJobVacancyHighlightV2MutationVariables,
  EpActivateJobVacancyHighlightV2ErrorEnum,
} from '~/types/graphql/graphql';
import usePremium from './firebase/use-premium';
import { useAppDispatch, useAppSelector } from './use-store';

export const useActivateJobVacancyHighlight = () => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<Nullable<string>>();
  const { t } = useTranslation();

  const [activateVacancyV2, { loading }] = useMutation<
    ActivateJobVacancyHighlightV2Mutation,
    ActivateJobVacancyHighlightV2MutationVariables
  >(MUTATION_ACTIVATE_JOB_VACANCY_HIGHLIGHT_V2);

  const handleActivateVacancy = useCallback(async (vacancyId: string) => {
    try {
      const { data } = await activateVacancyV2({
        variables: { id: vacancyId },
      });
      if (
        data?.activateJobVacancyHighlightV2.__typename ===
        'EpActivateJobVacancyHighlightV2Error'
      ) {
        if (
          data.activateJobVacancyHighlightV2.typeError ===
          EpActivateJobVacancyHighlightV2ErrorEnum.AlreadyPromoted
        ) {
          dispatch(
            setSnackbar({
              severity: 'error',
              message: t('vacancy:activatePromoteError.alreadyPromoted'),
            }),
          );
        }
      }
      return {
        isSuccess:
          data?.activateJobVacancyHighlightV2.__typename ===
          'EpJobVacancyV3IdResponse',
      };
    } catch (err: any) {
      setError(err.message);
      dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'error',
          message: err.message,
        }),
      );
    }
  }, []);

  return { handleActivateVacancy, isLoading: loading, error };
};

export const useDeactivateJobVacancyHighlight = () => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<Nullable<string>>();

  const [deactivateVacancyV2, { loading: loading }] = useMutation<
    DeactivateJobVacancyHighlightV2Mutation,
    DeactivateJobVacancyHighlightV2MutationVariables
  >(MUTATION_DEACTIVATE_JOB_VACANCY_HIGHLIGHT_V2);

  const handleDeactivateVacancy = useCallback(async (vacancyId: string) => {
    try {
      const { data } = await deactivateVacancyV2({
        variables: { id: vacancyId },
      });
      return { isSuccess: !!data?.deactivateJobVacancyHighlightV2 };
    } catch (err: any) {
      setError(err.message);
      dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'error',
          message: err.message,
        }),
      );
    }
  }, []);

  return { handleDeactivateVacancy, isLoading: loading, error };
};

export const useJobVacancyHighlightComposer = () => {
  const dispatch = useAppDispatch();
  const realtimeData = useAppSelector((state) => state.realtimeData);
  const [error, setError] = useState<Nullable<string>>();
  const [isLoading, setIsLoading] = useState(false);
  const { hasJobPromoteQuotaLeft } = usePremium();

  const handleVacancyHighlight = useCallback(
    async (
      action: VacancyHighlightDialogAction,
      vacancy: VacancyHighlightDialogContent['vacancy'],
      onCompleted?: VacancyHighlightDialogContent['onCompleted'],
    ) => {
      try {
        setIsLoading(true);
        let isHighlightQuotaAvailable = true;
        // validate is company able to do more highlight
        if (action === 'activate') {
          isHighlightQuotaAvailable = hasJobPromoteQuotaLeft;
        }

        // if it's allowed show confirmation modal
        if (isHighlightQuotaAvailable) {
          if (action === 'activate') {
            return dispatch(
              setDialog({
                vacancyHighlightDialog: {
                  open: true,
                  content: {
                    action,
                    vacancy,
                    onCompleted,
                  },
                },
              }),
            );
          } else {
            return dispatch(
              setDialog({
                vacancyUnHighlightDialog: {
                  open: true,
                  content: {
                    action,
                    vacancy,
                    onCompleted,
                  },
                },
              }),
            );
          }
        }

        // if it's not, show limit quota modal
        dispatch(
          setDialog({ vacancyHighlightQuotaExceededDialog: { open: true } }),
        );
      } catch (err: any) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    },
    [realtimeData, hasJobPromoteQuotaLeft],
  );

  return { handleVacancyHighlight, isLoading, error };
};
