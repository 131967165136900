import { lazy, Suspense } from 'react';
import { Layout as DashboardLayout, MainContent } from '@kitalulus/web-ui-kit';
import { Navigate, Route, Routes } from 'react-router-dom';

import Layout from '~/components/Layout';
import LoadingPage from '~/components/LoadingPage';
import { useRemoteConfigValue } from '~/hooks/firebase/use-remote-config';

import DashboardPrivacy from '~/pages/Privacy/DashboardPrivacy';
import { ACCOUNT_TABS } from '~/utils/constants';
import { useFeatureFlag } from '~/hooks/firebase/use-feature-flag';
import ErrorBoundary from '~/components/ErrorBoundary';

const Home = lazy(() => import('~/pages/Home'));
const VacancyList = lazy(() => import('~/pages/VacancyList'));
const VacancyCreate = lazy(() => import('~/pages/VacancyCreate'));
const VacancyUpdate = lazy(() => import('~/pages/VacancyUpdate'));
const VacancyRepost = lazy(() => import('~/pages/VacancyRepost'));
const VacancyCopy = lazy(() => import('~/pages/VacancyCopy'));
const VacancyDetail = lazy(() => import('~/pages/VacancyDetail'));
const CandidateRecommendationDetail = lazy(
  () => import('~/pages/CandidateRecommendationDetail'),
);
const CandidateRecommendationList = lazy(
  () => import('~/pages/CandidateRecommendationList'),
);
const CandidateRecommendationListV2 = lazy(
  () => import('~/pages/CandidateRecommendationListV2'),
);
const CandidateRecommendationVacancy = lazy(
  () => import('~/pages/CandidateRecommendationVacancy'),
);

const InterviewList = lazy(() => import('~/pages/InterviewList'));
const InterviewListDetail = lazy(() => import('~/pages/InterviewListDetail'));
const Account = lazy(() => import('~/pages/Account'));
const ChangePassword = lazy(() => import('~/pages/AccountChangePassword'));
const Profile = lazy(() => import('~/pages/AccountProfile'));
const AccountHr = lazy(() => import('~/pages/AccountHr'));
const AccountPackageDetailV2 = lazy(
  () => import('~/pages/AccountPackageDetailV2'),
);

const Applicants = lazy(() => import('~/pages/Applicants'));
const ApplicantDetail = lazy(() => import('~/pages/ApplicantDetail'));

const DashboardRoutes = () => {
  const isMonetizationActive = useRemoteConfigValue('ff_monetization');
  const isCandidateRecommendationActive = useRemoteConfigValue(
    'ff_candidate_recommendation',
  );
  const isCandidateRecommendationV2Active = useFeatureFlag(
    'candidate_recommendation_v2',
  );
  const isInterviewInvitationActive = useFeatureFlag('interview_invitation');

  return (
    <DashboardLayout>
      <ErrorBoundary type="screen">
        <Layout>
          <Suspense
            fallback={
              <MainContent sx={{ main: { height: '100%' } }} height="100vh">
                <LoadingPage height="100%" width="100%" variant="inline" />
              </MainContent>
            }
          >
            <Routes>
              <Route path="dashboard" element={<Home />} />
              <Route path="vacancy" element={<VacancyList />} />
              <Route path="vacancy/create" element={<VacancyCreate />} />
              <Route path="vacancy/create/:id" element={<VacancyCopy />} />
              <Route path="vacancy/:id" element={<VacancyDetail />} />
              <Route path="vacancy/:id/update" element={<VacancyUpdate />} />
              <Route path="vacancy/:id/repost" element={<VacancyRepost />} />
              <Route path="applicants" element={<Applicants />} />
              <Route path="applicants/detail" element={<ApplicantDetail />} />
              {isInterviewInvitationActive && (
                <>
                  <Route path="interview" element={<InterviewList />} />
                  <Route
                    path="interview/:id"
                    element={<InterviewListDetail />}
                  />
                </>
              )}
              {isCandidateRecommendationActive && (
                <>
                  <Route
                    path="candidate-recommendation"
                    element={<CandidateRecommendationVacancy />}
                  />
                  <Route
                    path="candidate-recommendation/list"
                    element={
                      isCandidateRecommendationV2Active ? (
                        <CandidateRecommendationListV2 />
                      ) : (
                        <CandidateRecommendationList />
                      )
                    }
                  />
                  <Route
                    path="candidate-recommendation/list/:id"
                    element={
                      isCandidateRecommendationV2Active ? (
                        <CandidateRecommendationListV2 />
                      ) : (
                        <CandidateRecommendationList />
                      )
                    }
                  />
                  <Route
                    path="candidate-recommendation/list/:id/detail/:candidateID"
                    element={<CandidateRecommendationDetail />}
                  />
                </>
              )}
              <Route path="account" element={<Account />}>
                <Route index element={<Profile />} />
                <Route
                  path={ACCOUNT_TABS.PROFILE.toLowerCase()}
                  element={<Profile />}
                />
                <Route
                  path={ACCOUNT_TABS.USER_PROFILE.toLowerCase()}
                  element={<AccountHr />}
                />
                <Route
                  path={ACCOUNT_TABS.CHANGE_PASSWORD.toLowerCase()}
                  element={<ChangePassword />}
                />
                {isMonetizationActive && (
                  <Route
                    path={ACCOUNT_TABS.PACKAGE_DETAIL.toLowerCase()}
                    element={<AccountPackageDetailV2 />}
                  />
                )}
                {/* <Route
                path={ACCOUNT_TABS.NOTIFICATION.toLowerCase()}
                element={<AccountNotification />}
              /> */}
              </Route>
              <Route path="privacy" element={<DashboardPrivacy />} />
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
          </Suspense>
        </Layout>
      </ErrorBoundary>
    </DashboardLayout>
  );
};

export default DashboardRoutes;
