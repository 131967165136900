import { gql } from '~/types/graphql/gql';

export const QUERY_COMPANY = gql(`
  query company {
    company: epCompany {
      logo {
        url
        id
      }
      legalDocument {
        url
        id
      }
      id
      name
      code
      companyType
      accManager
      displayName
      description
      contactPhone
      contactWhatsapp
      contactEmail
      contactWeblink
      foundedYear
      nib
      legalDocumentUrl
      logoUrl
      postalCode
      address
      verificationStatus
      verificationStatusStr
      verificationStatusNotice
      verificationStatusDisplayStr
      jobCompanyIndustry {
        id
        name
      }
      jobCompanySize {
        id
        name
        size
      }
      locationProvince {
        id
        name
      }
      locationCity {
        id
        name
      }
      locationDistrict {
        id
        name
      }
    }
  }
`);

export const QUERY_COMPANY_ACC_MANAGER = gql(`
  query companyAccManager($companyId: ID) {
    companyAccManager: epCompanyAccManager(companyId: $companyId) {
      accManager
      accManagerWhatsapp
      accManagerCopy
      accManagerDashboardCopy
      accManagerErrorCopy
      accManagerRegisterCopy
      accManagerRegisterCopyV2
    }
  }
`);

export const MUTATION_UPDATE_COMPANY = gql(`
  mutation updateCompany($payload: EpInputUpdateCompany) {
    updateCompany: epUpdateCompany(payload: $payload) {
      data
      error
    }
  }
`);

export const MUTATION_REGISTER_COMPANY_V2 = gql(`
  mutation registerCompanyV2($payload: EpRegisterCompanyInputV2) {
    registerCompanyV2: epRegisterCompanyV2(payload: $payload) {
      ... on EpRegisterCompanyV2Error {
        message
        typeError
      }
      ... on EpRegisterCompanyV2Response {
        id
        name
      }
    }
  }
`);
