import { LabelProps } from '@kitalulus/web-ui-kit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DropdownOption } from '~/types';
import { EpJobVacanciesV2, EpJobVacancyState } from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

export type VacancySummaryState = {
  fetchStatus: FETCH_STATUS;
  value: EpJobVacancyState;
  count: number;
  label: string;
  tooltip?: string;
  countLabelprops?:
    | LabelProps
    | ((opts: { isSelected: boolean }) => LabelProps);
};

export type VacancyState = {
  list: Partial<EpJobVacanciesV2> & {
    fetchStatus: FETCH_STATUS;
    isLoading: boolean;
    selectedCity?: DropdownOption | null;
  };
  summary: {
    summaries: VacancySummaryState[];
    fetchStatus: FETCH_STATUS;
    isLoading: boolean;
    fetchCount: number;
    isFirstFetch: boolean;
  };
};

export const vacancyDefaultValue: VacancyState = {
  list: {
    fetchStatus: FETCH_STATUS.IDLE,
    isLoading: false,
    selectedCity: null,
  },
  summary: {
    summaries: [],
    fetchStatus: FETCH_STATUS.IDLE,
    isLoading: false,
    fetchCount: 0,
    isFirstFetch: false,
  },
};

export const vacancyListSlice = createSlice({
  name: 'vacancyList',
  initialState: vacancyDefaultValue,
  reducers: {
    setVacancyList: (
      state,
      action: PayloadAction<Partial<VacancyState['list']>>,
    ) => ({
      ...state,
      list: {
        ...state.list,
        ...action.payload,
      },
    }),
    setVacancySummary: (
      state,
      action: PayloadAction<Partial<VacancyState['summary']>>,
    ) => ({
      ...state,
      summary: {
        ...state.summary,
        ...action.payload,
        isFirstFetch: Boolean(state.summary.fetchCount <= 0),
        fetchCount: state.summary.fetchCount + 1,
      },
    }),
    clearVacancyList: () => vacancyDefaultValue,
  },
});

export const { setVacancyList, setVacancySummary, clearVacancyList } =
  vacancyListSlice.actions;

export default vacancyListSlice.reducer;
