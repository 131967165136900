import type { History, Transition } from 'history';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import useDependentState from './use-dependent-state';

export type NavigationBlockPromptCallback = (tx: Transition) => void;

export const useNavigationBlockPrompt = (
  // this callback is invoked when the block status is true
  // and user tries to navigate to the other pages
  // we can pass either global or local state to control prompt state
  cb?: NavigationBlockPromptCallback,
  defaultIsBlocked = false,
) => {
  const [isBlocked, setIsBlocked] = useDependentState(defaultIsBlocked);

  const navigation = useContext(NavigationContext);
  const navigator = navigation.navigator as History;

  const transition = useRef<Transition | null>(null);
  const unblock = useRef<VoidFunction | null>(null);

  const retry = useCallback(() => {
    unblock.current?.();
    transition.current?.retry();
    transition.current = null;
  }, []);

  useEffect(() => {
    if (!isBlocked) return;
    unblock.current = navigator.block((tx) => {
      transition.current = tx;
      cb?.(tx);
    });

    return () => {
      unblock.current?.();
      unblock.current = null;
    };
  }, [isBlocked, cb]);

  return { isBlocked, setIsBlocked, retry };
};
