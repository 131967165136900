import { useAppDispatch } from '../use-store';
import { OpenVacancyShareDialogProps } from '~/components/GlobalDialogs/VacancyShareDialog';
import { clearDialog, setDialog } from '~/store/views/dialogs-slice';

export const useVacancyShareDialog = () => {
  const dispatch = useAppDispatch();

  const openVacancyShareDialog = ({
    content,
    onClose,
  }: OpenVacancyShareDialogProps) => {
    dispatch(
      setDialog({
        vacancyShareDialog: {
          open: true,
          content,
          onClose,
        },
      }),
    );
  };

  const closeVacancyShareDialog = () =>
    dispatch(clearDialog('vacancyShareDialog'));

  return { openVacancyShareDialog, closeVacancyShareDialog };
};
