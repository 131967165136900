/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigInt: { input: any; output: any };
  /** An RFC-3339 compliant Full Date Scalar */
  Date: { input: any; output: any };
  DateEpochTime: { input: any; output: any };
  /** A slightly refined version of RFC-3339 compliant DateTime Scalar */
  DateTime: { input: any; output: any };
  /** A custom scalar that represents files */
  Upload: { input: any; output: any };
};

export type AbandonmentReason = {
  abandonmentReasonOptionId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type AbandonmentReasonOption = {
  __typename?: 'AbandonmentReasonOption';
  category?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  ordering?: Maybe<Scalars['Int']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

export type AbandonmentReasonSubmitError = {
  __typename?: 'AbandonmentReasonSubmitError';
  message: Scalars['String']['output'];
  type: AbandonmentReasonSubmitErrorType;
};

export enum AbandonmentReasonSubmitErrorType {
  AlreadySubmitError = 'ALREADY_SUBMIT_ERROR',
  GenericError = 'GENERIC_ERROR',
  UnknownError = 'UNKNOWN_ERROR',
}

export type AbandonmentReasonSubmitResponse =
  | AbandonmentReasonSubmitError
  | AbandonmentReasonSubmitSuccess;

export type AbandonmentReasonSubmitSuccess = {
  __typename?: 'AbandonmentReasonSubmitSuccess';
  message: Scalars['String']['output'];
};

export type AboutMeUpdateInput = {
  about?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type AboutMeUpdateInputV2 = {
  about?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
};

export type AcceptInterviewInvitationError = {
  __typename?: 'AcceptInterviewInvitationError';
  errorCode?: Maybe<AcceptInterviewInvitationErrorEnum>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum AcceptInterviewInvitationErrorEnum {
  InvitationNotFound = 'INVITATION_NOT_FOUND',
  UserInvitationInvalid = 'USER_INVITATION_INVALID',
}

export type AcceptInterviewInvitationResult =
  | AcceptInterviewInvitationError
  | AcceptInterviewInvitationSuccess;

export type AcceptInterviewInvitationSuccess = {
  __typename?: 'AcceptInterviewInvitationSuccess';
  message?: Maybe<Scalars['String']['output']>;
};

export type AnalyticDataInput = {
  element: Scalars['String']['input'];
  eventName: Scalars['String']['input'];
  eventType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  params: Array<InputMaybe<KeyValueInput>>;
  tag: Scalars['String']['input'];
};

export type Answer = {
  __typename?: 'Answer';
  id?: Maybe<Scalars['ID']['output']>;
  idxStr?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isLatex?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type AppVersion = {
  __typename?: 'AppVersion';
  isNeedUpdate?: Maybe<Scalars['Boolean']['output']>;
  playStoreVersion?: Maybe<Scalars['String']['output']>;
  versionCode?: Maybe<Scalars['String']['output']>;
  versionName?: Maybe<Scalars['String']['output']>;
};

export type ApplicationDashboard = {
  __typename?: 'ApplicationDashboard';
  bookmark?: Maybe<Scalars['Int']['output']>;
  close?: Maybe<Scalars['Int']['output']>;
  isBadgeActive?: Maybe<Scalars['Boolean']['output']>;
  open?: Maybe<Scalars['Int']['output']>;
  userID?: Maybe<Scalars['ID']['output']>;
};

export type ApplicationFeedbackTemplate = {
  __typename?: 'ApplicationFeedbackTemplate';
  options?: Maybe<Array<Maybe<ApplicationFeedbackTemplateOption>>>;
  question?: Maybe<ApplicationFeedbackTemplateQuestion>;
};

export type ApplicationFeedbackTemplateOption = {
  __typename?: 'ApplicationFeedbackTemplateOption';
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  inputType?: Maybe<Scalars['String']['output']>;
  isClosing?: Maybe<Scalars['Boolean']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
};

export type ApplicationFeedbackTemplateQuestion = {
  __typename?: 'ApplicationFeedbackTemplateQuestion';
  category?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  inputType?: Maybe<Scalars['String']['output']>;
  isClosing?: Maybe<Scalars['Boolean']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
};

export type ApplicationLabel = {
  __typename?: 'ApplicationLabel';
  bgColor?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isShownDescription?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  textColor?: Maybe<Scalars['String']['output']>;
  tooltip?: Maybe<Scalars['String']['output']>;
};

export type ApplicationPreview = {
  __typename?: 'ApplicationPreview';
  company?: Maybe<ApplicationPreviewCompany>;
  companyId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  jobApplication?: Maybe<ApplicationPreviewJobApplication>;
  meta?: Maybe<ApplicationPreviewMeta>;
  profile?: Maybe<ApplicationPreviewProfile>;
  profileUnLocked?: Maybe<ApplicationPreviewProfileUnLocked>;
  status?: Maybe<ApplicationPreviewStatusEnum>;
  userId?: Maybe<Scalars['ID']['output']>;
  vacancy?: Maybe<JobVacancyPreview>;
  vacancyId?: Maybe<Scalars['ID']['output']>;
};

export type ApplicationPreviewCompany = {
  __typename?: 'ApplicationPreviewCompany';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ApplicationPreviewJobApplication = {
  __typename?: 'ApplicationPreviewJobApplication';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  interestedStatus?: Maybe<JobApplicationPreviewInterestEnum>;
  isRead: Scalars['Boolean']['output'];
  jobVacancyPositionName: Scalars['String']['output'];
};

export type ApplicationPreviewMeta = {
  __typename?: 'ApplicationPreviewMeta';
  companyId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  image: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ApplicationPreviewProfile = {
  __typename?: 'ApplicationPreviewProfile';
  age?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedAtStr?: Maybe<Scalars['String']['output']>;
};

export type ApplicationPreviewProfileUnLocked = {
  __typename?: 'ApplicationPreviewProfileUnLocked';
  age?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  educations?: Maybe<Array<Maybe<EpEducation>>>;
  experiences?: Maybe<Array<Maybe<EpExperience>>>;
  id: Scalars['ID']['output'];
  links?: Maybe<Array<Maybe<EpPreviewSupportLink>>>;
  name?: Maybe<Scalars['String']['output']>;
  skills?: Maybe<Array<Maybe<EpUserSkillV2>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum ApplicationPreviewStatusEnum {
  Expired = 'EXPIRED',
  Locked = 'LOCKED',
  Unlocked = 'UNLOCKED',
  UnlockedLimited = 'UNLOCKED_LIMITED',
}

export type ApplyErrorCopy = ErrorModal & {
  __typename?: 'ApplyErrorCopy';
  content?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum ApplyVacancyErrorEnum {
  ApplyLimitExceeded = 'APPLY_LIMIT_EXCEEDED',
  CommonError = 'COMMON_ERROR',
  InvitationNotFound = 'INVITATION_NOT_FOUND',
  OutsideWorkingHoursError = 'OUTSIDE_WORKING_HOURS_ERROR',
  UserInvitationInvalid = 'USER_INVITATION_INVALID',
}

export type ApplyVacancyErrorResponse = {
  __typename?: 'ApplyVacancyErrorResponse';
  errorCode?: Maybe<ApplyVacancyErrorEnum>;
  message?: Maybe<ApplyErrorCopy>;
};

export type ApplyVacancyResult = {
  __typename?: 'ApplyVacancyResult';
  contact?: Maybe<VacancyContactInformation>;
  totalUserApplication?: Maybe<Scalars['Int']['output']>;
  whatsappContent?: Maybe<WhatsappContent>;
};

export type ApplyVacancyResultV2 =
  | ApplyVacancyErrorResponse
  | ApplyVacancyResult;

export type Bank = {
  __typename?: 'Bank';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Banks = {
  __typename?: 'Banks';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<Bank>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type Banner = {
  __typename?: 'Banner';
  actionType?: Maybe<Scalars['String']['output']>;
  actionUrl?: Maybe<Scalars['String']['output']>;
  bannerType?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  expiryTime?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageBanner?: Maybe<Scalars['String']['output']>;
  imageHighlight?: Maybe<Scalars['String']['output']>;
  isHighlight?: Maybe<Scalars['Boolean']['output']>;
  isInternalApp?: Maybe<Scalars['Boolean']['output']>;
  isPublish?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type BannerRules = {
  __typename?: 'BannerRules';
  appVersion?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isAndroid?: Maybe<Scalars['Boolean']['output']>;
  isCompleteness?: Maybe<Scalars['Boolean']['output']>;
  isIos?: Maybe<Scalars['Boolean']['output']>;
  isLogin?: Maybe<Scalars['Boolean']['output']>;
  isWeb?: Maybe<Scalars['Boolean']['output']>;
};

export type Bill = {
  __typename?: 'Bill';
  autoPayments?: Maybe<Array<Maybe<OrderBillAutoPayment>>>;
  billAmount?: Maybe<Scalars['Float']['output']>;
  billNo?: Maybe<Scalars['String']['output']>;
  createdAtLocale?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountAmountStr?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  dueDateLocale?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  orderId?: Maybe<Scalars['ID']['output']>;
  orderRef?: Maybe<Scalars['String']['output']>;
  paidBillStr?: Maybe<Scalars['String']['output']>;
  remark?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['String']['output']>;
  voucherCode?: Maybe<Scalars['String']['output']>;
};

export type Bills = {
  __typename?: 'Bills';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<Bill>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type Cart = {
  __typename?: 'Cart';
  skus?: Maybe<Array<Maybe<SkuCart>>>;
  total?: Maybe<Scalars['Float']['output']>;
  totalStr?: Maybe<Scalars['String']['output']>;
  voucher?: Maybe<VoucherApplied>;
};

export type CategoryView = {
  __typename?: 'CategoryView';
  category?: Maybe<SkuCategory>;
  types?: Maybe<Array<Maybe<SkuCategory>>>;
};

export type Channel = {
  __typename?: 'Channel';
  channelName?: Maybe<Scalars['String']['output']>;
  isWaiting?: Maybe<Scalars['Boolean']['output']>;
  role?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  warning?: Maybe<Scalars['String']['output']>;
  whiteboard?: Maybe<Whiteboard>;
};

export type ChargeMidtrans = {
  __typename?: 'ChargeMidtrans';
  expiredAt?: Maybe<Scalars['String']['output']>;
  expiredAtStr?: Maybe<Scalars['String']['output']>;
  midtransOrderId?: Maybe<Scalars['String']['output']>;
  paymentCode?: Maybe<Scalars['String']['output']>;
};

export type Checkout = {
  __typename?: 'Checkout';
  checkoutTime?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Customer>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountAmountStr?: Maybe<Scalars['String']['output']>;
  orderNo?: Maybe<Scalars['String']['output']>;
  paymentPlan?: Maybe<Scalars['String']['output']>;
  skus?: Maybe<Array<Maybe<SkuCart>>>;
  subTotal?: Maybe<Scalars['Float']['output']>;
  subTotalStr?: Maybe<Scalars['String']['output']>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  totalPriceStr?: Maybe<Scalars['String']['output']>;
  voucher?: Maybe<VoucherApplied>;
};

export type ClaimFormOption = {
  __typename?: 'ClaimFormOption';
  parent?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type ClaimFormRules = {
  __typename?: 'ClaimFormRules';
  description?: Maybe<Scalars['String']['output']>;
  inputType?: Maybe<Scalars['String']['output']>;
  regex?: Maybe<Scalars['String']['output']>;
  required: Scalars['Boolean']['output'];
};

export type ClaimFreeTrialError = {
  __typename?: 'ClaimFreeTrialError';
  message?: Maybe<Scalars['String']['output']>;
  type: ClaimFreeTrialErrorType;
};

export enum ClaimFreeTrialErrorType {
  UnknownError = 'UNKNOWN_ERROR',
}

export type ClaimFreeTrialResponse =
  | ClaimFreeTrialError
  | ClaimFreeTrialSuccess;

export type ClaimFreeTrialSuccess = {
  __typename?: 'ClaimFreeTrialSuccess';
  message?: Maybe<Scalars['String']['output']>;
};

export type Classroom = {
  __typename?: 'Classroom';
  className?: Maybe<Scalars['String']['output']>;
  csPackage?: Maybe<CsPackage>;
  csPackageId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated drop */
  csPackageStr?: Maybe<Scalars['String']['output']>;
  descriptionStr?: Maybe<Scalars['String']['output']>;
  /** @deprecated drop */
  discountAmount?: Maybe<Scalars['Float']['output']>;
  /** @deprecated drop */
  discountType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  /** @deprecated drop */
  isBan?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated drop */
  isDiscount?: Maybe<Scalars['Boolean']['output']>;
  isFree?: Maybe<Scalars['Boolean']['output']>;
  isGuarantee?: Maybe<Scalars['Boolean']['output']>;
  isUpgrade?: Maybe<Scalars['Boolean']['output']>;
  liveAt?: Maybe<Scalars['String']['output']>;
  liveAtFormatted?: Maybe<Scalars['String']['output']>;
  liveAtLocale?: Maybe<Scalars['String']['output']>;
  /** @deprecated drop */
  paymentMethod?: Maybe<Scalars['String']['output']>;
  /** @deprecated drop */
  paymentStatus?: Maybe<Scalars['String']['output']>;
  /** @deprecated drop */
  phone?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  pricePerInstalment?: Maybe<Scalars['Float']['output']>;
  /** @deprecated drop */
  reference?: Maybe<Scalars['String']['output']>;
  sessions?: Maybe<Array<Maybe<Session>>>;
  /** @deprecated drop */
  totalInstalment?: Maybe<Scalars['Int']['output']>;
  totalSessions?: Maybe<Scalars['Int']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  /** @deprecated drop */
  voucherCode?: Maybe<Scalars['String']['output']>;
};

export type Classrooms = {
  __typename?: 'Classrooms';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  past?: Maybe<Array<Maybe<Classroom>>>;
  size?: Maybe<Scalars['Int']['output']>;
  upcoming?: Maybe<Array<Maybe<Classroom>>>;
};

export type Committee = {
  __typename?: 'Committee';
  expert?: Maybe<Array<Maybe<CommunityMembership>>>;
  manager?: Maybe<Array<Maybe<CommunityMembership>>>;
};

export type CommonField = {
  createdAt?: Maybe<Scalars['DateEpochTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateEpochTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type CommonFilter = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type CommonStatus = {
  __typename?: 'CommonStatus';
  id: Scalars['ID']['output'];
  status?: Maybe<Scalars['Boolean']['output']>;
};

export type CommunityBanCategory = {
  __typename?: 'CommunityBanCategory';
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  isDisplayed?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
};

export type CommunityBoard = {
  __typename?: 'CommunityBoard';
  code?: Maybe<Scalars['String']['output']>;
  event?: Maybe<EventTracking>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CommunityGroup = {
  __typename?: 'CommunityGroup';
  announcement?: Maybe<CommunityGroupAnnouncement>;
  boards?: Maybe<Array<Maybe<CommunityGroupBoard>>>;
  code?: Maybe<Scalars['String']['output']>;
  committee?: Maybe<Committee>;
  copy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  event?: Maybe<EventTracking>;
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isJoined?: Maybe<Scalars['Boolean']['output']>;
  isMute?: Maybe<Scalars['Boolean']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  shareLink?: Maybe<Scalars['String']['output']>;
  totalMembers?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CommunityGroupAnnouncement = {
  __typename?: 'CommunityGroupAnnouncement';
  copy?: Maybe<Scalars['String']['output']>;
  hasAnnouncement?: Maybe<Scalars['Boolean']['output']>;
};

export type CommunityGroupBoard = {
  __typename?: 'CommunityGroupBoard';
  communityBoard?: Maybe<CommunityBoard>;
  communityBoardId?: Maybe<Scalars['ID']['output']>;
  communityId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isDisplay?: Maybe<Scalars['Boolean']['output']>;
};

export type CommunityGroupHomeSection = {
  __typename?: 'CommunityGroupHomeSection';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
};

export type CommunityGroupPreview = {
  __typename?: 'CommunityGroupPreview';
  announcement?: Maybe<CommunityGroupAnnouncement>;
  boards?: Maybe<Array<Maybe<CommunityGroupBoard>>>;
  code?: Maybe<Scalars['String']['output']>;
  committee?: Maybe<Committee>;
  copy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  event?: Maybe<EventTracking>;
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isJoined?: Maybe<Scalars['Boolean']['output']>;
  isMute?: Maybe<Scalars['Boolean']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  shareLink?: Maybe<Scalars['String']['output']>;
  totalMembers?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CommunityGroupRecommendations = {
  __typename?: 'CommunityGroupRecommendations';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<CommunityGroup>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type CommunityGroupRegistration = {
  __typename?: 'CommunityGroupRegistration';
  alreadyRegistered?: Maybe<Scalars['Boolean']['output']>;
  community?: Maybe<CommunityGroup>;
  membership?: Maybe<CommunityMembership>;
  referee?: Maybe<CommunityMember>;
};

export type CommunityGroupRegistrationV2 = {
  __typename?: 'CommunityGroupRegistrationV2';
  alreadyRegistered?: Maybe<Scalars['Boolean']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  copy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  event?: Maybe<EventTracking>;
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isMute?: Maybe<Scalars['Boolean']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shareLink?: Maybe<Scalars['String']['output']>;
  totalMembers?: Maybe<Scalars['Int']['output']>;
};

export type CommunityGroupTermConditions = {
  __typename?: 'CommunityGroupTermConditions';
  content?: Maybe<Scalars['String']['output']>;
};

export type CommunityGroups = {
  __typename?: 'CommunityGroups';
  elements?: Maybe<Scalars['Int']['output']>;
  filter?: Maybe<Filter>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<CommunityGroup>>>;
  page?: Maybe<Scalars['Int']['output']>;
  recommendation?: Maybe<Array<Maybe<CommunityGroup>>>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum CommunityGroupsSortBy {
  Latest = 'LATEST',
  Name = 'NAME',
  Priority = 'PRIORITY',
}

export type CommunityGroupsV2 = {
  __typename?: 'CommunityGroupsV2';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<CommunityGroup>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type CommunityMember = {
  __typename?: 'CommunityMember';
  about?: Maybe<Scalars['String']['output']>;
  banReason?: Maybe<Scalars['String']['output']>;
  banVideoReason?: Maybe<Scalars['String']['output']>;
  bannedAt?: Maybe<Scalars['Date']['output']>;
  bannedBy?: Maybe<Scalars['String']['output']>;
  communityBanCategory?: Maybe<CommunityBanCategory>;
  communityBanCategoryId?: Maybe<Scalars['ID']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isBan?: Maybe<Scalars['Boolean']['output']>;
  isBanVideo?: Maybe<Scalars['Boolean']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  nickName?: Maybe<Scalars['String']['output']>;
  profileColor?: Maybe<Scalars['String']['output']>;
  refereeUid?: Maybe<Scalars['ID']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  userStatus?: Maybe<Scalars['String']['output']>;
  userUid?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type CommunityMembership = {
  __typename?: 'CommunityMembership';
  community?: Maybe<CommunityGroup>;
  communityId?: Maybe<Scalars['ID']['output']>;
  communityMember?: Maybe<CommunityMember>;
  communityMemberId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isBan?: Maybe<Scalars['Boolean']['output']>;
  refereeMember?: Maybe<CommunityMember>;
  refereeMemberId?: Maybe<Scalars['ID']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  roleColor?: Maybe<Scalars['String']['output']>;
};

export type CommunityReferralInfo = {
  __typename?: 'CommunityReferralInfo';
  communityCode?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  memberCode?: Maybe<Scalars['String']['output']>;
};

export type CommunityReportCategoryDetail = {
  __typename?: 'CommunityReportCategoryDetail';
  communityReportCategoryId?: Maybe<Scalars['ID']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
};

export type CommunityVideo = {
  __typename?: 'CommunityVideo';
  code?: Maybe<Scalars['String']['output']>;
  communityVideoCategory?: Maybe<CommunityVideoCategory>;
  communityVideoCategoryId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export enum CommunityVideoCtaDomain {
  Community = 'COMMUNITY',
  Job = 'JOB',
  Learning = 'LEARNING',
  Profile = 'PROFILE',
}

export enum CommunityVideoCtaType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL',
}

export type CommunityVideoCategory = {
  __typename?: 'CommunityVideoCategory';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
};

export type CommunityVideoV2 = {
  __typename?: 'CommunityVideoV2';
  code?: Maybe<Scalars['String']['output']>;
  commentCount?: Maybe<Scalars['Int']['output']>;
  communityMember?: Maybe<CommunityMember>;
  communityMemberId: Scalars['ID']['output'];
  communityVideoCategory?: Maybe<CommunityVideoCategory>;
  communityVideoCategoryId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  ctaConfig?: Maybe<Scalars['String']['output']>;
  ctaDomain?: Maybe<CommunityVideoCtaDomain>;
  ctaLink?: Maybe<Scalars['String']['output']>;
  ctaTitle?: Maybe<Scalars['String']['output']>;
  ctaType?: Maybe<CommunityVideoCtaType>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAds?: Maybe<Scalars['Boolean']['output']>;
  isBookmarked?: Maybe<Scalars['Boolean']['output']>;
  isCta?: Maybe<Scalars['Boolean']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  isVote?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated switch to ctaTitle */
  jobCta?: Maybe<Scalars['String']['output']>;
  partnershipLabel?: Maybe<Scalars['String']['output']>;
  shareLink?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  uploaderTitle?: Maybe<Scalars['String']['output']>;
  /** @deprecated switch to ctaConfig */
  vacancyConfig?: Maybe<Scalars['String']['output']>;
  /** @deprecated switch to ctaLink */
  vacancyRelatedUrl?: Maybe<Scalars['String']['output']>;
  video?: Maybe<MediaVideo>;
  videoId: Scalars['ID']['output'];
  viewCount?: Maybe<Scalars['Int']['output']>;
  voteCount?: Maybe<Scalars['Int']['output']>;
};

export type CommunityVideoV2Comment = {
  __typename?: 'CommunityVideoV2Comment';
  communityMember?: Maybe<CommunityMember>;
  communityMemberId?: Maybe<Scalars['ID']['output']>;
  communityVideoV2Id?: Maybe<Scalars['ID']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAuthor?: Maybe<Scalars['Boolean']['output']>;
  isBan?: Maybe<Scalars['Boolean']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  isVote?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  subCommentCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  voteCount?: Maybe<Scalars['Int']['output']>;
};

export type CommunityVideoV2Comments = {
  __typename?: 'CommunityVideoV2Comments';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  highlightedId?: Maybe<Scalars['ID']['output']>;
  lastCommentId?: Maybe<Scalars['ID']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<CommunityVideoV2Comment>>>;
  page?: Maybe<Scalars['Int']['output']>;
  remainingCommentCount?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type CommunityVideoV2ReportStatus = {
  __typename?: 'CommunityVideoV2ReportStatus';
  isVideoCommentReported?: Maybe<Scalars['Boolean']['output']>;
  isVideoUserReported?: Maybe<Scalars['Boolean']['output']>;
};

export type CommunityVideos = {
  __typename?: 'CommunityVideos';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<CommunityVideo>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum CommunityVideosSortBy {
  Latest = 'LATEST',
  Pinned = 'PINNED',
  View = 'VIEW',
}

export type CommunityVideosV2 = {
  __typename?: 'CommunityVideosV2';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<CommunityVideoV2>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

/** TODO: logoUrl, logoimage, shareLink */
export type Company = {
  __typename?: 'Company';
  code?: Maybe<Scalars['String']['output']>;
  companyAddress?: Maybe<Scalars['String']['output']>;
  companyIndustry?: Maybe<CompanyIndustry>;
  companySizeStr?: Maybe<Scalars['String']['output']>;
  contactDayEnd?: Maybe<Scalars['Int']['output']>;
  contactDayStart?: Maybe<Scalars['Int']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactHourEndStr?: Maybe<Scalars['String']['output']>;
  contactHourStartStr?: Maybe<Scalars['String']['output']>;
  contactWeblink?: Maybe<Scalars['String']['output']>;
  contactWhatsapp?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isKlep?: Maybe<Scalars['Boolean']['output']>;
  isTp?: Maybe<Scalars['Boolean']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  jobCompanyIndustryId?: Maybe<Scalars['ID']['output']>;
  logoId?: Maybe<Scalars['ID']['output']>;
  logoImage?: Maybe<MediaImage>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shareLink?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type CompanyIndustry = {
  __typename?: 'CompanyIndustry';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CompanyTab = {
  __typename?: 'CompanyTab';
  displayName?: Maybe<Scalars['String']['output']>;
  event?: Maybe<EventTracking>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CompanyVideo = {
  __typename?: 'CompanyVideo';
  code?: Maybe<Scalars['String']['output']>;
  communityVideoV2Id?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  jobCompanyId?: Maybe<Scalars['ID']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  video?: Maybe<CompanyVideoDetail>;
};

export type CompanyVideoDetail = {
  __typename?: 'CompanyVideoDetail';
  description?: Maybe<Scalars['String']['output']>;
  mediaVideo?: Maybe<MediaVideo>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Configuration = {
  __typename?: 'Configuration';
  dataType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  resetDate?: Maybe<Scalars['Date']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Course = {
  __typename?: 'Course';
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

export type CreateCommunityVideoV2CommentErrorCopy = ErrorModal & {
  __typename?: 'CreateCommunityVideoV2CommentErrorCopy';
  content?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CreateCommunityVideoV2CommentErrorEnum {
  UserVideoCommentRestricted = 'USER_VIDEO_COMMENT_RESTRICTED',
}

export type CreateCommunityVideoV2CommentErrorResponse = {
  __typename?: 'CreateCommunityVideoV2CommentErrorResponse';
  errorCode?: Maybe<CreateCommunityVideoV2CommentErrorEnum>;
  message?: Maybe<CreateCommunityVideoV2CommentErrorCopy>;
};

export type CreateCommunityVideoV2CommentV2Response =
  | CommunityVideoV2Comment
  | CreateCommunityVideoV2CommentErrorResponse;

export type CreateFeedCommentErrorCopy = ErrorModal & {
  __typename?: 'CreateFeedCommentErrorCopy';
  content?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CreateFeedCommentErrorEnum {
  UserCreateFeedCommentRestricted = 'USER_CREATE_FEED_COMMENT_RESTRICTED',
}

export type CreateFeedCommentErrorResponse = {
  __typename?: 'CreateFeedCommentErrorResponse';
  errorCode?: Maybe<CreateFeedCommentErrorEnum>;
  message?: Maybe<CreateFeedCommentErrorCopy>;
};

export type CreateFeedCommentResponse =
  | CreateFeedCommentErrorResponse
  | FeedComment;

export type CreateFeedErrorCopy = ErrorModal & {
  __typename?: 'CreateFeedErrorCopy';
  content?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CreateFeedErrorEnum {
  UserCreateFeedRestricted = 'USER_CREATE_FEED_RESTRICTED',
}

export type CreateFeedErrorResponse = {
  __typename?: 'CreateFeedErrorResponse';
  errorCode?: Maybe<CreateFeedErrorEnum>;
  message?: Maybe<CreateFeedErrorCopy>;
};

export type CreateFeedResponse = CreateFeedErrorResponse | Feed;

export type CreateOrderInput = {
  paymentChannelId: Scalars['ID']['input'];
  spSubscriptionTypeId: Scalars['ID']['input'];
};

export type CreateSegmentErrorResponse = {
  __typename?: 'CreateSegmentErrorResponse';
  code?: Maybe<OpsSegmentErrorEnum>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CreateSegmentSuccessResponse = {
  __typename?: 'CreateSegmentSuccessResponse';
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
};

export type CsPackage = {
  __typename?: 'CsPackage';
  csClassId?: Maybe<Scalars['ID']['output']>;
  csClassStr?: Maybe<Scalars['String']['output']>;
  currencyId?: Maybe<Scalars['ID']['output']>;
  currencyStr?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountType?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isDiscount?: Maybe<Scalars['Boolean']['output']>;
  isFree?: Maybe<Scalars['Boolean']['output']>;
  isGuarantee?: Maybe<Scalars['Boolean']['output']>;
  isInstalment?: Maybe<Scalars['Boolean']['output']>;
  isPublish?: Maybe<Scalars['Boolean']['output']>;
  liveAt?: Maybe<Scalars['String']['output']>;
  liveAtFormatted?: Maybe<Scalars['String']['output']>;
  liveAtLocale?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  pricePerInstalment?: Maybe<Scalars['String']['output']>;
  sessions?: Maybe<Array<Maybe<Session>>>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  totalInstalment?: Maybe<Scalars['Int']['output']>;
  totalSession?: Maybe<Scalars['Int']['output']>;
};

export type CsPackageDetailView = {
  __typename?: 'CsPackageDetailView';
  detail?: Maybe<CsPackage>;
};

export type CsPackageListView = {
  __typename?: 'CsPackageListView';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<CsPackage>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type CsPackageSearchListView = {
  __typename?: 'CsPackageSearchListView';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<CsPackage>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type Currency = {
  __typename?: 'Currency';
  displayInitial?: Maybe<Scalars['String']['output']>;
  regex?: Maybe<Scalars['String']['output']>;
  substitute?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Customer = {
  __typename?: 'Customer';
  userName?: Maybe<Scalars['String']['output']>;
  userPhone?: Maybe<Scalars['String']['output']>;
};

export type CvReview = {
  __typename?: 'CvReview';
  actionVerbsAndSuggestions?: Maybe<CvReviewActionVerbsAndSuggestions>;
  education?: Maybe<CvReviewEducation>;
  estimatedTimeToRead?: Maybe<CvReviewEstimatedTimeToRead>;
  experience?: Maybe<CvReviewExperience>;
  personalInfo?: Maybe<CvReviewPersonalInfo>;
  readability?: Maybe<CvReviewReadability>;
  summary?: Maybe<CvReviewSummary>;
  totalScore?: Maybe<CvReviewTotalScore>;
};

export type CvReviewActionVerbsAndSuggestions = {
  __typename?: 'CvReviewActionVerbsAndSuggestions';
  actionVerbs?: Maybe<Scalars['String']['output']>;
  example?: Maybe<Scalars['String']['output']>;
  positions?: Maybe<Scalars['String']['output']>;
  suggestion?: Maybe<Scalars['String']['output']>;
};

export type CvReviewEducation = {
  __typename?: 'CvReviewEducation';
  feedback?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  suggestion?: Maybe<Scalars['String']['output']>;
};

export type CvReviewEstimatedTimeToRead = {
  __typename?: 'CvReviewEstimatedTimeToRead';
  estimatedRead?: Maybe<Scalars['String']['output']>;
  length?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  suggestion?: Maybe<Scalars['String']['output']>;
};

export type CvReviewExperience = {
  __typename?: 'CvReviewExperience';
  buzzwords?: Maybe<Scalars['String']['output']>;
  exampleExperienceAchievements?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  suggestion?: Maybe<Scalars['String']['output']>;
};

export type CvReviewPersonalInfo = {
  __typename?: 'CvReviewPersonalInfo';
  personalInfo?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  suggestion?: Maybe<Scalars['String']['output']>;
};

export type CvReviewReadability = {
  __typename?: 'CvReviewReadability';
  grammar?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  typos?: Maybe<Scalars['String']['output']>;
};

export type CvReviewSummary = {
  __typename?: 'CvReviewSummary';
  example?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  suggestion?: Maybe<Scalars['String']['output']>;
};

export type CvReviewTotalScore = {
  __typename?: 'CvReviewTotalScore';
  score?: Maybe<Scalars['String']['output']>;
};

export type CvTemplate = {
  __typename?: 'CvTemplate';
  code?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isPremium?: Maybe<Scalars['Boolean']['output']>;
  mediaImage?: Maybe<MediaImage>;
};

export type CvTemplates = {
  __typename?: 'CvTemplates';
  elements?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<CvTemplate>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type DeclineInterviewInput = {
  applicationId: Scalars['ID']['input'];
  interviewDeclineReasonId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type DeclineInterviewInvitationError = {
  __typename?: 'DeclineInterviewInvitationError';
  errorCode?: Maybe<DeclineInterviewInvitationErrorEnum>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum DeclineInterviewInvitationErrorEnum {
  DeclineReasonNotFound = 'DECLINE_REASON_NOT_FOUND',
  InvitationNotFound = 'INVITATION_NOT_FOUND',
  UserInvitationInvalid = 'USER_INVITATION_INVALID',
}

export type DeclineInterviewInvitationResult =
  | DeclineInterviewInvitationError
  | DeclineInterviewInvitationSuccess;

export type DeclineInterviewInvitationSuccess = {
  __typename?: 'DeclineInterviewInvitationSuccess';
  message?: Maybe<Scalars['String']['output']>;
};

export type DeclineInvitationError = {
  __typename?: 'DeclineInvitationError';
  errorCode?: Maybe<DeclineInvitationErrorEnum>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum DeclineInvitationErrorEnum {
  AlreadyApplied = 'ALREADY_APPLIED',
  AlreadyDeclined = 'ALREADY_DECLINED',
  InvitationDeclineNotFound = 'INVITATION_DECLINE_NOT_FOUND',
  InvitationNotFound = 'INVITATION_NOT_FOUND',
  MaximumCharactersExceeded = 'MAXIMUM_CHARACTERS_EXCEEDED',
  MinimumCharactersNotReached = 'MINIMUM_CHARACTERS_NOT_REACHED',
  ReasonShouldBeFilled = 'REASON_SHOULD_BE_FILLED',
  VacancyClosed = 'VACANCY_CLOSED',
}

export type DeclineInvitationInput = {
  invitationDeclineReasonId: Scalars['ID']['input'];
  invitationId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type DeclineInvitationResult =
  | DeclineInvitationError
  | DeclineInvitationSuccess;

export type DeclineInvitationSuccess = {
  __typename?: 'DeclineInvitationSuccess';
  message?: Maybe<Scalars['String']['output']>;
};

export enum DeclineReasonCategoryEnum {
  Option = 'OPTION',
  Other = 'OTHER',
}

export type DisclaimerContent = {
  __typename?: 'DisclaimerContent';
  buttonCopy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type DynamicBannerSet = {
  __typename?: 'DynamicBannerSet';
  bannerImage?: Maybe<Scalars['String']['output']>;
  bannerImageId?: Maybe<Scalars['String']['output']>;
  bgColor?: Maybe<Scalars['String']['output']>;
  iconImage?: Maybe<Scalars['String']['output']>;
  iconImageId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  textColor?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum EpInvitationStatus {
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export type Education = {
  __typename?: 'Education';
  description?: Maybe<Scalars['String']['output']>;
  descriptionFormatted?: Maybe<Scalars['String']['output']>;
  educationInstitution?: Maybe<EducationInstitution>;
  educationInstitutionId?: Maybe<Scalars['ID']['output']>;
  educationLevel?: Maybe<Scalars['String']['output']>;
  educationProgram?: Maybe<EducationProgram>;
  educationProgramId?: Maybe<Scalars['ID']['output']>;
  endMonth?: Maybe<Scalars['Int']['output']>;
  endYear?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  periodStr?: Maybe<Scalars['String']['output']>;
  startMonth?: Maybe<Scalars['Int']['output']>;
  startYear?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type EducationCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionFormatted?: InputMaybe<Scalars['String']['input']>;
  educationInstitutionName?: InputMaybe<Scalars['String']['input']>;
  educationLevel?: InputMaybe<Scalars['String']['input']>;
  educationProgramName?: InputMaybe<Scalars['String']['input']>;
  endMonth?: InputMaybe<Scalars['Int']['input']>;
  endYear?: InputMaybe<Scalars['String']['input']>;
  startMonth?: InputMaybe<Scalars['Int']['input']>;
  startYear?: InputMaybe<Scalars['String']['input']>;
  userProfileId: Scalars['ID']['input'];
};

export type EducationCreateInputProfile = {
  educationInstitutionName?: InputMaybe<Scalars['String']['input']>;
  educationLevel?: InputMaybe<Scalars['String']['input']>;
  educationProgramName?: InputMaybe<Scalars['String']['input']>;
  endYear?: InputMaybe<Scalars['String']['input']>;
  startYear?: InputMaybe<Scalars['String']['input']>;
};

export type EducationInstitution = {
  __typename?: 'EducationInstitution';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EducationProgram = {
  __typename?: 'EducationProgram';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EducationUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionFormatted?: InputMaybe<Scalars['String']['input']>;
  educationInstitutionName?: InputMaybe<Scalars['String']['input']>;
  educationLevel?: InputMaybe<Scalars['String']['input']>;
  educationProgramName?: InputMaybe<Scalars['String']['input']>;
  endMonth?: InputMaybe<Scalars['Int']['input']>;
  endYear?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  startMonth?: InputMaybe<Scalars['Int']['input']>;
  startYear?: InputMaybe<Scalars['String']['input']>;
};

export enum EmploymentEnum {
  Contract = 'CONTRACT',
  Freelance = 'FREELANCE',
  FullTime = 'FULL_TIME',
  Internship = 'INTERNSHIP',
  PartTime = 'PART_TIME',
}

export type EmploymentType = {
  __typename?: 'EmploymentType';
  key?: Maybe<EmploymentEnum>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum EnumMissionStatus {
  BonusPending = 'BONUS_PENDING',
  BonusReceived = 'BONUS_RECEIVED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Latest = 'LATEST',
  OnProgress = 'ON_PROGRESS',
  OnVerification = 'ON_VERIFICATION',
  Successful = 'SUCCESSFUL',
}

export type EpActivateJobVacancyHighlightV2Error = {
  __typename?: 'EpActivateJobVacancyHighlightV2Error';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpActivateJobVacancyHighlightV2ErrorEnum>;
};

export enum EpActivateJobVacancyHighlightV2ErrorEnum {
  AlreadyPromoted = 'ALREADY_PROMOTED',
  NotFound = 'NOT_FOUND',
  QuotaNotAvailable = 'QUOTA_NOT_AVAILABLE',
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpActivateJobVacancyHighlightV2Result =
  | EpActivateJobVacancyHighlightV2Error
  | EpJobVacancyV3IdResponse;

export type EpAdditionalRoleAccess = {
  __typename?: 'EpAdditionalRoleAccess';
  id?: Maybe<Scalars['ID']['output']>;
  keyParam?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type EpAuth = {
  __typename?: 'EpAuth';
  authCode?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<EpVerifyCodeStatus>;
};

export type EpAuthResponse = {
  __typename?: 'EpAuthResponse';
  data?: Maybe<EpAuth>;
  error?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
  warning?: Maybe<Scalars['String']['output']>;
};

export type EpAuthenticationResponse = {
  __typename?: 'EpAuthenticationResponse';
  data?: Maybe<EpAuthenticationResult>;
  error?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
  warning?: Maybe<Scalars['String']['output']>;
};

export type EpAuthenticationResult = {
  __typename?: 'EpAuthenticationResult';
  accessToken?: Maybe<Scalars['String']['output']>;
  expiryTime?: Maybe<Scalars['DateTime']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type EpBenefitInput = {
  id: Scalars['ID']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EpCloseJobVacancyV2Error = {
  __typename?: 'EpCloseJobVacancyV2Error';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpCloseJobVacancyV2ErrorEnum>;
};

export enum EpCloseJobVacancyV2ErrorEnum {
  AlreadyClosed = 'ALREADY_CLOSED',
  NotFound = 'NOT_FOUND',
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpCloseJobVacancyV2Result =
  | EpCloseJobVacancyV2Error
  | EpJobVacancyV3IdResponse;

export type EpCompaniesOps = {
  __typename?: 'EpCompaniesOps';
  elements?: Maybe<Scalars['BigInt']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<EpCompanyOps>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpCompany = {
  accManager?: Maybe<Scalars['String']['output']>;
  accManagerWhatsapp?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  verificationStatus?: Maybe<EpCompanyVerificationStatus>;
};

export type EpCompanyOps = CommonField &
  EpCompany & {
    __typename?: 'EpCompanyOps';
    accManager?: Maybe<Scalars['String']['output']>;
    accManagerWhatsapp?: Maybe<Scalars['String']['output']>;
    code?: Maybe<Scalars['String']['output']>;
    companyUsersCount?: Maybe<Scalars['Int']['output']>;
    createdAt?: Maybe<Scalars['DateEpochTime']['output']>;
    createdBy?: Maybe<Scalars['String']['output']>;
    displayName?: Maybe<Scalars['String']['output']>;
    hasVacancy?: Maybe<Scalars['Boolean']['output']>;
    id?: Maybe<Scalars['ID']['output']>;
    isActive?: Maybe<Scalars['Boolean']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    subscriptionStatus?: Maybe<EpCompanySubscriptionOps>;
    updatedAt?: Maybe<Scalars['DateEpochTime']['output']>;
    updatedBy?: Maybe<Scalars['String']['output']>;
    verificationStatus?: Maybe<EpCompanyVerificationStatus>;
  };

export type EpCompanySubscriptionOps = {
  __typename?: 'EpCompanySubscriptionOps';
  errors?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['BigInt']['output']>;
  info?: Maybe<Scalars['String']['output']>;
  isDisplayInfo?: Maybe<Scalars['Boolean']['output']>;
  isPremium?: Maybe<Scalars['Boolean']['output']>;
  isValid?: Maybe<Scalars['Boolean']['output']>;
  offerPackageUpgrade?: Maybe<Scalars['Boolean']['output']>;
  packageEnum?: Maybe<EpUserSubscriptionPlanTypeEnum>;
  packageId?: Maybe<Scalars['ID']['output']>;
  packageStr?: Maybe<Scalars['String']['output']>;
  refreshDate?: Maybe<Scalars['BigInt']['output']>;
};

export enum EpCompanyVerificationStatus {
  Fraud = 'FRAUD',
  OnHold = 'ON_HOLD',
  OnReview = 'ON_REVIEW',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
}

export type EpCreateInterviewInvitationError = {
  __typename?: 'EpCreateInterviewInvitationError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpSetInterviewInvitationError>;
};

export type EpCreateInterviewInvitationInput = {
  applicationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  endTimeStr?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offlineDetail?: InputMaybe<EpInterviewSessionOfflineDetailInput>;
  onlineDetail?: InputMaybe<EpInterviewSessionOnlineDetailInput>;
  recruiterMessage?: InputMaybe<Scalars['String']['input']>;
  recruiterPhoneNumber: Scalars['String']['input'];
  schedule?: InputMaybe<Scalars['String']['input']>;
  sizeType?: InputMaybe<EpInterviewSessionSizeType>;
  startTimeStr?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EpInterviewSessionType>;
  vacancyId: Scalars['ID']['input'];
};

export type EpCreateInterviewInvitationResponse =
  | EpCreateInterviewInvitationError
  | EpCreateInterviewInvitationSuccess;

export type EpCreateInterviewInvitationSuccess = {
  __typename?: 'EpCreateInterviewInvitationSuccess';
  message?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type EpCreateJobVacancyDraftError = {
  __typename?: 'EpCreateJobVacancyDraftError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpCreateJobVacancyDraftErrorEnum>;
};

export enum EpCreateJobVacancyDraftErrorEnum {
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unknown = 'UNKNOWN',
}

export type EpCreateJobVacancyDraftResult =
  | EpCreateJobVacancyDraftError
  | EpCreateJobVacancyDraftSuccess;

export type EpCreateJobVacancyDraftSuccess = {
  __typename?: 'EpCreateJobVacancyDraftSuccess';
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type EpCreateJobVacancyError = {
  __typename?: 'EpCreateJobVacancyError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpCreateJobVacancyErrorEnum>;
};

export enum EpCreateJobVacancyErrorEnum {
  DuplicateName = 'DUPLICATE_NAME',
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpCreateJobVacancyV2Result =
  | EpCreateJobVacancyError
  | EpJobVacancyGeneratedLink;

export type EpCreateJobVacancyV3Error = {
  __typename?: 'EpCreateJobVacancyV3Error';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpCreateJobVacancyV3ErrorEnum>;
};

export enum EpCreateJobVacancyV3ErrorEnum {
  CloseDateLimitExceeded = 'CLOSE_DATE_LIMIT_EXCEEDED',
  DraftAlreadyDeleted = 'DRAFT_ALREADY_DELETED',
  DraftAlreadyPosted = 'DRAFT_ALREADY_POSTED',
  DraftNotFound = 'DRAFT_NOT_FOUND',
  DuplicateName = 'DUPLICATE_NAME',
  QuotaNotAvailable = 'QUOTA_NOT_AVAILABLE',
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpCreateJobVacancyV3Result =
  | EpCreateJobVacancyV3Error
  | EpJobVacancyV3Response;

export type EpDashboardApplicationSummaryCount =
  | EpDashboardApplicationSummaryError
  | EpDashboardApplicationSummaryResponse;

export type EpDashboardApplicationSummaryError = {
  __typename?: 'EpDashboardApplicationSummaryError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpDashboardApplicationSummaryErrorEnum>;
};

export enum EpDashboardApplicationSummaryErrorEnum {
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpDashboardApplicationSummaryResponse = {
  __typename?: 'EpDashboardApplicationSummaryResponse';
  totalNew?: Maybe<Scalars['Int']['output']>;
  totalPending?: Maybe<Scalars['Int']['output']>;
};

export type EpDashboardVacancySummaryError = {
  __typename?: 'EpDashboardVacancySummaryError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpDashboardVacancySummaryErrorEnum>;
};

export enum EpDashboardVacancySummaryErrorEnum {
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpDashboardVacancySummaryPayload = {
  __typename?: 'EpDashboardVacancySummaryPayload';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  id: Scalars['ID']['output'];
  locationCity?: Maybe<EpLocation>;
  locationId: Scalars['ID']['output'];
  locationProvince?: Maybe<EpLocation>;
  positionName?: Maybe<Scalars['String']['output']>;
  totalApplication?: Maybe<Scalars['Int']['output']>;
};

export type EpDashboardVacancySummaryResponse = {
  __typename?: 'EpDashboardVacancySummaryResponse';
  onProgress?: Maybe<Array<EpDashboardVacancySummaryPayload>>;
  pending?: Maybe<Array<EpDashboardVacancySummaryPayload>>;
};

export type EpDashboardVacancySummaryResult =
  | EpDashboardVacancySummaryError
  | EpDashboardVacancySummaryResponse;

export type EpDeactivateJobVacancyHighlightV2Error = {
  __typename?: 'EpDeactivateJobVacancyHighlightV2Error';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpDeactivateJobVacancyHighlightV2ErrorEnum>;
};

export enum EpDeactivateJobVacancyHighlightV2ErrorEnum {
  NotFound = 'NOT_FOUND',
  NotPromoted = 'NOT_PROMOTED',
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpDeactivateJobVacancyHighlightV2Result =
  | EpDeactivateJobVacancyHighlightV2Error
  | EpJobVacancyV3IdResponse;

export type EpEducation = {
  __typename?: 'EpEducation';
  description?: Maybe<Scalars['String']['output']>;
  descriptionFormatted?: Maybe<Scalars['String']['output']>;
  educationInstitution?: Maybe<EpEducationInstitution>;
  educationInstitutionId?: Maybe<Scalars['ID']['output']>;
  educationLevel?: Maybe<Scalars['String']['output']>;
  educationProgram?: Maybe<EpEducationProgram>;
  educationProgramId?: Maybe<Scalars['ID']['output']>;
  endMonth?: Maybe<Scalars['String']['output']>;
  endYear?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  periodStr?: Maybe<Scalars['String']['output']>;
  startMonth?: Maybe<Scalars['String']['output']>;
  startYear?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type EpEducationInstitution = {
  __typename?: 'EpEducationInstitution';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
};

export type EpEducationInstitutions = {
  __typename?: 'EpEducationInstitutions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpEducationInstitution>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum EpEducationLevel {
  Bachelor = 'BACHELOR',
  Diploma = 'DIPLOMA',
  Doctorate = 'DOCTORATE',
  HighSchool = 'HIGH_SCHOOL',
  Master = 'MASTER',
  MiddleSchool = 'MIDDLE_SCHOOL',
  Other = 'OTHER',
}

export enum EpEducationLevelFilter {
  Bachelor = 'BACHELOR',
  Diploma_1 = 'DIPLOMA_1',
  Diploma_2 = 'DIPLOMA_2',
  Diploma_3 = 'DIPLOMA_3',
  Diploma_4 = 'DIPLOMA_4',
  Doctorate = 'DOCTORATE',
  HighSchool = 'HIGH_SCHOOL',
  Master = 'MASTER',
  MiddleSchool = 'MIDDLE_SCHOOL',
}

export type EpEducationLevelFilterKeyValue = {
  __typename?: 'EpEducationLevelFilterKeyValue';
  key?: Maybe<EpEducationLevelFilter>;
  value?: Maybe<Scalars['String']['output']>;
};

export type EpEducationLevelKeyValue = {
  __typename?: 'EpEducationLevelKeyValue';
  key?: Maybe<EpEducationLevel>;
  value?: Maybe<Scalars['String']['output']>;
};

export type EpEducationProgram = {
  __typename?: 'EpEducationProgram';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EpEducationProgramPreference = {
  __typename?: 'EpEducationProgramPreference';
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EpEducationProgramPreferencesInput = {
  id: Scalars['ID']['input'];
  idx: Scalars['Int']['input'];
};

export type EpEducationPrograms = {
  __typename?: 'EpEducationPrograms';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpEducationProgram>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpEmploymentType = {
  __typename?: 'EpEmploymentType';
  key?: Maybe<EpEmploymentTypeEnum>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum EpEmploymentTypeEnum {
  Contract = 'CONTRACT',
  Freelance = 'FREELANCE',
  FullTime = 'FULL_TIME',
  Internship = 'INTERNSHIP',
  PartTime = 'PART_TIME',
}

export type EpExperience = {
  __typename?: 'EpExperience';
  companyName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionFormatted?: Maybe<Scalars['String']['output']>;
  employmentType?: Maybe<EpEmploymentType>;
  employmentTypeStr?: Maybe<Scalars['String']['output']>;
  endYear?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  jobFunction?: Maybe<Scalars['String']['output']>;
  jvRole?: Maybe<Scalars['String']['output']>;
  jvSpecializationRoleId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  periodStr?: Maybe<Scalars['String']['output']>;
  skills?: Maybe<Scalars['String']['output']>;
  startYear?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type EpExperienceJobFunction = {
  __typename?: 'EpExperienceJobFunction';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type EpExperienceJobFunctions = {
  __typename?: 'EpExperienceJobFunctions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  hasPrev?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpExperienceJobFunction>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum EpExperienceLevel {
  Advanced = 'ADVANCED',
  Beginner = 'BEGINNER',
  Expert = 'EXPERT',
  Mid = 'MID',
  Novice = 'NOVICE',
  NoExperience = 'NO_EXPERIENCE',
}

export type EpExperienceYearDropdown = {
  __typename?: 'EpExperienceYearDropdown';
  key?: Maybe<EpExperienceLevel>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum EpFreeTrialStatus {
  FreeTrialExpired = 'FREE_TRIAL_EXPIRED',
  FreeTrialExpiredAndQuotaExceeded = 'FREE_TRIAL_EXPIRED_AND_QUOTA_EXCEEDED',
  FreeTrialValid = 'FREE_TRIAL_VALID',
  FreeTrialValidAndQuotaExceeded = 'FREE_TRIAL_VALID_AND_QUOTA_EXCEEDED',
  QuotaExceeded = 'QUOTA_EXCEEDED',
}

export type EpGeneratedLink = {
  __typename?: 'EpGeneratedLink';
  code?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
};

export enum EpHighlightedStatus {
  Highlighted = 'HIGHLIGHTED',
  HighlightedExpired = 'HIGHLIGHTED_EXPIRED',
  NotHighlighted = 'NOT_HIGHLIGHTED',
}

export type EpInputRegisterCompany = {
  contactWeblink?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  jobCompanyIndustryId: Scalars['ID']['input'];
  jobCompanySizeId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type EpInputUpdateCompany = {
  address?: InputMaybe<Scalars['String']['input']>;
  contactDayEnd?: InputMaybe<Scalars['Int']['input']>;
  contactDayStart?: InputMaybe<Scalars['Int']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactHourEnd?: InputMaybe<Scalars['BigInt']['input']>;
  contactHourStart?: InputMaybe<Scalars['BigInt']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  contactWeblink?: InputMaybe<Scalars['String']['input']>;
  contactWhatsapp?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  foundedYear?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  jobCompanyIndustryId?: InputMaybe<Scalars['ID']['input']>;
  jobCompanySizeId?: InputMaybe<Scalars['ID']['input']>;
  legalDocumentId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Use legalDocumentId instead */
  legalDocumentPath?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  logoId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Use logoId instead */
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nib?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['Int']['input']>;
};

export type EpInterviewInvitation = {
  __typename?: 'EpInterviewInvitation';
  id?: Maybe<Scalars['ID']['output']>;
  invitedAt?: Maybe<Scalars['String']['output']>;
  status?: Maybe<EpInterviewSessionStatus>;
  statusStr?: Maybe<Scalars['String']['output']>;
};

export type EpInterviewSession = {
  __typename?: 'EpInterviewSession';
  endTimeStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  schedule?: Maybe<Scalars['String']['output']>;
  sizeType?: Maybe<EpInterviewSessionSizeType>;
  startTimeStr?: Maybe<Scalars['String']['output']>;
  statistic?: Maybe<Array<Maybe<EpInterviewStatistic>>>;
  type?: Maybe<EpInterviewSessionType>;
  vacancy?: Maybe<EpJobVacancy>;
};

export type EpInterviewSessionApplication = {
  __typename?: 'EpInterviewSessionApplication';
  application?: Maybe<EpJobApplication>;
  interviewInvitation?: Maybe<EpInterviewInvitation>;
  session?: Maybe<EpInterviewSession>;
};

export enum EpInterviewSessionApplicationState {
  NoInterviewSessionApplicationAttendFound = 'NO_INTERVIEW_SESSION_APPLICATION_ATTEND_FOUND',
  NoInterviewSessionApplicationNotAttendFound = 'NO_INTERVIEW_SESSION_APPLICATION_NOT_ATTEND_FOUND',
  NoInterviewSessionApplicationPendingFound = 'NO_INTERVIEW_SESSION_APPLICATION_PENDING_FOUND',
  NoInterviewSessionApplicationPendingPassedFound = 'NO_INTERVIEW_SESSION_APPLICATION_PENDING_PASSED_FOUND',
  NoInterviewSessionApplicationSearchAndFilterFound = 'NO_INTERVIEW_SESSION_APPLICATION_SEARCH_AND_FILTER_FOUND',
}

export type EpInterviewSessionApplications = {
  __typename?: 'EpInterviewSessionApplications';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpInterviewSessionApplication>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<EpInterviewSessionApplicationState>;
};

export type EpInterviewSessionCount = {
  __typename?: 'EpInterviewSessionCount';
  count: Scalars['Int']['output'];
};

export type EpInterviewSessionDropdown = {
  __typename?: 'EpInterviewSessionDropdown';
  endTimeStr: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  numberOfApplicants: Scalars['Int']['output'];
  offlineDetail?: Maybe<EpInterviewSessionOfflineDetail>;
  onlineDetail?: Maybe<EpInterviewSessionOnlineDetail>;
  recruiterMessage?: Maybe<Scalars['String']['output']>;
  recruiterPhoneNumber?: Maybe<Scalars['String']['output']>;
  schedule: Scalars['String']['output'];
  sizeType: EpInterviewSessionSizeType;
  startTimeStr: Scalars['String']['output'];
  type: EpInterviewSessionType;
  vacancyId: Scalars['ID']['output'];
};

export type EpInterviewSessionDropdowns = {
  __typename?: 'EpInterviewSessionDropdowns';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpInterviewSessionDropdown>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpInterviewSessionOfflineDetail = {
  __typename?: 'EpInterviewSessionOfflineDetail';
  address: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type EpInterviewSessionOfflineDetailInput = {
  address: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type EpInterviewSessionOnlineDetail = {
  __typename?: 'EpInterviewSessionOnlineDetail';
  password?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type EpInterviewSessionOnlineDetailInput = {
  password?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export enum EpInterviewSessionSizeType {
  Group = 'GROUP',
  Personal = 'PERSONAL',
}

export enum EpInterviewSessionSortEnum {
  FurthestSchedule = 'FURTHEST_SCHEDULE',
  NearestSchedule = 'NEAREST_SCHEDULE',
}

export enum EpInterviewSessionState {
  NoInterviewSessionFound = 'NO_INTERVIEW_SESSION_FOUND',
  NoInterviewSessionSearchAndFilterFound = 'NO_INTERVIEW_SESSION_SEARCH_AND_FILTER_FOUND',
}

export enum EpInterviewSessionStatus {
  Attend = 'ATTEND',
  NotAttend = 'NOT_ATTEND',
  Pending = 'PENDING',
  PendingPassed = 'PENDING_PASSED',
}

export type EpInterviewSessionTab = {
  __typename?: 'EpInterviewSessionTab';
  displayName?: Maybe<Scalars['String']['output']>;
  key?: Maybe<EpInterviewSessionStatus>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfApplicants?: Maybe<Scalars['Int']['output']>;
  sessionId?: Maybe<Scalars['ID']['output']>;
};

export enum EpInterviewSessionType {
  Offline = 'OFFLINE',
  Online = 'ONLINE',
}

export type EpInterviewSessions = {
  __typename?: 'EpInterviewSessions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpInterviewSession>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<EpInterviewSessionState>;
};

export type EpInterviewStatistic = {
  __typename?: 'EpInterviewStatistic';
  count?: Maybe<Scalars['Int']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<EpInterviewSessionStatus>;
};

export type EpInvitationStatusDropdownItem = {
  __typename?: 'EpInvitationStatusDropdownItem';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type EpInviteJobVacancyCandidateInput = {
  id: Scalars['ID']['input'];
  note: Scalars['String']['input'];
};

export type EpInviteJobVacancyCandidateVerification = {
  __typename?: 'EpInviteJobVacancyCandidateVerification';
  copyStr?: Maybe<Scalars['String']['output']>;
  jobApplicationId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<EpInviteJobVacancyCandidateVerificationStatus>;
  templateStr?: Maybe<Scalars['String']['output']>;
};

export enum EpInviteJobVacancyCandidateVerificationStatus {
  AlreadyApplied = 'ALREADY_APPLIED',
  AlreadyInvited = 'ALREADY_INVITED',
  Available = 'AVAILABLE',
}

export type EpJobApplicantFilterInput = {
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  domicile?: InputMaybe<Scalars['ID']['input']>;
  educationInstitutionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  epStatus?: InputMaybe<EpJobApplicationEpStatusEnum>;
  excludeDraft?: InputMaybe<Scalars['Boolean']['input']>;
  experienceYear?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  isRestrict?: InputMaybe<Scalars['Boolean']['input']>;
  jobFunctions?: InputMaybe<Array<Scalars['String']['input']>>;
  jobSpecializationRoles?: InputMaybe<Array<Scalars['ID']['input']>>;
  jobVacancyId?: InputMaybe<Scalars['ID']['input']>;
  lastEducationLevel?: InputMaybe<Scalars['String']['input']>;
  lastEducationLevels?: InputMaybe<Array<EpEducationLevelFilter>>;
  locations?: InputMaybe<Array<EpLocationAreaInput>>;
  maxSalary?: InputMaybe<Scalars['BigInt']['input']>;
  minSalary?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  skills?: InputMaybe<Array<Scalars['String']['input']>>;
  skillsV2?: InputMaybe<Array<Scalars['ID']['input']>>;
  tab?: InputMaybe<Scalars['String']['input']>;
};

export type EpJobApplication = {
  __typename?: 'EpJobApplication';
  cityId?: Maybe<Scalars['ID']['output']>;
  cityName?: Maybe<Scalars['String']['output']>;
  companyNote?: Maybe<Scalars['String']['output']>;
  companyStatus?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  districtName?: Maybe<Scalars['String']['output']>;
  endEducationYear?: Maybe<Scalars['Int']['output']>;
  endLastJobYear?: Maybe<Scalars['Int']['output']>;
  expectedSalaryStr?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use userProfile.gender instead. */
  gender?: Maybe<Scalars['String']['output']>;
  haveScreeningQuestionAnswers?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  interestedStatus?: Maybe<JobApplicationPreviewInterestEnum>;
  isAccepted?: Maybe<Scalars['Boolean']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isFullScoreScreeningQuestion?: Maybe<Scalars['Boolean']['output']>;
  isInteviewed?: Maybe<Scalars['Boolean']['output']>;
  isInvited?: Maybe<Scalars['Boolean']['output']>;
  isMasked?: Maybe<Scalars['Boolean']['output']>;
  isRead?: Maybe<Scalars['Boolean']['output']>;
  jobVacancyId?: Maybe<Scalars['ID']['output']>;
  lastEducationLevel?: Maybe<Scalars['String']['output']>;
  lastEducationProgram?: Maybe<Scalars['String']['output']>;
  lastJobDescription?: Maybe<Scalars['String']['output']>;
  lastJobName?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['ID']['output']>;
  matchingResult?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  provinceId?: Maybe<Scalars['ID']['output']>;
  provinceName?: Maybe<Scalars['String']['output']>;
  screeningQuestionResultStr?: Maybe<Scalars['String']['output']>;
  startEducationYear?: Maybe<Scalars['Int']['output']>;
  startLastJobYear?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userLastEducation?: Maybe<EpEducation>;
  userProfile?: Maybe<EpUserProfile>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type EpJobApplicationCountData = {
  __typename?: 'EpJobApplicationCountData';
  count?: Maybe<Scalars['BigInt']['output']>;
  countStr?: Maybe<Scalars['String']['output']>;
  menuId?: Maybe<Scalars['ID']['output']>;
};

export type EpJobApplicationDashboardSummary = {
  __typename?: 'EpJobApplicationDashboardSummary';
  totalNew?: Maybe<Scalars['Int']['output']>;
  totalPending?: Maybe<Scalars['Int']['output']>;
};

export enum EpJobApplicationEmptyState {
  MinimalNotReached = 'MINIMAL_NOT_REACHED',
  NoApplicantAcceptedFound = 'NO_APPLICANT_ACCEPTED_FOUND',
  NoApplicantFilterFound = 'NO_APPLICANT_FILTER_FOUND',
  NoApplicantFound = 'NO_APPLICANT_FOUND',
  NoApplicantInterviewFound = 'NO_APPLICANT_INTERVIEW_FOUND',
  NoApplicantOnProgressFound = 'NO_APPLICANT_ON_PROGRESS_FOUND',
  NoApplicantRejectedFound = 'NO_APPLICANT_REJECTED_FOUND',
  NoApplicantSavedFound = 'NO_APPLICANT_SAVED_FOUND',
  NoApplicantSearchAndFilterFound = 'NO_APPLICANT_SEARCH_AND_FILTER_FOUND',
  NoApplicantSearchFound = 'NO_APPLICANT_SEARCH_FOUND',
  NoApplicantWithdrawnFound = 'NO_APPLICANT_WITHDRAWN_FOUND',
  NoApplicantWithRestrictionFound = 'NO_APPLICANT_WITH_RESTRICTION_FOUND',
  NoCandidateAcceptedFound = 'NO_CANDIDATE_ACCEPTED_FOUND',
  NoCandidateFound = 'NO_CANDIDATE_FOUND',
  NoCandidateInterviewFound = 'NO_CANDIDATE_INTERVIEW_FOUND',
  NoCandidateOnProgressFound = 'NO_CANDIDATE_ON_PROGRESS_FOUND',
  NoCandidateRecommendation = 'NO_CANDIDATE_RECOMMENDATION',
  NoCandidateRejectedFound = 'NO_CANDIDATE_REJECTED_FOUND',
  NoCandidateSavedFound = 'NO_CANDIDATE_SAVED_FOUND',
  NoCandidateWithdrawnFound = 'NO_CANDIDATE_WITHDRAWN_FOUND',
  NoInvitedApplicantAcceptedFound = 'NO_INVITED_APPLICANT_ACCEPTED_FOUND',
  NoInvitedApplicantFound = 'NO_INVITED_APPLICANT_FOUND',
  NoInvitedApplicantInterviewFound = 'NO_INVITED_APPLICANT_INTERVIEW_FOUND',
  NoInvitedApplicantOnProgressFound = 'NO_INVITED_APPLICANT_ON_PROGRESS_FOUND',
  NoInvitedApplicantRejectedFound = 'NO_INVITED_APPLICANT_REJECTED_FOUND',
  NoInvitedApplicantSavedFound = 'NO_INVITED_APPLICANT_SAVED_FOUND',
}

export enum EpJobApplicationEpStatusEnum {
  Accepted = 'ACCEPTED',
  Interview = 'INTERVIEW',
  OnProgress = 'ON_PROGRESS',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Saved = 'SAVED',
  Withdrawn = 'WITHDRAWN',
}

export type EpJobApplicationHistoryLabel = {
  __typename?: 'EpJobApplicationHistoryLabel';
  bgColor?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  textColor?: Maybe<Scalars['String']['output']>;
};

export type EpJobApplicationNoteInput = {
  companyNote?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type EpJobApplicationPrevNext = {
  __typename?: 'EpJobApplicationPrevNext';
  id?: Maybe<Scalars['ID']['output']>;
  nextId?: Maybe<Scalars['ID']['output']>;
  prevId?: Maybe<Scalars['ID']['output']>;
};

export enum EpJobApplicationPreviewInterestEnum {
  Interested = 'INTERESTED',
  NotInterested = 'NOT_INTERESTED',
  Unknown = 'UNKNOWN',
}

export enum EpJobApplicationPreviewInterestInputEnum {
  Interested = 'INTERESTED',
  NotInterested = 'NOT_INTERESTED',
}

export type EpJobApplicationProfileExport = {
  __typename?: 'EpJobApplicationProfileExport';
  applicationId?: Maybe<Scalars['ID']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type EpJobApplicationRejectedReasonCategory = {
  __typename?: 'EpJobApplicationRejectedReasonCategory';
  disabledKeys?: Maybe<Array<Scalars['String']['output']>>;
  isActive: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  reasonDescription?: Maybe<Scalars['String']['output']>;
  reasonTitle?: Maybe<Scalars['String']['output']>;
  reasonType: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type EpJobApplicationRemarks = {
  __typename?: 'EpJobApplicationRemarks';
  data?: Maybe<EpJobApplicationRemarksData>;
  error?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
  warning?: Maybe<Scalars['String']['output']>;
};

export type EpJobApplicationRemarksData = {
  __typename?: 'EpJobApplicationRemarksData';
  note?: Maybe<Scalars['String']['output']>;
};

export type EpJobApplicationScreening = {
  __typename?: 'EpJobApplicationScreening';
  data?: Maybe<Array<Maybe<EpJobApplicationScreeningData>>>;
  isFullScore?: Maybe<Scalars['Boolean']['output']>;
  noticeStr?: Maybe<Scalars['String']['output']>;
  resultStr?: Maybe<Scalars['String']['output']>;
};

export type EpJobApplicationScreeningData = {
  __typename?: 'EpJobApplicationScreeningData';
  id?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  topic: JobApplicationScreeningTopic;
  values?: Maybe<Array<Maybe<EpJobApplicationScreeningDataValue>>>;
};

export type EpJobApplicationScreeningDataValue = {
  __typename?: 'EpJobApplicationScreeningDataValue';
  answer?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isMatch?: Maybe<Scalars['Boolean']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  requirement?: Maybe<Scalars['String']['output']>;
};

export type EpJobApplicationScreeningQuestionInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  topic: JobApplicationScreeningTopic;
  values?: InputMaybe<Array<EpJobApplicationScreeningQuestionValueInput>>;
};

export type EpJobApplicationScreeningQuestionValueInput = {
  choice: Scalars['String']['input'];
  entry?: InputMaybe<Scalars['String']['input']>;
};

export type EpJobApplicationStatistic = {
  __typename?: 'EpJobApplicationStatistic';
  totalAccepted?: Maybe<Scalars['Int']['output']>;
  totalInterview?: Maybe<Scalars['Int']['output']>;
  totalOnProgress?: Maybe<Scalars['Int']['output']>;
  totalPending?: Maybe<Scalars['Int']['output']>;
  totalRejected?: Maybe<Scalars['Int']['output']>;
};

export type EpJobApplicationStatus = {
  __typename?: 'EpJobApplicationStatus';
  key?: Maybe<EpJobApplicationEpStatusEnum>;
  value?: Maybe<Scalars['String']['output']>;
};

export type EpJobApplicationStatusAndRemarksUpdateInput = {
  companyStatus: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  isSendEmail: Scalars['Boolean']['input'];
  isSendNotification: Scalars['Boolean']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  reasons?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EpJobApplicationStatusBulkUpdateInput = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  isSendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  isSendNotification?: InputMaybe<Scalars['Boolean']['input']>;
  reasons?: InputMaybe<Array<Scalars['String']['input']>>;
  status: Scalars['String']['input'];
};

export type EpJobApplicationStatusUpdateInput = {
  companyStatus: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  isSendEmail: Scalars['Boolean']['input'];
  isSendNotification: Scalars['Boolean']['input'];
};

export type EpJobApplicationSummary = {
  __typename?: 'EpJobApplicationSummary';
  accepted?: Maybe<Scalars['Int']['output']>;
  interview?: Maybe<Scalars['Int']['output']>;
  onProgress?: Maybe<Scalars['Int']['output']>;
  pending?: Maybe<Scalars['Int']['output']>;
  rejected?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  withdrawn?: Maybe<Scalars['Int']['output']>;
};

export type EpJobApplications = {
  __typename?: 'EpJobApplications';
  elements?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpJobApplication>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<EpJobApplicationEmptyState>;
  stateBody?: Maybe<Scalars['String']['output']>;
  stateHeader?: Maybe<Scalars['String']['output']>;
  stateImageUrl?: Maybe<Scalars['String']['output']>;
};

export type EpJobCompany = {
  __typename?: 'EpJobCompany';
  accManager?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  companyType?: Maybe<Scalars['String']['output']>;
  contactDayEnd?: Maybe<Scalars['BigInt']['output']>;
  contactDayEndStr?: Maybe<Scalars['String']['output']>;
  contactDayStart?: Maybe<Scalars['BigInt']['output']>;
  contactDayStartStr?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactHourEnd?: Maybe<Scalars['Int']['output']>;
  contactHourEndStr?: Maybe<Scalars['String']['output']>;
  contactHourStart?: Maybe<Scalars['Int']['output']>;
  contactHourStartStr?: Maybe<Scalars['String']['output']>;
  contactPhone?: Maybe<Scalars['String']['output']>;
  contactWeblink?: Maybe<Scalars['String']['output']>;
  contactWhatsapp?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  foundedYear?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  jobCompanyIndustry?: Maybe<EpJobCompanyIndustry>;
  jobCompanyIndustryId?: Maybe<Scalars['ID']['output']>;
  jobCompanySize?: Maybe<EpJobCompanySize>;
  jobCompanySizeId?: Maybe<Scalars['ID']['output']>;
  legalDocument?: Maybe<MediaFile>;
  legalDocumentId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use url on legalDocument instead */
  legalDocumentUrl?: Maybe<Scalars['String']['output']>;
  locationCity?: Maybe<EpLocation>;
  locationDistrict?: Maybe<EpLocation>;
  locationId?: Maybe<Scalars['ID']['output']>;
  locationProvince?: Maybe<EpLocation>;
  logo?: Maybe<MediaImage>;
  logoId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use url on logo instead */
  logoUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nib?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['Int']['output']>;
  verificationStatus?: Maybe<EpCompanyVerificationStatus>;
  verificationStatusDisplayStr?: Maybe<Scalars['String']['output']>;
  verificationStatusNotice?: Maybe<Scalars['String']['output']>;
  verificationStatusStr?: Maybe<Scalars['String']['output']>;
};

export type EpJobCompanyAccManager = {
  __typename?: 'EpJobCompanyAccManager';
  accManager?: Maybe<Scalars['String']['output']>;
  accManagerCopy?: Maybe<Scalars['String']['output']>;
  accManagerDashboardCopy?: Maybe<Scalars['String']['output']>;
  accManagerErrorCopy?: Maybe<Scalars['String']['output']>;
  accManagerRegisterCopy?: Maybe<Scalars['String']['output']>;
  accManagerRegisterCopyV2?: Maybe<Scalars['String']['output']>;
  accManagerWhatsapp?: Maybe<Scalars['String']['output']>;
};

export type EpJobCompanyIndustries = {
  __typename?: 'EpJobCompanyIndustries';
  elements?: Maybe<Scalars['BigInt']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<EpJobCompanyIndustry>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpJobCompanyIndustry = {
  __typename?: 'EpJobCompanyIndustry';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EpJobCompanySize = {
  __typename?: 'EpJobCompanySize';
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
};

export type EpJobCompanySizes = {
  __typename?: 'EpJobCompanySizes';
  elements?: Maybe<Scalars['BigInt']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpJobCompanySize>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpJobLevelDropdowns = {
  __typename?: 'EpJobLevelDropdowns';
  list?: Maybe<Array<Maybe<KeyValue>>>;
};

export type EpJobPromoteStatus = {
  __typename?: 'EpJobPromoteStatus';
  jobPromoteCount?: Maybe<Scalars['Int']['output']>;
  jobPromoteExceeded?: Maybe<Scalars['Boolean']['output']>;
};

export type EpJobRole = {
  __typename?: 'EpJobRole';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EpJobSpecialization = {
  __typename?: 'EpJobSpecialization';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum EpJobSpecializationRoleSkillsGroupBy {
  Applicant = 'APPLICANT',
  Other = 'OTHER',
  Vacancy = 'VACANCY',
}

export type EpJobSpecializationRoleSkillsPriority = {
  jobSkillsId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export enum EpJobSpecializationRolesGroupBy {
  Applicant = 'APPLICANT',
  Other = 'OTHER',
  Vacancy = 'VACANCY',
}

export type EpJobSpecializationRolesPriority = {
  jobSpecializationRoleId?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>>
  >;
};

export type EpJobVacancies = {
  __typename?: 'EpJobVacancies';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  hasPrev?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpJobVacancy>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpJobVacanciesV2 = {
  __typename?: 'EpJobVacanciesV2';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpJobVacancy>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpJobVacancy = {
  __typename?: 'EpJobVacancy';
  benefits?: Maybe<Array<Maybe<KeyValue>>>;
  closeDate?: Maybe<Scalars['BigInt']['output']>;
  closeDateStr?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  contactDetail?: Maybe<Scalars['String']['output']>;
  contactType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  deferredLinkApp?: Maybe<Scalars['String']['output']>;
  deferredLinkWeb?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  educationLevel?: Maybe<Scalars['String']['output']>;
  educationLevelStr?: Maybe<Scalars['String']['output']>;
  educationProgramPreferences?: Maybe<Array<EpEducationProgramPreference>>;
  formattedDescription?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  genderStr?: Maybe<Scalars['String']['output']>;
  highlightedExpiredAt?: Maybe<Scalars['BigInt']['output']>;
  highlightedExpiredAtStr?: Maybe<Scalars['String']['output']>;
  highlightedStatus?: Maybe<EpHighlightedStatus>;
  hrEmail?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isHighlighted?: Maybe<Scalars['Boolean']['output']>;
  isOpenForAllEducationPrograms?: Maybe<Scalars['Boolean']['output']>;
  isOpenForFreshGraduate?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  isReposted?: Maybe<Scalars['Boolean']['output']>;
  jobApplicationStatistic?: Maybe<EpJobApplicationStatistic>;
  jobCompanyId?: Maybe<Scalars['ID']['output']>;
  jobFunction?: Maybe<EpJobVacancyFunction>;
  jobLevel?: Maybe<Scalars['String']['output']>;
  jobLevelStr?: Maybe<Scalars['String']['output']>;
  jobRole?: Maybe<EpJobRole>;
  jobSpecialization?: Maybe<EpJobSpecialization>;
  jobVacancyFunctionId?: Maybe<Scalars['ID']['output']>;
  jvSpecializationRoleId?: Maybe<Scalars['ID']['output']>;
  keyword?: Maybe<Scalars['String']['output']>;
  locationCity?: Maybe<EpLocation>;
  locationDistrict?: Maybe<EpLocation>;
  locationId?: Maybe<Scalars['ID']['output']>;
  locationProvince?: Maybe<EpLocation>;
  locationSite?: Maybe<Scalars['String']['output']>;
  locationSiteStr?: Maybe<Scalars['String']['output']>;
  maxAge?: Maybe<Scalars['Int']['output']>;
  minAge?: Maybe<Scalars['Int']['output']>;
  minExperience?: Maybe<Scalars['Int']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  positionName?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  requirements?: Maybe<Array<Maybe<EpVacancyRequirement>>>;
  restrictions?: Maybe<EpJobVacancyRestrictions>;
  salaryLowerBound?: Maybe<Scalars['Float']['output']>;
  salaryLowerBoundStr?: Maybe<Scalars['String']['output']>;
  salaryUpperBound?: Maybe<Scalars['Float']['output']>;
  salaryUpperBoundStr?: Maybe<Scalars['String']['output']>;
  skillIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  skillTags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  skillsV2?: Maybe<Array<Maybe<EpSkill>>>;
  slug?: Maybe<Scalars['String']['output']>;
  state?: Maybe<EpJobVacancyState>;
  status?: Maybe<Scalars['String']['output']>;
  tierCategory?: Maybe<EpJobVacancyTierCategoryResponse>;
  totalAccepted?: Maybe<Scalars['Int']['output']>;
  totalApplication?: Maybe<Scalars['Int']['output']>;
  totalCandidateRecommendation?: Maybe<Scalars['Int']['output']>;
  totalOnProgress?: Maybe<Scalars['Int']['output']>;
  totalPending?: Maybe<Scalars['Int']['output']>;
  totalRejected?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  typeStr?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vacancyCount?: Maybe<Scalars['Int']['output']>;
  webLink?: Maybe<Scalars['String']['output']>;
  workingDay?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  workingDayEnd?: Maybe<Scalars['Int']['output']>;
  workingDayEndStr?: Maybe<Scalars['String']['output']>;
  workingDayStart?: Maybe<Scalars['Int']['output']>;
  workingDayStartStr?: Maybe<Scalars['String']['output']>;
  workingHourEnd?: Maybe<Scalars['Int']['output']>;
  workingHourEndStr?: Maybe<Scalars['String']['output']>;
  workingHourStart?: Maybe<Scalars['Int']['output']>;
  workingHourStartStr?: Maybe<Scalars['String']['output']>;
};

export type EpJobVacancyCandidate = {
  __typename?: 'EpJobVacancyCandidate';
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  expectedSalaryStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  invitationStatus?: Maybe<EpInvitationStatus>;
  invitedAt?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  jobApplicationId?: Maybe<Scalars['ID']['output']>;
  jobCompanyId?: Maybe<Scalars['ID']['output']>;
  jobVacancyId?: Maybe<Scalars['ID']['output']>;
  matchingResult?: Maybe<Scalars['Int']['output']>;
  matchingScore?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<EpJobVacancyCandidateStatus>;
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userLastEducation?: Maybe<EpEducation>;
  userLastExperience?: Maybe<EpExperience>;
  userProfile?: Maybe<EpUserProfile>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type EpJobVacancyCandidateCount = {
  __typename?: 'EpJobVacancyCandidateCount';
  total?: Maybe<Scalars['Int']['output']>;
};

export enum EpJobVacancyCandidateEmptyState {
  NoCandidateAvailableFound = 'NO_CANDIDATE_AVAILABLE_FOUND',
  NoCandidateInvitationSentFound = 'NO_CANDIDATE_INVITATION_SENT_FOUND',
  NoCandidateNotInterestedFound = 'NO_CANDIDATE_NOT_INTERESTED_FOUND',
  NoCandidateSearchAndFilterFound = 'NO_CANDIDATE_SEARCH_AND_FILTER_FOUND',
}

export type EpJobVacancyCandidatePrevNext = {
  __typename?: 'EpJobVacancyCandidatePrevNext';
  id?: Maybe<Scalars['ID']['output']>;
  nextId?: Maybe<Scalars['ID']['output']>;
  prevId?: Maybe<Scalars['ID']['output']>;
};

export enum EpJobVacancyCandidateStatus {
  Available = 'AVAILABLE',
  InvitationSent = 'INVITATION_SENT',
  NotInterested = 'NOT_INTERESTED',
}

export type EpJobVacancyCandidateSummary = {
  __typename?: 'EpJobVacancyCandidateSummary';
  available?: Maybe<Scalars['Int']['output']>;
  invitationSent?: Maybe<Scalars['Int']['output']>;
  notInterested?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type EpJobVacancyCandidates = {
  __typename?: 'EpJobVacancyCandidates';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  hasPrev?: Maybe<Scalars['Boolean']['output']>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpJobVacancyCandidate>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<EpJobVacancyCandidateEmptyState>;
};

export type EpJobVacancyClosedFeedbackInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  jobVacancyId?: InputMaybe<Scalars['ID']['input']>;
  otherComment?: InputMaybe<Scalars['String']['input']>;
};

export type EpJobVacancyCreateDraftInput = {
  benefits?: InputMaybe<Array<EpBenefitInput>>;
  closeDate?: InputMaybe<Scalars['BigInt']['input']>;
  contactDetail?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  educationLevel?: InputMaybe<Scalars['String']['input']>;
  educationProgramPreferences?: InputMaybe<
    Array<EpEducationProgramPreferencesInput>
  >;
  formattedDescription?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  hrEmail?: InputMaybe<Scalars['String']['input']>;
  isOpenForAllEducationPrograms?: InputMaybe<Scalars['Boolean']['input']>;
  isOpenForFreshGraduate?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  jobSpecializationRoleId?: InputMaybe<Scalars['ID']['input']>;
  jobVacancyFunctionId?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationSite?: InputMaybe<Scalars['String']['input']>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minExperience?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  positionName: Scalars['String']['input'];
  restrictions?: InputMaybe<EpJobVacancyRestrictionsInput>;
  salaryLowerBound?: InputMaybe<Scalars['Float']['input']>;
  salaryUpperBound?: InputMaybe<Scalars['Float']['input']>;
  screeningQuestions?: InputMaybe<Array<InputMaybe<EpScreeningQuestionInput>>>;
  skillsV2?: InputMaybe<Array<InputMaybe<EpSkillsV2Input>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  vacancyCount?: InputMaybe<Scalars['Int']['input']>;
  workingDayEnd?: InputMaybe<Scalars['Int']['input']>;
  workingDayStart?: InputMaybe<Scalars['Int']['input']>;
  workingHourEndStr?: InputMaybe<Scalars['String']['input']>;
  workingHourStartStr?: InputMaybe<Scalars['String']['input']>;
};

export type EpJobVacancyCreateInput = {
  benefits?: InputMaybe<Array<EpBenefitInput>>;
  closeDate?: InputMaybe<Scalars['BigInt']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  contactDetail?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  educationLevel?: InputMaybe<Scalars['String']['input']>;
  educationProgramPreferences?: InputMaybe<
    Array<EpEducationProgramPreferencesInput>
  >;
  formattedDescription: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  hrEmail?: InputMaybe<Scalars['String']['input']>;
  isOpenForAllEducationPrograms?: InputMaybe<Scalars['Boolean']['input']>;
  isOpenForFreshGraduate?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  jobSpecializationRoleId?: InputMaybe<Scalars['ID']['input']>;
  jobVacancyFunctionId?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationSite?: InputMaybe<Scalars['String']['input']>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minExperience?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  positionName?: InputMaybe<Scalars['String']['input']>;
  restrictions?: InputMaybe<EpJobVacancyRestrictionsInput>;
  salaryLowerBound?: InputMaybe<Scalars['Float']['input']>;
  salaryUpperBound?: InputMaybe<Scalars['Float']['input']>;
  screeningQuestions?: InputMaybe<Array<InputMaybe<EpScreeningQuestionInput>>>;
  skillsV2?: InputMaybe<Array<InputMaybe<EpSkillsV2Input>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  usePriorityQuota?: InputMaybe<Scalars['Boolean']['input']>;
  vacancyCount?: InputMaybe<Scalars['Int']['input']>;
  workingDay?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  workingDayEnd?: InputMaybe<Scalars['Int']['input']>;
  workingDayStart?: InputMaybe<Scalars['Int']['input']>;
  workingHourEndStr?: InputMaybe<Scalars['String']['input']>;
  workingHourStartStr?: InputMaybe<Scalars['String']['input']>;
};

export type EpJobVacancyDashboard = {
  __typename?: 'EpJobVacancyDashboard';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  locationCity?: Maybe<EpLocation>;
  locationId?: Maybe<Scalars['ID']['output']>;
  positionName?: Maybe<Scalars['String']['output']>;
  totalAccepted?: Maybe<Scalars['Int']['output']>;
  totalOnProgress?: Maybe<Scalars['Int']['output']>;
  totalPending?: Maybe<Scalars['Int']['output']>;
  totalRejected?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type EpJobVacancyDashboardSummary = {
  __typename?: 'EpJobVacancyDashboardSummary';
  totalAccepted?: Maybe<Scalars['Int']['output']>;
  totalNew?: Maybe<Scalars['Int']['output']>;
  totalOnProgress?: Maybe<Scalars['Int']['output']>;
  totalPending?: Maybe<Scalars['Int']['output']>;
  totalRejected?: Maybe<Scalars['Int']['output']>;
  totalVacancy?: Maybe<Scalars['Int']['output']>;
};

export type EpJobVacancyDashboards = {
  __typename?: 'EpJobVacancyDashboards';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  hasPrev?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpJobVacancyDashboard>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpJobVacancyDistincts = {
  __typename?: 'EpJobVacancyDistincts';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  hasPrev?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpJobVacancyError = {
  __typename?: 'EpJobVacancyError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpJobVacancyErrorEnum>;
};

export enum EpJobVacancyErrorEnum {
  DuplicateName = 'DUPLICATE_NAME',
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpJobVacancyEthic = {
  __typename?: 'EpJobVacancyEthic';
  description?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
};

export type EpJobVacancyFunction = {
  __typename?: 'EpJobVacancyFunction';
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  onboarding?: Maybe<Scalars['Boolean']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type EpJobVacancyFunctions = {
  __typename?: 'EpJobVacancyFunctions';
  list?: Maybe<Array<Maybe<EpJobVacancyFunction>>>;
};

export type EpJobVacancyGeneratedLink = {
  __typename?: 'EpJobVacancyGeneratedLink';
  closeDate?: Maybe<Scalars['BigInt']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  jobPublished?: Maybe<JobPublished>;
  positionName?: Maybe<Scalars['String']['output']>;
  webLink?: Maybe<Scalars['String']['output']>;
};

export type EpJobVacancyLocationSites = {
  __typename?: 'EpJobVacancyLocationSites';
  list?: Maybe<Array<Maybe<JobVacancyLocationSitesKeyValue>>>;
};

export type EpJobVacancyPositionName = {
  __typename?: 'EpJobVacancyPositionName';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EpJobVacancyPositionNames = {
  __typename?: 'EpJobVacancyPositionNames';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpJobVacancyPositionName>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpJobVacancyRequirementCategorySub = {
  __typename?: 'EpJobVacancyRequirementCategorySub';
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAdditional?: Maybe<Scalars['Boolean']['output']>;
  jobVacancyRequirementCategoryId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type EpJobVacancyRequirementCategorySubMultiselectDropdowns = {
  __typename?: 'EpJobVacancyRequirementCategorySubMultiselectDropdowns';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  hasPrev?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpJobVacancyRequirementCategorySub>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpJobVacancyRequirementCreateInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAdditional?: InputMaybe<Scalars['Boolean']['input']>;
  jobVacancyReqCategorySubId: Scalars['ID']['input'];
  priority?: InputMaybe<Scalars['Int']['input']>;
};

/** will be moved to DMS-DGS */
export type EpJobVacancyRestrictions = {
  __typename?: 'EpJobVacancyRestrictions';
  city?: Maybe<Scalars['Boolean']['output']>;
  educationLevel?: Maybe<Scalars['Boolean']['output']>;
};

export type EpJobVacancyRestrictionsInput = {
  city?: InputMaybe<Scalars['Boolean']['input']>;
  educationLevel?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum EpJobVacancyState {
  All = 'ALL',
  Closed = 'CLOSED',
  Draft = 'DRAFT',
  OnHold = 'ON_HOLD',
  Open = 'OPEN',
}

export type EpJobVacancySummary = {
  __typename?: 'EpJobVacancySummary';
  total?: Maybe<Scalars['Int']['output']>;
  totalClosed?: Maybe<Scalars['Int']['output']>;
  totalDraft?: Maybe<Scalars['Int']['output']>;
  totalOnHold?: Maybe<Scalars['Int']['output']>;
  totalOpen?: Maybe<Scalars['Int']['output']>;
};

export type EpJobVacancyTierCategoryError = {
  __typename?: 'EpJobVacancyTierCategoryError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpJobVacancyTierCategoryErrorEnum>;
};

export enum EpJobVacancyTierCategoryErrorEnum {
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpJobVacancyTierCategoryResponse = {
  __typename?: 'EpJobVacancyTierCategoryResponse';
  id?: Maybe<JobVacancyTierCategoryEnum>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EpJobVacancyTierCategoryResult =
  | EpJobVacancyTierCategoryError
  | EpJobVacancyTierCategoryResponse;

export type EpJobVacancyTypes = {
  __typename?: 'EpJobVacancyTypes';
  list?: Maybe<Array<Maybe<KeyValue>>>;
};

export type EpJobVacancyUpdateInput = {
  benefits?: InputMaybe<Array<EpBenefitInput>>;
  closeDate?: InputMaybe<Scalars['BigInt']['input']>;
  contactDetail?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  educationLevel?: InputMaybe<Scalars['String']['input']>;
  educationProgramPreferences?: InputMaybe<
    Array<EpEducationProgramPreferencesInput>
  >;
  formattedDescription: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  hrEmail?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isOpenForAllEducationPrograms?: InputMaybe<Scalars['Boolean']['input']>;
  isOpenForFreshGraduate?: InputMaybe<Scalars['Boolean']['input']>;
  isReposted?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  jobSpecializationRoleId?: InputMaybe<Scalars['ID']['input']>;
  jobVacancyFunctionId?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationSite?: InputMaybe<Scalars['String']['input']>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minExperience?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  positionName?: InputMaybe<Scalars['String']['input']>;
  requirements?: InputMaybe<
    Array<InputMaybe<EpJobVacancyRequirementCreateInput>>
  >;
  salaryLowerBound?: InputMaybe<Scalars['Float']['input']>;
  salaryUpperBound?: InputMaybe<Scalars['Float']['input']>;
  skillsV2?: InputMaybe<Array<InputMaybe<EpSkillsV2Input>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  usePriorityQuota?: InputMaybe<Scalars['Boolean']['input']>;
  vacancyCount?: InputMaybe<Scalars['Int']['input']>;
  workingDay?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  workingDayEnd?: InputMaybe<Scalars['Int']['input']>;
  workingDayStart?: InputMaybe<Scalars['Int']['input']>;
  workingHourEndStr?: InputMaybe<Scalars['String']['input']>;
  workingHourStartStr?: InputMaybe<Scalars['String']['input']>;
};

export type EpJobVacancyV2Result = EpJobVacancy | EpJobVacancyError;

export type EpJobVacancyV3IdResponse = {
  __typename?: 'EpJobVacancyV3IdResponse';
  id?: Maybe<Scalars['String']['output']>;
};

export type EpJobVacancyV3Response = {
  __typename?: 'EpJobVacancyV3Response';
  closeDate?: Maybe<Scalars['BigInt']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  positionName?: Maybe<Scalars['String']['output']>;
  webLink?: Maybe<Scalars['String']['output']>;
};

/**  Mutation */
export type EpLocation = {
  __typename?: 'EpLocation';
  hasVacancy?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
};

export type EpLocationArea = {
  __typename?: 'EpLocationArea';
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: EpLocationAreaType;
};

export type EpLocationAreaInput = {
  areaType: EpLocationAreaType;
  name: Scalars['String']['input'];
};

export enum EpLocationAreaType {
  City = 'CITY',
  District = 'DISTRICT',
  Group = 'GROUP',
  Province = 'PROVINCE',
}

export type EpLocationAreas = {
  __typename?: 'EpLocationAreas';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  hasPrev?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<EpLocationArea>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpLocations = {
  __typename?: 'EpLocations';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  hasPrev?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpLocation>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpMenu = {
  __typename?: 'EpMenu';
  additionalRoleAccess?: Maybe<Array<Maybe<EpAdditionalRoleAccess>>>;
  displayName?: Maybe<Scalars['String']['output']>;
  iconActiveUrl?: Maybe<Scalars['String']['output']>;
  iconInactiveUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  roleAccess?: Maybe<EpRoleAccess>;
};

/**  Types */
export type EpMenuTab = {
  __typename?: 'EpMenuTab';
  badgeLabel?: Maybe<Scalars['String']['output']>;
  bgColor?: Maybe<Scalars['String']['output']>;
  countBgColor?: Maybe<Scalars['String']['output']>;
  countTextColor?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  domain: Scalars['String']['output'];
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDisplayLink?: Maybe<Scalars['Boolean']['output']>;
  isLock?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextLevelMenuTab?: Maybe<Array<Maybe<EpMenuTab>>>;
  parentId?: Maybe<Scalars['ID']['output']>;
  textColor?: Maybe<Scalars['String']['output']>;
  tooltipDescription?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/**
 * Inputs
 * Enums
 */
export enum EpMenuTabDomain {
  JobApplicant = 'JOB_APPLICANT',
  JobApplicantV2 = 'JOB_APPLICANT_V2',
  JobApplicantV3 = 'JOB_APPLICANT_V3',
  JobVacancy = 'JOB_VACANCY',
}

export type EpMigratedUserOpsResponse = {
  __typename?: 'EpMigratedUserOpsResponse';
  email?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  role?: Maybe<EpUserRole>;
};

export type EpMoveUserCompanyOpsResponse = {
  __typename?: 'EpMoveUserCompanyOpsResponse';
  error?: Maybe<Scalars['String']['output']>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  migratedUsers?: Maybe<Array<Maybe<EpMigratedUserOpsResponse>>>;
  newCompany?: Maybe<EpCompanyOps>;
};

export type EpOfferingPackage = {
  __typename?: 'EpOfferingPackage';
  btnCopy: Scalars['String']['output'];
  featureItems: Array<Scalars['String']['output']>;
  featureTitle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPopular: Scalars['Boolean']['output'];
  packageDescription: Scalars['String']['output'];
  packageName: Scalars['String']['output'];
};

export type EpOfferingPackages = {
  __typename?: 'EpOfferingPackages';
  list: Array<EpOfferingPackage>;
};

export type EpOnHoldJobVacancyV2Error = {
  __typename?: 'EpOnHoldJobVacancyV2Error';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpOnHoldJobVacancyV2ErrorEnum>;
};

export enum EpOnHoldJobVacancyV2ErrorEnum {
  AlreadyClosed = 'ALREADY_CLOSED',
  NotFound = 'NOT_FOUND',
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpOnHoldJobVacancyV2Result =
  | EpJobVacancyV3IdResponse
  | EpOnHoldJobVacancyV2Error;

export type EpOpenJobVacancyError = {
  __typename?: 'EpOpenJobVacancyError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpOpenJobVacancyErrorEnum>;
};

export enum EpOpenJobVacancyErrorEnum {
  DuplicateName = 'DUPLICATE_NAME',
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpOpenJobVacancyV2Result =
  | EpJobVacancyGeneratedLink
  | EpOpenJobVacancyError;

export type EpOpenJobVacancyV3Error = {
  __typename?: 'EpOpenJobVacancyV3Error';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpOpenJobVacancyV3ErrorEnum>;
};

export enum EpOpenJobVacancyV3ErrorEnum {
  AlreadyClosed = 'ALREADY_CLOSED',
  DuplicateName = 'DUPLICATE_NAME',
  NotFound = 'NOT_FOUND',
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpOpenJobVacancyV3Result =
  | EpJobVacancyV3Response
  | EpOpenJobVacancyV3Error;

export type EpPackageFeature = {
  __typename?: 'EpPackageFeature';
  description: Scalars['String']['output'];
  expiry?: Maybe<Scalars['String']['output']>;
  packageName: Scalars['String']['output'];
};

export type EpPackageFeatures = {
  __typename?: 'EpPackageFeatures';
  list: Array<EpPackageFeature>;
};

export type EpPingResponse = {
  __typename?: 'EpPingResponse';
  buildInfo?: Maybe<Scalars['String']['output']>;
  buildTime?: Maybe<Scalars['String']['output']>;
  now?: Maybe<Scalars['String']['output']>;
};

export type EpPreviewLocation = {
  __typename?: 'EpPreviewLocation';
  hasVacancy?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
};

export type EpPreviewSupportLink = {
  __typename?: 'EpPreviewSupportLink';
  id?: Maybe<Scalars['ID']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  supportLinkCategory?: Maybe<EpPreviewSupportLinkCategory>;
  supportLinkCategoryId?: Maybe<Scalars['ID']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type EpPreviewSupportLinkCategory = {
  __typename?: 'EpPreviewSupportLinkCategory';
  asset?: Maybe<EpPreviewSupportLinkCategoryAsset>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
};

export type EpPreviewSupportLinkCategoryAsset = {
  __typename?: 'EpPreviewSupportLinkCategoryAsset';
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  supportLinkCategoryId?: Maybe<Scalars['ID']['output']>;
};

export type EpRegisterCompanyInputV2 = {
  contactWeblink?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  jobCompanyIndustryId: Scalars['ID']['input'];
  jobCompanySizeId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type EpRegisterCompanyV2Error = {
  __typename?: 'EpRegisterCompanyV2Error';
  message: Scalars['String']['output'];
  typeError: EpRegisterCompanyV2ErrorEnum;
};

export enum EpRegisterCompanyV2ErrorEnum {
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpRegisterCompanyV2Response = {
  __typename?: 'EpRegisterCompanyV2Response';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type EpRegisterCompanyV2Result =
  | EpRegisterCompanyV2Error
  | EpRegisterCompanyV2Response;

export type EpRepostJobVacancyError = {
  __typename?: 'EpRepostJobVacancyError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpRepostJobVacancyErrorEnum>;
};

export enum EpRepostJobVacancyErrorEnum {
  CloseDateLimitExceeded = 'CLOSE_DATE_LIMIT_EXCEEDED',
  DuplicateName = 'DUPLICATE_NAME',
  QuotaNotAvailable = 'QUOTA_NOT_AVAILABLE',
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpRepostJobVacancyResult =
  | EpJobVacancyV3Response
  | EpRepostJobVacancyError;

export type EpResendSignUpLinkResponse = {
  __typename?: 'EpResendSignUpLinkResponse';
  data?: Maybe<EpSignUpResult>;
  error?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
  warning?: Maybe<Scalars['String']['output']>;
};

export type EpRoleAccess = {
  __typename?: 'EpRoleAccess';
  canApprove?: Maybe<Scalars['Boolean']['output']>;
  canCreate?: Maybe<Scalars['Boolean']['output']>;
  canDownload?: Maybe<Scalars['Boolean']['output']>;
  canRead?: Maybe<Scalars['Boolean']['output']>;
  canUpdate?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type EpScreeningQuestion = {
  __typename?: 'EpScreeningQuestion';
  answer?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  idx: Scalars['Int']['output'];
  question?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  topic: ScreeningQuestionTopic;
  value?: Maybe<Array<Maybe<EpScreeningQuestionValue>>>;
};

export type EpScreeningQuestionDocCert = {
  __typename?: 'EpScreeningQuestionDocCert';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EpScreeningQuestionDocCerts = {
  __typename?: 'EpScreeningQuestionDocCerts';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpScreeningQuestionDocCert>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpScreeningQuestionInput = {
  id: Scalars['ID']['input'];
  idx: Scalars['Int']['input'];
  topic: ScreeningQuestionTopic;
  value?: InputMaybe<Array<EpScreeningQuestionValueInput>>;
};

export type EpScreeningQuestionValue = {
  __typename?: 'EpScreeningQuestionValue';
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  idx: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type EpScreeningQuestionValueInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  idx: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type EpScreeningQuestions = {
  __typename?: 'EpScreeningQuestions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpScreeningQuestion>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpScreeningQuestionsFilter = {
  __typename?: 'EpScreeningQuestionsFilter';
  choices?: Maybe<Array<EpScreeningQuestionsFilterData>>;
  description?: Maybe<Scalars['String']['output']>;
  entries?: Maybe<Array<EpScreeningQuestionsFilterData>>;
  id?: Maybe<Scalars['ID']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  topic: ScreeningQuestionTopic;
  uiType?: Maybe<Scalars['String']['output']>;
};

export type EpScreeningQuestionsFilterData = {
  __typename?: 'EpScreeningQuestionsFilterData';
  idx?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum EpSetInterviewInvitationError {
  NameAlreadyExist = 'NAME_ALREADY_EXIST',
  NotFound = 'NOT_FOUND',
  ParameterRequired = 'PARAMETER_REQUIRED',
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpSignUpResponse = {
  __typename?: 'EpSignUpResponse';
  data?: Maybe<EpSignUpResult>;
  error?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
  warning?: Maybe<Scalars['String']['output']>;
};

export type EpSignUpResult = {
  __typename?: 'EpSignUpResult';
  id?: Maybe<Scalars['ID']['output']>;
};

export type EpSkill = {
  __typename?: 'EpSkill';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EpSkillsV2Input = {
  id: Scalars['ID']['input'];
  idx?: InputMaybe<Scalars['Int']['input']>;
};

export type EpSubmitApplicationPreviewInterestError = {
  __typename?: 'EpSubmitApplicationPreviewInterestError';
  errorCode?: Maybe<EpSubmitApplicationPreviewInterestErrorEnum>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum EpSubmitApplicationPreviewInterestErrorEnum {
  CommonError = 'COMMON_ERROR',
}

export type EpSubmitApplicationPreviewInterestResponse =
  | EpSubmitApplicationPreviewInterestError
  | EpSubmitApplicationPreviewInterestResult;

export type EpSubmitApplicationPreviewInterestResult = {
  __typename?: 'EpSubmitApplicationPreviewInterestResult';
  message?: Maybe<Scalars['String']['output']>;
};

export type EpSubmitApplicationPreviewViewStatusError = {
  __typename?: 'EpSubmitApplicationPreviewViewStatusError';
  errorCode?: Maybe<EpSubmitApplicationPreviewViewStatusErrorEnum>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum EpSubmitApplicationPreviewViewStatusErrorEnum {
  ApplicationNotFound = 'APPLICATION_NOT_FOUND',
}

export type EpSubmitApplicationPreviewViewStatusResponse =
  | EpSubmitApplicationPreviewViewStatusError
  | EpSubmitApplicationPreviewViewStatusSuccess;

export type EpSubmitApplicationPreviewViewStatusSuccess = {
  __typename?: 'EpSubmitApplicationPreviewViewStatusSuccess';
  message?: Maybe<Scalars['String']['output']>;
};

export type EpSubmitContactedPhoneNumberResponse = {
  __typename?: 'EpSubmitContactedPhoneNumberResponse';
  packageId?: Maybe<Scalars['String']['output']>;
};

export type EpSubmitContactedPhoneNumberResponseError = {
  __typename?: 'EpSubmitContactedPhoneNumberResponseError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpSubmitContactedPhoneNumberResponseErrorEnum>;
};

export enum EpSubmitContactedPhoneNumberResponseErrorEnum {
  InvalidLimitNumber = 'INVALID_LIMIT_NUMBER',
  InvalidNumber = 'INVALID_NUMBER',
  PackageNotFound = 'PACKAGE_NOT_FOUND',
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpSubmitContactedPhoneNumberResult =
  | EpSubmitContactedPhoneNumberResponse
  | EpSubmitContactedPhoneNumberResponseError;

export type EpSubscriptionPlanStatusDetail = {
  __typename?: 'EpSubscriptionPlanStatusDetail';
  description?: Maybe<Scalars['String']['output']>;
  isLimit?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type EpSupportLink = {
  __typename?: 'EpSupportLink';
  id?: Maybe<Scalars['ID']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  supportLinkCategory?: Maybe<EpSupportLinkCategory>;
  supportLinkCategoryId?: Maybe<Scalars['ID']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type EpSupportLinkCategory = {
  __typename?: 'EpSupportLinkCategory';
  asset?: Maybe<EpSupportLinkCategoryAsset>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
};

export type EpSupportLinkCategoryAsset = {
  __typename?: 'EpSupportLinkCategoryAsset';
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  supportLinkCategoryId?: Maybe<Scalars['ID']['output']>;
};

export type EpSyncPublishedJobVacancyError = {
  __typename?: 'EpSyncPublishedJobVacancyError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpSyncPublishedJobVacancyErrorEnum>;
};

export enum EpSyncPublishedJobVacancyErrorEnum {
  FreePlanOff = 'FREE_PLAN_OFF',
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpSyncPublishedJobVacancyResponse = {
  __typename?: 'EpSyncPublishedJobVacancyResponse';
  jobPublished?: Maybe<JobPublished>;
};

export type EpSyncPublishedJobVacancyResult =
  | EpSyncPublishedJobVacancyError
  | EpSyncPublishedJobVacancyResponse;

export type EpUpdateBulkJobApplicationCompanyStatusData = {
  __typename?: 'EpUpdateBulkJobApplicationCompanyStatusData';
  countFailed?: Maybe<Scalars['Int']['output']>;
  countSuccess?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Array<Maybe<EpJobApplication>>>;
};

export type EpUpdateBulkJobApplicationCompanyStatusInput = {
  companyStatus: Scalars['String']['input'];
  ids: Array<Scalars['ID']['input']>;
  reasons?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EpUpdateBulkJobApplicationCompanyStatusResponse = {
  __typename?: 'EpUpdateBulkJobApplicationCompanyStatusResponse';
  data?: Maybe<EpUpdateBulkJobApplicationCompanyStatusData>;
  error?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
  warning?: Maybe<Scalars['String']['output']>;
};

export type EpUpdateInterviewInvitationError = {
  __typename?: 'EpUpdateInterviewInvitationError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpSetInterviewInvitationError>;
};

export type EpUpdateInterviewInvitationInput = {
  applicationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  endTimeStr?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offlineDetail?: InputMaybe<EpInterviewSessionOfflineDetailInput>;
  onlineDetail?: InputMaybe<EpInterviewSessionOnlineDetailInput>;
  recruiterMessage?: InputMaybe<Scalars['String']['input']>;
  recruiterPhoneNumber: Scalars['String']['input'];
  schedule?: InputMaybe<Scalars['String']['input']>;
  sizeType?: InputMaybe<EpInterviewSessionSizeType>;
  startTimeStr?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EpInterviewSessionType>;
  vacancyId: Scalars['ID']['input'];
};

export type EpUpdateInterviewInvitationResponse =
  | EpUpdateInterviewInvitationError
  | EpUpdateInterviewInvitationSuccess;

export type EpUpdateInterviewInvitationSuccess = {
  __typename?: 'EpUpdateInterviewInvitationSuccess';
  message?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type EpUpdateJobApplicationCompanyStatusInput = {
  companyStatus: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  reasons?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EpUpdateJobApplicationIsRead = {
  id: Scalars['ID']['input'];
};

export type EpUpdateJobVacancyError = {
  __typename?: 'EpUpdateJobVacancyError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpUpdateJobVacancyErrorEnum>;
};

export enum EpUpdateJobVacancyErrorEnum {
  DuplicateName = 'DUPLICATE_NAME',
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpUpdateJobVacancyV2Result =
  | EpJobVacancyGeneratedLink
  | EpUpdateJobVacancyError;

export type EpUpdateJobVacancyV3Error = {
  __typename?: 'EpUpdateJobVacancyV3Error';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<EpUpdateJobVacancyV3ErrorEnum>;
};

export enum EpUpdateJobVacancyV3ErrorEnum {
  DuplicateName = 'DUPLICATE_NAME',
  UnknownError = 'UNKNOWN_ERROR',
}

export type EpUpdateJobVacancyV3Result =
  | EpJobVacancyV3Response
  | EpUpdateJobVacancyV3Error;

export type EpUploadResult = {
  __typename?: 'EpUploadResult';
  path?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type EpUser = {
  __typename?: 'EpUser';
  companyId?: Maybe<Scalars['ID']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  registrationStatus?: Maybe<EpUserRegistrationStatus>;
  roleId?: Maybe<Scalars['ID']['output']>;
  roleStr?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type EpUserConfig = {
  __typename?: 'EpUserConfig';
  vacancyReportSummaryPeriod?: Maybe<EpVacancyReportSummaryPeriod>;
};

export type EpUserConfigEmailNotificationIntervalDropdown = {
  __typename?: 'EpUserConfigEmailNotificationIntervalDropdown';
  description: Scalars['String']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type EpUserConfigUpdateInput = {
  vacancyReportSummaryPeriod?: InputMaybe<EpVacancyReportSummaryPeriod>;
};

export type EpUserCv = {
  __typename?: 'EpUserCv';
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  cv?: Maybe<MediaFile>;
  cvStatus?: Maybe<EpUserCvStatus>;
  id?: Maybe<Scalars['ID']['output']>;
  isMasked?: Maybe<Scalars['Boolean']['output']>;
  mediaId?: Maybe<Scalars['ID']['output']>;
  updatedAtStr?: Maybe<Scalars['String']['output']>;
  userProfileIdStr?: Maybe<Scalars['ID']['output']>;
};

export enum EpUserCvStatus {
  Available = 'AVAILABLE',
  NotAvailable = 'NOT_AVAILABLE',
  Removed = 'REMOVED',
}

export type EpUserForgotPasswordInput = {
  code: Scalars['String']['input'];
  confirmedPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  timestamp: Scalars['BigInt']['input'];
};

export type EpUserGenerateLinkInput = {
  companyId?: InputMaybe<Scalars['BigInt']['input']>;
  email: Scalars['String']['input'];
};

export type EpUserLoginInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type EpUserProfile = {
  __typename?: 'EpUserProfile';
  about?: Maybe<Scalars['String']['output']>;
  age?: Maybe<Scalars['Int']['output']>;
  birthdate?: Maybe<Scalars['BigInt']['output']>;
  birthdateLocaleStr?: Maybe<Scalars['String']['output']>;
  city?: Maybe<EpLocation>;
  cityId?: Maybe<Scalars['ID']['output']>;
  companyNote?: Maybe<Scalars['String']['output']>;
  companyStatus?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  cv?: Maybe<MediaFile>;
  cvMediaId?: Maybe<Scalars['ID']['output']>;
  district?: Maybe<EpLocation>;
  educations?: Maybe<Array<Maybe<EpEducation>>>;
  experiences?: Maybe<Array<Maybe<EpExperience>>>;
  experiencesYearStr?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  genderStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isContactRestricted: Scalars['Boolean']['output'];
  isMasked?: Maybe<Scalars['Boolean']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  lastEducation?: Maybe<EpEducation>;
  lastExperience?: Maybe<EpExperience>;
  links?: Maybe<Array<Maybe<EpSupportLink>>>;
  locationId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberMasked?: Maybe<Scalars['String']['output']>;
  phoneNumberPlain?: Maybe<Scalars['String']['output']>;
  profileUrl?: Maybe<Scalars['String']['output']>;
  province?: Maybe<EpLocation>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  skills?: Maybe<Array<Maybe<EpUserSkillV2>>>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
  updatedAtStr?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userEmailMasked?: Maybe<Scalars['String']['output']>;
  userEmailPlain?: Maybe<Scalars['String']['output']>;
  userUid?: Maybe<Scalars['String']['output']>;
};

export enum EpUserRegistrationStatus {
  Complete = 'COMPLETE',
  NotComplete = 'NOT_COMPLETE',
}

export type EpUserResetPasswordInput = {
  confirmedPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  timestamp: Scalars['BigInt']['input'];
};

export enum EpUserRole {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  Owner = 'OWNER',
  Recruiter = 'RECRUITER',
}

export type EpUserSignUpInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  confirmedPassword: Scalars['String']['input'];
  displayName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  timestamp: Scalars['BigInt']['input'];
};

export type EpUserSkill = {
  __typename?: 'EpUserSkill';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type EpUserSkillV2 = {
  __typename?: 'EpUserSkillV2';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  jvSkillId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EpUserSkills = {
  __typename?: 'EpUserSkills';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  hasPrev?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<EpUserSkill>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpUserStatus = {
  __typename?: 'EpUserStatus';
  status?: Maybe<EpUserStatusEnum>;
};

export enum EpUserStatusEnum {
  Active = 'ACTIVE',
  NotFound = 'NOT_FOUND',
  Revoked = 'REVOKED',
}

export enum EpUserSubscriptionPlanTypeEnum {
  Ba = 'BA',
  Fp = 'FP',
  NosAdv = 'NOS_ADV',
  NosGrw = 'NOS_GRW',
  NosPro = 'NOS_PRO',
  NosSme = 'NOS_SME',
  OsAdv = 'OS_ADV',
  OsBa = 'OS_BA',
  OsGrw = 'OS_GRW',
  OsPro = 'OS_PRO',
}

export type EpUserSubscriptionStatus = {
  __typename?: 'EpUserSubscriptionStatus';
  companyId?: Maybe<Scalars['ID']['output']>;
  currentPackageStr?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['BigInt']['output']>;
  freeTrialDuration?: Maybe<Scalars['Int']['output']>;
  freeTrialStatus?: Maybe<EpFreeTrialStatus>;
  info?: Maybe<Scalars['String']['output']>;
  isDisplayInfo?: Maybe<Scalars['Boolean']['output']>;
  isFreeTrial?: Maybe<Scalars['Boolean']['output']>;
  isPremium?: Maybe<Scalars['Boolean']['output']>;
  isValid?: Maybe<Scalars['Boolean']['output']>;
  jobPromoteStatus?: Maybe<EpJobPromoteStatus>;
  offerPackageUpgrade?: Maybe<Scalars['Boolean']['output']>;
  packageEnum?: Maybe<EpUserSubscriptionPlanTypeEnum>;
  packageId?: Maybe<Scalars['ID']['output']>;
  packageStr?: Maybe<Scalars['String']['output']>;
  refreshDate?: Maybe<Scalars['BigInt']['output']>;
  status?: Maybe<EpUserSubscriptionStatusDetail>;
};

export type EpUserSubscriptionStatusDetail = {
  __typename?: 'EpUserSubscriptionStatusDetail';
  hrAcc?: Maybe<Scalars['Int']['output']>;
  jobAppActive?: Maybe<Scalars['Int']['output']>;
  jobAppMax?: Maybe<Scalars['Int']['output']>;
  jobPostingActive?: Maybe<Scalars['Int']['output']>;
  jobPostingMax?: Maybe<Scalars['Int']['output']>;
  jobPromoteMax?: Maybe<Scalars['Int']['output']>;
};

export type EpUserSubscriptionType = {
  __typename?: 'EpUserSubscriptionType';
  btnConfirmationCopy?: Maybe<Scalars['String']['output']>;
  btnCopy?: Maybe<Scalars['String']['output']>;
  confirmationAsset?: Maybe<Scalars['String']['output']>;
  confirmationBodyCopy?: Maybe<Scalars['String']['output']>;
  confirmationTitleCopy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isRecommended?: Maybe<Scalars['Boolean']['output']>;
  isSelected?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subscriptionTypeItems: Array<Maybe<Scalars['String']['output']>>;
};

export type EpUserSubscriptionTypes = {
  __typename?: 'EpUserSubscriptionTypes';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  hasPrev?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<Maybe<EpUserSubscriptionType>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type EpUserUpdateInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum EpVacancyReportSummaryPeriod {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type EpVacancyRequirement = {
  __typename?: 'EpVacancyRequirement';
  id?: Maybe<Scalars['ID']['output']>;
  isAdditional?: Maybe<Scalars['Boolean']['output']>;
  jobVacancyId?: Maybe<Scalars['ID']['output']>;
  jobVacancyReqCategorySubId?: Maybe<Scalars['ID']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  vacancyReqCategorySub?: Maybe<EpJobVacancyRequirementCategorySub>;
};

export type EpValidateInterviewSessionName = {
  __typename?: 'EpValidateInterviewSessionName';
  code: EpValidateInterviewSessionNameCode;
  message?: Maybe<Scalars['String']['output']>;
};

export enum EpValidateInterviewSessionNameCode {
  CharacterLengthError = 'CHARACTER_LENGTH_ERROR',
  Exists = 'EXISTS',
  InvalidCharacter = 'INVALID_CHARACTER',
  Valid = 'VALID',
}

export type EpVerifiedSkill = {
  __typename?: 'EpVerifiedSkill';
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  skillName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export enum EpVerifyCodeStatus {
  AlreadyRegistered = 'ALREADY_REGISTERED',
  Expired = 'EXPIRED',
  Invalid = 'INVALID',
  Success = 'SUCCESS',
}

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = 'UNKNOWN',
}

export type ErrorModal = {
  content?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = 'UNKNOWN',
}

export type Event = {
  __typename?: 'Event';
  eventName?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type EventTracking = {
  __typename?: 'EventTracking';
  eventBody?: Maybe<Scalars['String']['output']>;
  eventName?: Maybe<Scalars['String']['output']>;
};

export type Exam = {
  __typename?: 'Exam';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  examResults?: Maybe<Array<Maybe<ExamResult>>>;
  id?: Maybe<Scalars['ID']['output']>;
  isTimeLimited?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  maxPoint?: Maybe<Scalars['Float']['output']>;
  msExamId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  timeLimit?: Maybe<Scalars['Float']['output']>;
  totalQuestions?: Maybe<Scalars['Int']['output']>;
};

export type ExamCategoryResult = {
  __typename?: 'ExamCategoryResult';
  categoryName?: Maybe<Scalars['String']['output']>;
  maxScore?: Maybe<Scalars['Int']['output']>;
  msCategoryMainId?: Maybe<Scalars['ID']['output']>;
  passed?: Maybe<Scalars['Boolean']['output']>;
  passedStr?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  scorePcg?: Maybe<Scalars['String']['output']>;
};

export type ExamDisplayCategory = {
  __typename?: 'ExamDisplayCategory';
  code?: Maybe<Scalars['ID']['output']>;
  details?: Maybe<Array<Maybe<ExamDisplayCategorySub>>>;
  examPackageId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ExamDisplayCategorySub = {
  __typename?: 'ExamDisplayCategorySub';
  code?: Maybe<Scalars['ID']['output']>;
  examDisplayCategoryId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ExamPackage = {
  __typename?: 'ExamPackage';
  code?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  displays?: Maybe<Array<Maybe<ExamDisplayCategory>>>;
  id?: Maybe<Scalars['ID']['output']>;
  isSubscription?: Maybe<Scalars['Boolean']['output']>;
  last?: Maybe<Exam>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ExamPackageDetail = {
  __typename?: 'ExamPackageDetail';
  duration?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<MsExam>;
  examId?: Maybe<Scalars['ID']['output']>;
  examPackageDisplayId?: Maybe<Scalars['ID']['output']>;
  examUser?: Maybe<Exam>;
  id?: Maybe<Scalars['ID']['output']>;
  isFree?: Maybe<Scalars['Boolean']['output']>;
  isLiveExam?: Maybe<Scalars['Boolean']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type ExamPackageDetails = {
  __typename?: 'ExamPackageDetails';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<ExamPackageDetail>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type ExamPackageUser = {
  __typename?: 'ExamPackageUser';
  examPackageId?: Maybe<Scalars['ID']['output']>;
  expiredAt?: Maybe<Scalars['String']['output']>;
  expiredAtStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isExpired?: Maybe<Scalars['Boolean']['output']>;
};

export type ExamPackageUsers = {
  __typename?: 'ExamPackageUsers';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<ExamPackageUser>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type ExamQuestionStatistic = {
  __typename?: 'ExamQuestionStatistic';
  avgTime?: Maybe<Scalars['Float']['output']>;
  avgTimeStr?: Maybe<Scalars['String']['output']>;
  correctPcg?: Maybe<Scalars['Float']['output']>;
  correctPcgStr?: Maybe<Scalars['String']['output']>;
  mostCommonAnswerId?: Maybe<Scalars['ID']['output']>;
  mostWrongAnswerId?: Maybe<Scalars['ID']['output']>;
  questionId?: Maybe<Scalars['ID']['output']>;
};

export type ExamResult = {
  __typename?: 'ExamResult';
  avgScore?: Maybe<Scalars['Float']['output']>;
  categoryResults?: Maybe<Array<Maybe<ExamCategoryResult>>>;
  copy?: Maybe<Scalars['String']['output']>;
  createdAtLocale?: Maybe<Scalars['String']['output']>;
  elapsedTime?: Maybe<Scalars['Float']['output']>;
  elapsedTimeStr?: Maybe<Scalars['String']['output']>;
  highestScore?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  maxPoint?: Maybe<Scalars['Float']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
  questions?: Maybe<Array<Maybe<ExamResultQuestion>>>;
  rank?: Maybe<Scalars['Float']['output']>;
  rankStr?: Maybe<Scalars['String']['output']>;
  totalCorrectAnswers?: Maybe<Scalars['Int']['output']>;
  totalQuestion?: Maybe<Scalars['Int']['output']>;
};

export type ExamResultQuestion = {
  __typename?: 'ExamResultQuestion';
  answerId?: Maybe<Scalars['ID']['output']>;
  answerIsLatex?: Maybe<Scalars['Boolean']['output']>;
  answerText?: Maybe<Scalars['String']['output']>;
  correctAnswerId?: Maybe<Scalars['ID']['output']>;
  correctAnswerIsLatex?: Maybe<Scalars['Boolean']['output']>;
  correctAnswerText?: Maybe<Scalars['String']['output']>;
  elapsedTime?: Maybe<Scalars['Float']['output']>;
  elapsedTimeStr?: Maybe<Scalars['String']['output']>;
  examId?: Maybe<Scalars['ID']['output']>;
  explanation?: Maybe<Explanation>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isDifficult?: Maybe<Scalars['Boolean']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
  question?: Maybe<Question>;
  questionId?: Maybe<Scalars['ID']['output']>;
  statistic?: Maybe<ExamQuestionStatistic>;
};

export type ExamSession = {
  __typename?: 'ExamSession';
  exam?: Maybe<MsExam>;
  examId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  startAt?: Maybe<Scalars['String']['output']>;
  startAtStr?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ExamSummarySku = {
  __typename?: 'ExamSummarySku';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountType?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isDiscount?: Maybe<Scalars['Boolean']['output']>;
  isFree?: Maybe<Scalars['Boolean']['output']>;
  isGuarantee?: Maybe<Scalars['Boolean']['output']>;
  isInstallment?: Maybe<Scalars['Boolean']['output']>;
  msCurrencyId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceStr?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  skus?: Maybe<Array<Maybe<Sku>>>;
  slug?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  validityColor?: Maybe<Scalars['String']['output']>;
  validityPeriod?: Maybe<Scalars['String']['output']>;
  validityPeriodStr?: Maybe<Scalars['String']['output']>;
  validityValue?: Maybe<Scalars['Int']['output']>;
};

export type ExamTryout = {
  __typename?: 'ExamTryout';
  examPackageDetails?: Maybe<Array<Maybe<ExamPackageDetail>>>;
  examPackageUser?: Maybe<ExamPackageUser>;
};

export type ExamTryout2 = {
  __typename?: 'ExamTryout2';
  examPackageDetails?: Maybe<ExamPackageDetails>;
  examPackageUser?: Maybe<ExamPackageUser>;
};

export type Experience = {
  __typename?: 'Experience';
  companyName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionFormatted?: Maybe<Scalars['String']['output']>;
  employmentType?: Maybe<EmploymentType>;
  endMonth?: Maybe<Scalars['Int']['output']>;
  endYear?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  jobFunction?: Maybe<KeyValue>;
  jobSpecializationRole?: Maybe<JobSpecializationRole>;
  jobSpecializationRoleId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  periodStr?: Maybe<Scalars['String']['output']>;
  skills?: Maybe<Array<Maybe<KeyValue>>>;
  startMonth?: Maybe<Scalars['Int']['output']>;
  startYear?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type ExperienceCreateInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endYear?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startYear?: InputMaybe<Scalars['String']['input']>;
  userProfileId: Scalars['ID']['input'];
};

export type ExperienceCreateInputProfile = {
  description?: InputMaybe<Scalars['String']['input']>;
  endYear?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startYear?: InputMaybe<Scalars['String']['input']>;
};

export type ExperienceCreateInputV2 = {
  companyName: Scalars['String']['input'];
  description: Scalars['String']['input'];
  endYear?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startYear?: InputMaybe<Scalars['String']['input']>;
  userProfileId: Scalars['ID']['input'];
};

export type ExperienceCreateInputV3 = {
  companyName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  endMonth?: InputMaybe<Scalars['Int']['input']>;
  endYear?: InputMaybe<Scalars['String']['input']>;
  inputEmploymentType?: InputMaybe<Scalars['String']['input']>;
  inputJobFunction?: InputMaybe<Scalars['String']['input']>;
  inputSkills?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  startMonth?: InputMaybe<Scalars['Int']['input']>;
  startYear?: InputMaybe<Scalars['String']['input']>;
  userProfileId: Scalars['ID']['input'];
};

export type ExperienceCreateInputV4 = {
  companyName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionFormatted?: InputMaybe<Scalars['String']['input']>;
  endMonth?: InputMaybe<Scalars['Int']['input']>;
  endYear?: InputMaybe<Scalars['String']['input']>;
  inputEmploymentType?: InputMaybe<Scalars['String']['input']>;
  jobSpecializationRoleId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startMonth?: InputMaybe<Scalars['Int']['input']>;
  startYear?: InputMaybe<Scalars['String']['input']>;
  userProfileId: Scalars['ID']['input'];
};

export type ExperienceJobFunctions = {
  __typename?: 'ExperienceJobFunctions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<KeyValue>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type ExperienceUpdateInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endYear?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  startYear?: InputMaybe<Scalars['String']['input']>;
};

export type ExperienceUpdateInputV2 = {
  companyName: Scalars['String']['input'];
  description: Scalars['String']['input'];
  endYear?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  startYear?: InputMaybe<Scalars['String']['input']>;
};

export type ExperienceUpdateInputV3 = {
  companyName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  endMonth?: InputMaybe<Scalars['Int']['input']>;
  endYear?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  inputEmploymentType?: InputMaybe<Scalars['String']['input']>;
  inputJobFunction?: InputMaybe<Scalars['String']['input']>;
  inputSkills?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  startMonth?: InputMaybe<Scalars['Int']['input']>;
  startYear?: InputMaybe<Scalars['String']['input']>;
};

export type ExperienceUpdateInputV4 = {
  companyName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionFormatted?: InputMaybe<Scalars['String']['input']>;
  endMonth?: InputMaybe<Scalars['Int']['input']>;
  endYear?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  inputEmploymentType?: InputMaybe<Scalars['String']['input']>;
  jobSpecializationRoleId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startMonth?: InputMaybe<Scalars['Int']['input']>;
  startYear?: InputMaybe<Scalars['String']['input']>;
};

export type Explanation = {
  __typename?: 'Explanation';
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isLatex?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['ID']['output']>;
  videoInfo?: Maybe<Video>;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  dataType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  resetDate?: Maybe<Scalars['Date']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Feed = {
  __typename?: 'Feed';
  code?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Array<Maybe<FeedComment>>>;
  community?: Maybe<CommunityGroup>;
  communityId?: Maybe<Scalars['ID']['output']>;
  communityMember?: Maybe<CommunityMember>;
  communityMemberId?: Maybe<Scalars['ID']['output']>;
  communityName?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  countComment?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdAtLocale?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  fieldType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  images?: Maybe<Array<Maybe<FeedImage>>>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAuthor?: Maybe<Scalars['Boolean']['output']>;
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  isPublish?: Maybe<Scalars['Boolean']['output']>;
  isVote?: Maybe<Scalars['Boolean']['output']>;
  job?: Maybe<FeedJob>;
  mission?: Maybe<Mission>;
  pinMsg?: Maybe<Scalars['String']['output']>;
  poll?: Maybe<FeedPoll>;
  quiz?: Maybe<FeedQuiz>;
  role?: Maybe<MemberRole>;
  shareLink?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  voteCount?: Maybe<Scalars['Int']['output']>;
  votes?: Maybe<Array<Maybe<FeedVote>>>;
};

export type FeedComment = {
  __typename?: 'FeedComment';
  comments?: Maybe<Array<Maybe<FeedCommentSub>>>;
  communityMember?: Maybe<CommunityMember>;
  communityMemberId?: Maybe<Scalars['ID']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdAtLocale?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  feedId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAuthor?: Maybe<Scalars['Boolean']['output']>;
  isBan?: Maybe<Scalars['String']['output']>;
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  isMultipleCommentSub?: Maybe<Scalars['Boolean']['output']>;
  isVote?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  voteCount?: Maybe<Scalars['Int']['output']>;
  votes?: Maybe<Array<Maybe<FeedCommentVote>>>;
};

export type FeedCommentSub = {
  __typename?: 'FeedCommentSub';
  communityMember?: Maybe<CommunityMember>;
  communityMemberId?: Maybe<Scalars['ID']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdAtLocale?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  feedId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAuthor?: Maybe<Scalars['Boolean']['output']>;
  isBan?: Maybe<Scalars['String']['output']>;
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  isVote?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  voteCount?: Maybe<Scalars['Int']['output']>;
  votes?: Maybe<Array<Maybe<FeedCommentVote>>>;
};

export type FeedCommentSubs = {
  __typename?: 'FeedCommentSubs';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<FeedCommentSub>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type FeedCommentVote = {
  __typename?: 'FeedCommentVote';
  communityMember?: Maybe<CommunityMember>;
  communityMemberId?: Maybe<Scalars['ID']['output']>;
  feedCommentId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type FeedComments = {
  __typename?: 'FeedComments';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<FeedComment>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type FeedDiscussion = {
  __typename?: 'FeedDiscussion';
  countryId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['String']['output']>;
  discountType?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isDiscount?: Maybe<Scalars['Boolean']['output']>;
  isFree?: Maybe<Scalars['Boolean']['output']>;
  isLive?: Maybe<Scalars['Boolean']['output']>;
  isPublish?: Maybe<Scalars['Boolean']['output']>;
  liveAt?: Maybe<Scalars['String']['output']>;
  liveAtFormatted?: Maybe<Scalars['String']['output']>;
  liveAtLocale?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pretestUrl?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  shortDesc?: Maybe<Scalars['String']['output']>;
  teacherId?: Maybe<Scalars['ID']['output']>;
  teacherStr?: Maybe<Scalars['String']['output']>;
  totalView?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type FeedDiscussionDetailView = {
  __typename?: 'FeedDiscussionDetailView';
  /** @deprecated move to CsPackage */
  detail?: Maybe<FeedDiscussion>;
  totalView?: Maybe<Scalars['Int']['output']>;
};

export type FeedDiscussionListView = {
  __typename?: 'FeedDiscussionListView';
  /** @deprecated move to CsPackage */
  past?: Maybe<Array<Maybe<FeedDiscussion>>>;
  /** @deprecated move to CsPackage */
  today?: Maybe<Array<Maybe<FeedDiscussion>>>;
  /** @deprecated move to CsPackage */
  upcoming?: Maybe<Array<Maybe<FeedDiscussion>>>;
};

export type FeedDiscussionSearchListView = {
  __typename?: 'FeedDiscussionSearchListView';
  /** @deprecated move to CsPackage */
  past?: Maybe<Array<Maybe<FeedDiscussion>>>;
  /** @deprecated move to CsPackage */
  today?: Maybe<Array<Maybe<FeedDiscussion>>>;
  /** @deprecated move to CsPackage */
  upcoming?: Maybe<Array<Maybe<FeedDiscussion>>>;
};

export type FeedImage = {
  __typename?: 'FeedImage';
  feedId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  mediaId?: Maybe<Scalars['String']['output']>;
  originalImageUrl?: Maybe<Scalars['String']['output']>;
};

export type FeedJob = {
  __typename?: 'FeedJob';
  feedId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  jobVacancyId?: Maybe<Scalars['ID']['output']>;
  vacancy?: Maybe<Vacancy>;
};

export type FeedPinResult = {
  __typename?: 'FeedPinResult';
  copy?: Maybe<Scalars['String']['output']>;
  isRefresh?: Maybe<Scalars['Boolean']['output']>;
};

export type FeedPoll = {
  __typename?: 'FeedPoll';
  createdAtLocale?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Array<Maybe<FeedPollDetail>>>;
  endTime?: Maybe<Scalars['String']['output']>;
  feedId?: Maybe<Scalars['ID']['output']>;
  hasVoted?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isMultipleAnswer?: Maybe<Scalars['Boolean']['output']>;
  participants?: Maybe<Scalars['Int']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
};

export type FeedPollDetail = {
  __typename?: 'FeedPollDetail';
  content?: Maybe<Scalars['String']['output']>;
  countVotePcg?: Maybe<Scalars['Float']['output']>;
  feedPollId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  /** isVoted can only be used in query feedPollDetailIsVoted */
  isVoted?: Maybe<Scalars['Boolean']['output']>;
  voteCount?: Maybe<Scalars['Int']['output']>;
};

export type FeedQuiz = {
  __typename?: 'FeedQuiz';
  createdAtLocale?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Array<Maybe<FeedQuizDetail>>>;
  endTime?: Maybe<Scalars['String']['output']>;
  explanation?: Maybe<Scalars['String']['output']>;
  feedId?: Maybe<Scalars['ID']['output']>;
  hasVoted?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isLatex?: Maybe<Scalars['Boolean']['output']>;
  isMultipleAnswer?: Maybe<Scalars['Boolean']['output']>;
  participants?: Maybe<Scalars['Int']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
};

export type FeedQuizDetail = {
  __typename?: 'FeedQuizDetail';
  content?: Maybe<Scalars['String']['output']>;
  countVotePcg?: Maybe<Scalars['Float']['output']>;
  createdAtLocale?: Maybe<Scalars['String']['output']>;
  feedQuizId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isAnswer?: Maybe<Scalars['Boolean']['output']>;
  isLatex?: Maybe<Scalars['Boolean']['output']>;
  /** isUserAnswer can only be used in query feedQuizDetailIsAnswered */
  isUserAnswer?: Maybe<Scalars['Boolean']['output']>;
  voteCount?: Maybe<Scalars['Int']['output']>;
};

export type FeedReportCategory = {
  __typename?: 'FeedReportCategory';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type FeedVote = {
  __typename?: 'FeedVote';
  communityMemberId?: Maybe<Scalars['ID']['output']>;
  feedId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type Feedback = {
  domain: FeedbackDomain;
  feedback?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
};

export enum FeedbackDomain {
  JobInsightFreeTrial = 'JOB_INSIGHT_FREE_TRIAL',
}

export type FeedbackQuestion = {
  __typename?: 'FeedbackQuestion';
  createdAt?: Maybe<Scalars['String']['output']>;
  feedbackId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  scaleMax?: Maybe<Scalars['Int']['output']>;
  scaleMin?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<QuestionType>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type FeedbackSubmitError = {
  __typename?: 'FeedbackSubmitError';
  message: Scalars['String']['output'];
  type: FeedbackSubmitErrorType;
};

export enum FeedbackSubmitErrorType {
  UnknownError = 'UNKNOWN_ERROR',
}

export type FeedbackSubmitResponse =
  | FeedbackSubmitError
  | FeedbackSubmitSuccess;

export type FeedbackSubmitSuccess = {
  __typename?: 'FeedbackSubmitSuccess';
  message: Scalars['String']['output'];
};

export type Feeds = {
  __typename?: 'Feeds';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<Feed>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type Filter = {
  __typename?: 'Filter';
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
};

export type FilterItems = {
  __typename?: 'FilterItems';
  copy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  event?: Maybe<EventTracking>;
  isSelected?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
};

export type FilterType = {
  __typename?: 'FilterType';
  displayName?: Maybe<Scalars['String']['output']>;
  isSingleSelection?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Maybe<FilterItems>>>;
  key?: Maybe<Scalars['String']['output']>;
  modalTitle?: Maybe<Scalars['String']['output']>;
};

export type FooterLink = {
  __typename?: 'FooterLink';
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  section?: Maybe<FooterSectionEnum>;
  slug?: Maybe<Scalars['String']['output']>;
};

export enum FooterSectionEnum {
  AboutCompany = 'ABOUT_COMPANY',
  CategoriesPerLocation = 'CATEGORIES_PER_LOCATION',
  CitiesPerCategory = 'CITIES_PER_CATEGORY',
  City = 'CITY',
  CompanyBlog = 'COMPANY_BLOG',
  CompanyLinks = 'COMPANY_LINKS',
  Education = 'EDUCATION',
  JobCategory = 'JOB_CATEGORY',
  JobIndustry = 'JOB_INDUSTRY',
  JobRecommendation = 'JOB_RECOMMENDATION',
  JobType = 'JOB_TYPE',
  Kitalulus = 'KITALULUS',
  LocationsPerCategory = 'LOCATIONS_PER_CATEGORY',
  Province = 'PROVINCE',
  ProvincesPerCategory = 'PROVINCES_PER_CATEGORY',
}

export type FreeExam = {
  __typename?: 'FreeExam';
  avgScore?: Maybe<Scalars['Float']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  examUser?: Maybe<Exam>;
  id?: Maybe<Scalars['ID']['output']>;
  isTaken?: Maybe<Scalars['Boolean']['output']>;
  isTimeLimited?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  maxPoint?: Maybe<Scalars['Float']['output']>;
  maxScore?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  timeLimit?: Maybe<Scalars['Float']['output']>;
  totalQuestion?: Maybe<Scalars['Int']['output']>;
};

export enum FreeTrialDomain {
  JobInsightFreeTrial = 'JOB_INSIGHT_FREE_TRIAL',
}

export type GenerateCvUserFeedbackInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['Int']['input'];
  referenceId: Scalars['ID']['input'];
  referenceType?: InputMaybe<GenerateCvUserFeedbackInputReferenceTypeEnum>;
};

export enum GenerateCvUserFeedbackInputReferenceTypeEnum {
  CvTemplate = 'CV_TEMPLATE',
  GeneratedCv = 'GENERATED_CV',
  JobApplication = 'JOB_APPLICATION',
}

export type GeneratedJobDescription = {
  __typename?: 'GeneratedJobDescription';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type GeneratedJobDescriptions = {
  __typename?: 'GeneratedJobDescriptions';
  list?: Maybe<Array<GeneratedJobDescription>>;
  quotaCount?: Maybe<Scalars['Int']['output']>;
  selectedCount?: Maybe<Scalars['Int']['output']>;
  userProfileId: Scalars['ID']['output'];
};

export type GlobalConfig = {
  __typename?: 'GlobalConfig';
  configuration?: Maybe<Array<Maybe<Configuration>>>;
  featureFlag?: Maybe<Array<Maybe<FeatureFlag>>>;
};

export type GovInstitute = {
  __typename?: 'GovInstitute';
  code?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type GovInstitutes = {
  __typename?: 'GovInstitutes';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<GovInstitute>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type GovPosition = {
  __typename?: 'GovPosition';
  code?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type GovPositions = {
  __typename?: 'GovPositions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<GovPosition>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type GovScore = {
  __typename?: 'GovScore';
  description?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  totalApplicant?: Maybe<Scalars['Int']['output']>;
  totalPosition?: Maybe<Scalars['Int']['output']>;
};

export type Guideline = {
  __typename?: 'Guideline';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  guidelineType?: Maybe<GuidelineType>;
  guidelineTypeId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type GuidelinePaging = {
  __typename?: 'GuidelinePaging';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<Guideline>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type GuidelineType = {
  __typename?: 'GuidelineType';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type HomeCategory = {
  __typename?: 'HomeCategory';
  details?: Maybe<Array<Maybe<HomeCategoryDetail>>>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isLimit?: Maybe<Scalars['Boolean']['output']>;
  isLoadDetails?: Maybe<Scalars['Boolean']['output']>;
  isRecommended?: Maybe<Scalars['Boolean']['output']>;
  loadHomeCategoryDetail?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  showOnlyRecommendation?: Maybe<Scalars['Boolean']['output']>;
};

export type HomeCategoryAsset = {
  __typename?: 'HomeCategoryAsset';
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
};

export type HomeCategoryDetail = {
  __typename?: 'HomeCategoryDetail';
  asset?: Maybe<HomeCategoryAsset>;
  displayName?: Maybe<Scalars['String']['output']>;
  event?: Maybe<EventTracking>;
  homeCategory?: Maybe<HomeCategory>;
  homeCategoryId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  loadHomeCategory?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
};

export type HomeCategoryDetailV2 = {
  __typename?: 'HomeCategoryDetailV2';
  asset?: Maybe<HomeCategoryAsset>;
  displayName?: Maybe<Scalars['String']['output']>;
  event?: Maybe<EventTracking>;
  homeCategory?: Maybe<HomeCategoryV2>;
  homeCategoryId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type HomeCategoryDetailV2AssetArgs = {
  screenDensity?: InputMaybe<ScreenDensityEnum>;
};

export type HomeCategoryV2 = {
  __typename?: 'HomeCategoryV2';
  details?: Maybe<Array<HomeCategoryDetailV2>>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isLimit?: Maybe<Scalars['Boolean']['output']>;
  isRecommended?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
};

export type HomeCommunity = {
  __typename?: 'HomeCommunity';
  communities?: Maybe<CommunityGroups>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type HomeCommunityActivities = {
  __typename?: 'HomeCommunityActivities';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<HomeCommunityActivity>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type HomeCommunityActivity = {
  __typename?: 'HomeCommunityActivity';
  code?: Maybe<Scalars['String']['output']>;
  copy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  feeds?: Maybe<Array<Maybe<Feed>>>;
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isJoined?: Maybe<Scalars['Boolean']['output']>;
  isMute?: Maybe<Scalars['Boolean']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  isPublish?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
};

export type HomeDynamicBanner = {
  __typename?: 'HomeDynamicBanner';
  actionType?: Maybe<Scalars['String']['output']>;
  actionUrl?: Maybe<Scalars['String']['output']>;
  banner?: Maybe<DynamicBannerSet>;
  event?: Maybe<EventTracking>;
  id?: Maybe<Scalars['ID']['output']>;
  rules?: Maybe<BannerRules>;
};

export type HomeFilter = {
  __typename?: 'HomeFilter';
  details?: Maybe<Array<Maybe<HomeFilterDetail>>>;
  displayName?: Maybe<Scalars['String']['output']>;
  event?: Maybe<EventTracking>;
  id?: Maybe<Scalars['ID']['output']>;
  isLoadDetails?: Maybe<Scalars['Boolean']['output']>;
  isSingleSelection?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
};

export type HomeFilterAndCategory = {
  __typename?: 'HomeFilterAndCategory';
  homeCategory?: Maybe<Array<Maybe<HomeCategory>>>;
  homeFilter?: Maybe<Array<Maybe<HomeFilter>>>;
  homeSortBy?: Maybe<Array<Maybe<HomeFilter>>>;
};

export type HomeFilterDetail = {
  __typename?: 'HomeFilterDetail';
  asset?: Maybe<HomeFilterDetailAsset>;
  displayName?: Maybe<Scalars['String']['output']>;
  event?: Maybe<EventTracking>;
  homeFilter?: Maybe<HomeFilter>;
  homeFilterId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDefaultSelected?: Maybe<Scalars['Boolean']['output']>;
  isPremium?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
};

export type HomeFilterDetailAsset = {
  __typename?: 'HomeFilterDetailAsset';
  imageUrl?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
};

export type HomeFilterPopular = {
  __typename?: 'HomeFilterPopular';
  displayName?: Maybe<Scalars['String']['output']>;
  homeFilterDetail?: Maybe<Array<Maybe<HomeFilterDetail>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type HomeFilterSearchRecommendation = {
  __typename?: 'HomeFilterSearchRecommendation';
  homeCategory?: Maybe<Array<Maybe<HomeCategory>>>;
  homeFilterPopular?: Maybe<HomeFilterPopular>;
};

export type HomeProfileCompleteness = {
  __typename?: 'HomeProfileCompleteness';
  completeness?: Maybe<Scalars['Int']['output']>;
  completenessStr?: Maybe<Scalars['String']['output']>;
  homeProgressBarTemplateDetail?: Maybe<HomeProgressBarTemplateDetail>;
};

export type HomeProfileCompletenessV2 = {
  __typename?: 'HomeProfileCompletenessV2';
  completeness?: Maybe<Scalars['Int']['output']>;
  completenessStr?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  subTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type HomeProgressBarTemplate = {
  __typename?: 'HomeProgressBarTemplate';
  id?: Maybe<Scalars['ID']['output']>;
  lowerPercentage?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  upperPercentage?: Maybe<Scalars['Int']['output']>;
};

export type HomeProgressBarTemplateDetail = {
  __typename?: 'HomeProgressBarTemplateDetail';
  content?: Maybe<Scalars['String']['output']>;
  homeProgressBarTemplate?: Maybe<HomeProgressBarTemplate>;
  homeProgressBarTemplateId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export enum HomeSectionNameEnum {
  Recommendation = 'RECOMMENDATION',
  RecommendationLatestApplied = 'RECOMMENDATION_LATEST_APPLIED',
  RecommendationProfile = 'RECOMMENDATION_PROFILE',
  RecommendationWorkPreference = 'RECOMMENDATION_WORK_PREFERENCE',
  SelectedVacancy = 'SELECTED_VACANCY',
}

export type HomeVacancySection = {
  __typename?: 'HomeVacancySection';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isLoadVacancies?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  /** @deprecated drop */
  vacancies?: Maybe<Array<Maybe<HomeVacancySectionDetail>>>;
};

export type HomeVacancySectionDetail = {
  __typename?: 'HomeVacancySectionDetail';
  id?: Maybe<Scalars['ID']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  vacancy?: Maybe<Vacancy>;
  vacancyId?: Maybe<Scalars['ID']['output']>;
};

export type HomeVacancySectionDetails = {
  __typename?: 'HomeVacancySectionDetails';
  copy?: Maybe<Scalars['String']['output']>;
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<HomeVacancySectionDetail>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type HomeView = {
  __typename?: 'HomeView';
  bannerButton?: Maybe<Array<Maybe<Banner>>>;
  /** @deprecated drop */
  bannerHighlights?: Maybe<Array<Maybe<Banner>>>;
  /** @deprecated drop */
  banners?: Maybe<Array<Maybe<Banner>>>;
  categories?: Maybe<Array<Maybe<SkuCategory>>>;
  /** @deprecated drop */
  classSection?: Maybe<Array<Maybe<Sku>>>;
  /** @deprecated move to Sku */
  csPackages?: Maybe<Array<Maybe<CsPackage>>>;
  /** @deprecated drop */
  examSection?: Maybe<Array<Maybe<Sku>>>;
  /** @deprecated move to CsPackage */
  feedDiscussions?: Maybe<Array<Maybe<FeedDiscussion>>>;
  freeExams?: Maybe<Array<Maybe<FreeExam>>>;
  popup?: Maybe<Banner>;
  /** @deprecated drop */
  skus?: Maybe<Array<Maybe<Sku>>>;
  topBanner?: Maybe<Banner>;
};

export type HomeViewV2 = {
  __typename?: 'HomeViewV2';
  applicationDashboard?: Maybe<ApplicationDashboard>;
  /** @deprecated move to query marketingBanners */
  banners?: Maybe<Array<Maybe<Banner>>>;
  dynamicBanner?: Maybe<Array<Maybe<HomeDynamicBanner>>>;
  filters?: Maybe<Array<Maybe<VacancyKeyword>>>;
  freeExams?: Maybe<Array<Maybe<FreeExam>>>;
  homeCategories?: Maybe<Array<Maybe<HomeCategoryDetail>>>;
  latestVacancies?: Maybe<Array<Maybe<Vacancy>>>;
  popup?: Maybe<Banner>;
  recommendations?: Maybe<Array<Maybe<Vacancy>>>;
  topBanner?: Maybe<Banner>;
  /** @deprecated move to topSectionV2 */
  topSection?: Maybe<HomeVacancySection>;
  /** @deprecated move to query topSectionV2 */
  topSectionV2?: Maybe<Array<Maybe<Vacancy>>>;
  /** @deprecated move to query topVacancies */
  topVacancies?: Maybe<Array<Maybe<Vacancy>>>;
  /** @deprecated Field no longer supported */
  vacancySections?: Maybe<Array<Maybe<HomeVacancySection>>>;
};

export type HomepageInterviewInformation = {
  __typename?: 'HomepageInterviewInformation';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  textColor?: Maybe<Scalars['String']['output']>;
};

export type InboxNotification = {
  __typename?: 'InboxNotification';
  actionType?: Maybe<Scalars['String']['output']>;
  actionUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type InboxNotifications = {
  __typename?: 'InboxNotifications';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<Notification>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type InputAddToCart = {
  code: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

export type InputBillConfirmation = {
  billId: Scalars['ID']['input'];
  receipt: Scalars['String']['input'];
};

export type InputBlockUserCommunity = {
  blockedCommunityMemberId: Scalars['ID']['input'];
  communityId: Scalars['ID']['input'];
};

export type InputChargeMidtrans = {
  orderBillNo: Scalars['String']['input'];
  paymentMethod: Scalars['String']['input'];
};

export type InputCheckout = {
  paymentPlan: Scalars['String']['input'];
  referralCode?: InputMaybe<Scalars['String']['input']>;
  userPhone: Scalars['String']['input'];
};

export type InputCreateCommunityVideoComment = {
  communityVideoV2Id: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type InputCreateFeed = {
  communityId: Scalars['String']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<InputFeedImage>;
  images?: InputMaybe<Array<InputMaybe<InputFeedImageV2>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type InputCreateFeedComment = {
  communityId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  feedId: Scalars['ID']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type InputCreateSegment = {
  data: InputSegmentData;
  division: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type InputDataClaim = {
  instruction: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type InputDataSubmission = {
  field: Scalars['String']['input'];
  instruction: Scalars['String']['input'];
  label: Scalars['String']['input'];
  mediaId?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type InputDeleteAcc = {
  reason: Scalars['String']['input'];
};

export type InputExamQuestionCreate = {
  examUserId: Scalars['ID']['input'];
  questions: Array<InputMaybe<InputQuestionCreate>>;
  totalElapsedTime: Scalars['Float']['input'];
};

export type InputFeedImage = {
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InputFeedImageV2 = {
  id?: InputMaybe<Scalars['ID']['input']>;
  idx: Scalars['Int']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  mediaId?: InputMaybe<Scalars['String']['input']>;
  originalImageUrl?: InputMaybe<Scalars['String']['input']>;
};

export type InputFeedPollCreate = {
  communityId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  details?: InputMaybe<Array<InputMaybe<InputFeedPollDetailCreate>>>;
  endTime: Scalars['String']['input'];
  isMultipleAnswer: Scalars['Boolean']['input'];
  startTime: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type InputFeedPollDetailCreate = {
  content?: InputMaybe<Scalars['String']['input']>;
  idx?: InputMaybe<Scalars['Int']['input']>;
};

export type InputFeedPollVote = {
  details: Array<InputMaybe<Scalars['String']['input']>>;
  fieldType: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type InputFeedback = {
  feedbackDetailId?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};

export type InputGetRegisterExamUser = {
  examId: Scalars['ID']['input'];
  examPackageDetailId: Scalars['ID']['input'];
};

export type InputGetRegisterExamUserV2 = {
  examId: Scalars['ID']['input'];
  examPackageDetailId: Scalars['ID']['input'];
  examPackageId: Scalars['ID']['input'];
};

export type InputGuidelineCreate = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  guidelineTypeId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type InputGuidelineUpdate = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  guidelineTypeId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type InputJoinCommunityMembership = {
  communityCode?: InputMaybe<Scalars['String']['input']>;
  nickName?: InputMaybe<Scalars['String']['input']>;
  refereeCode?: InputMaybe<Scalars['String']['input']>;
};

export type InputMissionClaim = {
  data: Array<InputDataClaim>;
  missionId: Scalars['ID']['input'];
};

export type InputOrder = {
  paymentMethod: Scalars['String']['input'];
  referralCode?: InputMaybe<Scalars['String']['input']>;
};

export type InputOrderInquiry = {
  csPackageId: Scalars['ID']['input'];
  isInstalment: Scalars['Boolean']['input'];
  phone: Scalars['String']['input'];
};

export type InputOrderPayload = {
  csPackageId: Scalars['ID']['input'];
  paymentMethod: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  voucherCode?: InputMaybe<Scalars['String']['input']>;
};

export type InputQuestionCreate = {
  answerId: Scalars['ID']['input'];
  elapsedTime: Scalars['Float']['input'];
  idx?: InputMaybe<Scalars['Int']['input']>;
  isDifficult?: InputMaybe<Scalars['Boolean']['input']>;
  questionId: Scalars['ID']['input'];
};

export type InputQuestionFeedback = {
  feedback: Scalars['String']['input'];
  questionFeedbackCategoryId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
};

export type InputReportCommunityVideoV2User = {
  communityReportCategoryDetailId: Scalars['ID']['input'];
  communityVideoV2CommentId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  reportedCommunityMemberId: Scalars['ID']['input'];
};

export type InputReportFeed = {
  feedId: Scalars['ID']['input'];
  report?: InputMaybe<Scalars['String']['input']>;
  reportCategoryId: Scalars['ID']['input'];
};

export type InputReportFeedComment = {
  feedCommentId: Scalars['ID']['input'];
  report?: InputMaybe<Scalars['String']['input']>;
  reportCategoryId: Scalars['ID']['input'];
};

export type InputReportUserCommunity = {
  communityId: Scalars['ID']['input'];
  communityReportCategoryDetailId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  reportedCommunityMemberId: Scalars['ID']['input'];
};

export type InputReportVideoComment = {
  communityReportCategoryDetailId: Scalars['ID']['input'];
  communityVideoV2CommentId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type InputSegmentData = {
  profile?: InputMaybe<InputSegmentProfileFilter>;
  subscription?: InputMaybe<InputSegmentSubscriptionFilter>;
  type: Array<Scalars['String']['input']>;
  user?: InputMaybe<InputSegmentUserIdFilter>;
};

export type InputSegmentFilterItem = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type InputSegmentProfileFilter = {
  cv?: InputMaybe<SegmentCvInput>;
  educationLevel?: InputMaybe<SegmentEducationLevelInput>;
  preferenceJobSpecialization?: InputMaybe<SegmentJobSpecializationInput>;
  preferenceLocation?: InputMaybe<SegmentLocationInput>;
  profileLocation?: InputMaybe<SegmentLocationInput>;
  skills?: InputMaybe<SegmentSkillsInput>;
  workExperience?: InputMaybe<SegmentWorkExperienceInput>;
};

export type InputSegmentSubscriptionFilter = {
  currentSubscriptionStatus?: InputMaybe<Scalars['String']['input']>;
  expirationDaysWithin?: InputMaybe<Scalars['Int']['input']>;
};

export type InputSegmentUserIdFilter = {
  userIds: Array<Scalars['String']['input']>;
};

export type InputSubmission = {
  data: Array<InputDataSubmission>;
  missionId: Scalars['ID']['input'];
};

export type InputSubmitSkill = {
  additionalSkills?: InputMaybe<Array<Scalars['String']['input']>>;
  skillIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type InputUpdateCommunityMemberAbout = {
  about?: InputMaybe<Scalars['String']['input']>;
};

export type InputUpdateFeed = {
  communityId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  image?: InputMaybe<InputFeedImage>;
  images?: InputMaybe<Array<InputMaybe<InputFeedImageV2>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type InputUpdateFeedComment = {
  communityId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  feedId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type InputVoteFeed = {
  feedId: Scalars['ID']['input'];
  vote: Scalars['Boolean']['input'];
};

export type InputVoteFeedComment = {
  feedCommentId: Scalars['ID']['input'];
  vote?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InputVoucherToCart = {
  code: Scalars['String']['input'];
};

export type InputWalletRedemptionCreate = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  bankAccName?: InputMaybe<Scalars['String']['input']>;
  bankAccNo?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type InterviewDeclineReason = {
  __typename?: 'InterviewDeclineReason';
  category: DeclineReasonCategoryEnum;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type InterviewDeclineReasons = {
  __typename?: 'InterviewDeclineReasons';
  list?: Maybe<Array<InterviewDeclineReason>>;
};

export type InterviewDetail = {
  __typename?: 'InterviewDetail';
  badge?: Maybe<InterviewStateBadge>;
  id: Scalars['ID']['output'];
  /**  needed for `rescheduleMessageTemplate` */
  interviewState?: Maybe<EpInterviewSessionStatus>;
  interviewStateLabel?: Maybe<InterviewStateEnum>;
  invitedDate?: Maybe<Scalars['String']['output']>;
  rescheduleMessageTemplate?: Maybe<Scalars['String']['output']>;
  session?: Maybe<InterviewSession>;
  sessionId: Scalars['ID']['output'];
  vacancy?: Maybe<Vacancy>;
};

export type InterviewDetailError = {
  __typename?: 'InterviewDetailError';
  code: InterviewDetailErrorEnum;
  message: Scalars['String']['output'];
};

export enum InterviewDetailErrorEnum {
  NotAuthorized = 'NOT_AUTHORIZED',
  NotFound = 'NOT_FOUND',
  ScheduleNotFound = 'SCHEDULE_NOT_FOUND',
}

export type InterviewDetailResponse =
  | InterviewDetailError
  | InterviewDetailSuccess;

export type InterviewDetailSuccess = {
  __typename?: 'InterviewDetailSuccess';
  application: JobApplication;
  interview: InterviewDetail;
  vacancy: Vacancy;
};

export type InterviewInfo = {
  __typename?: 'InterviewInfo';
  badge?: Maybe<InterviewStateBadge>;
  body?: Maybe<Scalars['String']['output']>;
  buttonLabel?: Maybe<Scalars['String']['output']>;
  interviewState: InterviewStateEnum;
  title?: Maybe<Scalars['String']['output']>;
};

export type InterviewRescheduleReason = {
  __typename?: 'InterviewRescheduleReason';
  category?: Maybe<InterviewRescheduleReasonEnum>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export enum InterviewRescheduleReasonEnum {
  Option = 'OPTION',
  Other = 'OTHER',
}

export type InterviewSession = {
  __typename?: 'InterviewSession';
  endTimeStr?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  interviewDate?: Maybe<Scalars['String']['output']>;
  isExpired?: Maybe<Scalars['Boolean']['output']>;
  offlineInterview?: Maybe<InterviewSessionOfflineDetail>;
  onlineInterview?: Maybe<InterviewSessionOnlineDetail>;
  recruiterMessage?: Maybe<Scalars['String']['output']>;
  recruiterPhone?: Maybe<Scalars['String']['output']>;
  schedule?: Maybe<Scalars['String']['output']>;
  startTimeStr?: Maybe<Scalars['String']['output']>;
};

export type InterviewSessionFilter = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  sessionType?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type InterviewSessionOfflineDetail = {
  __typename?: 'InterviewSessionOfflineDetail';
  address: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type InterviewSessionOnlineDetail = {
  __typename?: 'InterviewSessionOnlineDetail';
  password?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type InterviewStateBadge = {
  __typename?: 'InterviewStateBadge';
  badgeBackgroundColor?: Maybe<Scalars['String']['output']>;
  badgeLabel?: Maybe<Scalars['String']['output']>;
};

export enum InterviewStateEnum {
  AcceptedNew = 'accepted_new',
  AcceptedReschedule = 'accepted_reschedule',
  DeclinedNew = 'declined_new',
  DeclinedReschedule = 'declined_reschedule',
  EndedResponse = 'ended_response',
  EndedUnresponse = 'ended_unresponse',
  NoSchedule = 'no_schedule',
  PendingNew = 'pending_new',
  PendingReschedule = 'pending_reschedule',
}

export type Invitation = {
  __typename?: 'Invitation';
  id: Scalars['ID']['output'];
  invitedAt: Scalars['String']['output'];
  invitedAtStr: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  status: InvitationStatusEnum;
  uniqueId: Scalars['String']['output'];
  vacancy?: Maybe<Vacancy>;
  vacancyId: Scalars['ID']['output'];
};

export type InvitationDeclineReason = {
  __typename?: 'InvitationDeclineReason';
  category?: Maybe<InvitationDeclineReasonCategory>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export enum InvitationDeclineReasonCategory {
  Other = 'OTHER',
  Regular = 'REGULAR',
}

export type InvitationDetailError = {
  __typename?: 'InvitationDetailError';
  code?: Maybe<InvitationDetailErrorEnum>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum InvitationDetailErrorEnum {
  NotAuthorized = 'NOT_AUTHORIZED',
  NotFound = 'NOT_FOUND',
}

export enum InvitationListOrder {
  Newest = 'NEWEST',
  Oldest = 'OLDEST',
}

export type InvitationResult = Invitation | InvitationDetailError;

export enum InvitationStatusEnum {
  AlreadyApplied = 'ALREADY_APPLIED',
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  VacancyClosed = 'VACANCY_CLOSED',
}

export type JobApplication = {
  __typename?: 'JobApplication';
  appliedAt?: Maybe<Scalars['Date']['output']>;
  appliedAtDesc?: Maybe<Scalars['String']['output']>;
  appliedAtLocale?: Maybe<Scalars['String']['output']>;
  appliedAtWaDesc?: Maybe<Scalars['String']['output']>;
  birthdate?: Maybe<Scalars['Date']['output']>;
  city?: Maybe<Location>;
  contactType?: Maybe<JobApplicationContactTypeEnum>;
  contactTypeStr?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdAtLocale?: Maybe<Scalars['String']['output']>;
  createdAtStr?: Maybe<Scalars['String']['output']>;
  displayDateStr?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Location>;
  districtId?: Maybe<Scalars['ID']['output']>;
  endLastEducationYear?: Maybe<Scalars['Int']['output']>;
  endLastJobYear?: Maybe<Scalars['Int']['output']>;
  feedbackDescription?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  genderStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  infoStr?: Maybe<Scalars['String']['output']>;
  interviewInfo?: Maybe<InterviewInfo>;
  isAccepted?: Maybe<Scalars['Boolean']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isBadgeActive?: Maybe<Scalars['Boolean']['output']>;
  isInterviewed?: Maybe<Scalars['Boolean']['output']>;
  isSeen?: Maybe<Scalars['Boolean']['output']>;
  jobApplicationHistoryLabelId?: Maybe<Scalars['ID']['output']>;
  jobVacancyId?: Maybe<Scalars['ID']['output']>;
  label?: Maybe<ApplicationLabel>;
  lastEducationLevel?: Maybe<Scalars['String']['output']>;
  lastJobDescription?: Maybe<Scalars['String']['output']>;
  lastJobName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Location>;
  startLastEducationYear?: Maybe<Scalars['Int']['output']>;
  startLastJobYear?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
  vacancy?: Maybe<Vacancy>;
};

export type JobApplicationByVacancy = {
  __typename?: 'JobApplicationByVacancy';
  /** @deprecated unused */
  error?: Maybe<Scalars['String']['output']>;
  /** @deprecated unused */
  isApplied?: Maybe<Scalars['Boolean']['output']>;
  jobApplication?: Maybe<JobApplication>;
  jobVacancyId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated unused */
  lastAppliedAt?: Maybe<Scalars['Date']['output']>;
};

export enum JobApplicationContactTypeEnum {
  Email = 'EMAIL',
  KlApp = 'KL_APP',
  WebLink = 'WEB_LINK',
  Whatsapp = 'WHATSAPP',
}

export type JobApplicationCreateInput = {
  birthdate?: InputMaybe<Scalars['Date']['input']>;
  districtId?: InputMaybe<Scalars['ID']['input']>;
  endEducationYear?: InputMaybe<Scalars['Int']['input']>;
  endLastJobYear?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  jobVacancyId: Scalars['ID']['input'];
  lastEducationInstitutionName?: InputMaybe<Scalars['String']['input']>;
  lastEducationLevel: Scalars['String']['input'];
  lastEducationProgramName?: InputMaybe<Scalars['String']['input']>;
  lastJobCompanyName?: InputMaybe<Scalars['String']['input']>;
  lastJobDescription?: InputMaybe<Scalars['String']['input']>;
  lastJobName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  startEducationYear?: InputMaybe<Scalars['Int']['input']>;
  startLastJobYear?: InputMaybe<Scalars['Int']['input']>;
};

export type JobApplicationCreateInputV3 = {
  jobVacancyId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
};

export type JobApplicationFeedbackCreateInput = {
  feedbackDescription: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  isAccepted: Scalars['Boolean']['input'];
  isInterviewed: Scalars['Boolean']['input'];
};

export enum JobApplicationFeedbackEnum {
  JobApp = 'JOB_APP',
  JobAppKlep = 'JOB_APP_KLEP',
}

export type JobApplicationFilter = {
  __typename?: 'JobApplicationFilter';
  mainFilter?: Maybe<Array<Maybe<FilterType>>>;
  quickFilter?: Maybe<Array<Maybe<FilterType>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type JobApplicationMessage = {
  __typename?: 'JobApplicationMessage';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdAtLocale?: Maybe<Scalars['String']['output']>;
  createdAtTimeLocale?: Maybe<Scalars['String']['output']>;
  jobApplicationId?: Maybe<Scalars['ID']['output']>;
  param?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type JobApplicationMessages = {
  __typename?: 'JobApplicationMessages';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<JobApplicationMessage>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum JobApplicationPreviewInterestEnum {
  Interested = 'INTERESTED',
  NotInterested = 'NOT_INTERESTED',
  Unknown = 'UNKNOWN',
}

export enum JobApplicationScreeningTopic {
  DocCert = 'DOC_CERT',
  Education = 'EDUCATION',
  Industry = 'INDUSTRY',
  Location = 'LOCATION',
  LocationSite = 'LOCATION_SITE',
  Role = 'ROLE',
  Skill = 'SKILL',
}

export type JobApplicationStatusFeedbackInput = {
  feedbacks?: InputMaybe<Array<InputMaybe<JobApplicationStatusFeedbacks>>>;
  jobApplicationId: Scalars['ID']['input'];
};

export type JobApplicationStatusFeedbacks = {
  jobApplicationFeedbackTemplateId: Scalars['ID']['input'];
  otherComment?: InputMaybe<Scalars['String']['input']>;
};

export type JobApplications = {
  __typename?: 'JobApplications';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<JobApplication>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type JobListerFooterResponse = {
  __typename?: 'JobListerFooterResponse';
  description?: Maybe<Scalars['String']['output']>;
  linkSections?: Maybe<Array<Maybe<LinkSection>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type JobPublished = {
  __typename?: 'JobPublished';
  current?: Maybe<Scalars['Int']['output']>;
  hasExceeded?: Maybe<Scalars['Boolean']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
};

export type JobRole = {
  __typename?: 'JobRole';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  jobSpecializationId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type JobRoles = {
  __typename?: 'JobRoles';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<JobRole>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type JobSkill = {
  __typename?: 'JobSkill';
  displayName?: Maybe<Scalars['String']['output']>;
  groupBy?: Maybe<EpJobSpecializationRoleSkillsGroupBy>;
  groupByStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type JobSkills = {
  __typename?: 'JobSkills';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<JobSkill>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type JobSpecialization = {
  __typename?: 'JobSpecialization';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type JobSpecializationRole = {
  __typename?: 'JobSpecializationRole';
  displayName?: Maybe<Scalars['String']['output']>;
  groupBy?: Maybe<EpJobSpecializationRolesGroupBy>;
  groupByStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isRecommended?: Maybe<Scalars['Boolean']['output']>;
  jobRole?: Maybe<JobRole>;
  jobSpecialization?: Maybe<JobSpecialization>;
  name?: Maybe<Scalars['String']['output']>;
};

export type JobSpecializationRoleSkills = {
  __typename?: 'JobSpecializationRoleSkills';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<JobSkill>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type JobSpecializationRoles = {
  __typename?: 'JobSpecializationRoles';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<JobSpecializationRole>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type JobSpecializations = {
  __typename?: 'JobSpecializations';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<JobSpecialization>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type JobVacancyClosedFeedbackTemplate = {
  __typename?: 'JobVacancyClosedFeedbackTemplate';
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  inputType?: Maybe<JobVacancyClosedFeedbackTemplateEnum>;
};

export enum JobVacancyClosedFeedbackTemplateEnum {
  InputText = 'INPUT_TEXT',
  Radio = 'RADIO',
  TextField = 'TEXT_FIELD',
}

export type JobVacancyClosedFeedbackTemplates = {
  __typename?: 'JobVacancyClosedFeedbackTemplates';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<JobVacancyClosedFeedbackTemplate>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type JobVacancyFilter = {
  __typename?: 'JobVacancyFilter';
  mainFilter?: Maybe<Array<Maybe<FilterType>>>;
  quickFilter?: Maybe<Array<Maybe<FilterType>>>;
};

export type JobVacancyFilterInput = {
  filters?: InputMaybe<Array<SearchCategoryFilter>>;
  locationArea?: InputMaybe<Array<LocationAreaInput>>;
  userJobFunctions?: InputMaybe<Array<Scalars['String']['input']>>;
  userJobSpecializationRoleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  userMaxEducation?: InputMaybe<Scalars['String']['input']>;
};

export type JobVacancyFunction = {
  __typename?: 'JobVacancyFunction';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isOnboarding?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type JobVacancyFunctions = {
  __typename?: 'JobVacancyFunctions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  limitSelection?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<JobVacancyFunction>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type JobVacancyLocationSitesKeyValue = {
  __typename?: 'JobVacancyLocationSitesKeyValue';
  description?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type JobVacancyPreview = {
  __typename?: 'JobVacancyPreview';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  positionName: Scalars['String']['output'];
};

export type JobVacancyRestrictions = {
  __typename?: 'JobVacancyRestrictions';
  city?: Maybe<Scalars['Boolean']['output']>;
  educationLevel?: Maybe<Scalars['Boolean']['output']>;
};

export enum JobVacancyTierCategoryEnum {
  Growing = 'GROWING',
  Priority = 'PRIORITY',
  Regular = 'REGULAR',
}

export type KeyValue = {
  __typename?: 'KeyValue';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type KeyValueInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type LeaderBoard = {
  __typename?: 'LeaderBoard';
  image?: Maybe<Scalars['String']['output']>;
  periodStr?: Maybe<Scalars['String']['output']>;
  winners?: Maybe<Array<Maybe<UserLeaderBoard>>>;
};

export type LinkSection = {
  __typename?: 'LinkSection';
  links?: Maybe<Array<Maybe<FooterLink>>>;
  name?: Maybe<Scalars['String']['output']>;
  section?: Maybe<FooterSectionEnum>;
};

export type LinkSectionLinksArgs = {
  citySlug?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  companyIndustrySlug?: InputMaybe<Scalars['String']['input']>;
  educationLevelSlug?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  jobSpecializationSlug?: InputMaybe<Scalars['String']['input']>;
  jobTypeSlug?: InputMaybe<Scalars['String']['input']>;
  locationSlug?: InputMaybe<Scalars['String']['input']>;
  provinceSlug?: InputMaybe<Scalars['String']['input']>;
};

export type LiveMaterial = {
  __typename?: 'LiveMaterial';
  createdAt?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  liveId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum LiveType {
  Catalog = 'CATALOG',
  CatalogHost = 'CATALOG_HOST',
  User = 'USER',
}

export type Location = {
  __typename?: 'Location';
  id?: Maybe<Scalars['ID']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
};

export type LocationArea = {
  __typename?: 'LocationArea';
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: LocationAreaType;
};

export type LocationAreaInput = {
  areaType: LocationAreaType;
  name: Scalars['String']['input'];
};

export enum LocationAreaType {
  City = 'CITY',
  District = 'DISTRICT',
  Group = 'GROUP',
  Province = 'PROVINCE',
}

export type LocationAreas = {
  __typename?: 'LocationAreas';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<LocationArea>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type LocationBySlug = {
  __typename?: 'LocationBySlug';
  city?: Maybe<Location>;
  province?: Maybe<Location>;
};

export type LocationFooter = {
  __typename?: 'LocationFooter';
  cities?: Maybe<Array<Maybe<Location>>>;
  provinces?: Maybe<Array<Maybe<Location>>>;
};

export type LocationOption = {
  __typename?: 'LocationOption';
  id?: Maybe<Scalars['ID']['output']>;
  isSelected?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
};

export type LocationOptions = {
  __typename?: 'LocationOptions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  limitSelection?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<LocationOption>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type Locations = {
  __typename?: 'Locations';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<Location>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum MediaAccess {
  Private = 'PRIVATE',
  PrivateTemp = 'PRIVATE_TEMP',
  Public = 'PUBLIC',
}

export type MediaFile = {
  __typename?: 'MediaFile';
  access: MediaAccess;
  contentType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  fileSize?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: MediaState;
  uploader: MediaUser;
  url?: Maybe<Scalars['String']['output']>;
};

export type MediaFileIconUrlArgs = {
  screenDensity?: InputMaybe<ScreenDensityEnum>;
};

export type MediaFileUpload = {
  __typename?: 'MediaFileUpload';
  file: MediaFile;
  uploadUrl: Scalars['String']['output'];
};

export type MediaFileUploadInput = {
  access?: InputMaybe<MediaAccess>;
  name: Scalars['String']['input'];
};

export type MediaImage = {
  __typename?: 'MediaImage';
  access: MediaAccess;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: MediaState;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  uploader: MediaUser;
  url?: Maybe<Scalars['String']['output']>;
};

export type MediaImageUpload = {
  __typename?: 'MediaImageUpload';
  image: MediaImage;
  uploadUrl: Scalars['String']['output'];
};

export type MediaImageUploadInput = {
  access?: InputMaybe<MediaAccess>;
  name: Scalars['String']['input'];
};

export enum MediaState {
  Error = 'ERROR',
  PendingUpload = 'PENDING_UPLOAD',
  Processing = 'PROCESSING',
  Queued = 'QUEUED',
  Ready = 'READY',
  Uploading = 'UPLOADING',
}

export type MediaUser = {
  __typename?: 'MediaUser';
  companyId?: Maybe<Scalars['ID']['output']>;
  userId: Scalars['ID']['output'];
  userType: UserType;
};

export type MediaVideo = {
  __typename?: 'MediaVideo';
  access: MediaAccess;
  category: MediaVideoCategory;
  createdAt: Scalars['DateTime']['output'];
  duration?: Maybe<Scalars['Float']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  playback?: Maybe<MediaVideoPlayback>;
  readyAt?: Maybe<Scalars['DateTime']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  status: MediaState;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  thumbnailUrlJpg?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uploader: MediaUser;
  width?: Maybe<Scalars['Int']['output']>;
};

export enum MediaVideoCategory {
  CommunityVideo = 'COMMUNITY_VIDEO',
  CompanyProfile = 'COMPANY_PROFILE',
  UserPortfolio = 'USER_PORTFOLIO',
  VideoManagement = 'VIDEO_MANAGEMENT',
}

export type MediaVideoPlayback = {
  __typename?: 'MediaVideoPlayback';
  dash?: Maybe<Scalars['String']['output']>;
  hls?: Maybe<Scalars['String']['output']>;
};

export type MediaVideoUpload = {
  __typename?: 'MediaVideoUpload';
  uploadUrl: Scalars['String']['output'];
  video: MediaVideo;
};

export type MediaVideoUploadInput = {
  access?: InputMaybe<MediaAccess>;
  category: MediaVideoCategory;
  name?: InputMaybe<Scalars['String']['input']>;
  size: Scalars['String']['input'];
};

export enum MediaVideoVendor {
  Cloudflare = 'CLOUDFLARE',
}

export type MemberRole = {
  __typename?: 'MemberRole';
  role?: Maybe<Scalars['String']['output']>;
  roleColor?: Maybe<Scalars['String']['output']>;
};

export type Mission = {
  __typename?: 'Mission';
  claimUrl?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  mediaId?: Maybe<Scalars['ID']['output']>;
  mediaImage?: Maybe<MediaImage>;
  missionDetails?: Maybe<Array<Maybe<MissionDetail>>>;
  name?: Maybe<Scalars['String']['output']>;
  quota?: Maybe<Scalars['Int']['output']>;
  quotaAvailable?: Maybe<Scalars['Int']['output']>;
  reward?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<EnumMissionStatus>;
  submissionUrl?: Maybe<Scalars['String']['output']>;
  tab?: Maybe<Array<Maybe<MissionTab>>>;
  termCondition?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type MissionClaimForm = {
  __typename?: 'MissionClaimForm';
  formType: Scalars['String']['output'];
  instruction: Scalars['String']['output'];
  options: Array<Maybe<ClaimFormOption>>;
  placeholder: Scalars['String']['output'];
  rules: ClaimFormRules;
};

export type MissionDetail = {
  __typename?: 'MissionDetail';
  field?: Maybe<Scalars['String']['output']>;
  formType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instruction?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type MissionTab = {
  __typename?: 'MissionTab';
  content?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MsExam = {
  __typename?: 'MsExam';
  avgScore?: Maybe<Scalars['Float']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isTimeLimited?: Maybe<Scalars['Boolean']['output']>;
  maxPoint?: Maybe<Scalars['Float']['output']>;
  maxScore?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  timeLimit?: Maybe<Scalars['Float']['output']>;
  totalQuestion?: Maybe<Scalars['Int']['output']>;
};

export type MsTest = {
  __typename?: 'MsTest';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MsTests = {
  __typename?: 'MsTests';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<MsTest>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  OpsUpdateJobVacancyReport: MutationResponse;
  acceptInterviewInvitation?: Maybe<AcceptInterviewInvitationResult>;
  addJobApplicationStatusFeedback?: Maybe<JobApplication>;
  addQuestionFeedback?: Maybe<MutationResponse>;
  addQuestionResult?: Maybe<MutationResponse>;
  addToCart?: Maybe<Cart>;
  applyVacancyV5?: Maybe<ApplyVacancyResult>;
  applyVacancyV6?: Maybe<ApplyVacancyResultV2>;
  archiveJobApplication?: Maybe<MutationResponse>;
  blockUserCommunity?: Maybe<MutationResponse>;
  bookmarkVacancy?: Maybe<MutationResponse>;
  calculateVoucher?: Maybe<Cart>;
  /** @deprecated unused */
  cancelApplication?: Maybe<MutationResponse>;
  checkLocalization?: Maybe<Scalars['String']['output']>;
  checkoutSubmit?: Maybe<OrderSubmitSummary>;
  claimFreeTrial: ClaimFreeTrialResponse;
  countSelectedAiDescription?: Maybe<MutationResponse>;
  createCommunityVideoV2Comment?: Maybe<CommunityVideoV2Comment>;
  createCommunityVideoV2CommentV2?: Maybe<CreateCommunityVideoV2CommentV2Response>;
  createCustomToken?: Maybe<Scalars['String']['output']>;
  createEducationV2?: Maybe<MutationResponse>;
  createExperienceV2?: Maybe<MutationResponse>;
  createExperienceV3?: Maybe<MutationResponse>;
  createExperienceV4?: Maybe<MutationResponse>;
  createFeed?: Maybe<MutationResponse>;
  createFeedComment?: Maybe<MutationResponse>;
  createFeedCommentV2?: Maybe<FeedComment>;
  createFeedCommentV3?: Maybe<CreateFeedCommentResponse>;
  createFeedV2?: Maybe<Feed>;
  createFeedV3?: Maybe<CreateFeedResponse>;
  createGenerateCvUserFeedback?: Maybe<MutationResponse>;
  createJobApplicationUserFeedback?: Maybe<MutationResponse>;
  createMediaFile?: Maybe<MediaFileUpload>;
  createMediaImage?: Maybe<MediaImageUpload>;
  createMediaVideo?: Maybe<MediaVideoUpload>;
  createOrder?: Maybe<OrderResponse>;
  createPoll?: Maybe<MutationResponse>;
  createPollV2?: Maybe<Feed>;
  createProfile?: Maybe<MutationResponse>;
  createSalaryExpectation?: Maybe<MutationResponse>;
  createSupportLinkV2?: Maybe<MutationResponse>;
  createUsername?: Maybe<MutationResponse>;
  declineInterviewInvitation?: Maybe<DeclineInterviewInvitationResult>;
  declineInvitation?: Maybe<DeclineInvitationResult>;
  deleteAcc?: Maybe<MutationResponse>;
  deleteCommunityVideoV2Comment?: Maybe<MutationResponse>;
  deleteEducation?: Maybe<MutationResponse>;
  deleteExperience?: Maybe<MutationResponse>;
  deleteFeed?: Maybe<MutationResponse>;
  deleteFeedComment?: Maybe<MutationResponse>;
  deleteFeedV2?: Maybe<Feed>;
  deleteImageProfile?: Maybe<MutationResponse>;
  deleteImageProfileV2?: Maybe<MutationResponse>;
  deleteSalaryExpectation?: Maybe<MutationResponse>;
  deleteSupportLink?: Maybe<MutationResponse>;
  endPoll?: Maybe<MutationResponse>;
  endPollV2?: Maybe<Feed>;
  epActivateJobVacancyHighlight?: Maybe<MutationResponse>;
  epActivateJobVacancyHighlightV2: EpActivateJobVacancyHighlightV2Result;
  epBulkUpdateJobApplicationStatus: MutationResponse;
  epCloseJobVacancy?: Maybe<MutationResponse>;
  epCloseJobVacancyV2: EpCloseJobVacancyV2Result;
  epCreateInterviewInvitation?: Maybe<EpCreateInterviewInvitationResponse>;
  epCreateJobVacancy?: Maybe<EpJobVacancyGeneratedLink>;
  epCreateJobVacancyClosedFeedback?: Maybe<MutationResponse>;
  epCreateJobVacancyDraft?: Maybe<EpCreateJobVacancyDraftResult>;
  epCreateJobVacancyV2: EpCreateJobVacancyV2Result;
  epCreateJobVacancyV3: EpCreateJobVacancyV3Result;
  epDeactivateJobVacancyHighlight?: Maybe<MutationResponse>;
  epDeactivateJobVacancyHighlightV2: EpDeactivateJobVacancyHighlightV2Result;
  epDeleteJobVacancyCandidate?: Maybe<MutationResponse>;
  epForgotPassword?: Maybe<MutationResponse>;
  epForgotPasswordSubmission?: Maybe<MutationResponse>;
  epGenerateLink?: Maybe<EpGeneratedLink>;
  epInviteJobVacancyCandidate: MutationResponse;
  epJobVacancyPublish?: Maybe<MutationResponse>;
  epJobVacancyUnpublish?: Maybe<MutationResponse>;
  /** @deprecated Use epLoginV2 instead. */
  epLogin?: Maybe<MutationResponse>;
  epLoginV2?: Maybe<EpAuthenticationResponse>;
  epMoveUserCompanyOps?: Maybe<EpMoveUserCompanyOpsResponse>;
  epOnHoldJobVacancy?: Maybe<MutationResponse>;
  epOnHoldJobVacancyV2: EpOnHoldJobVacancyV2Result;
  epOpenJobVacancy?: Maybe<MutationResponse>;
  epOpenJobVacancyV2?: Maybe<EpOpenJobVacancyV2Result>;
  epOpenJobVacancyV3: EpOpenJobVacancyV3Result;
  /** @deprecated Use epRefreshTokenV2 instead. */
  epRefreshToken?: Maybe<MutationResponse>;
  epRefreshTokenV2?: Maybe<EpAuthenticationResponse>;
  epRegisterCompany?: Maybe<MutationResponse>;
  epRegisterCompanyV2?: Maybe<EpRegisterCompanyV2Result>;
  epRepostJobVacancy: EpRepostJobVacancyResult;
  epRequestSubcriptionPlanFreeTrial: MutationResponse;
  epRequestUserSubscriptionType: MutationResponse;
  epResendSignUpLink: EpResendSignUpLinkResponse;
  epResetPassword?: Maybe<MutationResponse>;
  epSelfSignUp: EpSignUpResponse;
  epSignUp: MutationResponse;
  epSubmitApplicationPreviewInterest?: Maybe<EpSubmitApplicationPreviewInterestResponse>;
  epSubmitApplicationPreviewViewStatus?: Maybe<EpSubmitApplicationPreviewViewStatusResponse>;
  epSubmitContactedPhoneNumber?: Maybe<EpSubmitContactedPhoneNumberResult>;
  epSyncPublishedJobVacancy?: Maybe<EpSyncPublishedJobVacancyResult>;
  epUpdateBulkJobApplicationCompanyStatus?: Maybe<MutationResponse>;
  epUpdateBulkJobApplicationCompanyStatusV2?: Maybe<EpUpdateBulkJobApplicationCompanyStatusResponse>;
  epUpdateCompany?: Maybe<MutationResponse>;
  epUpdateInterviewInvitation?: Maybe<EpUpdateInterviewInvitationResponse>;
  epUpdateJobApplicationCompanyStatus?: Maybe<MutationResponse>;
  epUpdateJobApplicationIsRead?: Maybe<MutationResponse>;
  epUpdateJobApplicationNote?: Maybe<MutationResponse>;
  epUpdateJobApplicationStatus?: Maybe<MutationResponse>;
  epUpdateJobApplicationStatusAndRemarks?: Maybe<EpJobApplicationRemarks>;
  epUpdateJobVacancy?: Maybe<EpJobVacancyGeneratedLink>;
  epUpdateJobVacancyV2: EpUpdateJobVacancyV2Result;
  epUpdateJobVacancyV3: EpUpdateJobVacancyV3Result;
  epUpdateUser: MutationResponse;
  epUpdateUserConfig: MutationResponse;
  /** @deprecated Use createMediaImage instead */
  epUploadCompanyLegalDocument?: Maybe<EpUploadResult>;
  /** @deprecated Use createMediaFile instead */
  epUploadCompanyLogo?: Maybe<EpUploadResult>;
  feedbackSubmit: FeedbackSubmitResponse;
  getRegisterExamUser?: Maybe<Exam>;
  getRegisterExamUserV2?: Maybe<Exam>;
  helloDMSMutation: Scalars['String']['output'];
  helloLmsMutation: Scalars['String']['output'];
  helloNotificationMutation: Scalars['String']['output'];
  helloSMS: Scalars['String']['output'];
  helloUmsMutation: Scalars['String']['output'];
  helloUnificationMutation: Scalars['String']['output'];
  joinCommunity?: Maybe<CommunityMembership>;
  leaveCommunity?: Maybe<MutationResponse>;
  opsAddBulkFreeClassroom?: Maybe<MutationResponse>;
  opsAddBulkFreeClassroomV2?: Maybe<MutationResponse>;
  opsAddSession?: Maybe<MutationResponse>;
  opsAddSessionClassroomUser?: Maybe<MutationResponse>;
  opsApproveExam?: Maybe<MutationResponse>;
  opsApproveQuestion?: Maybe<MutationResponse>;
  opsApproveSubmission?: Maybe<MutationResponse>;
  opsAssignCompanyPackage?: Maybe<OpsAssignCompanyPackageResult>;
  opsAssignOfflineProfile?: Maybe<MutationResponse>;
  opsBanCommunityVideoV2User?: Maybe<Scalars['Boolean']['output']>;
  opsBanOrder?: Maybe<MutationResponse>;
  opsCancelOrder?: Maybe<MutationResponse>;
  /** @deprecated drop */
  opsCancelOrderInquiry?: Maybe<MutationResponse>;
  opsClassroomUserActivation?: Maybe<MutationResponse>;
  opsClassroomUserDetailActivation?: Maybe<MutationResponse>;
  opsCreateClassroom?: Maybe<MutationResponse>;
  opsCreateCommunityGroup?: Maybe<MutationResponse>;
  opsCreateCommunityMembership?: Maybe<MutationResponse>;
  opsCreateCommunityVideo?: Maybe<MutationResponse>;
  opsCreateCommunityVideoV2?: Maybe<MutationResponse>;
  opsCreateCompanyAndSubmitKlepUserRegistry?: Maybe<OpsBulkJobKlepCompanyUserRegistry>;
  opsCreateCompanyAndSubmitKlepUserRegistryV2?: Maybe<OpsBulkJobKlepCompanyUserRegistryV2Result>;
  opsCreateCompanyVideo?: Maybe<MutationResponse>;
  opsCreateContent: OpsCreateContentResult;
  opsCreateCountry?: Maybe<MutationResponse>;
  opsCreateExamDraft?: Maybe<MutationResponse>;
  opsCreateExamFreeTrial?: Maybe<MutationResponse>;
  opsCreateExamPackage?: Maybe<MutationResponse>;
  opsCreateFeed?: Maybe<MutationResponse>;
  /** to create a Guideline */
  opsCreateGuideline?: Maybe<MutationResponse>;
  opsCreateHighlightedJob?: Maybe<MutationResponse>;
  opsCreateJobCompany?: Maybe<MutationResponse>;
  opsCreateJobCompanyAccManager: MutationResponse;
  opsCreateJobKlepCompanyDraft?: Maybe<MutationResponse>;
  /** @deprecated drop */
  opsCreateLive?: Maybe<MutationResponse>;
  /** @deprecated drop */
  opsCreateLiveUserDraft?: Maybe<MutationResponse>;
  opsCreateMarketingBanner?: Maybe<MutationResponse>;
  opsCreateMission?: Maybe<MutationResponse>;
  opsCreateNews?: Maybe<MutationResponse>;
  opsCreateNotification?: Maybe<MutationResponse>;
  opsCreateNotificationMessage?: Maybe<MutationResponse>;
  opsCreateOfflineProfile?: Maybe<MutationResponse>;
  opsCreateOrder?: Maybe<MutationResponse>;
  opsCreatePackage?: Maybe<MutationResponse>;
  opsCreatePassingGrade?: Maybe<MutationResponse>;
  opsCreateQuestionDraft?: Maybe<MutationResponse>;
  opsCreateReferralProgram?: Maybe<MutationResponse>;
  opsCreateSegment?: Maybe<OpsCreateSegmentResult>;
  opsCreateSku?: Maybe<MutationResponse>;
  opsCreateSkuCategory?: Maybe<MutationResponse>;
  opsCreateTeacher?: Maybe<MutationResponse>;
  opsCreateVacancy?: Maybe<MutationResponse>;
  opsCreateVacancyHighlightSection?: Maybe<MutationResponse>;
  opsCreateVacancyReqCatSub?: Maybe<MutationResponse>;
  opsCreateVideo?: Maybe<MutationResponse>;
  opsCreateVoucher?: Maybe<MutationResponse>;
  opsDeactivateKlepCompany: MutationResponse;
  opsDeactivateKlepCompanyUser: MutationResponse;
  opsDeleteClassroom?: Maybe<MutationResponse>;
  opsDeleteCommunityGroup?: Maybe<MutationResponse>;
  opsDeleteCommunityVideoV2?: Maybe<MutationResponse>;
  opsDeleteCompany?: Maybe<MutationResponse>;
  opsDeleteCompanyVideo?: Maybe<MutationResponse>;
  opsDeleteCountry?: Maybe<MutationResponse>;
  opsDeleteExam?: Maybe<MutationResponse>;
  opsDeleteExamDraft?: Maybe<MutationResponse>;
  opsDeleteExamFreeTrial?: Maybe<MutationResponse>;
  opsDeleteExamPackage?: Maybe<MutationResponse>;
  opsDeleteExamPackageDisplay?: Maybe<MutationResponse>;
  opsDeleteFeed?: Maybe<MutationResponse>;
  /** to safe delete a Guideline */
  opsDeleteGuideline?: Maybe<MutationResponse>;
  opsDeleteHighlightedJob?: Maybe<MutationResponse>;
  /** @deprecated drop */
  opsDeleteLive?: Maybe<MutationResponse>;
  /** @deprecated drop */
  opsDeleteLiveUser?: Maybe<MutationResponse>;
  /** @deprecated drop */
  opsDeleteLiveUserDraft?: Maybe<MutationResponse>;
  opsDeleteMarketingBanner?: Maybe<MutationResponse>;
  opsDeleteMission?: Maybe<MutationResponse>;
  opsDeleteNews?: Maybe<MutationResponse>;
  opsDeleteNotification?: Maybe<MutationResponse>;
  opsDeleteNotificationMessage?: Maybe<MutationResponse>;
  opsDeleteOrder?: Maybe<MutationResponse>;
  opsDeletePackage?: Maybe<MutationResponse>;
  opsDeletePassingGrade?: Maybe<MutationResponse>;
  opsDeleteQuestion?: Maybe<MutationResponse>;
  opsDeleteQuestionDraft?: Maybe<MutationResponse>;
  opsDeleteSession?: Maybe<MutationResponse>;
  opsDeleteSkill?: Maybe<MutationResponse>;
  opsDeleteSku?: Maybe<MutationResponse>;
  opsDeleteSkuCategory?: Maybe<MutationResponse>;
  opsDeleteTeacher?: Maybe<MutationResponse>;
  opsDeleteVacancy?: Maybe<MutationResponse>;
  opsDeleteVacancyHighlightSection?: Maybe<MutationResponse>;
  opsDeleteVideo?: Maybe<MutationResponse>;
  opsDeleteVoucher?: Maybe<MutationResponse>;
  opsDoLogin?: Maybe<MutationResponse>;
  opsFollowUpApplication?: Maybe<OpsFollowUpApplication>;
  opsMutateHello?: Maybe<Scalars['String']['output']>;
  opsNotifyBill?: Maybe<MutationResponse>;
  opsPinUnpinCommunityVideoV2: Scalars['Boolean']['output'];
  /** @deprecated drop */
  opsProceedOrderInquiry?: Maybe<MutationResponse>;
  opsPublishClassroom?: Maybe<MutationResponse>;
  opsPublishCommunityGroup?: Maybe<MutationResponse>;
  opsPublishContent?: Maybe<Scalars['Boolean']['output']>;
  opsPublishExamBySessionId?: Maybe<MutationResponse>;
  opsPublishExamFreeTrial?: Maybe<MutationResponse>;
  opsPublishExamPackage?: Maybe<MutationResponse>;
  opsPublishFeed?: Maybe<MutationResponse>;
  /** to publish a Guideline */
  opsPublishGuideline?: Maybe<MutationResponse>;
  opsPublishMarketingBanner?: Maybe<MutationResponse>;
  opsPublishMission?: Maybe<MutationResponse>;
  opsPublishNews?: Maybe<MutationResponse>;
  opsPublishPackage?: Maybe<MutationResponse>;
  opsPublishSku?: Maybe<MutationResponse>;
  opsPublishVacancy?: Maybe<MutationResponse>;
  opsPublishVacancyHighlightSection?: Maybe<MutationResponse>;
  opsPublishVacancyV2?: Maybe<OpsPublishVacancyV2Result>;
  opsReRegister?: Maybe<MutationResponse>;
  opsRecalculateExamQuestion?: Maybe<MutationResponse>;
  opsRecalculateExamUserResult?: Maybe<MutationResponse>;
  opsRefundBillConfirmation?: Maybe<MutationResponse>;
  opsRegisterExamUser?: Maybe<MutationResponse>;
  opsRegisterJobCompanyPremium: MutationResponse;
  /** @deprecated drop */
  opsRegisterLiveUserDraft?: Maybe<MutationResponse>;
  opsRejectExam?: Maybe<MutationResponse>;
  opsRejectQuestion?: Maybe<MutationResponse>;
  opsRejectSubmission?: Maybe<MutationResponse>;
  opsRejoinOrder?: Maybe<MutationResponse>;
  opsRepublishExamBySessionId?: Maybe<MutationResponse>;
  opsResolveCommunityMemberReport: OpsResolveReportResult;
  opsRetryBulkFreeClassroom?: Maybe<MutationResponse>;
  opsRetryNotification?: Maybe<MutationResponse>;
  /** @deprecated drop */
  opsStatusLiveUser?: Maybe<MutationResponse>;
  opsSubmitBulkAddHighlight?: Maybe<MutationResponse>;
  opsSubmitBulkCompanies?: Maybe<MutationResponse>;
  opsSubmitBulkDeleteHighlight?: Maybe<MutationResponse>;
  opsSubmitBulkJobKlepCompanyUserRegistry?: Maybe<OpsBulkJobKlepCompanyUserRegistry>;
  opsSubmitBulkJobKlepCompanyUserRegistryV2?: Maybe<OpsSubmitBulkJobKlepCompanyUserRegistryV2Result>;
  opsSubmitBulkSkills?: Maybe<MutationResponse>;
  opsSubmitBulkVacancies?: Maybe<MutationResponse>;
  opsSubmitCompanyPackage?: Maybe<OpsSubmitCompanyPackageResult>;
  opsSubmitUnPublishBulkVacancy?: Maybe<MutationResponse>;
  opsSwapSession?: Maybe<MutationResponse>;
  opsSwitchJobCompanyAccManagerSubmit: MutationResponse;
  opsSwitchJobCompanyAccManagerVerify: OpsVerifySwitchJobCompanyAccManagerResults;
  /** @deprecated drop */
  opsTotalInfoOrderInquiry?: Maybe<MutationResponse>;
  opsUnPublishCommunityGroup?: Maybe<MutationResponse>;
  opsUnPublishExamFreeTrial?: Maybe<MutationResponse>;
  /** to unPublish a Guideline */
  opsUnPublishGuideline?: Maybe<MutationResponse>;
  opsUnPublishMarketingBanner?: Maybe<MutationResponse>;
  opsUnPublishMission?: Maybe<MutationResponse>;
  opsUnPublishNews?: Maybe<MutationResponse>;
  opsUnPublishSku?: Maybe<MutationResponse>;
  opsUnPublishVacancy?: Maybe<MutationResponse>;
  opsUnbanCommunityVideoV2User?: Maybe<OpsUnbanCommunityVideoV2User>;
  opsUnpublishVacancyHighlightSection?: Maybe<MutationResponse>;
  opsUpdateBanComment?: Maybe<MutationResponse>;
  opsUpdateBanCommunityMembership?: Maybe<MutationResponse>;
  opsUpdateBanFeed?: Maybe<MutationResponse>;
  opsUpdateBill?: Maybe<MutationResponse>;
  opsUpdateBillConfirmation?: Maybe<MutationResponse>;
  opsUpdateClassroom?: Maybe<MutationResponse>;
  opsUpdateCommunityGroup?: Maybe<MutationResponse>;
  opsUpdateCommunityMembership?: Maybe<MutationResponse>;
  opsUpdateCommunityVideoV2?: Maybe<MutationResponse>;
  opsUpdateCompanyVideo?: Maybe<MutationResponse>;
  opsUpdateCountry?: Maybe<MutationResponse>;
  opsUpdateExam?: Maybe<MutationResponse>;
  opsUpdateExamDraft?: Maybe<MutationResponse>;
  opsUpdateExamFreeTrial?: Maybe<MutationResponse>;
  opsUpdateExamPackage?: Maybe<MutationResponse>;
  opsUpdateExamPackageDisplay?: Maybe<MutationResponse>;
  opsUpdateFeed?: Maybe<MutationResponse>;
  opsUpdateFeedCommentReport?: Maybe<MutationResponse>;
  opsUpdateFeedReport?: Maybe<MutationResponse>;
  /** to update a Guideline */
  opsUpdateGuideline?: Maybe<MutationResponse>;
  opsUpdateHighlightedJob?: Maybe<MutationResponse>;
  opsUpdateJobCompany?: Maybe<OpsJobCompanyUpdate>;
  opsUpdateJobCompanyAccManager: MutationResponse;
  opsUpdateJobCompanyPremium: MutationResponse;
  opsUpdateJobCompanyPremiumRequest: MutationResponse;
  opsUpdateJobCompanyVerificationStatus: MutationResponse;
  opsUpdateJobKlepCompanyDraft?: Maybe<MutationResponse>;
  /** @deprecated drop */
  opsUpdateLive?: Maybe<MutationResponse>;
  /** @deprecated drop */
  opsUpdateLiveUserDraft?: Maybe<MutationResponse>;
  opsUpdateMarketingBanner?: Maybe<MutationResponse>;
  opsUpdateNews?: Maybe<MutationResponse>;
  opsUpdateOfflineProfile?: Maybe<MutationResponse>;
  opsUpdateOrder?: Maybe<MutationResponse>;
  opsUpdatePackage?: Maybe<MutationResponse>;
  opsUpdatePassingGrade?: Maybe<MutationResponse>;
  opsUpdateQuestion?: Maybe<MutationResponse>;
  opsUpdateQuestionDraft?: Maybe<MutationResponse>;
  opsUpdateRedemption?: Maybe<MutationResponse>;
  opsUpdateReferralProgram?: Maybe<MutationResponse>;
  opsUpdateSession?: Maybe<MutationResponse>;
  opsUpdateSku?: Maybe<MutationResponse>;
  opsUpdateSkuCategory?: Maybe<MutationResponse>;
  opsUpdateTeacher?: Maybe<MutationResponse>;
  opsUpdateVacancy?: Maybe<MutationResponse>;
  opsUpdateVacancyHighlightSection?: Maybe<MutationResponse>;
  opsUpdateVacancyReqCatSub?: Maybe<MutationResponse>;
  opsUpdateVacancyV2?: Maybe<MutationResponse>;
  opsUpdateVideo?: Maybe<MutationResponse>;
  opsUpdateVoucher?: Maybe<MutationResponse>;
  opsUploadCommunityVideo?: Maybe<OpsCommunityUploadVideo>;
  opsUploadFile?: Maybe<OpsUploadFiles>;
  opsUploadedVerifiedSkillsSubmit?: Maybe<MutationResponse>;
  opsVerifyBulkAddHighlight?: Maybe<OpsVerifyBulkAddHighlightResult>;
  opsVerifyBulkAddHighlightV2?: Maybe<OpsVerifyBulkAddHighlightResult>;
  opsVerifyBulkCompanies?: Maybe<OpsVerifyBulkCompaniesResult>;
  opsVerifyBulkCompaniesV2?: Maybe<OpsVerifyBulkCompaniesResult>;
  opsVerifyBulkDeleteHighlight?: Maybe<OpsVerifyBulkDeleteHighlightResult>;
  opsVerifyBulkQuestions?: Maybe<Array<Maybe<OpsBulkQuestionResult>>>;
  opsVerifyBulkQuestionsV2?: Maybe<Array<Maybe<OpsBulkQuestionResult>>>;
  opsVerifyBulkSkills?: Maybe<OpsVerifyBulkSkillsResult>;
  opsVerifyBulkSkillsV2?: Maybe<OpsVerifyBulkSkillsResult>;
  opsVerifyBulkVacancies?: Maybe<OpsVerifyBulkVacanciesResult>;
  opsVerifyBulkVacanciesV2?: Maybe<OpsVerifyBulkVacanciesResult>;
  opsVerifyUnPublishBulkVacancy?: Maybe<OpsVerifyBulkUnPublishVacancyResult>;
  pinCommunityVideoV2Comment?: Maybe<MutationResponse>;
  pinFeed?: Maybe<FeedPinResult>;
  pushAnalytic?: Maybe<MutationResponse>;
  quickApply?: Maybe<QuickApplyResponse>;
  readJobApplication?: Maybe<MutationResponse>;
  removeBookmarkVacancy?: Maybe<MutationResponse>;
  removeUserCv?: Maybe<MutationResponse>;
  reportCommunityVideoV2Comment?: Maybe<MutationResponse>;
  reportCommunityVideoV2User?: Maybe<Scalars['Boolean']['output']>;
  reportFeed?: Maybe<MutationResponse>;
  reportFeedComment?: Maybe<MutationResponse>;
  reportUserCommunity?: Maybe<MutationResponse>;
  reportVacancy?: Maybe<MutationResponse>;
  reportVacancyFromApplication?: Maybe<MutationResponse>;
  requestChargeMidtrans?: Maybe<ChargeMidtrans>;
  rescheduleInterviewInvitation?: Maybe<RescheduleInterviewInvitationResult>;
  resendApplication?: Maybe<MutationResponse>;
  resendApplicationV2?: Maybe<ResendApplicationResult>;
  saveGeneratedUserCv?: Maybe<MutationResponse>;
  selectUserCv?: Maybe<MutationResponse>;
  setHasNoExperience?: Maybe<SetHasNoExperienceResponse>;
  submitAbandonmentReason: AbandonmentReasonSubmitResponse;
  submitMissionClaim?: Maybe<MutationResponse>;
  submitSkill?: Maybe<MutationResponse>;
  submitSubmission?: Maybe<MutationResponse>;
  submitUserInterests?: Maybe<MutationResponse>;
  submitUserJobFunction?: Maybe<MutationResponse>;
  submitVacancyScreeningV2?: Maybe<MutationResponse>;
  submitWorkPreference?: Maybe<MutationResponse>;
  submitWorkPreferenceV2?: Maybe<MutationResponse>;
  submitWorkPreferenceV3?: Maybe<SubmitWorkPreferenceV3Result>;
  suggestLocation?: Maybe<MutationResponse>;
  takeMission?: Maybe<MutationResponse>;
  toggleVideoBookmark?: Maybe<Scalars['Boolean']['output']>;
  triggerNotificationByCode?: Maybe<Scalars['Boolean']['output']>;
  unarchiveJobApplication?: Maybe<MutationResponse>;
  unpinFeed?: Maybe<FeedPinResult>;
  updateAboutMeV2?: Maybe<MutationResponse>;
  updateCommunityMemberAbout?: Maybe<MutationResponse>;
  updateCommunityMemberProfileColor?: Maybe<MutationResponse>;
  updateEducationV2?: Maybe<MutationResponse>;
  updateExperienceV2?: Maybe<MutationResponse>;
  updateExperienceV3?: Maybe<MutationResponse>;
  updateExperienceV4?: Maybe<MutationResponse>;
  updateFeed?: Maybe<MutationResponse>;
  updateFeedComment?: Maybe<MutationResponse>;
  updateFeedV2?: Maybe<Feed>;
  updateImageProfileV2?: Maybe<MutationResponse>;
  updateImageProfileV3?: Maybe<MutationResponse>;
  updateProfile?: Maybe<MutationResponse>;
  updateSalaryExpectation?: Maybe<MutationResponse>;
  updateSupportLinkV2?: Maybe<MutationResponse>;
  updateUsername?: Maybe<MutationResponse>;
  uploadUserCv?: Maybe<MutationResponse>;
  verifyApplicantPreviewPageCode?: Maybe<VerifyPreviewPageCodeResult>;
  voteCommunityVideoV2?: Maybe<MutationResponse>;
  voteCommunityVideoV2Comment?: Maybe<MutationResponse>;
  voteFeed?: Maybe<MutationResponse>;
  voteFeedComment?: Maybe<MutationResponse>;
  votePoll?: Maybe<MutationResponse>;
  votePollV2?: Maybe<VotePollResult>;
};

export type MutationOpsUpdateJobVacancyReportArgs = {
  payload: OpsJobVacancyReportUpdateInput;
};

export type MutationAcceptInterviewInvitationArgs = {
  applicationId: Scalars['ID']['input'];
};

export type MutationAddJobApplicationStatusFeedbackArgs = {
  data: JobApplicationStatusFeedbackInput;
};

export type MutationAddQuestionFeedbackArgs = {
  data: InputQuestionFeedback;
};

export type MutationAddQuestionResultArgs = {
  data: InputExamQuestionCreate;
};

export type MutationAddToCartArgs = {
  data: InputAddToCart;
};

export type MutationApplyVacancyV5Args = {
  data: JobApplicationCreateInputV3;
};

export type MutationApplyVacancyV6Args = {
  data: JobApplicationCreateInputV3;
};

export type MutationArchiveJobApplicationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationBlockUserCommunityArgs = {
  data?: InputMaybe<InputBlockUserCommunity>;
};

export type MutationBookmarkVacancyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationCalculateVoucherArgs = {
  data: InputVoucherToCart;
};

export type MutationCancelApplicationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationCheckLocalizationArgs = {
  lang: Scalars['String']['input'];
};

export type MutationCheckoutSubmitArgs = {
  data: InputCheckout;
};

export type MutationClaimFreeTrialArgs = {
  key: FreeTrialDomain;
};

export type MutationCountSelectedAiDescriptionArgs = {
  count: Scalars['Int']['input'];
};

export type MutationCreateCommunityVideoV2CommentArgs = {
  data: InputCreateCommunityVideoComment;
};

export type MutationCreateCommunityVideoV2CommentV2Args = {
  data: InputCreateCommunityVideoComment;
};

export type MutationCreateEducationV2Args = {
  payload: EducationCreateInput;
};

export type MutationCreateExperienceV2Args = {
  payload: ExperienceCreateInputV2;
};

export type MutationCreateExperienceV3Args = {
  payload: ExperienceCreateInputV3;
};

export type MutationCreateExperienceV4Args = {
  payload: ExperienceCreateInputV4;
};

export type MutationCreateFeedArgs = {
  data: InputCreateFeed;
};

export type MutationCreateFeedCommentArgs = {
  data: InputCreateFeedComment;
};

export type MutationCreateFeedCommentV2Args = {
  data: InputCreateFeedComment;
};

export type MutationCreateFeedCommentV3Args = {
  data: InputCreateFeedComment;
};

export type MutationCreateFeedV2Args = {
  data: InputCreateFeed;
};

export type MutationCreateFeedV3Args = {
  data: InputCreateFeed;
};

export type MutationCreateGenerateCvUserFeedbackArgs = {
  payload?: InputMaybe<GenerateCvUserFeedbackInput>;
};

export type MutationCreateJobApplicationUserFeedbackArgs = {
  data: UserFeedbackCreateInput;
};

export type MutationCreateMediaFileArgs = {
  file: MediaFileUploadInput;
};

export type MutationCreateMediaImageArgs = {
  image: MediaImageUploadInput;
};

export type MutationCreateMediaVideoArgs = {
  video: MediaVideoUploadInput;
};

export type MutationCreateOrderArgs = {
  payload?: InputMaybe<CreateOrderInput>;
};

export type MutationCreatePollArgs = {
  data: InputFeedPollCreate;
};

export type MutationCreatePollV2Args = {
  data: InputFeedPollCreate;
};

export type MutationCreateProfileArgs = {
  payload: ProfileCreateInput;
};

export type MutationCreateSalaryExpectationArgs = {
  payload: SalaryCreateInput;
};

export type MutationCreateSupportLinkV2Args = {
  payload: SupportLinkCreateInputV2;
};

export type MutationCreateUsernameArgs = {
  username: Scalars['String']['input'];
};

export type MutationDeclineInterviewInvitationArgs = {
  input: DeclineInterviewInput;
};

export type MutationDeclineInvitationArgs = {
  input?: InputMaybe<DeclineInvitationInput>;
};

export type MutationDeleteAccArgs = {
  data?: InputMaybe<InputDeleteAcc>;
};

export type MutationDeleteCommunityVideoV2CommentArgs = {
  communityVideoCommentV2Id: Scalars['ID']['input'];
};

export type MutationDeleteEducationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteExperienceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFeedArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFeedCommentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFeedV2Args = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteImageProfileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteImageProfileV2Args = {
  id: Scalars['ID']['input'];
  mediaImageId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteSalaryExpectationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSupportLinkArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEndPollArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEndPollV2Args = {
  id: Scalars['ID']['input'];
};

export type MutationEpActivateJobVacancyHighlightArgs = {
  jobVacancyId: Scalars['ID']['input'];
};

export type MutationEpActivateJobVacancyHighlightV2Args = {
  jobVacancyId: Scalars['ID']['input'];
};

export type MutationEpBulkUpdateJobApplicationStatusArgs = {
  data: EpJobApplicationStatusBulkUpdateInput;
};

export type MutationEpCloseJobVacancyArgs = {
  jobVacancyId: Scalars['ID']['input'];
};

export type MutationEpCloseJobVacancyV2Args = {
  jobVacancyId: Scalars['ID']['input'];
};

export type MutationEpCreateInterviewInvitationArgs = {
  input: EpCreateInterviewInvitationInput;
};

export type MutationEpCreateJobVacancyArgs = {
  data: EpJobVacancyCreateInput;
};

export type MutationEpCreateJobVacancyClosedFeedbackArgs = {
  payload?: InputMaybe<EpJobVacancyClosedFeedbackInput>;
};

export type MutationEpCreateJobVacancyDraftArgs = {
  input?: InputMaybe<EpJobVacancyCreateDraftInput>;
};

export type MutationEpCreateJobVacancyV2Args = {
  data: EpJobVacancyCreateInput;
};

export type MutationEpCreateJobVacancyV3Args = {
  data: EpJobVacancyCreateInput;
};

export type MutationEpDeactivateJobVacancyHighlightArgs = {
  jobVacancyId: Scalars['ID']['input'];
};

export type MutationEpDeactivateJobVacancyHighlightV2Args = {
  jobVacancyId: Scalars['ID']['input'];
};

export type MutationEpDeleteJobVacancyCandidateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEpForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationEpForgotPasswordSubmissionArgs = {
  data: EpUserForgotPasswordInput;
};

export type MutationEpGenerateLinkArgs = {
  data?: InputMaybe<EpUserGenerateLinkInput>;
};

export type MutationEpInviteJobVacancyCandidateArgs = {
  data: EpInviteJobVacancyCandidateInput;
};

export type MutationEpJobVacancyPublishArgs = {
  jobVacancyId: Scalars['ID']['input'];
};

export type MutationEpJobVacancyUnpublishArgs = {
  jobVacancyId: Scalars['ID']['input'];
};

export type MutationEpLoginArgs = {
  user: EpUserLoginInput;
};

export type MutationEpLoginV2Args = {
  user: EpUserLoginInput;
};

export type MutationEpMoveUserCompanyOpsArgs = {
  originCompany: Scalars['ID']['input'];
  targetCompany: Scalars['ID']['input'];
};

export type MutationEpOnHoldJobVacancyArgs = {
  jobVacancyId: Scalars['ID']['input'];
};

export type MutationEpOnHoldJobVacancyV2Args = {
  jobVacancyId: Scalars['ID']['input'];
};

export type MutationEpOpenJobVacancyArgs = {
  jobVacancyId: Scalars['ID']['input'];
};

export type MutationEpOpenJobVacancyV2Args = {
  jobVacancyId: Scalars['ID']['input'];
};

export type MutationEpOpenJobVacancyV3Args = {
  jobVacancyId: Scalars['ID']['input'];
};

export type MutationEpRefreshTokenV2Args = {
  accessToken: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};

export type MutationEpRegisterCompanyArgs = {
  payload?: InputMaybe<EpInputRegisterCompany>;
};

export type MutationEpRegisterCompanyV2Args = {
  payload?: InputMaybe<EpRegisterCompanyInputV2>;
};

export type MutationEpRepostJobVacancyArgs = {
  data: EpJobVacancyUpdateInput;
};

export type MutationEpRequestSubcriptionPlanFreeTrialArgs = {
  companyId: Scalars['ID']['input'];
};

export type MutationEpRequestUserSubscriptionTypeArgs = {
  companyId: Scalars['ID']['input'];
  subscriptionTypeId: Scalars['ID']['input'];
};

export type MutationEpResendSignUpLinkArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEpResetPasswordArgs = {
  data: EpUserResetPasswordInput;
};

export type MutationEpSelfSignUpArgs = {
  data: EpUserSignUpInput;
};

export type MutationEpSignUpArgs = {
  data: EpUserSignUpInput;
};

export type MutationEpSubmitApplicationPreviewInterestArgs = {
  interest: EpJobApplicationPreviewInterestInputEnum;
  jobApplicationCode: Scalars['String']['input'];
};

export type MutationEpSubmitApplicationPreviewViewStatusArgs = {
  jobApplicationCode: Scalars['String']['input'];
};

export type MutationEpSubmitContactedPhoneNumberArgs = {
  packageId: Scalars['ID']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type MutationEpUpdateBulkJobApplicationCompanyStatusArgs = {
  data?: InputMaybe<EpUpdateBulkJobApplicationCompanyStatusInput>;
};

export type MutationEpUpdateBulkJobApplicationCompanyStatusV2Args = {
  data?: InputMaybe<EpUpdateBulkJobApplicationCompanyStatusInput>;
};

export type MutationEpUpdateCompanyArgs = {
  payload?: InputMaybe<EpInputUpdateCompany>;
};

export type MutationEpUpdateInterviewInvitationArgs = {
  input: EpUpdateInterviewInvitationInput;
};

export type MutationEpUpdateJobApplicationCompanyStatusArgs = {
  data?: InputMaybe<EpUpdateJobApplicationCompanyStatusInput>;
};

export type MutationEpUpdateJobApplicationIsReadArgs = {
  data?: InputMaybe<EpUpdateJobApplicationIsRead>;
};

export type MutationEpUpdateJobApplicationNoteArgs = {
  data?: InputMaybe<EpJobApplicationNoteInput>;
};

export type MutationEpUpdateJobApplicationStatusArgs = {
  data: EpJobApplicationStatusUpdateInput;
};

export type MutationEpUpdateJobApplicationStatusAndRemarksArgs = {
  data: EpJobApplicationStatusAndRemarksUpdateInput;
};

export type MutationEpUpdateJobVacancyArgs = {
  data: EpJobVacancyUpdateInput;
};

export type MutationEpUpdateJobVacancyV2Args = {
  data: EpJobVacancyUpdateInput;
};

export type MutationEpUpdateJobVacancyV3Args = {
  data: EpJobVacancyUpdateInput;
};

export type MutationEpUpdateUserArgs = {
  data: EpUserUpdateInput;
};

export type MutationEpUpdateUserConfigArgs = {
  data: EpUserConfigUpdateInput;
};

export type MutationEpUploadCompanyLegalDocumentArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationEpUploadCompanyLogoArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationFeedbackSubmitArgs = {
  feedback: Feedback;
};

export type MutationGetRegisterExamUserArgs = {
  data: InputGetRegisterExamUser;
};

export type MutationGetRegisterExamUserV2Args = {
  data: InputGetRegisterExamUserV2;
};

export type MutationHelloDmsMutationArgs = {
  payload?: InputMaybe<Scalars['String']['input']>;
};

export type MutationHelloNotificationMutationArgs = {
  payload: Scalars['String']['input'];
};

export type MutationHelloUmsMutationArgs = {
  payload: Scalars['String']['input'];
};

export type MutationHelloUnificationMutationArgs = {
  payload?: InputMaybe<Scalars['String']['input']>;
};

export type MutationJoinCommunityArgs = {
  data: InputJoinCommunityMembership;
};

export type MutationLeaveCommunityArgs = {
  communityId: Scalars['ID']['input'];
};

export type MutationOpsAddBulkFreeClassroomArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationOpsAddBulkFreeClassroomV2Args = {
  mediaFileId: Scalars['ID']['input'];
};

export type MutationOpsAddSessionArgs = {
  id: Scalars['ID']['input'];
  payload?: InputMaybe<OpsSessionPayload>;
};

export type MutationOpsAddSessionClassroomUserArgs = {
  payload: OpsClassroomUserAddSessionInput;
};

export type MutationOpsApproveExamArgs = {
  payload: OpsInputQcExam;
};

export type MutationOpsApproveQuestionArgs = {
  payload: OpsInputQcQuestion;
};

export type MutationOpsApproveSubmissionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsAssignCompanyPackageArgs = {
  payload?: InputMaybe<OpsAssignCompanyPackageInput>;
};

export type MutationOpsAssignOfflineProfileArgs = {
  payload: OpsAssignProfileInput;
};

export type MutationOpsBanCommunityVideoV2UserArgs = {
  reportedCommunityMemberId: Scalars['ID']['input'];
};

export type MutationOpsBanOrderArgs = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationOpsCancelOrderArgs = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationOpsCancelOrderInquiryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationOpsClassroomUserActivationArgs = {
  payload: OpsClassroomUserActivationInput;
};

export type MutationOpsClassroomUserDetailActivationArgs = {
  payload: OpsClassroomUserDetailActivationInput;
};

export type MutationOpsCreateClassroomArgs = {
  payload?: InputMaybe<OpsClassroomPayload>;
};

export type MutationOpsCreateCommunityGroupArgs = {
  payload: OpsCommunityGroupCreateInput;
};

export type MutationOpsCreateCommunityMembershipArgs = {
  payload: OpsInputCreateCommunityMembership;
};

export type MutationOpsCreateCommunityVideoArgs = {
  payload: OpsInputCreateCommunityVideo;
};

export type MutationOpsCreateCommunityVideoV2Args = {
  data: OpsInputCreateCommunityVideoV2;
};

export type MutationOpsCreateCompanyAndSubmitKlepUserRegistryArgs = {
  jobCompanyPayload: OpsInputJobCompany;
  userRegistryPayload?: InputMaybe<
    Array<InputMaybe<OpsJobKlepCompanyUserRegistryInput>>
  >;
};

export type MutationOpsCreateCompanyAndSubmitKlepUserRegistryV2Args = {
  jobCompanyPayload: OpsCompanyInputV2;
  userRegistryPayload: Array<OpsJobKlepCompanyUserRegistryInputV2>;
};

export type MutationOpsCreateCompanyVideoArgs = {
  data: OpsInputCreateCompanyVideo;
};

export type MutationOpsCreateContentArgs = {
  payload: OpsCreateContentInput;
};

export type MutationOpsCreateCountryArgs = {
  payload?: InputMaybe<OpsCountryPayload>;
};

export type MutationOpsCreateExamDraftArgs = {
  payload: OpsInputCreateExamDraft;
  type?: InputMaybe<OpsCreationType>;
};

export type MutationOpsCreateExamFreeTrialArgs = {
  payload: OpsInputExamFreeTrialCreate;
};

export type MutationOpsCreateExamPackageArgs = {
  payload: OpsInputExamPackageCreate;
};

export type MutationOpsCreateFeedArgs = {
  payload: OpsInputCreateFeed;
};

export type MutationOpsCreateGuidelineArgs = {
  payload: OpsInputGuidelineCreate;
};

export type MutationOpsCreateHighlightedJobArgs = {
  payload: OpsJobHighlightInput;
};

export type MutationOpsCreateJobCompanyArgs = {
  payload: OpsInputJobCompany;
};

export type MutationOpsCreateJobCompanyAccManagerArgs = {
  payload: OpsJobCompanyAccManagerCreateInput;
};

export type MutationOpsCreateJobKlepCompanyDraftArgs = {
  payload: OpsInputJobCompanyDraft;
};

export type MutationOpsCreateLiveArgs = {
  payload?: InputMaybe<OpsLivePayload>;
};

export type MutationOpsCreateLiveUserDraftArgs = {
  payload?: InputMaybe<OpsLiveUserDraftPayload>;
};

export type MutationOpsCreateMarketingBannerArgs = {
  payload: OpsMarketingBannerPayload;
};

export type MutationOpsCreateMissionArgs = {
  payload: OpsInputCreateMission;
};

export type MutationOpsCreateNewsArgs = {
  payload: OpsInputNewsCreate;
};

export type MutationOpsCreateNotificationArgs = {
  payload: OpsInputMrNotificationCreate;
};

export type MutationOpsCreateNotificationMessageArgs = {
  input: OpsCreateNotificationInput;
};

export type MutationOpsCreateOfflineProfileArgs = {
  payload: OpsProfileCreateInput;
};

export type MutationOpsCreateOrderArgs = {
  payload?: InputMaybe<OpsOrderPayload>;
};

export type MutationOpsCreatePackageArgs = {
  payload?: InputMaybe<OpsPackagePayload>;
};

export type MutationOpsCreatePassingGradeArgs = {
  payload: OpsInputCategoryMainCreate;
};

export type MutationOpsCreateQuestionDraftArgs = {
  payload: OpsInputCreateQuestionDraft;
  type?: InputMaybe<OpsCreationType>;
};

export type MutationOpsCreateReferralProgramArgs = {
  payload: OpsInputReferralProgramsCreate;
};

export type MutationOpsCreateSegmentArgs = {
  payload: InputCreateSegment;
};

export type MutationOpsCreateSkuArgs = {
  payload: OpsInputSkuCreate;
};

export type MutationOpsCreateSkuCategoryArgs = {
  payload: OpsInputSkuCategoryCreate;
};

export type MutationOpsCreateTeacherArgs = {
  payload?: InputMaybe<OpsTeacherPayload>;
};

export type MutationOpsCreateVacancyArgs = {
  payload: OpsInputSingleJobVacancy;
};

export type MutationOpsCreateVacancyHighlightSectionArgs = {
  payload: OpsCreateVacancyHighlightSectionInput;
};

export type MutationOpsCreateVacancyReqCatSubArgs = {
  payload: OpsVacancyReqCatSubInputCreate;
};

export type MutationOpsCreateVideoArgs = {
  payload: OpsInputCreateVideo;
};

export type MutationOpsCreateVoucherArgs = {
  payload: OpsInputCreateVoucher;
};

export type MutationOpsDeactivateKlepCompanyArgs = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationOpsDeactivateKlepCompanyUserArgs = {
  companyId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
};

export type MutationOpsDeleteClassroomArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteCommunityGroupArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteCommunityVideoV2Args = {
  communityVideoV2Id: Scalars['ID']['input'];
};

export type MutationOpsDeleteCompanyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteCompanyVideoArgs = {
  companyVideoId: Scalars['ID']['input'];
};

export type MutationOpsDeleteCountryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationOpsDeleteExamArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteExamDraftArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteExamFreeTrialArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteExamPackageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteExamPackageDisplayArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteFeedArgs = {
  id: Scalars['ID']['input'];
  publishType: Scalars['String']['input'];
};

export type MutationOpsDeleteGuidelineArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteHighlightedJobArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteLiveArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationOpsDeleteLiveUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationOpsDeleteLiveUserDraftArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteMarketingBannerArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteMissionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteNewsArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteNotificationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteNotificationMessageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteOrderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeletePackageArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationOpsDeletePassingGradeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteQuestionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteQuestionDraftArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteSessionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteSkillArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteSkuArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteSkuCategoryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteTeacherArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationOpsDeleteVacancyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteVacancyHighlightSectionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteVideoArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDeleteVoucherArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsDoLoginArgs = {
  user?: InputMaybe<OpsUser>;
};

export type MutationOpsNotifyBillArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsPinUnpinCommunityVideoV2Args = {
  communityVideoV2Id: Scalars['ID']['input'];
};

export type MutationOpsProceedOrderInquiryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationOpsPublishClassroomArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsPublishCommunityGroupArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsPublishContentArgs = {
  contentId: Scalars['ID']['input'];
};

export type MutationOpsPublishExamBySessionIdArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsPublishExamFreeTrialArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsPublishExamPackageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsPublishFeedArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsPublishGuidelineArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsPublishMarketingBannerArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsPublishMissionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsPublishNewsArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsPublishPackageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsPublishSkuArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsPublishVacancyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsPublishVacancyHighlightSectionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsPublishVacancyV2Args = {
  id: Scalars['ID']['input'];
};

export type MutationOpsReRegisterArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsRecalculateExamQuestionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsRecalculateExamUserResultArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsRefundBillConfirmationArgs = {
  payload: OpsBillConfirmationUpdatePayload;
};

export type MutationOpsRegisterExamUserArgs = {
  payload: OpsInputExamUserRegister;
};

export type MutationOpsRegisterJobCompanyPremiumArgs = {
  data: OpsRegisterJobCompanyPremiumInput;
};

export type MutationOpsRegisterLiveUserDraftArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsRejectExamArgs = {
  payload: OpsInputQcExam;
};

export type MutationOpsRejectQuestionArgs = {
  payload: OpsInputQcQuestion;
};

export type MutationOpsRejectSubmissionArgs = {
  id: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type MutationOpsRejoinOrderArgs = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationOpsRepublishExamBySessionIdArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsResolveCommunityMemberReportArgs = {
  communityBanCategoryId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type MutationOpsRetryBulkFreeClassroomArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsRetryNotificationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsStatusLiveUserArgs = {
  payload?: InputMaybe<OpsStatusLiveUserPayload>;
};

export type MutationOpsSubmitBulkAddHighlightArgs = {
  payload: Array<OpsJobHighlightInput>;
};

export type MutationOpsSubmitBulkCompaniesArgs = {
  payload: Array<OpsInputBulkJobCompany>;
};

export type MutationOpsSubmitBulkDeleteHighlightArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationOpsSubmitBulkJobKlepCompanyUserRegistryArgs = {
  input: Array<InputMaybe<OpsJobKlepCompanyUserRegistryInput>>;
};

export type MutationOpsSubmitBulkJobKlepCompanyUserRegistryV2Args = {
  payload: Array<OpsSubmitBulkJobKlepCompanyUserRegistryInputV2>;
};

export type MutationOpsSubmitBulkSkillsArgs = {
  payload: Array<OpsInputJobSkill>;
};

export type MutationOpsSubmitBulkVacanciesArgs = {
  payload: Array<OpsInputJobVacancy>;
};

export type MutationOpsSubmitCompanyPackageArgs = {
  payload?: InputMaybe<OpsSubmitCompanyPackageInput>;
};

export type MutationOpsSubmitUnPublishBulkVacancyArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationOpsSwapSessionArgs = {
  id: Scalars['ID']['input'];
  swapId: Scalars['ID']['input'];
};

export type MutationOpsSwitchJobCompanyAccManagerSubmitArgs = {
  payload: Array<OpsJobCompanyAccManagerSwitchSubmitInput>;
};

export type MutationOpsSwitchJobCompanyAccManagerVerifyArgs = {
  payload: OpsJobCompanyAccManagerSwitchVerifyInput;
};

export type MutationOpsTotalInfoOrderInquiryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationOpsUnPublishCommunityGroupArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsUnPublishExamFreeTrialArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsUnPublishGuidelineArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsUnPublishMarketingBannerArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsUnPublishMissionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsUnPublishNewsArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsUnPublishSkuArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsUnPublishVacancyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsUnbanCommunityVideoV2UserArgs = {
  id: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type MutationOpsUnpublishVacancyHighlightSectionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationOpsUpdateBanCommentArgs = {
  payload: OpsInputBanComment;
};

export type MutationOpsUpdateBanCommunityMembershipArgs = {
  payload: OpsInputBanCommunityMembership;
};

export type MutationOpsUpdateBanFeedArgs = {
  payload: OpsInputBanFeed;
};

export type MutationOpsUpdateBillArgs = {
  payload: OpsBillUpdatePayload;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MutationOpsUpdateBillConfirmationArgs = {
  payload: OpsBillConfirmationUpdatePayload;
};

export type MutationOpsUpdateClassroomArgs = {
  payload?: InputMaybe<OpsClassroomPayload>;
};

export type MutationOpsUpdateCommunityGroupArgs = {
  payload: OpsCommunityGroupUpdateInput;
};

export type MutationOpsUpdateCommunityMembershipArgs = {
  payload: OpsInputUpdateCommunityMembership;
};

export type MutationOpsUpdateCommunityVideoV2Args = {
  data: OpsInputUpdateCommunityVideoV2;
};

export type MutationOpsUpdateCompanyVideoArgs = {
  data: OpsInputUpdateCompanyVideo;
};

export type MutationOpsUpdateCountryArgs = {
  payload?: InputMaybe<OpsCountryPayload>;
};

export type MutationOpsUpdateExamArgs = {
  payload: OpsInputUpdateExam;
};

export type MutationOpsUpdateExamDraftArgs = {
  payload: OpsInputUpdateExamDraft;
  type?: InputMaybe<OpsCreationType>;
};

export type MutationOpsUpdateExamFreeTrialArgs = {
  payload: OpsInputExamFreeTrialUpdate;
};

export type MutationOpsUpdateExamPackageArgs = {
  payload: OpsInputExamPackageUpdate;
};

export type MutationOpsUpdateExamPackageDisplayArgs = {
  payload: OpsInputExamPackageDisplayUpdate;
};

export type MutationOpsUpdateFeedArgs = {
  payload: OpsInputUpdateFeed;
};

export type MutationOpsUpdateFeedCommentReportArgs = {
  payload: OpsInputUpdateFeedCommentReport;
};

export type MutationOpsUpdateFeedReportArgs = {
  payload: OpsInputUpdateFeedReport;
};

export type MutationOpsUpdateGuidelineArgs = {
  payload: OpsInputGuidelineUpdate;
};

export type MutationOpsUpdateHighlightedJobArgs = {
  payload: OpsJobHighlightUpdate;
};

export type MutationOpsUpdateJobCompanyArgs = {
  payload: OpsJobCompanyInputUpdate;
};

export type MutationOpsUpdateJobCompanyAccManagerArgs = {
  payload: OpsJobCompanyAccManagerUpdateInput;
};

export type MutationOpsUpdateJobCompanyPremiumArgs = {
  data: OpsUpdateJobCompanyPremiumInput;
};

export type MutationOpsUpdateJobCompanyPremiumRequestArgs = {
  data: OpsUpdateJobCompanyPremiumRequestInput;
};

export type MutationOpsUpdateJobCompanyVerificationStatusArgs = {
  payload?: InputMaybe<OpsUpdateJobCompanyVerificationStatusInput>;
};

export type MutationOpsUpdateJobKlepCompanyDraftArgs = {
  payload: OpsJobCompanyDraftInputUpdate;
};

export type MutationOpsUpdateLiveArgs = {
  payload?: InputMaybe<OpsLivePayload>;
};

export type MutationOpsUpdateLiveUserDraftArgs = {
  payload?: InputMaybe<OpsLiveUserDraftPayload>;
};

export type MutationOpsUpdateMarketingBannerArgs = {
  payload: OpsMarketingBannerPayload;
};

export type MutationOpsUpdateNewsArgs = {
  payload: OpsInputNewsUpdate;
};

export type MutationOpsUpdateOfflineProfileArgs = {
  payload: OpsProfileUpdateInput;
};

export type MutationOpsUpdateOrderArgs = {
  payload?: InputMaybe<OpsOrderPayload>;
};

export type MutationOpsUpdatePackageArgs = {
  payload?: InputMaybe<OpsPackagePayload>;
};

export type MutationOpsUpdatePassingGradeArgs = {
  payload: OpsInputCategoryMainUpdate;
};

export type MutationOpsUpdateQuestionArgs = {
  payload: OpsInputUpdateQuestion;
};

export type MutationOpsUpdateQuestionDraftArgs = {
  payload: OpsInputUpdateQuestionDraft;
  type?: InputMaybe<OpsCreationType>;
};

export type MutationOpsUpdateRedemptionArgs = {
  payload: OpsInputUpdateRedemption;
};

export type MutationOpsUpdateReferralProgramArgs = {
  payload: OpsInputReferralProgramsUpdate;
};

export type MutationOpsUpdateSessionArgs = {
  payload?: InputMaybe<OpsUpdateSessionPayload>;
};

export type MutationOpsUpdateSkuArgs = {
  payload: OpsInputSkuUpdate;
};

export type MutationOpsUpdateSkuCategoryArgs = {
  payload: OpsInputSkuCategoryUpdate;
};

export type MutationOpsUpdateTeacherArgs = {
  payload?: InputMaybe<OpsTeacherPayload>;
};

export type MutationOpsUpdateVacancyArgs = {
  payload: OpsUpdateJobVacancy;
};

export type MutationOpsUpdateVacancyHighlightSectionArgs = {
  payload: OpsUpdateVacancyHighlightSectionInput;
};

export type MutationOpsUpdateVacancyReqCatSubArgs = {
  payload: OpsVacancyReqCatSubInputUpdate;
};

export type MutationOpsUpdateVacancyV2Args = {
  payload: OpsUpdateJobVacancyV2;
};

export type MutationOpsUpdateVideoArgs = {
  payload: OpsInputUpdateVideo;
};

export type MutationOpsUpdateVoucherArgs = {
  payload: OpsInputUpdateVoucher;
};

export type MutationOpsUploadCommunityVideoArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationOpsUploadFileArgs = {
  file1?: InputMaybe<Scalars['Upload']['input']>;
  file2?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationOpsUploadedVerifiedSkillsSubmitArgs = {
  mediaFileId: Scalars['ID']['input'];
};

export type MutationOpsVerifyBulkAddHighlightArgs = {
  file: Scalars['Upload']['input'];
};

export type MutationOpsVerifyBulkAddHighlightV2Args = {
  mediaFileId: Scalars['ID']['input'];
};

export type MutationOpsVerifyBulkCompaniesArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationOpsVerifyBulkCompaniesV2Args = {
  mediaFileId: Scalars['ID']['input'];
};

export type MutationOpsVerifyBulkDeleteHighlightArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationOpsVerifyBulkQuestionsArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationOpsVerifyBulkQuestionsV2Args = {
  mediaFileId: Scalars['ID']['input'];
};

export type MutationOpsVerifyBulkSkillsArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationOpsVerifyBulkSkillsV2Args = {
  mediaFileId: Scalars['ID']['input'];
};

export type MutationOpsVerifyBulkVacanciesArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationOpsVerifyBulkVacanciesV2Args = {
  mediaFileId: Scalars['ID']['input'];
};

export type MutationOpsVerifyUnPublishBulkVacancyArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationPinCommunityVideoV2CommentArgs = {
  communityVideoCommentV2Id: Scalars['ID']['input'];
};

export type MutationPinFeedArgs = {
  feedId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationPushAnalyticArgs = {
  data: AnalyticDataInput;
};

export type MutationQuickApplyArgs = {
  input: QuickApplyInput;
};

export type MutationReadJobApplicationArgs = {
  jobApplicationId: Scalars['ID']['input'];
};

export type MutationRemoveBookmarkVacancyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveUserCvArgs = {
  id: Scalars['ID']['input'];
};

export type MutationReportCommunityVideoV2CommentArgs = {
  data: InputReportVideoComment;
};

export type MutationReportCommunityVideoV2UserArgs = {
  data: InputReportCommunityVideoV2User;
};

export type MutationReportFeedArgs = {
  data: InputReportFeed;
};

export type MutationReportFeedCommentArgs = {
  data: InputReportFeedComment;
};

export type MutationReportUserCommunityArgs = {
  data: InputReportUserCommunity;
};

export type MutationReportVacancyArgs = {
  data: VacancyReportSubmitInput;
};

export type MutationReportVacancyFromApplicationArgs = {
  applicationId: Scalars['ID']['input'];
  data: VacancyReportSubmitInput;
};

export type MutationRequestChargeMidtransArgs = {
  data: InputChargeMidtrans;
};

export type MutationRescheduleInterviewInvitationArgs = {
  input: RescheduleInterviewInput;
};

export type MutationResendApplicationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationResendApplicationV2Args = {
  id: Scalars['ID']['input'];
};

export type MutationSaveGeneratedUserCvArgs = {
  cvTemplateId: Scalars['ID']['input'];
};

export type MutationSelectUserCvArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSetHasNoExperienceArgs = {
  value: Scalars['Boolean']['input'];
};

export type MutationSubmitAbandonmentReasonArgs = {
  reason: AbandonmentReason;
};

export type MutationSubmitMissionClaimArgs = {
  payload: InputMissionClaim;
};

export type MutationSubmitSkillArgs = {
  payload: InputSubmitSkill;
};

export type MutationSubmitSubmissionArgs = {
  payload: InputSubmission;
};

export type MutationSubmitUserInterestsArgs = {
  payload?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type MutationSubmitUserJobFunctionArgs = {
  data: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationSubmitVacancyScreeningV2Args = {
  payload?: InputMaybe<VacancyScreeningSubmitInputV2>;
};

export type MutationSubmitWorkPreferenceArgs = {
  payload: WorkPreferenceInput;
};

export type MutationSubmitWorkPreferenceV2Args = {
  payload: WorkPreferenceInputV2;
};

export type MutationSubmitWorkPreferenceV3Args = {
  payload: WorkPreferenceInputV3;
};

export type MutationSuggestLocationArgs = {
  payload: SuggestLocationInput;
};

export type MutationTakeMissionArgs = {
  missionId: Scalars['ID']['input'];
};

export type MutationToggleVideoBookmarkArgs = {
  communityMemberId: Scalars['ID']['input'];
  communityVideoId: Scalars['ID']['input'];
};

export type MutationTriggerNotificationByCodeArgs = {
  code: Scalars['String']['input'];
};

export type MutationUnarchiveJobApplicationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUnpinFeedArgs = {
  feedId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateAboutMeV2Args = {
  payload: AboutMeUpdateInputV2;
};

export type MutationUpdateCommunityMemberAboutArgs = {
  payload?: InputMaybe<InputUpdateCommunityMemberAbout>;
};

export type MutationUpdateCommunityMemberProfileColorArgs = {
  color?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateEducationV2Args = {
  payload: EducationUpdateInput;
};

export type MutationUpdateExperienceV2Args = {
  payload: ExperienceUpdateInputV2;
};

export type MutationUpdateExperienceV3Args = {
  payload: ExperienceUpdateInputV3;
};

export type MutationUpdateExperienceV4Args = {
  payload: ExperienceUpdateInputV4;
};

export type MutationUpdateFeedArgs = {
  data: InputUpdateFeed;
};

export type MutationUpdateFeedCommentArgs = {
  data: InputUpdateFeedComment;
};

export type MutationUpdateFeedV2Args = {
  data: InputUpdateFeed;
};

export type MutationUpdateImageProfileV2Args = {
  payload: ProfilePictureUrlUpdateInput;
};

export type MutationUpdateImageProfileV3Args = {
  payload: ProfilePictureUrlUpdateInputV2;
};

export type MutationUpdateProfileArgs = {
  payload: ProfileUpdateInput;
};

export type MutationUpdateSalaryExpectationArgs = {
  payload: SalaryUpdateInput;
};

export type MutationUpdateSupportLinkV2Args = {
  payload: SupportLinkUpdateInputV2;
};

export type MutationUpdateUsernameArgs = {
  newUsername: Scalars['String']['input'];
};

export type MutationUploadUserCvArgs = {
  payload: UploadUserCvInput;
};

export type MutationVerifyApplicantPreviewPageCodeArgs = {
  pin: Scalars['String']['input'];
  uniqueId: Scalars['String']['input'];
};

export type MutationVoteCommunityVideoV2Args = {
  communityVideoV2Id: Scalars['ID']['input'];
};

export type MutationVoteCommunityVideoV2CommentArgs = {
  communityVideoCommentV2Id: Scalars['ID']['input'];
};

export type MutationVoteFeedArgs = {
  data: InputVoteFeed;
};

export type MutationVoteFeedCommentArgs = {
  data: InputVoteFeedComment;
};

export type MutationVotePollArgs = {
  data: InputFeedPollVote;
};

export type MutationVotePollV2Args = {
  data: InputFeedPollVote;
};

export type MutationResponse = {
  __typename?: 'MutationResponse';
  data?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
  warning?: Maybe<Scalars['String']['output']>;
};

export type Notification = {
  __typename?: 'Notification';
  actionUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  shortDescription?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type NotificationBadge = {
  __typename?: 'NotificationBadge';
  count?: Maybe<Scalars['Int']['output']>;
  resetDate?: Maybe<Scalars['String']['output']>;
};

export type NotificationBadgeDomain = {
  __typename?: 'NotificationBadgeDomain';
  count?: Maybe<Scalars['Int']['output']>;
  domainName?: Maybe<Scalars['String']['output']>;
  lifeTime?: Maybe<Scalars['Int']['output']>;
};

export type NotificationHistories = {
  __typename?: 'NotificationHistories';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<NotificationHistory>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type NotificationHistory = {
  __typename?: 'NotificationHistory';
  actionUrl?: Maybe<Scalars['String']['output']>;
  attributes?: Maybe<Array<Maybe<KeyValue>>>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdAtLocale?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  domainDetail?: Maybe<NotificationType>;
  expiredAt?: Maybe<Scalars['BigInt']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  messageName?: Maybe<Scalars['String']['output']>;
  notificationType?: Maybe<NotificationType>;
  receivedAt?: Maybe<Scalars['BigInt']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type NotificationListView = {
  __typename?: 'NotificationListView';
  list?: Maybe<Array<Maybe<Notification>>>;
};

export type NotificationStatus = {
  __typename?: 'NotificationStatus';
  publishedAt?: Maybe<Scalars['String']['output']>;
};

export type NotificationTarget = {
  __typename?: 'NotificationTarget';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type NotificationTargets = {
  __typename?: 'NotificationTargets';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<NotificationTarget>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type NotificationType = {
  __typename?: 'NotificationType';
  asset?: Maybe<NotificationTypeAsset>;
  displayName?: Maybe<Scalars['String']['output']>;
  event?: Maybe<EventTracking>;
  id?: Maybe<Scalars['ID']['output']>;
  isDomain?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notificationTypeEmptyState?: Maybe<NotificationTypeEmptyState>;
  screenDensity?: Maybe<Scalars['String']['output']>;
};

export type NotificationTypeAsset = {
  __typename?: 'NotificationTypeAsset';
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  notificationTypeId?: Maybe<Scalars['ID']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
};

export type NotificationTypeEmptyState = {
  __typename?: 'NotificationTypeEmptyState';
  ctaLabel?: Maybe<Scalars['String']['output']>;
  ctaUrl?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  event?: Maybe<EventTracking>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type OpsAnswer = {
  __typename?: 'OpsAnswer';
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  idxStr?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isCorrect?: Maybe<Scalars['Boolean']['output']>;
  isLatex?: Maybe<Scalars['Boolean']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export enum OpsAnswerType {
  MultipleChoice = 'MULTIPLE_CHOICE',
}

export type OpsAssignCompanyPackageError = {
  __typename?: 'OpsAssignCompanyPackageError';
  message: Scalars['String']['output'];
  typeError: OpsAssignCompanyPackageErrorEnum;
};

export enum OpsAssignCompanyPackageErrorEnum {
  UnknownError = 'UNKNOWN_ERROR',
}

export type OpsAssignCompanyPackageInput = {
  attachmentId?: InputMaybe<Scalars['ID']['input']>;
  companyId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  packageId: Scalars['ID']['input'];
};

export type OpsAssignCompanyPackageResponse = {
  __typename?: 'OpsAssignCompanyPackageResponse';
  companyId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  packageId: Scalars['ID']['output'];
};

export type OpsAssignCompanyPackageResult =
  | OpsAssignCompanyPackageError
  | OpsAssignCompanyPackageResponse;

export type OpsAssignProfileInput = {
  jobVacancyId?: InputMaybe<Scalars['String']['input']>;
  userProfileIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export enum OpsBannerType {
  Banner = 'BANNER',
  Highlight = 'HIGHLIGHT',
  ImageButton = 'IMAGE_BUTTON',
  Popup = 'POPUP',
  TopBanner = 'TOP_BANNER',
}

export type OpsBill = {
  __typename?: 'OpsBill';
  autoPayments?: Maybe<Array<Maybe<OpsOrderBillAutoPayment>>>;
  bankAccName?: Maybe<Scalars['String']['output']>;
  bankAccNo?: Maybe<Scalars['String']['output']>;
  bankRefNo?: Maybe<Scalars['String']['output']>;
  billAmount?: Maybe<Scalars['Float']['output']>;
  billNo?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  dueDateLocale?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isRefund?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<OpsOrder>;
  reason?: Maybe<Scalars['String']['output']>;
  receipt?: Maybe<Scalars['String']['output']>;
  refundAmount?: Maybe<Scalars['Float']['output']>;
  refundMethod?: Maybe<Scalars['String']['output']>;
  refundReceipt?: Maybe<Scalars['String']['output']>;
  refundStatus?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsBillConfirmation = {
  __typename?: 'OpsBillConfirmation';
  amount?: Maybe<Scalars['Float']['output']>;
  bill?: Maybe<OpsBill>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<OpsOrder>;
  reason?: Maybe<Scalars['String']['output']>;
  receipt?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsBillConfirmationUpdatePayload = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  bankAccName?: InputMaybe<Scalars['String']['input']>;
  bankAccNo?: InputMaybe<Scalars['String']['input']>;
  bankRefNo?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  refundAmount?: InputMaybe<Scalars['Float']['input']>;
  refundReceipt?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type OpsBillConfirmations = {
  __typename?: 'OpsBillConfirmations';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsBillConfirmation>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsBillUpdatePayload = {
  bankAccName?: InputMaybe<Scalars['String']['input']>;
  bankAccNo?: InputMaybe<Scalars['String']['input']>;
  bankRefNo?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageId?: InputMaybe<Scalars['String']['input']>;
  isRefund?: InputMaybe<Scalars['Boolean']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  receipt?: InputMaybe<Scalars['String']['input']>;
  refundAmount?: InputMaybe<Scalars['Float']['input']>;
  refundMethod?: InputMaybe<Scalars['String']['input']>;
  refundReceipt?: InputMaybe<Scalars['String']['input']>;
  refundStatus?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type OpsBills = {
  __typename?: 'OpsBills';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsBill>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsBulkAddHighlightResult = {
  __typename?: 'OpsBulkAddHighlightResult';
  companyName?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  highlightedTitle?: Maybe<Scalars['String']['output']>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  jobVacancyCode?: Maybe<Scalars['String']['output']>;
  jobVacancyHomeSectionId?: Maybe<Scalars['ID']['output']>;
  jobVacancyId?: Maybe<Scalars['ID']['output']>;
  positionName?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
};

export type OpsBulkCompaniesResult = {
  __typename?: 'OpsBulkCompaniesResult';
  accManager?: Maybe<Scalars['String']['output']>;
  accManagerWhatsapp?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyType?: Maybe<Scalars['String']['output']>;
  contactDayEnd?: Maybe<Scalars['Int']['output']>;
  contactDayStart?: Maybe<Scalars['Int']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactHourEnd?: Maybe<Scalars['Int']['output']>;
  contactHourStart?: Maybe<Scalars['Int']['output']>;
  contactWeblink?: Maybe<Scalars['String']['output']>;
  contactWhatsapp?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  foundedYear?: Maybe<Scalars['Int']['output']>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  jobCompanyIndustryId?: Maybe<Scalars['ID']['output']>;
  jobCompanyIndustryName?: Maybe<Scalars['String']['output']>;
  jobCompanySizeId?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
};

export type OpsBulkDeleteHighlightResult = {
  __typename?: 'OpsBulkDeleteHighlightResult';
  error?: Maybe<Scalars['String']['output']>;
  highlightedJob?: Maybe<OpsHighlightedJob>;
  highlightedJobId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
};

export type OpsBulkJobKlepCompanyUserRegistry = {
  __typename?: 'OpsBulkJobKlepCompanyUserRegistry';
  data?: Maybe<Array<Maybe<OpsBulkJobKlepCompanyUserRegistryData>>>;
  error?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
  warning?: Maybe<Scalars['String']['output']>;
};

export type OpsBulkJobKlepCompanyUserRegistryData = {
  __typename?: 'OpsBulkJobKlepCompanyUserRegistryData';
  companyId?: Maybe<Scalars['ID']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type OpsBulkJobKlepCompanyUserRegistryDataV2 = {
  __typename?: 'OpsBulkJobKlepCompanyUserRegistryDataV2';
  companyId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type OpsBulkJobKlepCompanyUserRegistryV2Error = {
  __typename?: 'OpsBulkJobKlepCompanyUserRegistryV2Error';
  message: Scalars['String']['output'];
  typeError: OpsBulkJobKlepCompanyUserRegistryV2ErrorEnum;
};

export enum OpsBulkJobKlepCompanyUserRegistryV2ErrorEnum {
  UnknownError = 'UNKNOWN_ERROR',
}

export type OpsBulkJobKlepCompanyUserRegistryV2Response = {
  __typename?: 'OpsBulkJobKlepCompanyUserRegistryV2Response';
  data: Array<OpsBulkJobKlepCompanyUserRegistryDataV2>;
};

export type OpsBulkJobKlepCompanyUserRegistryV2Result =
  | OpsBulkJobKlepCompanyUserRegistryV2Error
  | OpsBulkJobKlepCompanyUserRegistryV2Response;

export type OpsBulkQuestionResult = {
  __typename?: 'OpsBulkQuestionResult';
  error?: Maybe<Scalars['String']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  question?: Maybe<OpsQuestion>;
};

export type OpsBulkUnPublishVacancyResult = {
  __typename?: 'OpsBulkUnPublishVacancyResult';
  error?: Maybe<Scalars['String']['output']>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  jobVacancyId?: Maybe<Scalars['ID']['output']>;
  vacancy?: Maybe<OpsJobVacancyV2>;
};

export type OpsBulkVacanciesResult = {
  __typename?: 'OpsBulkVacanciesResult';
  /** @deprecated feature screening question is on hold */
  additionalRequirementOne?: Maybe<Scalars['ID']['output']>;
  /** @deprecated feature screening question is on hold */
  additionalRequirementOneId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated feature screening question is on hold */
  additionalRequirementThree?: Maybe<Scalars['ID']['output']>;
  /** @deprecated feature screening question is on hold */
  additionalRequirementThreeId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated feature screening question is on hold */
  additionalRequirementTwo?: Maybe<Scalars['ID']['output']>;
  /** @deprecated feature screening question is on hold */
  additionalRequirementTwoId?: Maybe<Scalars['ID']['output']>;
  closeDate?: Maybe<Scalars['Date']['output']>;
  companyAbout?: Maybe<Scalars['String']['output']>;
  companyIndustry?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  contactDetail?: Maybe<Scalars['String']['output']>;
  contactType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  educationLevel?: Maybe<Scalars['String']['output']>;
  educationProgramIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  error?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  isOpenForAllEducationPrograms?: Maybe<Scalars['Boolean']['output']>;
  isOpenForFreshGraduate?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  jobCompanyId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated replace with jobVacancySpecializationRoleId */
  jobFunction?: Maybe<Scalars['String']['output']>;
  jobLevel?: Maybe<Scalars['String']['output']>;
  /** @deprecated feature screening question is on hold */
  jobVacancyFunctionId?: Maybe<Scalars['ID']['output']>;
  jobVacancySkillIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  jobVacancySpecializationRoleId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementFive?: Maybe<Scalars['String']['output']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementFiveId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementFour?: Maybe<Scalars['String']['output']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementFourId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementOne?: Maybe<Scalars['String']['output']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementOneId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementStr?: Maybe<Scalars['String']['output']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementThree?: Maybe<Scalars['String']['output']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementThreeId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementTwo?: Maybe<Scalars['String']['output']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementTwoId?: Maybe<Scalars['ID']['output']>;
  keyword?: Maybe<Scalars['String']['output']>;
  locationCity?: Maybe<Scalars['String']['output']>;
  locationDistrict?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['ID']['output']>;
  locationProvince?: Maybe<Scalars['String']['output']>;
  locationSite?: Maybe<Scalars['String']['output']>;
  maxAge?: Maybe<Scalars['Int']['output']>;
  minExperience?: Maybe<Scalars['Int']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  positionName?: Maybe<Scalars['String']['output']>;
  salaryLowerBound?: Maybe<Scalars['Float']['output']>;
  salaryUpperBound?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  vacancyCount?: Maybe<Scalars['Int']['output']>;
  workingDayEnd?: Maybe<Scalars['Int']['output']>;
  workingDayStart?: Maybe<Scalars['Int']['output']>;
  workingHourEnd?: Maybe<Scalars['Int']['output']>;
  workingHourStart?: Maybe<Scalars['Int']['output']>;
};

export type OpsBulkVacancySkillsResult = {
  __typename?: 'OpsBulkVacancySkillsResult';
  answerFour?: Maybe<Scalars['String']['output']>;
  answerOne?: Maybe<Scalars['String']['output']>;
  answerThree?: Maybe<Scalars['String']['output']>;
  answerTwo?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  correctAnswer?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  explanation?: Maybe<Scalars['String']['output']>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  jobVacancyRequirementCategoryId?: Maybe<Scalars['ID']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  subCategory?: Maybe<Scalars['String']['output']>;
};

export type OpsCategories = {
  __typename?: 'OpsCategories';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsCategory>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsCategory = {
  __typename?: 'OpsCategory';
  categoryMain?: Maybe<OpsCategoryMain>;
  categoryMainId?: Maybe<Scalars['ID']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsCategoryComposition = {
  __typename?: 'OpsCategoryComposition';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subCategories?: Maybe<Array<Maybe<OpsSubCategoryComposition>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type OpsCategoryMain = {
  __typename?: 'OpsCategoryMain';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  msTestSub?: Maybe<OpsMsTestSub>;
  msTestSubId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  passGradeType?: Maybe<Scalars['String']['output']>;
  passGradeValue?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsCategoryMains = {
  __typename?: 'OpsCategoryMains';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsCategoryMain>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsCategorySub = {
  __typename?: 'OpsCategorySub';
  category?: Maybe<OpsCategory>;
  categoryMain?: Maybe<OpsCategoryMain>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  msTest?: Maybe<OpsMsTest>;
  msTestSub?: Maybe<OpsMsTestSub>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsCategorySubs = {
  __typename?: 'OpsCategorySubs';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsCategorySub>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsClassroom = {
  __typename?: 'OpsClassroom';
  bulkCreation?: Maybe<Scalars['Boolean']['output']>;
  countryId?: Maybe<Scalars['ID']['output']>;
  countryStr?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  firstSession?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPublish?: Maybe<Scalars['Boolean']['output']>;
  latestIdx?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  repeatEvery?: Maybe<Scalars['String']['output']>;
  repeatOn?: Maybe<Scalars['String']['output']>;
  sessions?: Maybe<Array<Maybe<OpsSession>>>;
  totalSessions?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsClassroomPayload = {
  bulkCreation?: InputMaybe<Scalars['Boolean']['input']>;
  countryId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  firstSession?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  repeatEvery?: InputMaybe<Scalars['String']['input']>;
  repeatOn?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<Array<InputMaybe<OpsSessionPayload>>>;
  totalSessions?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsClassroomUploadSummaries = {
  __typename?: 'OpsClassroomUploadSummaries';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsClassroomUploadSummary>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsClassroomUploadSummary = {
  __typename?: 'OpsClassroomUploadSummary';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Array<Maybe<OpsClassroomUploadSummaryDetail>>>;
  failed?: Maybe<Scalars['Int']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Int']['output']>;
  successTime?: Maybe<Scalars['Date']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  uploadTime?: Maybe<Scalars['Date']['output']>;
};

export type OpsClassroomUploadSummaryDetail = {
  __typename?: 'OpsClassroomUploadSummaryDetail';
  classroomUploadSummaryId?: Maybe<Scalars['ID']['output']>;
  elements?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type OpsClassroomUser = {
  __typename?: 'OpsClassroomUser';
  className?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  csPackageId?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Array<Maybe<OpsClassroomUserDetail>>>;
  firstSession?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  lastSession?: Maybe<Scalars['Date']['output']>;
  reasonStr?: Maybe<Scalars['String']['output']>;
  reasons?: Maybe<Array<Maybe<OpsClassroomUserReason>>>;
  source?: Maybe<Scalars['String']['output']>;
  totalSessions?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
};

export type OpsClassroomUserActivationInput = {
  id: Scalars['ID']['input'];
  isActive: Scalars['Boolean']['input'];
  reason: Scalars['String']['input'];
};

export type OpsClassroomUserAddSessionInput = {
  id: Scalars['ID']['input'];
  sessionIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type OpsClassroomUserDetail = {
  __typename?: 'OpsClassroomUserDetail';
  classSessionId?: Maybe<Scalars['ID']['output']>;
  classroom?: Maybe<OpsClassroomUser>;
  classroomId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isBan?: Maybe<Scalars['Boolean']['output']>;
  reasonStr?: Maybe<Scalars['String']['output']>;
  reasons?: Maybe<Array<Maybe<OpsClassroomUserReason>>>;
  session?: Maybe<OpsSession>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsClassroomUserDetailActivationInput = {
  id: Scalars['ID']['input'];
  isBan: Scalars['Boolean']['input'];
  reason: Scalars['String']['input'];
};

export type OpsClassroomUserDetails = {
  __typename?: 'OpsClassroomUserDetails';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsClassroomUserDetail>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsClassroomUserReason = {
  __typename?: 'OpsClassroomUserReason';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  domainId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsClassroomUserSessionSummary = {
  __typename?: 'OpsClassroomUserSessionSummary';
  firstIdx?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastIdx?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type OpsClassroomUsers = {
  __typename?: 'OpsClassroomUsers';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsClassroomUser>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsClassrooms = {
  __typename?: 'OpsClassrooms';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsClassroom>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsCommentReportSummaries = {
  __typename?: 'OpsCommentReportSummaries';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsCommentReportSummary>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsCommentReportSummary = {
  __typename?: 'OpsCommentReportSummary';
  commentIsBan?: Maybe<Scalars['Boolean']['output']>;
  communityId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  feed?: Maybe<OpsFeed>;
  feedCode?: Maybe<Scalars['String']['output']>;
  feedComment?: Maybe<OpsFeedComment>;
  feedCommentId?: Maybe<Scalars['ID']['output']>;
  feedCommentReports?: Maybe<Array<Maybe<OpsFeedCommentReport>>>;
  feedId?: Maybe<Scalars['ID']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  reportCount?: Maybe<Scalars['Int']['output']>;
  reportStatus?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsCommunityBanCategory = {
  __typename?: 'OpsCommunityBanCategory';
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
};

export type OpsCommunityBoard = {
  __typename?: 'OpsCommunityBoard';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsCommunityBoardGroup = {
  __typename?: 'OpsCommunityBoardGroup';
  communityBoard?: Maybe<OpsCommunityBoard>;
  communityBoardId?: Maybe<Scalars['ID']['output']>;
  communityId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDisplay?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsCommunityBoardGroupCreateInput = {
  communityBoardId: Scalars['ID']['input'];
  idx?: InputMaybe<Scalars['Int']['input']>;
  isDisplay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OpsCommunityBoardGroupUpdateInput = {
  communityBoardId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idx?: InputMaybe<Scalars['Int']['input']>;
  isDisplay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OpsCommunityGroup = {
  __typename?: 'OpsCommunityGroup';
  boards?: Maybe<Array<Maybe<OpsCommunityBoardGroup>>>;
  code?: Maybe<Scalars['String']['output']>;
  copy?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  isPublish?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  totalMember?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsCommunityGroupCreateInput = {
  boards?: InputMaybe<Array<InputMaybe<OpsCommunityBoardGroupCreateInput>>>;
  code?: InputMaybe<Scalars['String']['input']>;
  copy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsCommunityGroupUpdateInput = {
  boards?: InputMaybe<Array<InputMaybe<OpsCommunityBoardGroupUpdateInput>>>;
  code?: InputMaybe<Scalars['String']['input']>;
  copy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsCommunityGroups = {
  __typename?: 'OpsCommunityGroups';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsCommunityGroup>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsCommunityMember = {
  __typename?: 'OpsCommunityMember';
  banVideoReason?: Maybe<Scalars['String']['output']>;
  communityBanCategory?: Maybe<OpsCommunityBanCategory>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isBan?: Maybe<Scalars['Boolean']['output']>;
  isBanVideo?: Maybe<Scalars['Boolean']['output']>;
  nickName?: Maybe<Scalars['String']['output']>;
  refereeUid?: Maybe<Scalars['ID']['output']>;
  statusBanVideo?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  user_uid?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type OpsCommunityMemberReport = {
  __typename?: 'OpsCommunityMemberReport';
  communityMemberEmail?: Maybe<Scalars['String']['output']>;
  communityName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  reportReason?: Maybe<Scalars['String']['output']>;
};

export type OpsCommunityMemberReports = {
  __typename?: 'OpsCommunityMemberReports';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsCommunityMemberReport>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsCommunityMemberUserReport = {
  __typename?: 'OpsCommunityMemberUserReport';
  communityMember?: Maybe<OpsCommunityMember>;
  list?: Maybe<Array<Maybe<OpsCommunityMemberReport>>>;
};

export type OpsCommunityMemberUserReportSummaries = {
  __typename?: 'OpsCommunityMemberUserReportSummaries';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsCommunityMemberUserReportSummary>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsCommunityMemberUserReportSummary = {
  __typename?: 'OpsCommunityMemberUserReportSummary';
  reportedCommunityMemberId?: Maybe<Scalars['ID']['output']>;
  reportedFraudCount?: Maybe<Scalars['Int']['output']>;
  reportedImposterCount?: Maybe<Scalars['Int']['output']>;
  reportedMemberEmail?: Maybe<Scalars['String']['output']>;
  reportedMemberUsername?: Maybe<Scalars['String']['output']>;
  reportedNotRelevantCount?: Maybe<Scalars['Int']['output']>;
  reportedOtherCount?: Maybe<Scalars['Int']['output']>;
  reportedSaraCount?: Maybe<Scalars['Int']['output']>;
  reportedSpamCount?: Maybe<Scalars['Int']['output']>;
};

export type OpsCommunityMembership = {
  __typename?: 'OpsCommunityMembership';
  community?: Maybe<OpsCommunityGroup>;
  communityId?: Maybe<Scalars['ID']['output']>;
  communityMember?: Maybe<OpsCommunityMember>;
  communityMemberId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isBan?: Maybe<Scalars['Boolean']['output']>;
  reasonStr?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsCommunityMemberships = {
  __typename?: 'OpsCommunityMemberships';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsCommunityMembership>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsCommunityReportCategoryDetail = {
  __typename?: 'OpsCommunityReportCategoryDetail';
  id?: Maybe<Scalars['ID']['output']>;
};

export type OpsCommunityUploadVideo = {
  __typename?: 'OpsCommunityUploadVideo';
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export enum OpsCommunityVideoCtaDomain {
  Community = 'COMMUNITY',
  Job = 'JOB',
  Learning = 'LEARNING',
  Profile = 'PROFILE',
}

export enum OpsCommunityVideoCtaType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL',
}

export type OpsCommunityVideoCategory = {
  __typename?: 'OpsCommunityVideoCategory';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsCommunityVideoV2 = {
  __typename?: 'OpsCommunityVideoV2';
  code?: Maybe<Scalars['String']['output']>;
  communityMember?: Maybe<OpsCommunityMember>;
  communityMemberId: Scalars['ID']['output'];
  communityVideoCategory?: Maybe<OpsCommunityVideoCategory>;
  communityVideoCategoryId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  ctaConfig?: Maybe<Scalars['String']['output']>;
  ctaDomain?: Maybe<OpsCommunityVideoCtaDomain>;
  ctaLink?: Maybe<Scalars['String']['output']>;
  ctaTitle?: Maybe<Scalars['String']['output']>;
  ctaType?: Maybe<OpsCommunityVideoCtaType>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAds?: Maybe<Scalars['Boolean']['output']>;
  isCta?: Maybe<Scalars['Boolean']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated switch to ctaTitle */
  jobCta?: Maybe<Scalars['String']['output']>;
  partnershipLabel?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  uploaderTitle?: Maybe<Scalars['String']['output']>;
  /** @deprecated switch to ctaConfig */
  vacancyConfig?: Maybe<Scalars['String']['output']>;
  /** @deprecated switch to ctaLink */
  vacancyRelatedUrl?: Maybe<Scalars['String']['output']>;
  video?: Maybe<MediaVideo>;
  videoId: Scalars['ID']['output'];
};

export type OpsCommunityVideoV2Comment = {
  __typename?: 'OpsCommunityVideoV2Comment';
  communityMember?: Maybe<CommunityMember>;
  communityMemberId?: Maybe<Scalars['ID']['output']>;
  communityVideoV2Id?: Maybe<Scalars['ID']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAuthor?: Maybe<Scalars['Boolean']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  isVote?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  subCommentCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  voteCount?: Maybe<Scalars['Int']['output']>;
};

export enum OpsCommunityVideoV2PinUnpinAction {
  Pin = 'PIN',
  Replace = 'REPLACE',
  Unpin = 'UNPIN',
}

export type OpsCommunityVideoV2PinUnpinValidation = {
  __typename?: 'OpsCommunityVideoV2PinUnpinValidation';
  actionType: OpsCommunityVideoV2PinUnpinAction;
  body: Scalars['String']['output'];
  submitLabel: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type OpsCommunityVideoV2UserReport = {
  __typename?: 'OpsCommunityVideoV2UserReport';
  communityMemberReportedBy?: Maybe<OpsCommunityMember>;
  communityVideoV2?: Maybe<OpsCommunityVideoV2>;
  communityVideoV2Comment?: Maybe<OpsCommunityVideoV2Comment>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdAtStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  reportReason?: Maybe<Scalars['String']['output']>;
};

export type OpsCommunityVideoV2UserReportSummaries = {
  __typename?: 'OpsCommunityVideoV2UserReportSummaries';
  elements?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsCommunityVideoV2UserReportSummary>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsCommunityVideoV2UserReportSummary = {
  __typename?: 'OpsCommunityVideoV2UserReportSummary';
  isBan?: Maybe<Scalars['Boolean']['output']>;
  reportedCommunityMemberId?: Maybe<Scalars['ID']['output']>;
  reportedFraudCount?: Maybe<Scalars['Int']['output']>;
  reportedImposterCount?: Maybe<Scalars['Int']['output']>;
  reportedMemberEmail?: Maybe<Scalars['String']['output']>;
  reportedMemberUsername?: Maybe<Scalars['String']['output']>;
  reportedNotRelevantCount?: Maybe<Scalars['Int']['output']>;
  reportedOtherCount?: Maybe<Scalars['Int']['output']>;
  reportedSaraCount?: Maybe<Scalars['Int']['output']>;
  reportedSpamCount?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type OpsCommunityVideoV2UserReports = {
  __typename?: 'OpsCommunityVideoV2UserReports';
  communityMember?: Maybe<OpsCommunityMember>;
  elements?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsCommunityVideoV2UserReport>>>;
  page?: Maybe<Scalars['Int']['output']>;
};

export type OpsCommunityVideosV2 = {
  __typename?: 'OpsCommunityVideosV2';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<OpsCommunityVideoV2>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsCompanyDetailVerifiedOrPendingError = {
  __typename?: 'OpsCompanyDetailVerifiedOrPendingError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<OpsCompanyDetailVerifiedOrPendingErrorEnum>;
};

export enum OpsCompanyDetailVerifiedOrPendingErrorEnum {
  CompanyNotFound = 'COMPANY_NOT_FOUND',
  UnknownError = 'UNKNOWN_ERROR',
  Unverified = 'UNVERIFIED',
}

export type OpsCompanyDetailVerifiedOrPendingResponse = {
  __typename?: 'OpsCompanyDetailVerifiedOrPendingResponse';
  companyCode: Scalars['String']['output'];
  companyIndustry?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  companyType?: Maybe<Scalars['String']['output']>;
  companyTypeEnum?: Maybe<Scalars['String']['output']>;
  hasPurchasedBundle?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isPremium?: Maybe<Scalars['Boolean']['output']>;
  picName?: Maybe<Scalars['String']['output']>;
  picWhatsapp?: Maybe<Scalars['String']['output']>;
  remainingQuotaGrowing?: Maybe<Scalars['Int']['output']>;
  remainingQuotaPriority?: Maybe<Scalars['Int']['output']>;
  remainingQuotaRegular?: Maybe<Scalars['Int']['output']>;
};

export type OpsCompanyDetailVerifiedOrPendingResult =
  | OpsCompanyDetailVerifiedOrPendingError
  | OpsCompanyDetailVerifiedOrPendingResponse;

export type OpsCompanyInputV2 = {
  accManager?: InputMaybe<Scalars['String']['input']>;
  accManagerCountryCode?: InputMaybe<Scalars['String']['input']>;
  accManagerWhatsapp?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  companyTypeId?: InputMaybe<Scalars['ID']['input']>;
  contactDayEnd?: InputMaybe<Scalars['Int']['input']>;
  contactDayStart?: InputMaybe<Scalars['Int']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactHourEnd?: InputMaybe<Scalars['Int']['input']>;
  contactHourEndInputStr?: InputMaybe<Scalars['String']['input']>;
  contactHourStart?: InputMaybe<Scalars['Int']['input']>;
  contactHourStartInputStr?: InputMaybe<Scalars['String']['input']>;
  contactWeblink?: InputMaybe<Scalars['String']['input']>;
  contactWhatsapp?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  foundedYear?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isLegalDocumentAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  jobCompanyIndustryId: Scalars['ID']['input'];
  jobCompanySizeId?: InputMaybe<Scalars['ID']['input']>;
  legalDocumentId?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  logoId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  verificationStatus?: InputMaybe<Scalars['String']['input']>;
};

export type OpsCompanyPackage = {
  __typename?: 'OpsCompanyPackage';
  assignedAt?: Maybe<Scalars['BigInt']['output']>;
  assignedBy?: Maybe<Scalars['String']['output']>;
  attachment?: Maybe<MediaFile>;
  attachmentId?: Maybe<Scalars['ID']['output']>;
  expiry?: Maybe<Scalars['BigInt']['output']>;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  packageName: Scalars['String']['output'];
  packageType: Scalars['String']['output'];
  remainingQuotaGrowing?: Maybe<Scalars['Int']['output']>;
  remainingQuotaPriority?: Maybe<Scalars['Int']['output']>;
  remainingQuotaRegular?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
  validity?: Maybe<Scalars['String']['output']>;
};

export type OpsCompanyPackages = {
  __typename?: 'OpsCompanyPackages';
  elements?: Maybe<Scalars['BigInt']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<OpsCompanyPackage>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsCompanyPremiumDetailError = {
  __typename?: 'OpsCompanyPremiumDetailError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<OpsCompanyPremiumDetailErrorEnum>;
};

export enum OpsCompanyPremiumDetailErrorEnum {
  UnknownError = 'UNKNOWN_ERROR',
}

export type OpsCompanyPremiumDetailResponse = {
  __typename?: 'OpsCompanyPremiumDetailResponse';
  assignedAt?: Maybe<Scalars['BigInt']['output']>;
  assignedBy?: Maybe<Scalars['String']['output']>;
  companyCode: Scalars['String']['output'];
  companyId: Scalars['ID']['output'];
  companyIndustry?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  companyType?: Maybe<Scalars['String']['output']>;
  companyTypeEnum?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPremium?: Maybe<Scalars['Boolean']['output']>;
  packages?: Maybe<Array<OpsPackageV2>>;
  picName?: Maybe<Scalars['String']['output']>;
  picWhatsapp?: Maybe<Scalars['String']['output']>;
  remainingQuotaGrowing?: Maybe<Scalars['Int']['output']>;
  remainingQuotaPriority?: Maybe<Scalars['Int']['output']>;
  remainingQuotaRegular?: Maybe<Scalars['Int']['output']>;
};

export type OpsCompanyPremiumDetailResult =
  | OpsCompanyPremiumDetailError
  | OpsCompanyPremiumDetailResponse;

export type OpsCompanyPremiums = {
  __typename?: 'OpsCompanyPremiums';
  elements?: Maybe<Scalars['BigInt']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<OpsCompanyPremiumDetailResponse>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsCompanyTypeDropDown = {
  __typename?: 'OpsCompanyTypeDropDown';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OpsCompanyVideo = {
  __typename?: 'OpsCompanyVideo';
  code?: Maybe<Scalars['String']['output']>;
  communityVideoV2Id?: Maybe<Scalars['ID']['output']>;
  company?: Maybe<OpsJobCompany>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  jobCompanyId?: Maybe<Scalars['ID']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  video?: Maybe<OpsCompanyVideoDetail>;
};

export type OpsCompanyVideoDetail = {
  __typename?: 'OpsCompanyVideoDetail';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  videoId: Scalars['ID']['output'];
};

export type OpsCompanyVideos = {
  __typename?: 'OpsCompanyVideos';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsCompanyVideo>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsConsUser = {
  __typename?: 'OpsConsUser';
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  whatsappCopyStr?: Maybe<Scalars['String']['output']>;
};

/**  Type */
export type OpsContent = {
  __typename?: 'OpsContent';
  code?: Maybe<Scalars['String']['output']>;
  contentType?: Maybe<OpsContentType>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  skuCode?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsContentActivationLog = {
  __typename?: 'OpsContentActivationLog';
  activationStatus: Scalars['String']['output'];
  contentCode: Scalars['String']['output'];
  contentName: Scalars['String']['output'];
  createdAtStr: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
  userEmail: Scalars['String']['output'];
};

export type OpsContentActivationLogs = {
  __typename?: 'OpsContentActivationLogs';
  elements?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsContentActivationLog>>>;
  page?: Maybe<Scalars['Int']['output']>;
};

export type OpsContentExamPackage = {
  __typename?: 'OpsContentExamPackage';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<OpsContentType>;
};

export type OpsContentExamPackageSummaries = {
  __typename?: 'OpsContentExamPackageSummaries';
  limit: Scalars['Int']['output'];
  list: Array<OpsContentExamPackageSummary>;
  page: Scalars['Int']['output'];
};

export type OpsContentExamPackageSummary = {
  __typename?: 'OpsContentExamPackageSummary';
  displayCategory?: Maybe<Scalars['String']['output']>;
  displayCategorySub?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  totalExam?: Maybe<Scalars['Int']['output']>;
};

export type OpsContentExamPackages = {
  __typename?: 'OpsContentExamPackages';
  limit: Scalars['Int']['output'];
  list: Array<OpsContentExamPackage>;
  page: Scalars['Int']['output'];
};

export type OpsContentIsPublishedDropdown = {
  __typename?: 'OpsContentIsPublishedDropdown';
  key?: Maybe<Scalars['Boolean']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type OpsContentSku = {
  __typename?: 'OpsContentSku';
  code?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OpsContentSkus = {
  __typename?: 'OpsContentSkus';
  limit: Scalars['Int']['output'];
  list: Array<OpsContentSku>;
  page: Scalars['Int']['output'];
};

/**  Enum */
export enum OpsContentType {
  Class = 'CLASS',
  Exam = 'EXAM',
}

export type OpsContentTypeDropdown = {
  __typename?: 'OpsContentTypeDropdown';
  key?: Maybe<OpsContentType>;
  value?: Maybe<Scalars['String']['output']>;
};

export type OpsContents = {
  __typename?: 'OpsContents';
  limit: Scalars['Int']['output'];
  list: Array<OpsContent>;
  page: Scalars['Int']['output'];
};

export type OpsCountries = {
  __typename?: 'OpsCountries';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsCountry>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsCountry = {
  __typename?: 'OpsCountry';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsCountryPayload = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type OpsCreateContentError = {
  __typename?: 'OpsCreateContentError';
  message?: Maybe<Scalars['String']['output']>;
  type: OpsCreateContentErrorEnum;
};

export enum OpsCreateContentErrorEnum {
  UnknownError = 'UNKNOWN_ERROR',
}

/**  Input */
export type OpsCreateContentInput = {
  skuCode: Scalars['String']['input'];
  type: OpsContentType;
  typeId: Scalars['ID']['input'];
};

export type OpsCreateContentResult = OpsContent | OpsCreateContentError;

export type OpsCreateNotificationInput = {
  bodyText: Scalars['String']['input'];
  campaignName: Scalars['String']['input'];
  category: OpsNotificationCategory;
  eventGoals: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  label: OpsNotificationLabel;
  name: Scalars['String']['input'];
  platform: OpsNotificationPlatform;
  redirectionLink?: InputMaybe<Scalars['String']['input']>;
  redirectionType: OpsNotificationRedirection;
  schedule: OpsNotificationType;
  scheduleTime?: InputMaybe<Scalars['String']['input']>;
  segmentId?: InputMaybe<Scalars['ID']['input']>;
  targetUsers: OpsNotificationTarget;
  title: Scalars['String']['input'];
};

export type OpsCreateSegmentResult =
  | CreateSegmentErrorResponse
  | CreateSegmentSuccessResponse;

export type OpsCreateVacancyHighlightSectionInput = {
  config?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export enum OpsCreationType {
  SaveAsDraft = 'SAVE_AS_DRAFT',
  Submit = 'SUBMIT',
}

export type OpsCsClass = {
  __typename?: 'OpsCsClass';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OpsCsPackage = {
  __typename?: 'OpsCsPackage';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OpsCsPackageDetailContentSummaries = {
  __typename?: 'OpsCsPackageDetailContentSummaries';
  elements?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsCsPackageDetailContentSummary>>>;
  page?: Maybe<Scalars['Int']['output']>;
};

export type OpsCsPackageDetailContentSummary = {
  __typename?: 'OpsCsPackageDetailContentSummary';
  session?: Maybe<Scalars['Int']['output']>;
  syllabus?: Maybe<Scalars['String']['output']>;
  teacher?: Maybe<Scalars['String']['output']>;
};

export type OpsCsPackageRegisterSummary = {
  __typename?: 'OpsCsPackageRegisterSummary';
  exams?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  sessions?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Scalars['Int']['output']>;
};

export type OpsCsPackages = {
  __typename?: 'OpsCsPackages';
  elements?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsCsPackage>>>;
  page?: Maybe<Scalars['Int']['output']>;
};

export type OpsCurrencies = {
  __typename?: 'OpsCurrencies';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsCurrency>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsCurrency = {
  __typename?: 'OpsCurrency';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type OpsDashboard = {
  __typename?: 'OpsDashboard';
  closedJobApplicationCount?: Maybe<Scalars['Int']['output']>;
  closedJobVacancyCount?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  feedCommentReport?: Maybe<Scalars['Int']['output']>;
  feedReport?: Maybe<Scalars['Int']['output']>;
  openJobApplicationCount?: Maybe<Scalars['Int']['output']>;
  openJobVacancyCount?: Maybe<Scalars['Int']['output']>;
  orderConfirmed?: Maybe<Scalars['Int']['output']>;
  orderCreated?: Maybe<Scalars['Int']['output']>;
  totalBillConfirmation?: Maybe<Scalars['Int']['output']>;
  totalInquiry?: Maybe<Scalars['Int']['output']>;
  totalLive?: Maybe<Scalars['Int']['output']>;
  totalUser?: Maybe<Scalars['Int']['output']>;
  walletRedemption?: Maybe<Scalars['Int']['output']>;
};

export type OpsDataClaim = {
  __typename?: 'OpsDataClaim';
  instruction: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type OpsDataSubmission = {
  __typename?: 'OpsDataSubmission';
  field: Scalars['String']['output'];
  image?: Maybe<MediaImage>;
  instruction: Scalars['String']['output'];
  label: Scalars['String']['output'];
  mediaId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type OpsDetailLog = {
  __typename?: 'OpsDetailLog';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  domainId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  newValue?: Maybe<Scalars['String']['output']>;
  oldValue?: Maybe<Scalars['String']['output']>;
  sideBySideValue?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsDetailLogs = {
  __typename?: 'OpsDetailLogs';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsDetailLog>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum OpsDiscountType {
  Amount = 'AMOUNT',
  Percentage = 'PERCENTAGE',
}

export type OpsEducationInputProfile = {
  description?: InputMaybe<Scalars['String']['input']>;
  educationInstitutionName?: InputMaybe<Scalars['String']['input']>;
  educationLevel?: InputMaybe<Scalars['String']['input']>;
  educationProgramName?: InputMaybe<Scalars['String']['input']>;
  endMonth?: InputMaybe<Scalars['Int']['input']>;
  endYear?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  startMonth?: InputMaybe<Scalars['Int']['input']>;
  startYear?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsEducationInstitution = {
  __typename?: 'OpsEducationInstitution';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OpsEducationInstitutions = {
  __typename?: 'OpsEducationInstitutions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsEducationInstitution>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsEducationProgram = {
  __typename?: 'OpsEducationProgram';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OpsEducationPrograms = {
  __typename?: 'OpsEducationPrograms';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsEducationProgram>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum OpsEmploymentEnum {
  Contract = 'CONTRACT',
  Freelance = 'FREELANCE',
  FullTime = 'FULL_TIME',
  Internship = 'INTERNSHIP',
  PartTime = 'PART_TIME',
}

export type OpsEmploymentType = {
  __typename?: 'OpsEmploymentType';
  key?: Maybe<OpsEmploymentEnum>;
  value?: Maybe<Scalars['String']['output']>;
};

export type OpsExam = {
  __typename?: 'OpsExam';
  approvedAt?: Maybe<Scalars['Date']['output']>;
  approvedBy?: Maybe<Scalars['String']['output']>;
  category?: Maybe<OpsCategory>;
  categoryMain?: Maybe<OpsCategoryMain>;
  categorySub?: Maybe<OpsCategorySub>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  csClass?: Maybe<OpsCsClass>;
  description?: Maybe<Scalars['String']['output']>;
  grade?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isTimeLimited?: Maybe<Scalars['Boolean']['output']>;
  maxPoint?: Maybe<Scalars['Float']['output']>;
  msTest?: Maybe<OpsMsTest>;
  msTestSub?: Maybe<OpsMsTestSub>;
  name?: Maybe<Scalars['String']['output']>;
  questions?: Maybe<Array<Maybe<OpsQuestion>>>;
  questionsIdx?: Maybe<Array<Maybe<OpsExamQuestion>>>;
  timeLimit?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsExamComposition = {
  __typename?: 'OpsExamComposition';
  mainCategories?: Maybe<Array<Maybe<OpsMainCategoryComposition>>>;
};

export type OpsExamDisplayCategories = {
  __typename?: 'OpsExamDisplayCategories';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsExamDisplayCategory>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsExamDisplayCategory = {
  __typename?: 'OpsExamDisplayCategory';
  code?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsExamDisplayCategorySub = {
  __typename?: 'OpsExamDisplayCategorySub';
  code?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  examDisplayCategoryId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsExamDisplayCategorySubs = {
  __typename?: 'OpsExamDisplayCategorySubs';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsExamDisplayCategorySub>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsExamDraft = {
  __typename?: 'OpsExamDraft';
  approvedAt?: Maybe<Scalars['Date']['output']>;
  approvedBy?: Maybe<Scalars['String']['output']>;
  category?: Maybe<OpsCategory>;
  categoryId?: Maybe<Scalars['ID']['output']>;
  categoryMain?: Maybe<OpsCategoryMain>;
  categoryMainId?: Maybe<Scalars['ID']['output']>;
  categorySub?: Maybe<OpsCategorySub>;
  categorySubId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  grade?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isTimeLimited?: Maybe<Scalars['Boolean']['output']>;
  maxPoint?: Maybe<Scalars['Float']['output']>;
  msTest?: Maybe<OpsMsTest>;
  msTestId?: Maybe<Scalars['ID']['output']>;
  msTestSub?: Maybe<OpsMsTestSub>;
  msTestSubId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  questions?: Maybe<Array<Maybe<OpsQuestion>>>;
  questionsIdx?: Maybe<Array<Maybe<OpsExamDraftQuestion>>>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  timeLimit?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsExamDraftQuestion = {
  __typename?: 'OpsExamDraftQuestion';
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  question?: Maybe<OpsQuestion>;
  questionId?: Maybe<Scalars['ID']['output']>;
};

export enum OpsExamDraftStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  OnQc = 'ON_QC',
  Rejected = 'REJECTED',
}

export type OpsExamDrafts = {
  __typename?: 'OpsExamDrafts';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsExamDraft>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsExamFreeTrial = {
  __typename?: 'OpsExamFreeTrial';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  endAt?: Maybe<Scalars['Date']['output']>;
  exam?: Maybe<OpsExam>;
  examId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPublish?: Maybe<Scalars['Boolean']['output']>;
  startAt?: Maybe<Scalars['Date']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsExamFreeTrials = {
  __typename?: 'OpsExamFreeTrials';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsExamFreeTrial>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum OpsExamGenerationType {
  SelfGenerated = 'SELF_GENERATED',
  SystemGenerated = 'SYSTEM_GENERATED',
}

export type OpsExamPackage = {
  __typename?: 'OpsExamPackage';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayCategory?: Maybe<Scalars['String']['output']>;
  displayCategorySub?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  displays?: Maybe<Array<Maybe<OpsExamPackageDisplay>>>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPublish?: Maybe<Scalars['Boolean']['output']>;
  isSubscription?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  totalExams?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsExamPackageDisplay = {
  __typename?: 'OpsExamPackageDisplay';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Array<Maybe<OpsExamPackageDisplayDetail>>>;
  displayCategory?: Maybe<Scalars['String']['output']>;
  displayCategorySub?: Maybe<Scalars['String']['output']>;
  examDisplayCategorySubId?: Maybe<Scalars['ID']['output']>;
  examPackage?: Maybe<OpsExamPackage>;
  examPackageId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  selectedDetails?: Maybe<Array<Maybe<OpsExamPackageDisplayDetail>>>;
  totalExam?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsExamPackageDisplayDetail = {
  __typename?: 'OpsExamPackageDisplayDetail';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  exam?: Maybe<OpsExam>;
  examId?: Maybe<Scalars['ID']['output']>;
  examPackageDisplayId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isFree?: Maybe<Scalars['Boolean']['output']>;
  isLiveExam?: Maybe<Scalars['Boolean']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsExamPackageDisplays = {
  __typename?: 'OpsExamPackageDisplays';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsExamPackageDisplay>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsExamPackageUser = {
  __typename?: 'OpsExamPackageUser';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  examPackage?: Maybe<OpsExamPackage>;
  examPackageId?: Maybe<Scalars['ID']['output']>;
  expiredAt?: Maybe<Scalars['Date']['output']>;
  expiredStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isExpired?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
};

export type OpsExamPackageUsers = {
  __typename?: 'OpsExamPackageUsers';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsExamPackageUser>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsExamPackages = {
  __typename?: 'OpsExamPackages';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsExamPackage>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsExamQuestion = {
  __typename?: 'OpsExamQuestion';
  avgTime?: Maybe<Scalars['Float']['output']>;
  avgTimeStr?: Maybe<Scalars['String']['output']>;
  correctPcg?: Maybe<Scalars['Float']['output']>;
  correctPcgStr?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<OpsExam>;
  examId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  mostCommonAnswer?: Maybe<OpsAnswer>;
  mostCommonAnswerId?: Maybe<Scalars['ID']['output']>;
  mostWrongAnswer?: Maybe<OpsAnswer>;
  mostWrongAnswerId?: Maybe<Scalars['ID']['output']>;
  question?: Maybe<OpsQuestion>;
  questionId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsExamQuestions = {
  __typename?: 'OpsExamQuestions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsExamQuestion>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsExamStructure = {
  __typename?: 'OpsExamStructure';
  compositions?: Maybe<OpsExamComposition>;
  msTest?: Maybe<OpsMsTest>;
  msTestSub?: Maybe<OpsMsTestSub>;
};

export type OpsExamUser = {
  __typename?: 'OpsExamUser';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  examId?: Maybe<OpsExam>;
  expiredAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
};

export type OpsExamUserResult = {
  __typename?: 'OpsExamUserResult';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  csClassName?: Maybe<Scalars['String']['output']>;
  elapsedTime?: Maybe<Scalars['Float']['output']>;
  elapsedTimeStr?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<OpsExam>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
};

export type OpsExamUserResults = {
  __typename?: 'OpsExamUserResults';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsExamUserResult>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsExamUsers = {
  __typename?: 'OpsExamUsers';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsExamUser>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsExams = {
  __typename?: 'OpsExams';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsExam>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsExperienceInputProfile = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endMonth?: InputMaybe<Scalars['Int']['input']>;
  endYear?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inputEmploymentType?: InputMaybe<OpsEmploymentEnum>;
  inputJobFunction?: InputMaybe<Scalars['String']['input']>;
  inputJobSpecializationRoleId?: InputMaybe<Scalars['ID']['input']>;
  inputSkills?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  startMonth?: InputMaybe<Scalars['Int']['input']>;
  startYear?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsExperienceJobFunctions = {
  __typename?: 'OpsExperienceJobFunctions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<KeyValue>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsExplanation = {
  __typename?: 'OpsExplanation';
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isLatex?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['ID']['output']>;
};

export type OpsFeed = {
  __typename?: 'OpsFeed';
  code?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Array<Maybe<OpsFeedComment>>>;
  community?: Maybe<OpsCommunityGroup>;
  communityId?: Maybe<Scalars['ID']['output']>;
  communityMember?: Maybe<OpsCommunityMember>;
  communityMemberId?: Maybe<Scalars['ID']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  feedMissionId?: Maybe<Scalars['String']['output']>;
  fieldType?: Maybe<OpsFieldType>;
  id?: Maybe<Scalars['ID']['output']>;
  images?: Maybe<Array<Maybe<OpsFeedImage>>>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isBan?: Maybe<Scalars['Boolean']['output']>;
  isPublish?: Maybe<Scalars['Boolean']['output']>;
  isSendNotification?: Maybe<Scalars['Boolean']['output']>;
  job?: Maybe<OpsFeedJob>;
  mission?: Maybe<OpsMission>;
  poll?: Maybe<OpsFeedPoll>;
  publishType?: Maybe<Scalars['String']['output']>;
  quiz?: Maybe<OpsFeedQuiz>;
  reasonStr?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  voteCount?: Maybe<Scalars['Int']['output']>;
  votes?: Maybe<Array<Maybe<OpsFeedVote>>>;
};

export type OpsFeedComment = {
  __typename?: 'OpsFeedComment';
  code?: Maybe<Scalars['String']['output']>;
  communityMember?: Maybe<OpsCommunityMember>;
  communityMemberId?: Maybe<Scalars['ID']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  feed?: Maybe<OpsFeed>;
  feedId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isBan?: Maybe<Scalars['String']['output']>;
  reasonStr?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  voteCount?: Maybe<Scalars['Int']['output']>;
  votes?: Maybe<Array<Maybe<OpsFeedCommentVote>>>;
};

export type OpsFeedCommentReport = {
  __typename?: 'OpsFeedCommentReport';
  communityMember?: Maybe<OpsCommunityMember>;
  communityMemberId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  feedCommentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isResolved?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsFeedCommentReports = {
  __typename?: 'OpsFeedCommentReports';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsFeedCommentReport>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsFeedCommentVote = {
  __typename?: 'OpsFeedCommentVote';
  communityMember?: Maybe<OpsCommunityMember>;
  communityMemberId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  feedCommentId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsFeedComments = {
  __typename?: 'OpsFeedComments';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsFeedComment>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsFeedImage = {
  __typename?: 'OpsFeedImage';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  feedId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  mediaId?: Maybe<Scalars['String']['output']>;
  publishType?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsFeedJob = {
  __typename?: 'OpsFeedJob';
  feedId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  jobVacancyId?: Maybe<Scalars['ID']['output']>;
  vacancy?: Maybe<OpsJobVacancy>;
};

export type OpsFeedPoll = {
  __typename?: 'OpsFeedPoll';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Array<Maybe<OpsFeedPollDetail>>>;
  endTime?: Maybe<Scalars['Date']['output']>;
  feedDraftId?: Maybe<Scalars['ID']['output']>;
  feedId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isMultipleAnswer?: Maybe<Scalars['Boolean']['output']>;
  startTime?: Maybe<Scalars['Date']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsFeedPollDetail = {
  __typename?: 'OpsFeedPollDetail';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  feedDraftPollId?: Maybe<Scalars['ID']['output']>;
  feedPollId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  voteCount?: Maybe<Scalars['Int']['output']>;
};

export type OpsFeedQuiz = {
  __typename?: 'OpsFeedQuiz';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Array<Maybe<OpsFeedQuizDetail>>>;
  endTime?: Maybe<Scalars['Date']['output']>;
  explanation?: Maybe<Scalars['String']['output']>;
  feedDraftId?: Maybe<Scalars['ID']['output']>;
  feedId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isLatex?: Maybe<Scalars['Boolean']['output']>;
  isMultipleAnswer?: Maybe<Scalars['Boolean']['output']>;
  startTime?: Maybe<Scalars['Date']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsFeedQuizDetail = {
  __typename?: 'OpsFeedQuizDetail';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  feedDraftQuizId?: Maybe<Scalars['ID']['output']>;
  feedQuizId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAnswer?: Maybe<Scalars['Boolean']['output']>;
  isLatex?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  voteCount?: Maybe<Scalars['Int']['output']>;
};

export type OpsFeedReport = {
  __typename?: 'OpsFeedReport';
  communityMember?: Maybe<OpsCommunityMember>;
  communityMemberId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  feedId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isResolved?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsFeedReportSummaries = {
  __typename?: 'OpsFeedReportSummaries';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsFeedReportSummary>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsFeedReportSummary = {
  __typename?: 'OpsFeedReportSummary';
  community?: Maybe<OpsCommunityGroup>;
  communityId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  feed?: Maybe<OpsFeed>;
  feedCode?: Maybe<Scalars['String']['output']>;
  feedId?: Maybe<Scalars['ID']['output']>;
  feedReports?: Maybe<Array<Maybe<OpsFeedReport>>>;
  feedStatus?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  reportCount?: Maybe<Scalars['Int']['output']>;
  reportStatus?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsFeedReports = {
  __typename?: 'OpsFeedReports';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsFeedReport>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsFeedVote = {
  __typename?: 'OpsFeedVote';
  communityMemberId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  feedId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsFeeds = {
  __typename?: 'OpsFeeds';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsFeed>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum OpsFieldType {
  JobInfo = 'JOB_INFO',
  Mission = 'MISSION',
  Polling = 'POLLING',
  Question = 'QUESTION',
  Quiz = 'QUIZ',
  Regular = 'REGULAR',
}

export type OpsFollowUpApplication = {
  __typename?: 'OpsFollowUpApplication';
  followUpStatus?: Maybe<Scalars['String']['output']>;
  followedUpBy?: Maybe<Scalars['String']['output']>;
  followedUpDate?: Maybe<Scalars['Date']['output']>;
};

export type OpsGuideline = {
  __typename?: 'OpsGuideline';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  guidelineType?: Maybe<OpsGuidelineType>;
  guidelineTypeId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type OpsGuidelinePaging = {
  __typename?: 'OpsGuidelinePaging';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsGuideline>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsGuidelineType = {
  __typename?: 'OpsGuidelineType';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsHighlightedJob = {
  __typename?: 'OpsHighlightedJob';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  homeSection?: Maybe<OpsHomeVacancySection>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  jobVacancy?: Maybe<OpsJobVacancy>;
  jobVacancyHomeSectionId?: Maybe<Scalars['ID']['output']>;
  jobVacancyId?: Maybe<Scalars['ID']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsHighlightedJobs = {
  __typename?: 'OpsHighlightedJobs';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsHighlightedJob>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsHomeVacancySection = {
  __typename?: 'OpsHomeVacancySection';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isTopSection?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
};

export type OpsInputBanComment = {
  id: Scalars['ID']['input'];
  isBan?: InputMaybe<Scalars['Boolean']['input']>;
  reason: Scalars['String']['input'];
};

export type OpsInputBanCommunityMembership = {
  id: Scalars['ID']['input'];
  isBan: Scalars['Boolean']['input'];
  reason: Scalars['String']['input'];
};

export type OpsInputBanFeed = {
  id: Scalars['ID']['input'];
  isBan?: InputMaybe<Scalars['Boolean']['input']>;
  reason: Scalars['String']['input'];
};

export type OpsInputBulkJobCompany = {
  accManager?: InputMaybe<Scalars['String']['input']>;
  accManagerCountryCode?: InputMaybe<Scalars['String']['input']>;
  accManagerWhatsapp?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  companyType?: InputMaybe<Scalars['String']['input']>;
  contactDayEnd?: InputMaybe<Scalars['Int']['input']>;
  contactDayStart?: InputMaybe<Scalars['Int']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactHourEnd?: InputMaybe<Scalars['Int']['input']>;
  contactHourEndInputStr?: InputMaybe<Scalars['String']['input']>;
  contactHourStart?: InputMaybe<Scalars['Int']['input']>;
  contactHourStartInputStr?: InputMaybe<Scalars['String']['input']>;
  contactWeblink?: InputMaybe<Scalars['String']['input']>;
  contactWhatsapp?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  foundedYear?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isLegalDocumentAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  jobCompanyIndustryId: Scalars['ID']['input'];
  jobCompanySizeId?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  verificationStatus?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputCategoryComposition = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subCategories?: InputMaybe<Array<InputMaybe<OpsInputSubCategoryComposition>>>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsInputCategoryMainCreate = {
  id: Scalars['ID']['input'];
  passGradeType: OpsPassingGradeType;
  passGradeValue: Scalars['Float']['input'];
};

export type OpsInputCategoryMainUpdate = {
  id: Scalars['ID']['input'];
  passGradeType?: InputMaybe<OpsPassingGradeType>;
  passGradeValue?: InputMaybe<Scalars['Float']['input']>;
};

export type OpsInputCreateAnswer = {
  image?: InputMaybe<Scalars['String']['input']>;
  isCorrect: Scalars['Boolean']['input'];
  point: Scalars['Float']['input'];
  text: Scalars['String']['input'];
};

export type OpsInputCreateCommunityMembership = {
  communityId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  nickName: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export type OpsInputCreateCommunityVideo = {
  communityVideoCategoryId: Scalars['ID']['input'];
  customThumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  duration: Scalars['String']['input'];
  priority?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  videoUrl: Scalars['String']['input'];
};

export type OpsInputCreateCommunityVideoV2 = {
  communityVideoCategoryId: Scalars['ID']['input'];
  ctaConfig?: InputMaybe<Scalars['String']['input']>;
  ctaDomain?: InputMaybe<OpsCommunityVideoCtaDomain>;
  ctaLink?: InputMaybe<Scalars['String']['input']>;
  ctaTitle?: InputMaybe<Scalars['String']['input']>;
  ctaType?: InputMaybe<OpsCommunityVideoCtaType>;
  description?: InputMaybe<Scalars['String']['input']>;
  isAds?: InputMaybe<Scalars['Boolean']['input']>;
  isCta?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished: Scalars['Boolean']['input'];
  /** @deprecated switch to ctaTitle */
  jobCta?: InputMaybe<Scalars['String']['input']>;
  partnershipLabel?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  uploaderTitle?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated switch to ctaConfig */
  vacancyConfig?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated switch to ctaLink */
  vacancyRelatedUrl?: InputMaybe<Scalars['String']['input']>;
  videoId: Scalars['ID']['input'];
};

export type OpsInputCreateCompanyVideo = {
  communityVideoV2Id: Scalars['ID']['input'];
  jobCompanyId: Scalars['ID']['input'];
};

export type OpsInputCreateExamDraft = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  categoryMainId?: InputMaybe<Scalars['ID']['input']>;
  categorySubId?: InputMaybe<Scalars['ID']['input']>;
  csClassId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isTimeLimited?: InputMaybe<Scalars['Boolean']['input']>;
  maxPoint?: InputMaybe<Scalars['Float']['input']>;
  msTestId: Scalars['ID']['input'];
  msTestSubId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  questions: Array<InputMaybe<OpsInputExamQuestion>>;
  timeLimit?: InputMaybe<Scalars['Float']['input']>;
  type: OpsExamGenerationType;
};

export type OpsInputCreateExplanation = {
  image?: InputMaybe<Scalars['String']['input']>;
  isLatex?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
  video?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputCreateFeed = {
  communityId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  fieldType: OpsFieldType;
  image?: InputMaybe<OpsInputFeedImage>;
  isSendNotification: Scalars['Boolean']['input'];
  job?: InputMaybe<OpsInputCreateFeedJob>;
  missionId?: InputMaybe<Scalars['ID']['input']>;
  poll?: InputMaybe<OpsInputCreateFeedPoll>;
  quiz?: InputMaybe<OpsInputCreateFeedQuiz>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type OpsInputCreateFeedJob = {
  feedId?: InputMaybe<Scalars['ID']['input']>;
  jobVacancyId: Scalars['ID']['input'];
};

export type OpsInputCreateFeedPoll = {
  details?: InputMaybe<Array<InputMaybe<OpsInputFeedPollDetailCreate>>>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  isMultipleAnswer: Scalars['Boolean']['input'];
  startTime?: InputMaybe<Scalars['Date']['input']>;
};

export type OpsInputCreateFeedQuiz = {
  details?: InputMaybe<Array<InputMaybe<OpsInputFeedQuizDetailCreate>>>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  isLatex?: InputMaybe<Scalars['Boolean']['input']>;
  isMultipleAnswer: Scalars['Boolean']['input'];
  startTime?: InputMaybe<Scalars['Date']['input']>;
};

export type OpsInputCreateMission = {
  client: Scalars['String']['input'];
  description: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  missionDetails: Array<OpsInputCreateMissionDetail>;
  name: Scalars['String']['input'];
  quota?: InputMaybe<Scalars['Int']['input']>;
  reward?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  termCondition: Scalars['String']['input'];
};

export type OpsInputCreateMissionDetail = {
  field: Scalars['String']['input'];
  instruction: Scalars['String']['input'];
  isText?: InputMaybe<Scalars['Boolean']['input']>;
  isUpload?: InputMaybe<Scalars['Boolean']['input']>;
  textLabel?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  uploadLabel?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputCreateQuestionDraft = {
  answerLatex: Scalars['Boolean']['input'];
  answers: Array<InputMaybe<OpsInputCreateAnswer>>;
  categorySubIds: Array<InputMaybe<Scalars['String']['input']>>;
  explanation: OpsInputCreateExplanation;
  image?: InputMaybe<Scalars['String']['input']>;
  isLatex?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
  type: OpsQuestionType;
};

export type OpsInputCreateVideo = {
  description: Scalars['String']['input'];
  gcs?: InputMaybe<Scalars['String']['input']>;
  host: Scalars['String']['input'];
  tags: Scalars['String']['input'];
  title: Scalars['String']['input'];
  videoId?: InputMaybe<Scalars['ID']['input']>;
};

export type OpsInputCreateVoucher = {
  code: Scalars['String']['input'];
  description: Scalars['String']['input'];
  discountAmount: Scalars['Float']['input'];
  discountType: Scalars['String']['input'];
  expiryDate?: InputMaybe<Scalars['Date']['input']>;
  isMultipleUse: Scalars['Boolean']['input'];
  minTransaction?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  userTarget: Scalars['String']['input'];
};

export type OpsInputExamComposition = {
  mainCategories?: InputMaybe<
    Array<InputMaybe<OpsInputMainCategoryComposition>>
  >;
  total?: InputMaybe<Scalars['Int']['input']>;
  unUsed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OpsInputExamFreeTrialCreate = {
  endAt: Scalars['Date']['input'];
  examId: Scalars['ID']['input'];
  startAt: Scalars['Date']['input'];
};

export type OpsInputExamFreeTrialUpdate = {
  endAt?: InputMaybe<Scalars['Date']['input']>;
  examId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  startAt?: InputMaybe<Scalars['Date']['input']>;
};

export type OpsInputExamPackageCreate = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displays?: InputMaybe<Array<InputMaybe<OpsInputExamPackageDisplayCreate>>>;
  isSubscription?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputExamPackageDisplayCreate = {
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<
    Array<InputMaybe<OpsInputExamPackageDisplayDetailCreate>>
  >;
  examDisplayCategorySubId?: InputMaybe<Scalars['ID']['input']>;
  examPackageId?: InputMaybe<Scalars['ID']['input']>;
  idx?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsInputExamPackageDisplayDetailCreate = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  examId?: InputMaybe<Scalars['ID']['input']>;
  idx?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  isLiveExam?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type OpsInputExamPackageDisplayDetailUpdate = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  examId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idx?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  isLiveExam?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type OpsInputExamPackageDisplayUpdate = {
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<
    Array<InputMaybe<OpsInputExamPackageDisplayDetailUpdate>>
  >;
  examDisplayCategorySubId?: InputMaybe<Scalars['ID']['input']>;
  examPackageId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  idx?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsInputExamPackageUpdate = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displays?: InputMaybe<Array<InputMaybe<OpsInputExamPackageDisplayCreate>>>;
  id: Scalars['ID']['input'];
  isSubscription?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputExamQuestion = {
  idx: Scalars['Int']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  questionId: Scalars['ID']['input'];
};

export type OpsInputExamUserRegister = {
  classId?: InputMaybe<Scalars['ID']['input']>;
  email: Scalars['String']['input'];
  examId?: InputMaybe<Scalars['ID']['input']>;
  expiryDate?: InputMaybe<Scalars['Date']['input']>;
  orderNo?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputFeedImage = {
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  mediaId?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputFeedPollDetailCreate = {
  content?: InputMaybe<Scalars['String']['input']>;
  idx?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsInputFeedPollDetailUpdate = {
  content?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idx?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OpsInputFeedQuizDetailCreate = {
  content?: InputMaybe<Scalars['String']['input']>;
  idx?: InputMaybe<Scalars['Int']['input']>;
  isAnswer?: InputMaybe<Scalars['Boolean']['input']>;
  isLatex?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OpsInputFeedQuizDetailUpdate = {
  content?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idx?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAnswer?: InputMaybe<Scalars['Boolean']['input']>;
  isLatex?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OpsInputGuidelineCreate = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  guidelineTypeId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputGuidelineUpdate = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  guidelineTypeId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputJobCompany = {
  accManager?: InputMaybe<Scalars['String']['input']>;
  accManagerCountryCode?: InputMaybe<Scalars['String']['input']>;
  accManagerWhatsapp?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  companyTypeId?: InputMaybe<Scalars['ID']['input']>;
  contactDayEnd?: InputMaybe<Scalars['Int']['input']>;
  contactDayStart?: InputMaybe<Scalars['Int']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactHourEnd?: InputMaybe<Scalars['Int']['input']>;
  contactHourEndInputStr?: InputMaybe<Scalars['String']['input']>;
  contactHourStart?: InputMaybe<Scalars['Int']['input']>;
  contactHourStartInputStr?: InputMaybe<Scalars['String']['input']>;
  contactWeblink?: InputMaybe<Scalars['String']['input']>;
  contactWhatsapp?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  foundedYear?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isLegalDocumentAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  jobCompanyIndustryId: Scalars['ID']['input'];
  jobCompanySizeId?: InputMaybe<Scalars['ID']['input']>;
  legalDocumentId?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  logoId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  verificationStatus?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputJobCompanyDraft = {
  accManager?: InputMaybe<Scalars['String']['input']>;
  accManagerCountryCode?: InputMaybe<Scalars['String']['input']>;
  accManagerWhatsapp?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  contactDayEnd?: InputMaybe<Scalars['Int']['input']>;
  contactDayStart?: InputMaybe<Scalars['Int']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactHourEnd?: InputMaybe<Scalars['Int']['input']>;
  contactHourEndStr?: InputMaybe<Scalars['String']['input']>;
  contactHourStart?: InputMaybe<Scalars['Int']['input']>;
  contactHourStartStr?: InputMaybe<Scalars['String']['input']>;
  contactWeblink?: InputMaybe<Scalars['String']['input']>;
  contactWhatsapp?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  foundedYear?: InputMaybe<Scalars['Int']['input']>;
  isLegalDocumentAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  jobCompanyIndustryId: Scalars['ID']['input'];
  jobCompanySizeId?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  verificationStatus?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputJobSkill = {
  answerFour?: InputMaybe<Scalars['String']['input']>;
  answerOne?: InputMaybe<Scalars['String']['input']>;
  answerThree?: InputMaybe<Scalars['String']['input']>;
  answerTwo?: InputMaybe<Scalars['String']['input']>;
  correctAnswer?: InputMaybe<Scalars['Int']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  jobVacancyRequirementCategoryId?: InputMaybe<Scalars['ID']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  subCategory?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputJobVacancy = {
  /** @deprecated feature screening question is on hold */
  additionalRequirementOne?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated feature screening question is on hold */
  additionalRequirementOneId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated feature screening question is on hold */
  additionalRequirementThree?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated feature screening question is on hold */
  additionalRequirementThreeId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated feature screening question is on hold */
  additionalRequirementTwo?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated feature screening question is on hold */
  additionalRequirementTwoId?: InputMaybe<Scalars['ID']['input']>;
  closeDate?: InputMaybe<Scalars['Date']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contactDetail?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  educationLevel?: InputMaybe<Scalars['String']['input']>;
  educationProgramIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  gender?: InputMaybe<Scalars['String']['input']>;
  isOpenForAllEducationPrograms?: InputMaybe<Scalars['Boolean']['input']>;
  isOpenForFreshGraduate?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  jobCompanyId?: InputMaybe<Scalars['ID']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated replace with jobVacancySpecializationRoleId */
  jobVacancyFunctionId?: InputMaybe<Scalars['ID']['input']>;
  jobVacancySkillIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  jobVacancySpecializationRoleId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementFive?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementFiveId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementFour?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementFourId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementOne?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementOneId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementThree?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementThreeId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementTwo?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated feature screening question is on hold */
  keyRequirementTwoId?: InputMaybe<Scalars['ID']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  locationCity?: InputMaybe<Scalars['String']['input']>;
  locationDistrict?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationProvince?: InputMaybe<Scalars['String']['input']>;
  locationSite?: InputMaybe<Scalars['String']['input']>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  minExperience?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  positionName?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  salaryLowerBound?: InputMaybe<Scalars['Float']['input']>;
  salaryUpperBound?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  vacancyCount?: InputMaybe<Scalars['Int']['input']>;
  workingDayEnd?: InputMaybe<Scalars['Int']['input']>;
  workingDayStart?: InputMaybe<Scalars['Int']['input']>;
  workingHourEnd?: InputMaybe<Scalars['Int']['input']>;
  workingHourStart?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsInputMainCategoryComposition = {
  categories?: InputMaybe<Array<InputMaybe<OpsInputCategoryComposition>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsInputMrNotificationCreate = {
  actionType: OpsNotificationActionType;
  actionUrl: Scalars['String']['input'];
  description: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isSendNotification?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['Date']['input']>;
  shortDescription: Scalars['String']['input'];
  target?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: OpsNotificationType;
};

export type OpsInputNewsCreate = {
  imageUrl: Scalars['String']['input'];
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  link: Scalars['String']['input'];
  priority?: InputMaybe<Scalars['Int']['input']>;
  shortDesc: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type OpsInputNewsUpdate = {
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  shortDesc?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputQcExam = {
  grade?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputQcQuestion = {
  grade?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputReferralBonusCreate = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  bonusCode?: InputMaybe<Scalars['String']['input']>;
  inviteeCount?: InputMaybe<Scalars['Int']['input']>;
  skuId?: InputMaybe<Scalars['ID']['input']>;
};

export type OpsInputReferralBonusUpdate = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  bonusCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inviteeCount?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  skuId?: InputMaybe<Scalars['ID']['input']>;
};

export type OpsInputReferralIncentiveCreate = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  skuId?: InputMaybe<Scalars['ID']['input']>;
};

export type OpsInputReferralIncentiveUpdate = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  skuId?: InputMaybe<Scalars['ID']['input']>;
};

export type OpsInputReferralProgramsCreate = {
  bonusCode?: InputMaybe<Scalars['String']['input']>;
  bonuses?: InputMaybe<Array<InputMaybe<OpsInputReferralBonusCreate>>>;
  description: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  incentives?: InputMaybe<Array<InputMaybe<OpsInputReferralIncentiveCreate>>>;
  name: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type OpsInputReferralProgramsUpdate = {
  bonusCode?: InputMaybe<Scalars['String']['input']>;
  bonuses?: InputMaybe<Array<InputMaybe<OpsInputReferralBonusUpdate>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  incentives?: InputMaybe<Array<InputMaybe<OpsInputReferralIncentiveUpdate>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type OpsInputSingleJobVacancy = {
  additionalRequirements?: InputMaybe<
    Array<InputMaybe<OpsVacancyRequirementInputCreate>>
  >;
  closeDate?: InputMaybe<Scalars['Date']['input']>;
  contactDetail?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  educationLevel?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  jobCompanyId?: InputMaybe<Scalars['ID']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  jobVacancyFunctionId?: InputMaybe<Scalars['ID']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationSite?: InputMaybe<Scalars['String']['input']>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  minExperience?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  positionName?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  requirements?: InputMaybe<
    Array<InputMaybe<OpsVacancyRequirementInputCreate>>
  >;
  salaryLowerBound?: InputMaybe<Scalars['Float']['input']>;
  salaryUpperBound?: InputMaybe<Scalars['Float']['input']>;
  skills?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  vacancyCount?: InputMaybe<Scalars['Int']['input']>;
  workingDayEnd?: InputMaybe<Scalars['Int']['input']>;
  workingDayStart?: InputMaybe<Scalars['Int']['input']>;
  workingHourEndStr?: InputMaybe<Scalars['String']['input']>;
  workingHourStartStr?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputSkuCategoryCreate = {
  name?: InputMaybe<Scalars['String']['input']>;
  parentCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type OpsInputSkuCategoryUpdate = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type OpsInputSkuCreate = {
  description?: InputMaybe<Scalars['String']['input']>;
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  discountType?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  isGuarantee?: InputMaybe<Scalars['Boolean']['input']>;
  isInstallment?: InputMaybe<Scalars['Boolean']['input']>;
  mediaImageId?: InputMaybe<Scalars['String']['input']>;
  msCurrencyId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  packageType?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skuInstallments?: InputMaybe<Array<InputMaybe<OpsInputSkuInstallmentCreate>>>;
  skuSkuCategories?: InputMaybe<
    Array<InputMaybe<OpsInputSkuSkuCategoryCreate>>
  >;
  validityPeriod?: InputMaybe<Scalars['String']['input']>;
  validityValue?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsInputSkuInstallmentCreate = {
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  idx?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type OpsInputSkuInstallmentUpdate = {
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idx?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  skuId?: InputMaybe<Scalars['ID']['input']>;
};

export type OpsInputSkuSkuCategoryComponentCreate = {
  skuCategoryComponentId: Scalars['ID']['input'];
  status?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OpsInputSkuSkuCategoryComponentUpdate = {
  id?: InputMaybe<Scalars['ID']['input']>;
  skuCategoryComponentId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OpsInputSkuSkuCategoryCreate = {
  skuCategoryId: Scalars['ID']['input'];
  skuComponents: Array<InputMaybe<OpsInputSkuSkuCategoryComponentCreate>>;
  typeId: Scalars['ID']['input'];
};

export type OpsInputSkuSkuCategoryUpdate = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  skuCategoryId?: InputMaybe<Scalars['ID']['input']>;
  skuComponents?: InputMaybe<
    Array<InputMaybe<OpsInputSkuSkuCategoryComponentUpdate>>
  >;
  typeId?: InputMaybe<Scalars['ID']['input']>;
};

export type OpsInputSkuUpdate = {
  description?: InputMaybe<Scalars['String']['input']>;
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  discountType?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  isDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  isGuarantee?: InputMaybe<Scalars['Boolean']['input']>;
  isInstallment?: InputMaybe<Scalars['Boolean']['input']>;
  mediaImageId?: InputMaybe<Scalars['String']['input']>;
  msCurrencyId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  packageType?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skuInstallments?: InputMaybe<Array<InputMaybe<OpsInputSkuInstallmentUpdate>>>;
  skuSkuCategories?: InputMaybe<
    Array<InputMaybe<OpsInputSkuSkuCategoryUpdate>>
  >;
  validityPeriod?: InputMaybe<Scalars['String']['input']>;
  validityValue?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsInputSubCategoryComposition = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsInputUpdateAnswer = {
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive: Scalars['Boolean']['input'];
  isCorrect: Scalars['Boolean']['input'];
  point: Scalars['Float']['input'];
  text: Scalars['String']['input'];
};

export type OpsInputUpdateCommunityMembership = {
  communityId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  nickName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputUpdateCommunityVideoV2 = {
  communityVideoCategoryId: Scalars['ID']['input'];
  ctaConfig?: InputMaybe<Scalars['String']['input']>;
  ctaDomain?: InputMaybe<OpsCommunityVideoCtaDomain>;
  ctaLink?: InputMaybe<Scalars['String']['input']>;
  ctaTitle?: InputMaybe<Scalars['String']['input']>;
  ctaType?: InputMaybe<OpsCommunityVideoCtaType>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isAds?: InputMaybe<Scalars['Boolean']['input']>;
  isCta?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished: Scalars['Boolean']['input'];
  /** @deprecated switch to ctaTitle */
  jobCta?: InputMaybe<Scalars['String']['input']>;
  partnershipLabel?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  uploaderTitle?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated switch to ctaConfig */
  vacancyConfig?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated switch to ctaLink */
  vacancyRelatedUrl?: InputMaybe<Scalars['String']['input']>;
  videoId: Scalars['ID']['input'];
};

export type OpsInputUpdateCompanyVideo = {
  communityVideoV2Id: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  jobCompanyId: Scalars['ID']['input'];
};

export type OpsInputUpdateExam = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  categoryMainId?: InputMaybe<Scalars['ID']['input']>;
  categorySubId?: InputMaybe<Scalars['ID']['input']>;
  csClassId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isTimeLimited?: InputMaybe<Scalars['Boolean']['input']>;
  maxPoint?: InputMaybe<Scalars['Float']['input']>;
  msTestId?: InputMaybe<Scalars['ID']['input']>;
  msTestSubId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<Array<InputMaybe<OpsInputExamQuestion>>>;
  timeLimit?: InputMaybe<Scalars['Float']['input']>;
};

export type OpsInputUpdateExamDraft = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  categoryMainId?: InputMaybe<Scalars['ID']['input']>;
  categorySubId?: InputMaybe<Scalars['ID']['input']>;
  csClassId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isTimeLimited?: InputMaybe<Scalars['Boolean']['input']>;
  maxPoint?: InputMaybe<Scalars['Float']['input']>;
  msTestId: Scalars['ID']['input'];
  msTestSubId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  questions: Array<InputMaybe<OpsInputExamQuestion>>;
  timeLimit?: InputMaybe<Scalars['Float']['input']>;
  type: OpsExamGenerationType;
};

export type OpsInputUpdateExplanation = {
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  isLatex?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
  video?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputUpdateFeed = {
  communityId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  fieldType?: InputMaybe<OpsFieldType>;
  id: Scalars['ID']['input'];
  image?: InputMaybe<OpsInputFeedImage>;
  isSendNotification?: InputMaybe<Scalars['Boolean']['input']>;
  job?: InputMaybe<OpsInputUpdateFeedJob>;
  missionId?: InputMaybe<Scalars['ID']['input']>;
  poll?: InputMaybe<OpsInputUpdateFeedPoll>;
  publishType: Scalars['String']['input'];
  quiz?: InputMaybe<OpsInputUpdateFeedQuiz>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputUpdateFeedCommentReport = {
  id: Scalars['ID']['input'];
  isBan: Scalars['Boolean']['input'];
};

export type OpsInputUpdateFeedJob = {
  feedId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  jobVacancyId?: InputMaybe<Scalars['ID']['input']>;
};

export type OpsInputUpdateFeedPoll = {
  details?: InputMaybe<Array<InputMaybe<OpsInputFeedPollDetailUpdate>>>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  isMultipleAnswer?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['Date']['input']>;
};

export type OpsInputUpdateFeedQuiz = {
  details?: InputMaybe<Array<InputMaybe<OpsInputFeedQuizDetailUpdate>>>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isLatex?: InputMaybe<Scalars['Boolean']['input']>;
  isMultipleAnswer: Scalars['Boolean']['input'];
  startTime?: InputMaybe<Scalars['Date']['input']>;
};

export type OpsInputUpdateFeedReport = {
  id: Scalars['ID']['input'];
  isBan: Scalars['Boolean']['input'];
};

export type OpsInputUpdateMission = {
  client: Scalars['String']['input'];
  description: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  imageId?: InputMaybe<Scalars['String']['input']>;
  missionDetails: Array<OpsInputUpdateMissionDetail>;
  name: Scalars['String']['input'];
  quota?: InputMaybe<Scalars['Int']['input']>;
  reward?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  termCondition: Scalars['String']['input'];
};

export type OpsInputUpdateMissionDetail = {
  field: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  instruction: Scalars['String']['input'];
  isText?: InputMaybe<Scalars['Boolean']['input']>;
  isUpload?: InputMaybe<Scalars['Boolean']['input']>;
  textLabel?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  uploadLabel?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputUpdateQuestion = {
  answerLatex?: InputMaybe<Scalars['Boolean']['input']>;
  answerType?: InputMaybe<OpsAnswerType>;
  answers?: InputMaybe<Array<InputMaybe<OpsInputUpdateAnswer>>>;
  explanation?: InputMaybe<OpsInputUpdateExplanation>;
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  isLatex?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<OpsQuestionType>;
};

export type OpsInputUpdateQuestionDraft = {
  answerLatex?: InputMaybe<Scalars['Boolean']['input']>;
  answerType?: InputMaybe<OpsAnswerType>;
  answers?: InputMaybe<Array<InputMaybe<OpsInputUpdateAnswer>>>;
  categorySubIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  explanation?: InputMaybe<OpsInputUpdateExplanation>;
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  isLatex?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<OpsQuestionType>;
};

export type OpsInputUpdateRedemption = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  bankAccName?: InputMaybe<Scalars['String']['input']>;
  bankAccNo?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  paymentDate?: InputMaybe<Scalars['Date']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  receiptUrl?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  walletId?: InputMaybe<Scalars['ID']['input']>;
};

export type OpsInputUpdateVideo = {
  description?: InputMaybe<Scalars['String']['input']>;
  host?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  tags?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type OpsInputUpdateVoucher = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  discountType?: InputMaybe<OpsDiscountType>;
  expiryDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  isMultipleUse?: InputMaybe<Scalars['Boolean']['input']>;
  minTransaction?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  userTarget?: InputMaybe<OpsUserTargetType>;
};

export type OpsJobApplication = {
  __typename?: 'OpsJobApplication';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  endEducationYear?: Maybe<Scalars['Int']['output']>;
  endLastJobYear?: Maybe<Scalars['Int']['output']>;
  feedbackDescription?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  genderStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isAccepted?: Maybe<Scalars['Boolean']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isInteviewed?: Maybe<Scalars['Boolean']['output']>;
  jobApplicationClosing?: Maybe<OpsJobApplicationClosing>;
  jobVacancy?: Maybe<OpsJobVacancy>;
  jobVacancyId?: Maybe<Scalars['ID']['output']>;
  lastEducationLevel?: Maybe<Scalars['String']['output']>;
  lastJobDescription?: Maybe<Scalars['String']['output']>;
  lastJobName?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  startEducationYear?: Maybe<Scalars['Int']['output']>;
  startLastJobYear?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userProfile?: Maybe<OpsProfile>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type OpsJobApplicationClosing = {
  __typename?: 'OpsJobApplicationClosing';
  closingNotificationTime?: Maybe<Scalars['Date']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  jobApplicationId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsJobApplications = {
  __typename?: 'OpsJobApplications';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsJobApplication>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobCompanies = {
  __typename?: 'OpsJobCompanies';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsJobCompany>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobCompany = {
  __typename?: 'OpsJobCompany';
  accManager?: Maybe<Scalars['String']['output']>;
  accManagerCountryCode?: Maybe<Scalars['String']['output']>;
  accManagerWhatsapp?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  companyType?: Maybe<Scalars['String']['output']>;
  companyTypeId?: Maybe<Scalars['ID']['output']>;
  contactDayEnd?: Maybe<Scalars['Int']['output']>;
  contactDayEndStr?: Maybe<Scalars['String']['output']>;
  contactDayStart?: Maybe<Scalars['Int']['output']>;
  contactDayStartStr?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactHourEnd?: Maybe<Scalars['Int']['output']>;
  contactHourEndStr?: Maybe<Scalars['String']['output']>;
  contactHourStart?: Maybe<Scalars['Int']['output']>;
  contactHourStartStr?: Maybe<Scalars['String']['output']>;
  contactWeblink?: Maybe<Scalars['String']['output']>;
  contactWhatsapp?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  foundedYear?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  info?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isLegalDocumentAvailable?: Maybe<Scalars['Boolean']['output']>;
  isLegalDocumentAvailableStr?: Maybe<Scalars['String']['output']>;
  isSelfRegistration?: Maybe<Scalars['Boolean']['output']>;
  isTp?: Maybe<Scalars['Boolean']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  jobCompanyIndustry?: Maybe<OpsJobCompanyIndustry>;
  jobCompanyIndustryId?: Maybe<Scalars['ID']['output']>;
  jobCompanySize?: Maybe<OpsJobCompanySize>;
  klepData?: Maybe<OpsKlepData>;
  legalDocument?: Maybe<MediaFile>;
  legalDocumentId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use 'url' on legalDocument instead */
  legalDocumentUrl?: Maybe<Scalars['String']['output']>;
  locationCity?: Maybe<Location>;
  locationDistrict?: Maybe<Location>;
  locationId?: Maybe<Scalars['String']['output']>;
  locationProvince?: Maybe<Location>;
  logo?: Maybe<MediaImage>;
  name?: Maybe<Scalars['String']['output']>;
  nib?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  statusKlep?: Maybe<Scalars['String']['output']>;
  totalVacancy?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  typeStr?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userSr?: Maybe<OpsUserSr>;
  verificationStatus?: Maybe<Scalars['String']['output']>;
  verificationStatusStr?: Maybe<Scalars['String']['output']>;
  verificationStatusUpdatedAt?: Maybe<Scalars['String']['output']>;
  verificationStatusUpdatedBy?: Maybe<Scalars['String']['output']>;
  verificationStatusUpdatedStr?: Maybe<Scalars['String']['output']>;
};

export type OpsJobCompanyAccManager = {
  __typename?: 'OpsJobCompanyAccManager';
  contactWhatsapp?: Maybe<Scalars['String']['output']>;
  contactWhatsappCountryCode?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  statusStr?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  teamStr?: Maybe<Scalars['String']['output']>;
  totalCompany?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobCompanyAccManagerCreateInput = {
  contactWhatsapp: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  team: Scalars['String']['input'];
};

export type OpsJobCompanyAccManagerSwitchSubmitInput = {
  companyCode: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  newAccManager: Scalars['String']['input'];
  newAccManagerWhatsapp: Scalars['String']['input'];
  oldAccManager: Scalars['String']['input'];
  oldAccManagerWhatsapp: Scalars['String']['input'];
};

export type OpsJobCompanyAccManagerSwitchVerifyInput = {
  newContactWhatsapp: Scalars['String']['input'];
  newName: Scalars['String']['input'];
  oldContactWhatsapp: Scalars['String']['input'];
  oldName: Scalars['String']['input'];
};

export type OpsJobCompanyAccManagerUpdateInput = {
  contactWhatsapp: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  statusStr: Scalars['String']['input'];
  team: Scalars['String']['input'];
};

export type OpsJobCompanyAccManagers = {
  __typename?: 'OpsJobCompanyAccManagers';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<OpsJobCompanyAccManager>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobCompanyDraftInputUpdate = {
  accManager?: InputMaybe<Scalars['String']['input']>;
  accManagerCountryCode?: InputMaybe<Scalars['String']['input']>;
  accManagerWhatsapp?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  contactDayEnd?: InputMaybe<Scalars['Int']['input']>;
  contactDayStart?: InputMaybe<Scalars['Int']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactHourEnd?: InputMaybe<Scalars['Int']['input']>;
  contactHourEndStr?: InputMaybe<Scalars['String']['input']>;
  contactHourStart?: InputMaybe<Scalars['Int']['input']>;
  contactHourStartStr?: InputMaybe<Scalars['String']['input']>;
  contactWeblink?: InputMaybe<Scalars['String']['input']>;
  contactWhatsapp?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  foundedYear?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  isLegalDocumentAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  jobCompanyIndustryId?: InputMaybe<Scalars['ID']['input']>;
  jobCompanySizeId?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  verificationStatus?: InputMaybe<Scalars['String']['input']>;
};

export type OpsJobCompanyIndustries = {
  __typename?: 'OpsJobCompanyIndustries';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsJobCompanyIndustry>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobCompanyIndustry = {
  __typename?: 'OpsJobCompanyIndustry';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsJobCompanyInputUpdate = {
  accManager?: InputMaybe<Scalars['String']['input']>;
  accManagerCountryCode?: InputMaybe<Scalars['String']['input']>;
  accManagerWhatsapp?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  companyTypeId?: InputMaybe<Scalars['ID']['input']>;
  contactDayEnd?: InputMaybe<Scalars['Int']['input']>;
  contactDayStart?: InputMaybe<Scalars['Int']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactHourEnd?: InputMaybe<Scalars['Int']['input']>;
  contactHourEndInputStr?: InputMaybe<Scalars['String']['input']>;
  contactHourStart?: InputMaybe<Scalars['Int']['input']>;
  contactHourStartInputStr?: InputMaybe<Scalars['String']['input']>;
  contactWeblink?: InputMaybe<Scalars['String']['input']>;
  contactWhatsapp?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  foundedYear?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  isLegalDocumentAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  jobCompanyIndustryId?: InputMaybe<Scalars['ID']['input']>;
  jobCompanySizeId?: InputMaybe<Scalars['ID']['input']>;
  legalDocumentId?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  logoId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  verificationStatus?: InputMaybe<Scalars['String']['input']>;
};

export type OpsJobCompanyPremium = {
  __typename?: 'OpsJobCompanyPremium';
  attachment?: Maybe<MediaFile>;
  attachmentId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  currentPackageId?: Maybe<Scalars['ID']['output']>;
  currentPackageStr?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  jobCompany?: Maybe<OpsJobCompany>;
  jobCompanyAccManager?: Maybe<Scalars['String']['output']>;
  jobCompanyCode?: Maybe<Scalars['String']['output']>;
  jobCompanyId?: Maybe<Scalars['ID']['output']>;
  jobCompanyIndustryId?: Maybe<Scalars['String']['output']>;
  jobCompanyIndustryStr?: Maybe<Scalars['String']['output']>;
  jobCompanyInfo?: Maybe<Scalars['String']['output']>;
  jobCompanyName?: Maybe<Scalars['String']['output']>;
  jobCompanyType?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  packageRequestId?: Maybe<Scalars['ID']['output']>;
  packageRequestStr?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsJobCompanyPremiumRegisterSearchResult = {
  __typename?: 'OpsJobCompanyPremiumRegisterSearchResult';
  accManager?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  currentPackageStr?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  jobCompanyIndustryId?: Maybe<Scalars['ID']['output']>;
  jobCompanyIndustryStr?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  typeId?: Maybe<Scalars['ID']['output']>;
  typeStr?: Maybe<Scalars['String']['output']>;
};

export type OpsJobCompanyPremiumRequest = {
  __typename?: 'OpsJobCompanyPremiumRequest';
  attachment?: Maybe<MediaFile>;
  attachmentId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  currentPackageId?: Maybe<Scalars['ID']['output']>;
  currentPackageStr?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  jobCompany?: Maybe<OpsJobCompany>;
  jobCompanyAccManager?: Maybe<Scalars['String']['output']>;
  jobCompanyCode?: Maybe<Scalars['String']['output']>;
  jobCompanyId?: Maybe<Scalars['ID']['output']>;
  jobCompanyIndustryId?: Maybe<Scalars['String']['output']>;
  jobCompanyIndustryStr?: Maybe<Scalars['String']['output']>;
  jobCompanyInfo?: Maybe<Scalars['String']['output']>;
  jobCompanyName?: Maybe<Scalars['String']['output']>;
  jobCompanyType?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  packageRequestId?: Maybe<Scalars['ID']['output']>;
  packageRequestStr?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export enum OpsJobCompanyPremiumRequestStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
}

export type OpsJobCompanyPremiumRequests = {
  __typename?: 'OpsJobCompanyPremiumRequests';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<Maybe<OpsJobCompanyPremiumRequest>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobCompanyPremiums = {
  __typename?: 'OpsJobCompanyPremiums';
  elements?: Maybe<Scalars['BigInt']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<Maybe<OpsJobCompanyPremium>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobCompanySearchInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  contactWhatsapp?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type OpsJobCompanySearchResult = {
  __typename?: 'OpsJobCompanySearchResult';
  accManager?: Maybe<Scalars['String']['output']>;
  accManagerCountryCode?: Maybe<Scalars['String']['output']>;
  accManagerWhatsapp?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  contactDayEnd?: Maybe<Scalars['Int']['output']>;
  contactDayEndStr?: Maybe<Scalars['String']['output']>;
  contactDayStart?: Maybe<Scalars['Int']['output']>;
  contactDayStartStr?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactHourEnd?: Maybe<Scalars['Int']['output']>;
  contactHourEndStr?: Maybe<Scalars['String']['output']>;
  contactHourStart?: Maybe<Scalars['Int']['output']>;
  contactHourStartStr?: Maybe<Scalars['String']['output']>;
  contactWeblink?: Maybe<Scalars['String']['output']>;
  contactWhatsapp?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  foundedYear?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isExist?: Maybe<Scalars['Boolean']['output']>;
  isLegalDocumentAvailable?: Maybe<Scalars['Boolean']['output']>;
  isLegalDocumentAvailableStr?: Maybe<Scalars['String']['output']>;
  jobCompanyIndustry?: Maybe<OpsJobCompanyIndustry>;
  jobCompanyIndustryId?: Maybe<Scalars['ID']['output']>;
  jobCompanySize?: Maybe<OpsJobCompanySize>;
  locationCity?: Maybe<Location>;
  locationDistrict?: Maybe<Location>;
  locationId?: Maybe<Scalars['String']['output']>;
  locationProvince?: Maybe<Location>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  verificationStatus?: Maybe<Scalars['String']['output']>;
  verificationStatusStr?: Maybe<Scalars['String']['output']>;
  verificationStatusUpdatedAt?: Maybe<Scalars['String']['output']>;
  verificationStatusUpdatedBy?: Maybe<Scalars['String']['output']>;
  verificationStatusUpdatedStr?: Maybe<Scalars['String']['output']>;
};

export type OpsJobCompanySize = {
  __typename?: 'OpsJobCompanySize';
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
};

export type OpsJobCompanySubscriptionPackage = {
  __typename?: 'OpsJobCompanySubscriptionPackage';
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type OpsJobCompanySubscriptionPackages = {
  __typename?: 'OpsJobCompanySubscriptionPackages';
  elements?: Maybe<Scalars['BigInt']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<Maybe<OpsJobCompanySubscriptionPackage>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobCompanyType = {
  __typename?: 'OpsJobCompanyType';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OpsJobCompanyUpdate = {
  __typename?: 'OpsJobCompanyUpdate';
  data?: Maybe<OpsJobCompanyUpdateData>;
  error?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
  warning?: Maybe<Scalars['String']['output']>;
};

export type OpsJobCompanyUpdateData = {
  __typename?: 'OpsJobCompanyUpdateData';
  verificationStatusConfirmCopyStr?: Maybe<Scalars['String']['output']>;
};

export type OpsJobCompanyVerification = {
  __typename?: 'OpsJobCompanyVerification';
  accManager?: Maybe<Scalars['String']['output']>;
  accManagerCountryCode?: Maybe<Scalars['String']['output']>;
  accManagerWhatsapp?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  contactDayEnd?: Maybe<Scalars['Int']['output']>;
  contactDayEndStr?: Maybe<Scalars['String']['output']>;
  contactDayStart?: Maybe<Scalars['Int']['output']>;
  contactDayStartStr?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactHourEnd?: Maybe<Scalars['Int']['output']>;
  contactHourEndStr?: Maybe<Scalars['String']['output']>;
  contactHourStart?: Maybe<Scalars['Int']['output']>;
  contactHourStartStr?: Maybe<Scalars['String']['output']>;
  contactWeblink?: Maybe<Scalars['String']['output']>;
  contactWhatsapp?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  foundedYear?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isLegalDocumentAvailable?: Maybe<Scalars['Boolean']['output']>;
  isLegalDocumentAvailableStr?: Maybe<Scalars['String']['output']>;
  jobCompanyIndustry?: Maybe<OpsJobCompanyIndustry>;
  jobCompanyIndustryId?: Maybe<Scalars['ID']['output']>;
  jobCompanySize?: Maybe<OpsJobCompanySize>;
  legalDocument?: Maybe<MediaFile>;
  legalDocumentId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use 'url' on legalDocument instead */
  legalDocumentUrl?: Maybe<Scalars['String']['output']>;
  locationCity?: Maybe<Location>;
  locationDistrict?: Maybe<Location>;
  locationId?: Maybe<Scalars['String']['output']>;
  locationProvince?: Maybe<Location>;
  name?: Maybe<Scalars['String']['output']>;
  nib?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  verificationStatus?: Maybe<Scalars['String']['output']>;
  verificationStatusCopyStr?: Maybe<Scalars['String']['output']>;
  verificationStatusStr?: Maybe<Scalars['String']['output']>;
  verificationStatusUpdatedAt?: Maybe<Scalars['String']['output']>;
  verificationStatusUpdatedBy?: Maybe<Scalars['String']['output']>;
  verificationStatusUpdatedStr?: Maybe<Scalars['String']['output']>;
};

export type OpsJobCompanyVerificationResult = {
  __typename?: 'OpsJobCompanyVerificationResult';
  accManager?: Maybe<Scalars['String']['output']>;
  accManagerCountryCode?: Maybe<Scalars['String']['output']>;
  accManagerWhatsapp?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  contactDayEnd?: Maybe<Scalars['Int']['output']>;
  contactDayEndStr?: Maybe<Scalars['String']['output']>;
  contactDayStart?: Maybe<Scalars['Int']['output']>;
  contactDayStartStr?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactHourEnd?: Maybe<Scalars['Int']['output']>;
  contactHourEndStr?: Maybe<Scalars['String']['output']>;
  contactHourStart?: Maybe<Scalars['Int']['output']>;
  contactHourStartStr?: Maybe<Scalars['String']['output']>;
  contactWeblink?: Maybe<Scalars['String']['output']>;
  contactWhatsapp?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  foundedYear?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isLegalDocumentAvailable?: Maybe<Scalars['Boolean']['output']>;
  isLegalDocumentAvailableStr?: Maybe<Scalars['String']['output']>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  jobCompanyIndustry?: Maybe<OpsJobCompanyIndustry>;
  jobCompanyIndustryId?: Maybe<Scalars['ID']['output']>;
  jobCompanySize?: Maybe<OpsJobCompanySize>;
  locationCity?: Maybe<Location>;
  locationDistrict?: Maybe<Location>;
  locationId?: Maybe<Scalars['String']['output']>;
  locationProvince?: Maybe<Location>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  verificationStatus?: Maybe<Scalars['String']['output']>;
  verificationStatusStr?: Maybe<Scalars['String']['output']>;
  verificationStatusUpdatedAt?: Maybe<Scalars['String']['output']>;
  verificationStatusUpdatedBy?: Maybe<Scalars['String']['output']>;
  verificationStatusUpdatedStr?: Maybe<Scalars['String']['output']>;
};

export type OpsJobCompanyVerificationStatus = {
  __typename?: 'OpsJobCompanyVerificationStatus';
  key: EpCompanyVerificationStatus;
  value?: Maybe<Scalars['String']['output']>;
};

export type OpsJobCompanyVerifications = {
  __typename?: 'OpsJobCompanyVerifications';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsJobCompanyVerification>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobHighlightInput = {
  jobVacancyCode?: InputMaybe<Scalars['String']['input']>;
  jobVacancyHomeSectionId: Scalars['ID']['input'];
  jobVacancyId: Scalars['ID']['input'];
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsJobHighlightUpdate = {
  id: Scalars['ID']['input'];
  jobVacancyHomeSectionId: Scalars['ID']['input'];
  jobVacancyId: Scalars['ID']['input'];
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsJobKlepCompanies = {
  __typename?: 'OpsJobKlepCompanies';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsJobKlepCompany>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobKlepCompany = {
  __typename?: 'OpsJobKlepCompany';
  accManager?: Maybe<Scalars['String']['output']>;
  accManagerWhatsapp?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  klepData?: Maybe<OpsKlepData>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsJobKlepCompanyHistoryAction = {
  __typename?: 'OpsJobKlepCompanyHistoryAction';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  domainId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  newValue?: Maybe<Scalars['String']['output']>;
  oldValue?: Maybe<Scalars['String']['output']>;
  sideBySideValue?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsJobKlepCompanyHistoryActions = {
  __typename?: 'OpsJobKlepCompanyHistoryActions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsJobKlepCompanyHistoryAction>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobKlepCompanyUserRegistriesOtp = {
  __typename?: 'OpsJobKlepCompanyUserRegistriesOtp';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsJobKlepCompanyUserRegistryOtp>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobKlepCompanyUserRegistryInput = {
  accManager?: InputMaybe<Scalars['String']['input']>;
  companyIdStr?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type OpsJobKlepCompanyUserRegistryInputV2 = {
  accManager?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type OpsJobKlepCompanyUserRegistryOtp = {
  __typename?: 'OpsJobKlepCompanyUserRegistryOtp';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  role?: Maybe<Scalars['ID']['output']>;
  roleData?: Maybe<OpsJobKlepCompanyUserRole>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsJobKlepCompanyUserRole = {
  __typename?: 'OpsJobKlepCompanyUserRole';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OpsJobKlepCompanyUserRoleDropdownItem = {
  __typename?: 'OpsJobKlepCompanyUserRoleDropdownItem';
  description?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type OpsJobSkillRequirement = {
  __typename?: 'OpsJobSkillRequirement';
  jobVacancyRequirementAnswers?: Maybe<
    Array<Maybe<OpsJobVacancyRequirementAnswer>>
  >;
  jobVacancyRequirementQuestion?: Maybe<OpsJobVacancyRequirementQuestion>;
};

export type OpsJobSkillRequirements = {
  __typename?: 'OpsJobSkillRequirements';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsJobVacancyRequirementQuestion>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobSpecializationRole = {
  __typename?: 'OpsJobSpecializationRole';
  id?: Maybe<Scalars['ID']['output']>;
  jobRole?: Maybe<JobRole>;
  jobSpecialization?: Maybe<JobSpecialization>;
};

export type OpsJobSpecializationRoles = {
  __typename?: 'OpsJobSpecializationRoles';
  elements?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<OpsJobSpecializationRole>>;
  page?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobVacancies = {
  __typename?: 'OpsJobVacancies';
  elements?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsJobVacancyV2>>>;
  page?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobVacancy = {
  __typename?: 'OpsJobVacancy';
  /** @deprecated screening question is on hold */
  additionalRequirements?: Maybe<Array<Maybe<OpsVacancyRequirement>>>;
  city?: Maybe<Location>;
  closeDate?: Maybe<Scalars['Date']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  contactDetail?: Maybe<Scalars['String']['output']>;
  contactType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Location>;
  educationLevel?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  jobCompany?: Maybe<OpsJobCompany>;
  jobCompanyId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated move to job role and job spec */
  jobFunction?: Maybe<OpsJobVacancyFunction>;
  jobLevel?: Maybe<Scalars['String']['output']>;
  jobVacancyFunctionId?: Maybe<Scalars['ID']['output']>;
  keyword?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['ID']['output']>;
  locationSite?: Maybe<Scalars['String']['output']>;
  maxAge?: Maybe<Scalars['Int']['output']>;
  minExperience?: Maybe<Scalars['Int']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  positionName?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  province?: Maybe<Location>;
  /** @deprecated screening question is on hold */
  requirements?: Maybe<Array<Maybe<OpsVacancyRequirement>>>;
  salaryLowerBound?: Maybe<Scalars['Float']['output']>;
  salaryUpperBound?: Maybe<Scalars['Float']['output']>;
  skillStr?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vacancyCount?: Maybe<Scalars['Int']['output']>;
  viewCount?: Maybe<Scalars['Int']['output']>;
  workingDayEnd?: Maybe<Scalars['Int']['output']>;
  workingDayStart?: Maybe<Scalars['Int']['output']>;
  workingHourEnd?: Maybe<Scalars['Int']['output']>;
  workingHourEndStr?: Maybe<Scalars['String']['output']>;
  workingHourStart?: Maybe<Scalars['Int']['output']>;
  workingHourStartStr?: Maybe<Scalars['String']['output']>;
};

export type OpsJobVacancyByCode = {
  __typename?: 'OpsJobVacancyByCode';
  city?: Maybe<Location>;
  id?: Maybe<Scalars['ID']['output']>;
  jobCompany?: Maybe<OpsJobCompany>;
  jobCompanyId?: Maybe<Scalars['ID']['output']>;
  locationId?: Maybe<Scalars['ID']['output']>;
  locationSite?: Maybe<Scalars['String']['output']>;
  positionName?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Location>;
  salaryUpperBound?: Maybe<Scalars['Float']['output']>;
  skillStr?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  vacancyCount?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobVacancyFunction = {
  __typename?: 'OpsJobVacancyFunction';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsJobVacancyFunctionRequirements = {
  __typename?: 'OpsJobVacancyFunctionRequirements';
  requirements?: Maybe<Array<Maybe<OpsJobVacancyRequirementCategorySub>>>;
};

export type OpsJobVacancyFunctions = {
  __typename?: 'OpsJobVacancyFunctions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsJobVacancyFunction>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobVacancyLocationSites = {
  __typename?: 'OpsJobVacancyLocationSites';
  list?: Maybe<Array<Maybe<JobVacancyLocationSitesKeyValue>>>;
};

export type OpsJobVacancyReport = {
  __typename?: 'OpsJobVacancyReport';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  jobVacancy?: Maybe<OpsJobVacancy>;
  jobVacancyId?: Maybe<Scalars['ID']['output']>;
  jobVacancyReportCategory?: Maybe<OpsJobVacancyReportCategory>;
  reason?: Maybe<Scalars['String']['output']>;
  teamConfirmationAt?: Maybe<Scalars['Date']['output']>;
  teamConfirmationBy?: Maybe<Scalars['String']['output']>;
  teamConfirmationNote?: Maybe<Scalars['String']['output']>;
  teamConfirmationStatus?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  user?: Maybe<OpsConsUser>;
  userConfirmationAt?: Maybe<Scalars['Date']['output']>;
  userConfirmationBy?: Maybe<Scalars['String']['output']>;
  userConfirmationNote?: Maybe<Scalars['String']['output']>;
  userConfirmationStatus?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
};

export type OpsJobVacancyReportCategories = {
  __typename?: 'OpsJobVacancyReportCategories';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<OpsJobVacancyReportCategory>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobVacancyReportCategory = {
  __typename?: 'OpsJobVacancyReportCategory';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OpsJobVacancyReportUpdateInput = {
  id: Scalars['ID']['input'];
  jobCompanyCode: Scalars['String']['input'];
  jobCompanyName: Scalars['String']['input'];
  jobCompanyVerificationStatus?: InputMaybe<Scalars['String']['input']>;
  teamConfirmationNote?: InputMaybe<Scalars['String']['input']>;
  teamConfirmationStatus: Scalars['String']['input'];
  userConfirmationNote?: InputMaybe<Scalars['String']['input']>;
  userConfirmationStatus: Scalars['String']['input'];
};

export type OpsJobVacancyReports = {
  __typename?: 'OpsJobVacancyReports';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<OpsJobVacancyReport>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobVacancyRequirementAnswer = {
  __typename?: 'OpsJobVacancyRequirementAnswer';
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isCorrect?: Maybe<Scalars['Boolean']['output']>;
  jobVacancyRequirementQuestion?: Maybe<OpsJobVacancyRequirementQuestion>;
  jobVacancyRequirementQuestionId?: Maybe<Scalars['ID']['output']>;
  point?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobVacancyRequirementCategory = {
  __typename?: 'OpsJobVacancyRequirementCategory';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
};

export type OpsJobVacancyRequirementCategorySub = {
  __typename?: 'OpsJobVacancyRequirementCategorySub';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  jobVacancyRequirementCategory?: Maybe<OpsJobVacancyRequirementCategory>;
  jobVacancyRequirementCategoryId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  questions?: Maybe<Array<Maybe<OpsJobVacancyRequirementQuestion>>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsJobVacancyRequirementExplanation = {
  __typename?: 'OpsJobVacancyRequirementExplanation';
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  jobVacancyRequirementQuestion?: Maybe<OpsJobVacancyRequirementQuestion>;
  jobVacancyRequirementQuestionId?: Maybe<Scalars['ID']['output']>;
};

export type OpsJobVacancyRequirementQuestion = {
  __typename?: 'OpsJobVacancyRequirementQuestion';
  code?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  correctAnswer?: Maybe<OpsJobVacancyRequirementAnswer>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  jobVacancyRequirementCategorySub?: Maybe<OpsJobVacancyRequirementCategorySub>;
  jobVacancyRequirementCategorySubId?: Maybe<Scalars['ID']['output']>;
  options?: Maybe<Array<Maybe<OpsJobVacancyRequirementAnswer>>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsJobVacancyV2 = {
  __typename?: 'OpsJobVacancyV2';
  city?: Maybe<Location>;
  closeDate?: Maybe<Scalars['Date']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  contactDetail?: Maybe<Scalars['String']['output']>;
  contactType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionFormatted?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Location>;
  educationLevel?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  jobCompany?: Maybe<OpsJobCompany>;
  jobCompanyId?: Maybe<Scalars['ID']['output']>;
  jobLevel?: Maybe<Scalars['String']['output']>;
  jvRoleId?: Maybe<Scalars['ID']['output']>;
  jvRoleName?: Maybe<Scalars['String']['output']>;
  jvSpecializationId?: Maybe<Scalars['ID']['output']>;
  jvSpecializationName?: Maybe<Scalars['String']['output']>;
  jvSpecializationRoleId?: Maybe<Scalars['ID']['output']>;
  keyword?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['ID']['output']>;
  locationSite?: Maybe<Scalars['String']['output']>;
  maxAge?: Maybe<Scalars['Int']['output']>;
  minExperience?: Maybe<Scalars['Int']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  positionName?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  province?: Maybe<Location>;
  salaryLowerBound?: Maybe<Scalars['Float']['output']>;
  salaryUpperBound?: Maybe<Scalars['Float']['output']>;
  skillIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  skillsV2?: Maybe<Array<Maybe<OpsSkill>>>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vacancyCount?: Maybe<Scalars['Int']['output']>;
  workingDayEnd?: Maybe<Scalars['Int']['output']>;
  workingDayStart?: Maybe<Scalars['Int']['output']>;
  workingHourEnd?: Maybe<Scalars['Int']['output']>;
  workingHourEndStr?: Maybe<Scalars['String']['output']>;
  workingHourStart?: Maybe<Scalars['Int']['output']>;
  workingHourStartStr?: Maybe<Scalars['String']['output']>;
};

export type OpsKlepData = {
  __typename?: 'OpsKlepData';
  status?: Maybe<Scalars['String']['output']>;
  userCount?: Maybe<Scalars['String']['output']>;
};

export type OpsLive = {
  __typename?: 'OpsLive';
  country?: Maybe<OpsCountry>;
  countryId?: Maybe<Scalars['ID']['output']>;
  countryStr?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountType?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDiscount?: Maybe<Scalars['Boolean']['output']>;
  isFree?: Maybe<Scalars['Boolean']['output']>;
  isLive?: Maybe<Scalars['Boolean']['output']>;
  isPublish?: Maybe<Scalars['Boolean']['output']>;
  liveAt?: Maybe<Scalars['Date']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pretestUrl?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  shortDesc?: Maybe<Scalars['String']['output']>;
  teacher?: Maybe<OpsTeacher>;
  teacherId?: Maybe<Scalars['ID']['output']>;
  teacherStr?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type OpsLivePayload = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  discountType?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  host?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPublish?: InputMaybe<Scalars['Boolean']['input']>;
  liveAt?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pretestUrl?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  shortDesc?: InputMaybe<Scalars['String']['input']>;
  teacherId?: InputMaybe<Scalars['String']['input']>;
  videoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type OpsLivePublish = {
  __typename?: 'OpsLivePublish';
  data?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
};

export type OpsLiveUser = {
  __typename?: 'OpsLiveUser';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  liveId?: Maybe<Scalars['String']['output']>;
  liveStr?: Maybe<Scalars['String']['output']>;
  paymentStatus?: Maybe<Scalars['Boolean']['output']>;
  reasonCancel?: Maybe<Scalars['String']['output']>;
  reasonRejoin?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  teacherStr?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userUid?: Maybe<Scalars['String']['output']>;
};

export type OpsLiveUserDraft = {
  __typename?: 'OpsLiveUserDraft';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDraft?: Maybe<Scalars['Boolean']['output']>;
  liveId?: Maybe<Scalars['String']['output']>;
  liveName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userEmails?: Maybe<Scalars['String']['output']>;
};

export type OpsLiveUserDraftPayload = {
  id?: InputMaybe<Scalars['ID']['input']>;
  liveId?: InputMaybe<Scalars['String']['input']>;
  userEmails?: InputMaybe<Scalars['String']['input']>;
};

export type OpsLiveUserDrafts = {
  __typename?: 'OpsLiveUserDrafts';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsLiveUserDraft>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsLiveUserTotal = {
  __typename?: 'OpsLiveUserTotal';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  liveAt?: Maybe<Scalars['Date']['output']>;
  liveId?: Maybe<Scalars['ID']['output']>;
  liveName?: Maybe<Scalars['String']['output']>;
  teacher?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsLiveUserTotals = {
  __typename?: 'OpsLiveUserTotals';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsLiveUserTotal>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsLiveUsers = {
  __typename?: 'OpsLiveUsers';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsLiveUser>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsLives = {
  __typename?: 'OpsLives';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsLive>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsLocationConfig = {
  __typename?: 'OpsLocationConfig';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<Location>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsLocationLevelOption = {
  __typename?: 'OpsLocationLevelOption';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OpsLocationLevelOptions = {
  __typename?: 'OpsLocationLevelOptions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsLocationLevelOption>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsMainCategoryComposition = {
  __typename?: 'OpsMainCategoryComposition';
  categories?: Maybe<Array<Maybe<OpsCategoryComposition>>>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type OpsMarketingBanner = {
  __typename?: 'OpsMarketingBanner';
  actionType?: Maybe<Scalars['String']['output']>;
  actionUrl?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['ID']['output']>;
  countryStr?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  expiryTime?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageBanner?: Maybe<Scalars['String']['output']>;
  imageHighlight?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isHighlight?: Maybe<Scalars['Boolean']['output']>;
  isPublish?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<KeyValue>;
  priority?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  urlHighlight?: Maybe<Scalars['String']['output']>;
};

export type OpsMarketingBannerPayload = {
  actionType?: InputMaybe<Scalars['String']['input']>;
  actionUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  expiryTime?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageBanner?: InputMaybe<Scalars['String']['input']>;
  imageHighlight?: InputMaybe<Scalars['String']['input']>;
  isHighlight?: InputMaybe<Scalars['Boolean']['input']>;
  isPublish?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  urlHighlight?: InputMaybe<Scalars['String']['input']>;
};

export type OpsMarketingBanners = {
  __typename?: 'OpsMarketingBanners';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsMarketingBanner>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsMenu = {
  __typename?: 'OpsMenu';
  canApprove?: Maybe<Scalars['Boolean']['output']>;
  canCreate?: Maybe<Scalars['Boolean']['output']>;
  canDownload?: Maybe<Scalars['Boolean']['output']>;
  canRead?: Maybe<Scalars['Boolean']['output']>;
  canUpdate?: Maybe<Scalars['Boolean']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type OpsMenuList = {
  __typename?: 'OpsMenuList';
  data?: Maybe<OpsMenus>;
};

export type OpsMenus = {
  __typename?: 'OpsMenus';
  divisions?: Maybe<Scalars['String']['output']>;
  menus?: Maybe<Array<Maybe<OpsMenu>>>;
};

export type OpsMission = {
  __typename?: 'OpsMission';
  client?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<MediaImage>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  missionDetails?: Maybe<Array<Maybe<OpsMissionDetail>>>;
  name?: Maybe<Scalars['String']['output']>;
  quota?: Maybe<Scalars['Int']['output']>;
  quotaAvailable?: Maybe<Scalars['Int']['output']>;
  reward?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  termCondition?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsMissionDetail = {
  __typename?: 'OpsMissionDetail';
  field?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instruction?: Maybe<Scalars['String']['output']>;
  isText?: Maybe<Scalars['Boolean']['output']>;
  isUpload?: Maybe<Scalars['Boolean']['output']>;
  textLabel?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uploadLabel?: Maybe<Scalars['String']['output']>;
};

export type OpsMissions = {
  __typename?: 'OpsMissions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsMission>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsMrNotification = {
  __typename?: 'OpsMrNotification';
  actionType?: Maybe<Scalars['String']['output']>;
  actionUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isSendNotification?: Maybe<Scalars['Boolean']['output']>;
  publishedAt?: Maybe<Scalars['Date']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  target?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsMrNotifications = {
  __typename?: 'OpsMrNotifications';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsMrNotification>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsMsTest = {
  __typename?: 'OpsMsTest';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsMsTestSub = {
  __typename?: 'OpsMsTestSub';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  msTest?: Maybe<OpsMsTest>;
  msTestId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsMsTestSubs = {
  __typename?: 'OpsMsTestSubs';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsMsTestSub>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsMsTests = {
  __typename?: 'OpsMsTests';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsMsTest>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum OpsNameSegmentErrorNum {
  ParamBlank = 'PARAM_BLANK',
}

export type OpsNews = {
  __typename?: 'OpsNews';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  shortDesc?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsNewsPaging = {
  __typename?: 'OpsNewsPaging';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsNews>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum OpsNotificationActionType {
  DeepLink = 'DEEP_LINK',
  Web = 'WEB',
}

export enum OpsNotificationCategory {
  InfoImportant = 'INFO_IMPORTANT',
}

export enum OpsNotificationLabel {
  Announcement = 'ANNOUNCEMENT',
}

export type OpsNotificationMessage = {
  __typename?: 'OpsNotificationMessage';
  bodyText?: Maybe<Scalars['String']['output']>;
  campaignName?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  createdAtStr?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  eventGoal?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  publishedAtStr?: Maybe<Scalars['String']['output']>;
  redirectionLink?: Maybe<Scalars['String']['output']>;
  redirectionType?: Maybe<Scalars['String']['output']>;
  schedule?: Maybe<Scalars['String']['output']>;
  segment?: Maybe<OpsSegmentSummary>;
  segmentId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  targetUser?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type OpsNotificationMessageEventGoal = {
  __typename?: 'OpsNotificationMessageEventGoal';
  eventGoal: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type OpsNotificationMessageEventGoals = {
  __typename?: 'OpsNotificationMessageEventGoals';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<OpsNotificationMessageEventGoal>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsNotificationMessages = {
  __typename?: 'OpsNotificationMessages';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsNotificationMessage>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum OpsNotificationPlatform {
  Android = 'ANDROID',
  Both = 'BOTH',
  Ios = 'IOS',
}

export enum OpsNotificationRedirection {
  ExternalLink = 'EXTERNAL_LINK',
  InAppPage = 'IN_APP_PAGE',
  NoRedirection = 'NO_REDIRECTION',
}

export enum OpsNotificationStatus {
  Failed = 'FAILED',
  Success = 'SUCCESS',
}

export enum OpsNotificationTarget {
  AllUsers = 'ALL_USERS',
  Segmentation = 'SEGMENTATION',
}

export enum OpsNotificationType {
  Scheduled = 'SCHEDULED',
  Soon = 'SOON',
}

export type OpsOfflineProfile = {
  __typename?: 'OpsOfflineProfile';
  about?: Maybe<Scalars['String']['output']>;
  birthdate?: Maybe<Scalars['Date']['output']>;
  birthdateLocaleStr?: Maybe<Scalars['String']['output']>;
  birthdateMillis?: Maybe<Scalars['Date']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  cityId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  districtId?: Maybe<Scalars['ID']['output']>;
  educations?: Maybe<Array<Maybe<OpsUserEducation>>>;
  expectSalary?: Maybe<OpsUserSalary>;
  experiences?: Maybe<Array<Maybe<OpsUserExperience>>>;
  facebook?: Maybe<Scalars['String']['output']>;
  genderStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  isComplete?: Maybe<Scalars['Boolean']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  links?: Maybe<Array<Maybe<OpsUserSupportLink>>>;
  locationId?: Maybe<Scalars['ID']['output']>;
  mediaImage?: Maybe<MediaImage>;
  mediaImageId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profileUrl?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  provinceId?: Maybe<Scalars['ID']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  skills?: Maybe<Array<OpsSkill>>;
  slug?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userEmailStr?: Maybe<Scalars['String']['output']>;
  userGender?: Maybe<KeyValue>;
  userUid?: Maybe<Scalars['String']['output']>;
};

export type OpsOfflineProfileDashboard = {
  __typename?: 'OpsOfflineProfileDashboard';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsOfflineProfileDashboardItem>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsOfflineProfileDashboardItem = {
  __typename?: 'OpsOfflineProfileDashboardItem';
  city?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  experienceJobFunctions?: Maybe<Scalars['String']['output']>;
  experiencesYearStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastEducationLevel?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
};

export type OpsOrder = {
  __typename?: 'OpsOrder';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isBan?: Maybe<Scalars['Boolean']['output']>;
  isDiscount?: Maybe<Scalars['Boolean']['output']>;
  isFree?: Maybe<Scalars['Boolean']['output']>;
  isGuarantee?: Maybe<Scalars['Boolean']['output']>;
  itemType?: Maybe<Scalars['String']['output']>;
  itemsNames?: Maybe<Scalars['String']['output']>;
  package?: Maybe<OpsPackage>;
  paymentMethod?: Maybe<OpsPaymentMethod>;
  paymentMethodId?: Maybe<Scalars['String']['output']>;
  paymentPlan?: Maybe<Scalars['String']['output']>;
  paymentStatus?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  pricePerInstalment?: Maybe<Scalars['Float']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<OpsSku>;
  source?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  totalInstalment?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
  userUid?: Maybe<Scalars['String']['output']>;
  voucherCode?: Maybe<Scalars['String']['output']>;
};

export type OpsOrderBillAutoPayment = {
  __typename?: 'OpsOrderBillAutoPayment';
  id?: Maybe<Scalars['ID']['output']>;
  midtrans?: Maybe<OpsOrderBillDetailMidtrans>;
  orderBillDetailId?: Maybe<Scalars['ID']['output']>;
  orderBillId?: Maybe<Scalars['ID']['output']>;
  paymentCode?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
};

export type OpsOrderBillDetailMidtrans = {
  __typename?: 'OpsOrderBillDetailMidtrans';
  attemptNo?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  midtransOrderId?: Maybe<Scalars['String']['output']>;
  midtransTransactionId?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  transactionStatus?: Maybe<Scalars['String']['output']>;
};

export type OpsOrderInquiries = {
  __typename?: 'OpsOrderInquiries';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  /** @deprecated drop */
  list?: Maybe<Array<Maybe<OpsOrderInquiry>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsOrderInquiry = {
  __typename?: 'OpsOrderInquiry';
  classStr?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  csPackageId?: Maybe<Scalars['String']['output']>;
  csPackageStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isInstalment?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
  userUid?: Maybe<Scalars['String']['output']>;
};

export type OpsOrderItem = {
  __typename?: 'OpsOrderItem';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['ID']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceStr?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  skuId?: Maybe<Scalars['ID']['output']>;
  subTotal?: Maybe<Scalars['Float']['output']>;
  subTotalStr?: Maybe<Scalars['String']['output']>;
};

export type OpsOrderPayload = {
  id?: InputMaybe<Scalars['ID']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  paymentPlan?: InputMaybe<Scalars['String']['input']>;
  paymentStatus?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  skuId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
  voucherCode?: InputMaybe<Scalars['String']['input']>;
};

export type OpsOrders = {
  __typename?: 'OpsOrders';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsOrder>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsPackage = {
  __typename?: 'OpsPackage';
  classroom?: Maybe<OpsClassroom>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  csPackageId?: Maybe<Scalars['ID']['output']>;
  csPackageStr?: Maybe<Scalars['String']['output']>;
  currencyId?: Maybe<Scalars['ID']['output']>;
  currencyStr?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountType?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  dueDateStr?: Maybe<Scalars['String']['output']>;
  dueDates?: Maybe<Array<Maybe<Scalars['Date']['output']>>>;
  id?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAddon?: Maybe<Scalars['Boolean']['output']>;
  isDiscount?: Maybe<Scalars['Boolean']['output']>;
  isFree?: Maybe<Scalars['Boolean']['output']>;
  isGuarantee?: Maybe<Scalars['Boolean']['output']>;
  isPublish?: Maybe<Scalars['Boolean']['output']>;
  liveAt?: Maybe<Scalars['Date']['output']>;
  liveAtFormatted?: Maybe<Scalars['Date']['output']>;
  liveAtLocale?: Maybe<Scalars['Date']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  pricePerInstalment?: Maybe<Scalars['Float']['output']>;
  sessions?: Maybe<Array<Maybe<OpsSession>>>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  totalInstalment?: Maybe<Scalars['Int']['output']>;
  totalSession?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsPackageDetail = {
  csClassSessionId?: InputMaybe<Scalars['ID']['input']>;
};

export type OpsPackageDropdown = {
  __typename?: 'OpsPackageDropdown';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OpsPackagePayload = {
  csClassId?: InputMaybe<Scalars['String']['input']>;
  csPackageId?: InputMaybe<Scalars['ID']['input']>;
  currencyId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountAmount?: InputMaybe<Scalars['Int']['input']>;
  discountType?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  dueDates?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isAddon?: InputMaybe<Scalars['Boolean']['input']>;
  isDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  isGuarantee?: InputMaybe<Scalars['Boolean']['input']>;
  isPublish?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  packageDetails?: InputMaybe<Array<InputMaybe<OpsPackageDetail>>>;
  price?: InputMaybe<Scalars['Int']['input']>;
  pricePerInstalment?: InputMaybe<Scalars['Int']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  totalInstalment?: InputMaybe<Scalars['Int']['input']>;
  totalSession?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsPackageTypeDropdown = {
  __typename?: 'OpsPackageTypeDropdown';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type OpsPackageV2 = {
  __typename?: 'OpsPackageV2';
  expiry?: Maybe<Scalars['BigInt']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  quotaGrowing?: Maybe<Scalars['Int']['output']>;
  quotaPriority?: Maybe<Scalars['Int']['output']>;
  quotaRegular?: Maybe<Scalars['Int']['output']>;
  validity?: Maybe<Scalars['String']['output']>;
};

export type OpsPackages = {
  __typename?: 'OpsPackages';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsPackage>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsParam = {
  __typename?: 'OpsParam';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type OpsParams = {
  __typename?: 'OpsParams';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsParam>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum OpsPassingGradeType {
  Amount = 'AMOUNT',
  Percentage = 'PERCENTAGE',
}

export type OpsPaymentMethod = {
  __typename?: 'OpsPaymentMethod';
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum OpsPeriodEnum {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export type OpsProfile = {
  __typename?: 'OpsProfile';
  about?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userUid?: Maybe<Scalars['String']['output']>;
};

export type OpsProfileAssignment = {
  __typename?: 'OpsProfileAssignment';
  applicantEmail?: Maybe<Scalars['String']['output']>;
  applicantName?: Maybe<Scalars['String']['output']>;
  assignedAt?: Maybe<Scalars['String']['output']>;
  assignedBy?: Maybe<Scalars['String']['output']>;
  jobCompanyName?: Maybe<Scalars['String']['output']>;
  jobVacancyCode?: Maybe<Scalars['String']['output']>;
  jobVacancyPositionName?: Maybe<Scalars['String']['output']>;
};

export type OpsProfileAssignments = {
  __typename?: 'OpsProfileAssignments';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsProfileAssignment>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsProfileCreateInput = {
  about?: InputMaybe<Scalars['String']['input']>;
  birthdateIso?: InputMaybe<Scalars['String']['input']>;
  educations?: InputMaybe<Array<OpsEducationInputProfile>>;
  experiences?: InputMaybe<Array<OpsExperienceInputProfile>>;
  gender?: InputMaybe<Scalars['String']['input']>;
  links?: InputMaybe<Array<OpsSupportLinkInputProfile>>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  mediaImageId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  salary?: InputMaybe<OpsSalaryInputProfile>;
  skills?: InputMaybe<Array<Scalars['String']['input']>>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
};

export type OpsProfileUpdateInput = {
  about?: InputMaybe<Scalars['String']['input']>;
  birthdateIso?: InputMaybe<Scalars['String']['input']>;
  educations?: InputMaybe<Array<OpsEducationInputProfile>>;
  experiences?: InputMaybe<Array<OpsExperienceInputProfile>>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  links?: InputMaybe<Array<OpsSupportLinkInputProfile>>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  mediaImageId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  salary?: InputMaybe<OpsSalaryInputProfile>;
  skills?: InputMaybe<Array<Scalars['String']['input']>>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
};

export enum OpsPublishVacancyErrorEnum {
  QuotaExceeded = 'QUOTA_EXCEEDED',
  UnknownError = 'UNKNOWN_ERROR',
  VacancyAlreadyClosed = 'VACANCY_ALREADY_CLOSED',
  VacancyAlreadyPublished = 'VACANCY_ALREADY_PUBLISHED',
  VacancyNotFound = 'VACANCY_NOT_FOUND',
}

export type OpsPublishVacancyResponse = {
  __typename?: 'OpsPublishVacancyResponse';
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OpsPublishVacancyResponseError = {
  __typename?: 'OpsPublishVacancyResponseError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<OpsPublishVacancyErrorEnum>;
};

export type OpsPublishVacancyV2Result =
  | OpsPublishVacancyResponse
  | OpsPublishVacancyResponseError;

export type OpsQuestion = {
  __typename?: 'OpsQuestion';
  answerLatex?: Maybe<Scalars['Boolean']['output']>;
  answers?: Maybe<Array<Maybe<OpsAnswer>>>;
  approvedAt?: Maybe<Scalars['Date']['output']>;
  approvedBy?: Maybe<Scalars['String']['output']>;
  categorySubs?: Maybe<Array<Maybe<OpsCategorySub>>>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  explanation?: Maybe<OpsExplanation>;
  grade?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isLatex?: Maybe<Scalars['Boolean']['output']>;
  tags?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsQuestionDraft = {
  __typename?: 'OpsQuestionDraft';
  answerLatex?: Maybe<Scalars['Boolean']['output']>;
  answers?: Maybe<Array<Maybe<OpsAnswer>>>;
  categorySubs?: Maybe<Array<Maybe<OpsCategorySub>>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  explanation?: Maybe<OpsExplanation>;
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isLatex?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export enum OpsQuestionDraftStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  OnQc = 'ON_QC',
  Rejected = 'REJECTED',
}

export type OpsQuestionDrafts = {
  __typename?: 'OpsQuestionDrafts';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsQuestionDraft>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum OpsQuestionType {
  MultipleChoice = 'MULTIPLE_CHOICE',
}

export type OpsQuestions = {
  __typename?: 'OpsQuestions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsQuestion>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsRedemption = {
  __typename?: 'OpsRedemption';
  approvalAt: Scalars['String']['output'];
  approvalBy?: Maybe<Scalars['String']['output']>;
  claimForm?: Maybe<Array<OpsDataClaim>>;
  communityMember: OpsCommunityMember;
  communityMemberId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  mission: OpsMission;
  missionId: Scalars['ID']['output'];
  rejectedReason?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  submittedAt: Scalars['String']['output'];
};

export type OpsRedemptions = {
  __typename?: 'OpsRedemptions';
  elements: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  list: Array<OpsRedemption>;
  page: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
};

export type OpsReferralBonus = {
  __typename?: 'OpsReferralBonus';
  amount?: Maybe<Scalars['Float']['output']>;
  bonusCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  inviteeCount?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  referralProgramId?: Maybe<Scalars['ID']['output']>;
  sku?: Maybe<OpsSku>;
  skuId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsReferralIncentive = {
  __typename?: 'OpsReferralIncentive';
  amount?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  referralProgramId?: Maybe<Scalars['ID']['output']>;
  sku?: Maybe<OpsSku>;
  skuId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsReferralProgram = {
  __typename?: 'OpsReferralProgram';
  bonusCode?: Maybe<Scalars['String']['output']>;
  bonuses?: Maybe<Array<Maybe<OpsReferralBonus>>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  incentives?: Maybe<Array<Maybe<OpsReferralIncentive>>>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsReferralPrograms = {
  __typename?: 'OpsReferralPrograms';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsReferralProgram>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsRegisterJobCompanyPremiumInput = {
  attachmentId?: InputMaybe<Scalars['ID']['input']>;
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  jobCompanyId: Scalars['ID']['input'];
  newPackageId?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type OpsResolveReportResult = {
  __typename?: 'OpsResolveReportResult';
  bannedAt?: Maybe<Scalars['Date']['output']>;
  bannedBy?: Maybe<Scalars['String']['output']>;
  communityBanCategory?: Maybe<OpsCommunityBanCategory>;
  communityBanCategoryId?: Maybe<Scalars['ID']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  isBan?: Maybe<Scalars['Boolean']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  userUid?: Maybe<Scalars['ID']['output']>;
};

export type OpsSalaryInputProfile = {
  salary?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsSegment = {
  __typename?: 'OpsSegment';
  createdAtStr?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Array<Scalars['String']['output']>>;
  updatedAtStr?: Maybe<Scalars['String']['output']>;
  userCount?: Maybe<Scalars['Int']['output']>;
  versioning?: Maybe<Scalars['String']['output']>;
};

export type OpsSegmentConfigCv = {
  __typename?: 'OpsSegmentConfigCv';
  isExist?: Maybe<Scalars['Boolean']['output']>;
};

export type OpsSegmentConfigEducation = {
  __typename?: 'OpsSegmentConfigEducation';
  data?: Maybe<Array<SegmentFilterItem>>;
  isExist?: Maybe<Scalars['Boolean']['output']>;
};

export type OpsSegmentConfigExperienceJobSpec = {
  __typename?: 'OpsSegmentConfigExperienceJobSpec';
  data?: Maybe<Array<SegmentFilterItem>>;
  descriptionTotalWords?: Maybe<SegmentFilterItem>;
  isExist?: Maybe<Scalars['Boolean']['output']>;
};

export type OpsSegmentConfigJobSpecPreference = {
  __typename?: 'OpsSegmentConfigJobSpecPreference';
  data?: Maybe<Array<SegmentFilterItem>>;
  isExist?: Maybe<Scalars['Boolean']['output']>;
};

export type OpsSegmentConfigLocation = {
  __typename?: 'OpsSegmentConfigLocation';
  data?: Maybe<Array<SegmentFilterItem>>;
  isExist?: Maybe<Scalars['Boolean']['output']>;
  locationLevel?: Maybe<SegmentFilterItem>;
};

export type OpsSegmentConfigSkill = {
  __typename?: 'OpsSegmentConfigSkill';
  data?: Maybe<Array<SegmentFilterItem>>;
  isExist?: Maybe<Scalars['Boolean']['output']>;
};

export type OpsSegmentData = {
  __typename?: 'OpsSegmentData';
  profile?: Maybe<OpsSegmentProfileFilter>;
  subscription?: Maybe<OpsSegmentSubscriptionFilter>;
  type?: Maybe<Array<Scalars['String']['output']>>;
  user?: Maybe<OpsSegmentUserIdFilter>;
};

export type OpsSegmentDivision = {
  __typename?: 'OpsSegmentDivision';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum OpsSegmentDivisionEnum {
  AdsAndPartner = 'ADS_AND_PARTNER',
  Community = 'COMMUNITY',
  Engineering = 'ENGINEERING',
  Product = 'PRODUCT',
  Promar = 'PROMAR',
  Qa = 'QA',
}

export type OpsSegmentDivisions = {
  __typename?: 'OpsSegmentDivisions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<OpsSegmentDivision>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum OpsSegmentErrorEnum {
  InvalidPayload = 'INVALID_PAYLOAD',
  ParamBlank = 'PARAM_BLANK',
  ParamConfigExceed = 'PARAM_CONFIG_EXCEED',
  ParsingFailed = 'PARSING_FAILED',
  SegmentExist = 'SEGMENT_EXIST',
  SegmentNotFound = 'SEGMENT_NOT_FOUND',
}

export type OpsSegmentJobDescriptionLength = {
  __typename?: 'OpsSegmentJobDescriptionLength';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum OpsSegmentJobDescriptionLengthEnum {
  MoreThan_44Words = 'MORE_THAN_44_WORDS',
  Words_0 = 'WORDS_0',
  Words_1To_44 = 'WORDS_1_TO_44',
}

export type OpsSegmentJobDescriptionLengths = {
  __typename?: 'OpsSegmentJobDescriptionLengths';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<OpsSegmentJobDescriptionLength>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsSegmentNameAvailabilityResult =
  | SegmentNameAvailabilityError
  | SegmentNameAvailabilityResponse;

export type OpsSegmentProfileFilter = {
  __typename?: 'OpsSegmentProfileFilter';
  cv?: Maybe<OpsSegmentConfigCv>;
  educationLevel?: Maybe<OpsSegmentConfigEducation>;
  preferenceJobSpecialization?: Maybe<OpsSegmentConfigJobSpecPreference>;
  preferenceLocation?: Maybe<OpsSegmentConfigLocation>;
  profileLocation?: Maybe<OpsSegmentConfigLocation>;
  skills?: Maybe<OpsSegmentConfigSkill>;
  workExperience?: Maybe<OpsSegmentConfigExperienceJobSpec>;
};

export type OpsSegmentSubscriptionFilter = {
  __typename?: 'OpsSegmentSubscriptionFilter';
  currentSubscriptionStatus?: Maybe<Scalars['String']['output']>;
  expirationDaysWithin?: Maybe<Scalars['Int']['output']>;
};

export type OpsSegmentSubscriptionStatus = {
  __typename?: 'OpsSegmentSubscriptionStatus';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum OpsSegmentSubscriptionStatusEnum {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Never = 'NEVER',
}

export type OpsSegmentSubscriptionStatuses = {
  __typename?: 'OpsSegmentSubscriptionStatuses';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<OpsSegmentSubscriptionStatus>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsSegmentSummary = {
  __typename?: 'OpsSegmentSummary';
  createdAtStr?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  data?: Maybe<OpsSegmentData>;
  division?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastVersionName?: Maybe<Scalars['String']['output']>;
  segmentName?: Maybe<Scalars['String']['output']>;
  updatedAtStr?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userCount?: Maybe<Scalars['Int']['output']>;
};

export type OpsSegmentType = {
  __typename?: 'OpsSegmentType';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum OpsSegmentTypeEnum {
  Profile = 'PROFILE',
  Subscription = 'SUBSCRIPTION',
  UserId = 'USER_ID',
}

export type OpsSegmentTypes = {
  __typename?: 'OpsSegmentTypes';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<OpsSegmentType>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsSegmentUserIdFilter = {
  __typename?: 'OpsSegmentUserIdFilter';
  userIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type OpsSegmentUserUpdateCountResult =
  | SegmentUserUpdateCountResponse
  | SegmentUserUpdateCountResponseError;

export type OpsSegments = {
  __typename?: 'OpsSegments';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsSegment>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsSession = {
  __typename?: 'OpsSession';
  classDescription?: Maybe<Scalars['String']['output']>;
  classId?: Maybe<Scalars['ID']['output']>;
  className?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  /** @deprecated drop */
  exam?: Maybe<OpsExam>;
  /** @deprecated drop */
  examId?: Maybe<Scalars['ID']['output']>;
  exams?: Maybe<Array<Maybe<OpsSessionExam>>>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  fileUrl1?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isLive?: Maybe<Scalars['Boolean']['output']>;
  isLiveInApp?: Maybe<Scalars['Boolean']['output']>;
  isPublishExam?: Maybe<Scalars['Boolean']['output']>;
  liveAt?: Maybe<Scalars['Date']['output']>;
  liveLink?: Maybe<Scalars['String']['output']>;
  postTestUrl?: Maybe<Scalars['String']['output']>;
  preTestUrl?: Maybe<Scalars['String']['output']>;
  syllabus?: Maybe<Scalars['String']['output']>;
  teacherId?: Maybe<Scalars['String']['output']>;
  teacherStr?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  video?: Maybe<OpsVideo>;
  videoId?: Maybe<Scalars['ID']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
  waSummaryUrl?: Maybe<Scalars['String']['output']>;
};

export type OpsSessionExam = {
  __typename?: 'OpsSessionExam';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<OpsExam>;
  examId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPublish?: Maybe<Scalars['Boolean']['output']>;
  sequence?: Maybe<Scalars['Int']['output']>;
  session?: Maybe<OpsSession>;
  sessionId?: Maybe<Scalars['ID']['output']>;
  startAt?: Maybe<Scalars['Date']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsSessionExamInputUpdate = {
  examId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<Scalars['Int']['input']>;
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  startAt?: InputMaybe<Scalars['Date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type OpsSessionPayload = {
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  examId?: InputMaybe<Scalars['ID']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  fileUrl1?: InputMaybe<Scalars['String']['input']>;
  host?: InputMaybe<Scalars['String']['input']>;
  idx?: InputMaybe<Scalars['Int']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isLiveInApp?: InputMaybe<Scalars['Boolean']['input']>;
  liveAt?: InputMaybe<Scalars['Date']['input']>;
  liveLink?: InputMaybe<Scalars['String']['input']>;
  postTestUrl?: InputMaybe<Scalars['String']['input']>;
  preTestUrl?: InputMaybe<Scalars['String']['input']>;
  syllabus?: InputMaybe<Scalars['String']['input']>;
  teacherId?: InputMaybe<Scalars['String']['input']>;
  teacherStr?: InputMaybe<Scalars['String']['input']>;
  videoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type OpsSessions = {
  __typename?: 'OpsSessions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsSession>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsSkill = {
  __typename?: 'OpsSkill';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OpsSkillInput = {
  id: Scalars['ID']['input'];
  idx?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsSkills = {
  __typename?: 'OpsSkills';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsSkill>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsSku = {
  __typename?: 'OpsSku';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountType?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  installments?: Maybe<Array<Maybe<OpsSkuInstallment>>>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDiscount?: Maybe<Scalars['Boolean']['output']>;
  isFree?: Maybe<Scalars['Boolean']['output']>;
  isGuarantee?: Maybe<Scalars['Boolean']['output']>;
  isInstallment?: Maybe<Scalars['Boolean']['output']>;
  isPublish?: Maybe<Scalars['Boolean']['output']>;
  mediaImageId?: Maybe<Scalars['String']['output']>;
  msCurrency?: Maybe<OpsCurrency>;
  msCurrencyId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  packageType?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceStr?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  skuComponents?: Maybe<Array<Maybe<OpsSkuSkuCategoryComponent>>>;
  skuSkuCategories?: Maybe<Array<Maybe<OpsSkuSkuCategory>>>;
  skuSkuCategoriesNames?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  validityPeriod?: Maybe<Scalars['String']['output']>;
  validityPeriodStr?: Maybe<Scalars['String']['output']>;
  validityValue?: Maybe<Scalars['Int']['output']>;
};

export type OpsSkuCategories = {
  __typename?: 'OpsSkuCategories';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsSkuCategory>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsSkuCategoriesStr = {
  __typename?: 'OpsSkuCategoriesStr';
  names?: Maybe<Scalars['String']['output']>;
  skuCategories?: Maybe<Array<Maybe<OpsSkuCategory>>>;
};

export type OpsSkuCategory = {
  __typename?: 'OpsSkuCategory';
  children?: Maybe<Array<Maybe<OpsSkuCategory>>>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentCategory?: Maybe<OpsSkuCategory>;
  parentCategoryId?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsSkuCategoryComponent = {
  __typename?: 'OpsSkuCategoryComponent';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  skuCategoryId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsSkuCategoryComponents = {
  __typename?: 'OpsSkuCategoryComponents';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsSkuCategoryComponent>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsSkuCategoryForm = {
  __typename?: 'OpsSkuCategoryForm';
  root?: Maybe<Array<Maybe<OpsSkuCategoryParent>>>;
};

export type OpsSkuCategoryParent = {
  __typename?: 'OpsSkuCategoryParent';
  children?: Maybe<Array<Maybe<OpsSkuCategory>>>;
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OpsSkuInstallment = {
  __typename?: 'OpsSkuInstallment';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  dueDateStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceStr?: Maybe<Scalars['String']['output']>;
  skuId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsSkuSkuCategory = {
  __typename?: 'OpsSkuSkuCategory';
  id?: Maybe<Scalars['ID']['output']>;
  skuCategory?: Maybe<OpsSkuCategory>;
  skuCategoryId?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  typeId: Scalars['ID']['output'];
};

export type OpsSkuSkuCategoryComponent = {
  __typename?: 'OpsSkuSkuCategoryComponent';
  id?: Maybe<Scalars['ID']['output']>;
  skuCategoryComponent?: Maybe<OpsSkuCategoryComponent>;
  skuCategoryComponentId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
};

export type OpsSkus = {
  __typename?: 'OpsSkus';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsSku>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsSocialMedia = {
  __typename?: 'OpsSocialMedia';
  facebook?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  linkedid?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
};

export type OpsStatusLiveUserPayload = {
  id?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type OpsSubCategoryComposition = {
  __typename?: 'OpsSubCategoryComposition';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type OpsSubmission = {
  __typename?: 'OpsSubmission';
  approvalAt?: Maybe<Scalars['String']['output']>;
  approvalBy?: Maybe<Scalars['String']['output']>;
  communityMember?: Maybe<OpsCommunityMember>;
  communityMemberId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  mission?: Maybe<OpsMission>;
  missionId: Scalars['ID']['output'];
  rejectedReason?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  submissionForm?: Maybe<Array<Maybe<OpsDataSubmission>>>;
  submittedAt?: Maybe<Scalars['String']['output']>;
};

export type OpsSubmissions = {
  __typename?: 'OpsSubmissions';
  elements: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  list?: Maybe<Array<OpsSubmission>>;
  page: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
};

export type OpsSubmitBulkJobKlepCompanyUserRegistryInputV2 = {
  accManager?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type OpsSubmitBulkJobKlepCompanyUserRegistryV2Data = {
  __typename?: 'OpsSubmitBulkJobKlepCompanyUserRegistryV2Data';
  accManager?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type OpsSubmitBulkJobKlepCompanyUserRegistryV2Error = {
  __typename?: 'OpsSubmitBulkJobKlepCompanyUserRegistryV2Error';
  message: Scalars['String']['output'];
  typeError: OpsSubmitBulkJobKlepCompanyUserRegistryV2ErrorEnum;
};

export enum OpsSubmitBulkJobKlepCompanyUserRegistryV2ErrorEnum {
  UnknownError = 'UNKNOWN_ERROR',
}

export type OpsSubmitBulkJobKlepCompanyUserRegistryV2Response = {
  __typename?: 'OpsSubmitBulkJobKlepCompanyUserRegistryV2Response';
  data: Array<OpsSubmitBulkJobKlepCompanyUserRegistryV2Data>;
};

export type OpsSubmitBulkJobKlepCompanyUserRegistryV2Result =
  | OpsSubmitBulkJobKlepCompanyUserRegistryV2Error
  | OpsSubmitBulkJobKlepCompanyUserRegistryV2Response;

export type OpsSubmitCompanyPackageError = {
  __typename?: 'OpsSubmitCompanyPackageError';
  message?: Maybe<Scalars['String']['output']>;
  typeError?: Maybe<OpsSubmitCompanyPackageErrorEnum>;
};

export enum OpsSubmitCompanyPackageErrorEnum {
  UnknownError = 'UNKNOWN_ERROR',
}

export type OpsSubmitCompanyPackageInput = {
  attachmentId?: InputMaybe<Scalars['ID']['input']>;
  companyId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  packageId: Scalars['ID']['input'];
};

export type OpsSubmitCompanyPackageResponse = {
  __typename?: 'OpsSubmitCompanyPackageResponse';
  companyId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  packageId: Scalars['ID']['output'];
};

export type OpsSubmitCompanyPackageResult =
  | OpsSubmitCompanyPackageError
  | OpsSubmitCompanyPackageResponse;

export type OpsSupportLinkCategory = {
  __typename?: 'OpsSupportLinkCategory';
  asset?: Maybe<OpsSupportLinkCategoryAsset>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  rules?: Maybe<Array<Maybe<OpsSupportLinkCategoryRule>>>;
  screenDensity?: Maybe<Scalars['String']['output']>;
};

export type OpsSupportLinkCategoryAsset = {
  __typename?: 'OpsSupportLinkCategoryAsset';
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  supportLinkCategoryId?: Maybe<Scalars['ID']['output']>;
};

export type OpsSupportLinkCategoryRule = {
  __typename?: 'OpsSupportLinkCategoryRule';
  errorMessage?: Maybe<Scalars['String']['output']>;
  type?: Maybe<OpsSupportLinkCategoryRuleType>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum OpsSupportLinkCategoryRuleType {
  MaxChar = 'MAX_CHAR',
  MinChar = 'MIN_CHAR',
  Regex = 'REGEX',
}

export type OpsSupportLinkInputProfile = {
  id?: InputMaybe<Scalars['ID']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  supportLinkCategoryId?: InputMaybe<Scalars['String']['input']>;
};

export type OpsTeacher = {
  __typename?: 'OpsTeacher';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsTeacherPayload = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type OpsTeachers = {
  __typename?: 'OpsTeachers';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsTeacher>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsUnbanCommunityVideoV2User =
  | UnbanCommunityVideoV2UserErrorResponse
  | UnbanCommunityVideoV2UserResponse;

export type OpsUpdateJobCompanyPremiumInput = {
  attachmentId?: InputMaybe<Scalars['ID']['input']>;
  expiryDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  jobCompanyId: Scalars['ID']['input'];
  newPackageId?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type OpsUpdateJobCompanyPremiumRequestInput = {
  attachmentId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  status: OpsJobCompanyPremiumRequestStatus;
  updateReason: Scalars['String']['input'];
};

export type OpsUpdateJobCompanyVerificationStatusInput = {
  companyVerificationStatus: EpCompanyVerificationStatus;
  jobCompanyId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type OpsUpdateJobVacancy = {
  additionalRequirements?: InputMaybe<
    Array<InputMaybe<OpsVacancyRequirementInputUpdate>>
  >;
  closeDate?: InputMaybe<Scalars['Date']['input']>;
  contactDetail?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  educationLevel?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  jobCompanyId: Scalars['ID']['input'];
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  jobVacancyFunctionId?: InputMaybe<Scalars['ID']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationSite?: InputMaybe<Scalars['String']['input']>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  minExperience?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  positionName?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  requirements?: InputMaybe<
    Array<InputMaybe<OpsVacancyRequirementInputUpdate>>
  >;
  salaryLowerBound?: InputMaybe<Scalars['Float']['input']>;
  salaryUpperBound?: InputMaybe<Scalars['Float']['input']>;
  skills?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  vacancyCount?: InputMaybe<Scalars['Int']['input']>;
  workingDayEnd?: InputMaybe<Scalars['Int']['input']>;
  workingDayStart?: InputMaybe<Scalars['Int']['input']>;
  workingHourEndStr?: InputMaybe<Scalars['String']['input']>;
  workingHourStartStr?: InputMaybe<Scalars['String']['input']>;
};

export type OpsUpdateJobVacancyV2 = {
  closeDate?: InputMaybe<Scalars['Date']['input']>;
  contactDetail?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionFormatted?: InputMaybe<Scalars['String']['input']>;
  educationLevel?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  jobCompanyId: Scalars['ID']['input'];
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  jobSpecializationRoleId?: InputMaybe<Scalars['ID']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationSite?: InputMaybe<Scalars['String']['input']>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  minExperience?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  positionName?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  salaryLowerBound?: InputMaybe<Scalars['Float']['input']>;
  salaryUpperBound?: InputMaybe<Scalars['Float']['input']>;
  skills?: InputMaybe<Scalars['String']['input']>;
  skillsV2?: InputMaybe<Array<InputMaybe<OpsSkillInput>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  vacancyCount?: InputMaybe<Scalars['Int']['input']>;
  workingDayEnd?: InputMaybe<Scalars['Int']['input']>;
  workingDayStart?: InputMaybe<Scalars['Int']['input']>;
  workingHourEndStr?: InputMaybe<Scalars['String']['input']>;
  workingHourStartStr?: InputMaybe<Scalars['String']['input']>;
};

export type OpsUpdateSessionPayload = {
  classId?: InputMaybe<Scalars['String']['input']>;
  classSessionExamIds?: InputMaybe<
    Array<InputMaybe<OpsSessionExamInputUpdate>>
  >;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  fileUrl1?: InputMaybe<Scalars['String']['input']>;
  host?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idx?: InputMaybe<Scalars['Int']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isLiveInApp?: InputMaybe<Scalars['Boolean']['input']>;
  liveAt?: InputMaybe<Scalars['Date']['input']>;
  liveLink?: InputMaybe<Scalars['String']['input']>;
  postTestUrl?: InputMaybe<Scalars['String']['input']>;
  preTestUrl?: InputMaybe<Scalars['String']['input']>;
  syllabus?: InputMaybe<Scalars['String']['input']>;
  teacherId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['ID']['input']>;
  videoUrl?: InputMaybe<Scalars['String']['input']>;
  waSummaryUrl?: InputMaybe<Scalars['String']['input']>;
};

export type OpsUpdateVacancyHighlightSectionInput = {
  config?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsUploadFile = {
  __typename?: 'OpsUploadFile';
  key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type OpsUploadFiles = {
  __typename?: 'OpsUploadFiles';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsUploadFile>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsUser = {
  password?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type OpsUserCurrency = {
  __typename?: 'OpsUserCurrency';
  displayInitial?: Maybe<Scalars['String']['output']>;
  regex?: Maybe<Scalars['String']['output']>;
  substitute?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type OpsUserEducation = {
  __typename?: 'OpsUserEducation';
  description?: Maybe<Scalars['String']['output']>;
  educationInstitution?: Maybe<OpsEducationInstitution>;
  educationInstitutionId?: Maybe<Scalars['ID']['output']>;
  educationLevel?: Maybe<Scalars['String']['output']>;
  educationProgram?: Maybe<OpsEducationProgram>;
  educationProgramId?: Maybe<Scalars['ID']['output']>;
  endMonth?: Maybe<Scalars['Int']['output']>;
  endYear?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  periodStr?: Maybe<Scalars['String']['output']>;
  startMonth?: Maybe<Scalars['Int']['output']>;
  startYear?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type OpsUserExperience = {
  __typename?: 'OpsUserExperience';
  companyName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  employmentType?: Maybe<OpsEmploymentType>;
  endMonth?: Maybe<Scalars['Int']['output']>;
  endYear?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  jobFunction?: Maybe<KeyValue>;
  jobSpecializationRole?: Maybe<OpsJobSpecializationRole>;
  jobSpecializationRoleId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  periodStr?: Maybe<Scalars['String']['output']>;
  skills?: Maybe<Array<Maybe<KeyValue>>>;
  startMonth?: Maybe<Scalars['Int']['output']>;
  startYear?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type OpsUserLocationDropdown = {
  __typename?: 'OpsUserLocationDropdown';
  description?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  mediaId?: Maybe<Scalars['String']['output']>;
  mediaImage?: Maybe<MediaImage>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type OpsUserSalary = {
  __typename?: 'OpsUserSalary';
  currencyId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  period?: Maybe<OpsPeriodEnum>;
  salary?: Maybe<Scalars['Int']['output']>;
  salaryStr?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type OpsUserSkills = {
  __typename?: 'OpsUserSkills';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  limitSelection?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<KeyValue>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsUserSr = {
  __typename?: 'OpsUserSr';
  companyId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type OpsUserSupportLink = {
  __typename?: 'OpsUserSupportLink';
  id?: Maybe<Scalars['ID']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  supportLinkCategory?: Maybe<OpsSupportLinkCategory>;
  supportLinkCategoryId?: Maybe<Scalars['ID']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export enum OpsUserTargetType {
  AllUsers = 'ALL_USERS',
  NeverOrdered = 'NEVER_ORDERED',
  OrderedPreviously = 'ORDERED_PREVIOUSLY',
}

export type OpsVacancyHighlightSection = {
  __typename?: 'OpsVacancyHighlightSection';
  config?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OpsVacancyHighlightSections = {
  __typename?: 'OpsVacancyHighlightSections';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsVacancyHighlightSection>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsVacancyReqAnswerInputCreate = {
  content: Scalars['String']['input'];
  idx?: InputMaybe<Scalars['Int']['input']>;
  isCorrect: Scalars['Boolean']['input'];
  point?: InputMaybe<Scalars['Float']['input']>;
};

export type OpsVacancyReqAnswerInputUpdate = {
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  idx?: InputMaybe<Scalars['Int']['input']>;
  isCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  point?: InputMaybe<Scalars['Float']['input']>;
};

export type OpsVacancyReqCatSubInputCreate = {
  jobVacancyRequirementCategoryId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  questions: Array<InputMaybe<OpsVacancyReqQuestionInputCreate>>;
};

export type OpsVacancyReqCatSubInputUpdate = {
  id: Scalars['ID']['input'];
  jobVacancyRequirementCategoryId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<Array<InputMaybe<OpsVacancyReqQuestionInputUpdate>>>;
};

export type OpsVacancyReqQuestionInputCreate = {
  answers: Array<InputMaybe<OpsVacancyReqAnswerInputCreate>>;
  content: Scalars['String']['input'];
};

export type OpsVacancyReqQuestionInputUpdate = {
  answers?: InputMaybe<Array<InputMaybe<OpsVacancyReqAnswerInputUpdate>>>;
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type OpsVacancyRequirement = {
  __typename?: 'OpsVacancyRequirement';
  id?: Maybe<Scalars['ID']['output']>;
  isAdditional?: Maybe<Scalars['Boolean']['output']>;
  jobVacancyId?: Maybe<Scalars['ID']['output']>;
  jobVacancyReqCategorySubId?: Maybe<Scalars['ID']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  vacancyReqCategorySub?: Maybe<OpsJobVacancyRequirementCategorySub>;
};

export type OpsVacancyRequirementInputCreate = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  jobVacancyReqCategorySubId: Scalars['ID']['input'];
  priority: Scalars['Int']['input'];
};

export type OpsVacancyRequirementInputUpdate = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  jobVacancyReqCategorySubId?: InputMaybe<Scalars['ID']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type OpsVerifyBulkAddHighlightResult = {
  __typename?: 'OpsVerifyBulkAddHighlightResult';
  countFailed?: Maybe<Scalars['Int']['output']>;
  countSuccess?: Maybe<Scalars['Int']['output']>;
  failed?: Maybe<Array<Maybe<OpsBulkAddHighlightResult>>>;
  result?: Maybe<Array<Maybe<OpsBulkAddHighlightResult>>>;
  success?: Maybe<Array<Maybe<OpsBulkAddHighlightResult>>>;
};

export type OpsVerifyBulkCompaniesResult = {
  __typename?: 'OpsVerifyBulkCompaniesResult';
  countFailed?: Maybe<Scalars['Int']['output']>;
  countSuccess?: Maybe<Scalars['Int']['output']>;
  failed?: Maybe<Array<Maybe<OpsBulkCompaniesResult>>>;
  result?: Maybe<Array<Maybe<OpsBulkCompaniesResult>>>;
  success?: Maybe<Array<Maybe<OpsBulkCompaniesResult>>>;
};

export type OpsVerifyBulkDeleteHighlightResult = {
  __typename?: 'OpsVerifyBulkDeleteHighlightResult';
  countFailed?: Maybe<Scalars['Int']['output']>;
  countSuccess?: Maybe<Scalars['Int']['output']>;
  failed?: Maybe<Array<Maybe<OpsBulkDeleteHighlightResult>>>;
  result?: Maybe<Array<Maybe<OpsBulkDeleteHighlightResult>>>;
  success?: Maybe<Array<Maybe<OpsBulkDeleteHighlightResult>>>;
};

export type OpsVerifyBulkSkillsResult = {
  __typename?: 'OpsVerifyBulkSkillsResult';
  countFailed?: Maybe<Scalars['Int']['output']>;
  countSuccess?: Maybe<Scalars['Int']['output']>;
  failed?: Maybe<Array<Maybe<OpsBulkVacancySkillsResult>>>;
  result?: Maybe<Array<Maybe<OpsBulkVacancySkillsResult>>>;
  success?: Maybe<Array<Maybe<OpsBulkVacancySkillsResult>>>;
};

export type OpsVerifyBulkUnPublishVacancyResult = {
  __typename?: 'OpsVerifyBulkUnPublishVacancyResult';
  countFailed?: Maybe<Scalars['Int']['output']>;
  countSuccess?: Maybe<Scalars['Int']['output']>;
  failed?: Maybe<Array<OpsBulkUnPublishVacancyResult>>;
  result?: Maybe<Array<OpsBulkUnPublishVacancyResult>>;
  success?: Maybe<Array<OpsBulkUnPublishVacancyResult>>;
};

export type OpsVerifyBulkVacanciesResult = {
  __typename?: 'OpsVerifyBulkVacanciesResult';
  countFailed?: Maybe<Scalars['Int']['output']>;
  countSuccess?: Maybe<Scalars['Int']['output']>;
  failed?: Maybe<Array<Maybe<OpsBulkVacanciesResult>>>;
  result?: Maybe<Array<Maybe<OpsBulkVacanciesResult>>>;
  success?: Maybe<Array<Maybe<OpsBulkVacanciesResult>>>;
};

export type OpsVerifySwitchJobCompanyAccManagerResult = {
  __typename?: 'OpsVerifySwitchJobCompanyAccManagerResult';
  companyCode?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  newAccManager?: Maybe<Scalars['String']['output']>;
  newAccManagerWhatsapp?: Maybe<Scalars['String']['output']>;
  oldAccManager?: Maybe<Scalars['String']['output']>;
  oldAccManagerWhatsapp?: Maybe<Scalars['String']['output']>;
};

export type OpsVerifySwitchJobCompanyAccManagerResults = {
  __typename?: 'OpsVerifySwitchJobCompanyAccManagerResults';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list: Array<OpsVerifySwitchJobCompanyAccManagerResult>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsVideo = {
  __typename?: 'OpsVideo';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  gcs?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  tags?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  video?: Maybe<MediaVideo>;
  videoId?: Maybe<Scalars['ID']['output']>;
};

export type OpsVideos = {
  __typename?: 'OpsVideos';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsVideo>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsVoucher = {
  __typename?: 'OpsVoucher';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountType?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isMultipleUse?: Maybe<Scalars['Boolean']['output']>;
  isValid?: Maybe<Scalars['Boolean']['output']>;
  minTransaction?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userTarget?: Maybe<Scalars['String']['output']>;
};

export type OpsVouchers = {
  __typename?: 'OpsVouchers';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsVoucher>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type OpsWallet = {
  __typename?: 'OpsWallet';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userUid?: Maybe<Scalars['String']['output']>;
};

export type OpsWalletRedemption = {
  __typename?: 'OpsWalletRedemption';
  amount?: Maybe<Scalars['Float']['output']>;
  bankAccName?: Maybe<Scalars['String']['output']>;
  bankAccNo?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  paymentDate?: Maybe<Scalars['Date']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  receiptUrl?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  wallet?: Maybe<OpsWallet>;
  walletId?: Maybe<Scalars['ID']['output']>;
};

export type OpsWalletRedemptions = {
  __typename?: 'OpsWalletRedemptions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<OpsWalletRedemption>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum OptionTypeEnum {
  Horizontal = 'HORIZONTAL',
  Vertical = 'VERTICAL',
}

export type Order = {
  __typename?: 'Order';
  bills?: Maybe<Array<Maybe<Bill>>>;
  createdAtLocale?: Maybe<Scalars['String']['output']>;
  csPackageId?: Maybe<Scalars['ID']['output']>;
  csPackageStr?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isBan?: Maybe<Scalars['Boolean']['output']>;
  isDiscount?: Maybe<Scalars['Boolean']['output']>;
  isFree?: Maybe<Scalars['Boolean']['output']>;
  isGuarantee?: Maybe<Scalars['Boolean']['output']>;
  liveAt?: Maybe<Scalars['String']['output']>;
  liveAtFormatted?: Maybe<Scalars['String']['output']>;
  liveAtLocale?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  paymentMethodId?: Maybe<Scalars['ID']['output']>;
  paymentMethodInfo?: Maybe<PaymentMethod>;
  paymentPlan?: Maybe<Scalars['String']['output']>;
  paymentStatus?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  pricePerInstalment?: Maybe<Scalars['Float']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<SkuInfo>;
  status?: Maybe<Scalars['String']['output']>;
  totalInstalment?: Maybe<Scalars['Int']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  voucherCode?: Maybe<Scalars['String']['output']>;
};

export type OrderBillAutoPayment = {
  __typename?: 'OrderBillAutoPayment';
  id?: Maybe<Scalars['ID']['output']>;
  midtrans?: Maybe<OrderBillDetailMidtrans>;
  orderBillDetailId?: Maybe<Scalars['ID']['output']>;
  orderBillId?: Maybe<Scalars['ID']['output']>;
  paymentCode?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
};

export type OrderBillDetailMidtrans = {
  __typename?: 'OrderBillDetailMidtrans';
  attemptNo?: Maybe<Scalars['Int']['output']>;
  expiredAt?: Maybe<Scalars['String']['output']>;
  expiredAtStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isExpired?: Maybe<Scalars['Boolean']['output']>;
  midtransOrderId?: Maybe<Scalars['String']['output']>;
  midtransTransactionId?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  transactionStatus?: Maybe<Scalars['String']['output']>;
};

export type OrderErrorResponse = {
  __typename?: 'OrderErrorResponse';
  errorCode?: Maybe<PaymentErrorEnum>;
  isSuccess: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['ID']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceStr?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  skuId?: Maybe<Scalars['ID']['output']>;
  subTotal?: Maybe<Scalars['Float']['output']>;
  subTotalStr?: Maybe<Scalars['String']['output']>;
};

export type OrderResponse = OrderErrorResponse | OrderSuccessResponse;

export type OrderSubmitSummary = {
  __typename?: 'OrderSubmitSummary';
  bills?: Maybe<Array<Maybe<Bill>>>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountAmountStr?: Maybe<Scalars['String']['output']>;
  firstInstalment?: Maybe<Scalars['Float']['output']>;
  firstInstalmentStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isInstallment?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Maybe<OrderItem>>>;
  orderNo?: Maybe<Scalars['String']['output']>;
  orderTime?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  paymentMethodId?: Maybe<Scalars['ID']['output']>;
  paymentMethodInfo?: Maybe<PaymentMethodInfo>;
  paymentPlan?: Maybe<Scalars['String']['output']>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  totalPriceStr?: Maybe<Scalars['String']['output']>;
};

export type OrderSuccessResponse = {
  __typename?: 'OrderSuccessResponse';
  invoiceUrl: Scalars['String']['output'];
  isSuccess: Scalars['Boolean']['output'];
};

export type Orders = {
  __typename?: 'Orders';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<Order>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type PaymentChannel = {
  __typename?: 'PaymentChannel';
  displayName: Scalars['String']['output'];
  iconUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum PaymentErrorEnum {
  ApiValidationError = 'API_VALIDATION_ERROR',
  AvailablePaymentCodeNotFoundError = 'AVAILABLE_PAYMENT_CODE_NOT_FOUND_ERROR',
  CallbackVirtualAccountNotFoundError = 'CALLBACK_VIRTUAL_ACCOUNT_NOT_FOUND_ERROR',
  InvalidJsonFormat = 'INVALID_JSON_FORMAT',
  InvalidReminderTime = 'INVALID_REMINDER_TIME',
  InvoiceNotFoundError = 'INVOICE_NOT_FOUND_ERROR',
  MaximumTransferAmountError = 'MAXIMUM_TRANSFER_AMOUNT_ERROR',
  MinimalTransferAmountError = 'MINIMAL_TRANSFER_AMOUNT_ERROR',
  NoCollectionMethodsError = 'NO_COLLECTION_METHODS_ERROR',
  RequestForbiddenError = 'REQUEST_FORBIDDEN_ERROR',
  ServiceError = 'SERVICE_ERROR',
  UnavailablePaymentMethodError = 'UNAVAILABLE_PAYMENT_METHOD_ERROR',
  UniqueAccountNumberUnavailableError = 'UNIQUE_ACCOUNT_NUMBER_UNAVAILABLE_ERROR',
  UnirestError = 'UNIREST_ERROR',
  VendorEnumNotFoundError = 'VENDOR_ENUM_NOT_FOUND_ERROR',
}

export type PaymentInstruction = {
  __typename?: 'PaymentInstruction';
  description?: Maybe<Scalars['String']['output']>;
  descriptionArrs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentMethodId?: Maybe<Scalars['ID']['output']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
};

export type PaymentMethodGroup = {
  __typename?: 'PaymentMethodGroup';
  group?: Maybe<Scalars['String']['output']>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

export type PaymentMethodInfo = {
  __typename?: 'PaymentMethodInfo';
  bankAccName?: Maybe<Scalars['String']['output']>;
  bankAccNo?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type PaymentMethodV3 = {
  __typename?: 'PaymentMethodV3';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  paymentChannels?: Maybe<Array<PaymentChannel>>;
};

export enum PeriodEnum {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export type PingResponse = {
  __typename?: 'PingResponse';
  buildInfo: Scalars['String']['output'];
  buildTime: Scalars['String']['output'];
  now: Scalars['String']['output'];
};

export type PreviewProfileBeforeApply = {
  __typename?: 'PreviewProfileBeforeApply';
  completeness?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  locationStr?: Maybe<Scalars['String']['output']>;
  mediaImageId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type Profile = {
  __typename?: 'Profile';
  about?: Maybe<Scalars['String']['output']>;
  birthdate?: Maybe<Scalars['Date']['output']>;
  birthdateLocaleStr?: Maybe<Scalars['String']['output']>;
  birthdateMillis?: Maybe<Scalars['Date']['output']>;
  city?: Maybe<Location>;
  cityId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  district?: Maybe<Location>;
  educations?: Maybe<Array<Maybe<Education>>>;
  expectSalary?: Maybe<UserSalary>;
  experiences?: Maybe<Array<Maybe<Experience>>>;
  facebook?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  hasActiveSubscription?: Maybe<Scalars['Boolean']['output']>;
  hasClass?: Maybe<Scalars['Boolean']['output']>;
  hasExam?: Maybe<Scalars['Boolean']['output']>;
  hasJob?: Maybe<Scalars['Boolean']['output']>;
  hasNoExperience?: Maybe<Scalars['Boolean']['output']>;
  hasSubmittedCvFeedback?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  isComplete?: Maybe<Scalars['Boolean']['output']>;
  isPreferenceCompleted?: Maybe<Scalars['Boolean']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  links?: Maybe<Array<Maybe<SupportLink>>>;
  locationId?: Maybe<Scalars['ID']['output']>;
  mediaImageId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profileUrl?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Location>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  skills?: Maybe<Array<Skill>>;
  slug?: Maybe<Scalars['String']['output']>;
  subscriptionPlan?: Maybe<SpSubscriptionPlan>;
  twitter?: Maybe<Scalars['String']['output']>;
  userCv?: Maybe<Array<Maybe<UserCv>>>;
  userCvs?: Maybe<UserCvs>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userEmailStr?: Maybe<Scalars['String']['output']>;
  userEmploymentTypes?: Maybe<Array<EmploymentType>>;
  userInterests?: Maybe<Array<Maybe<UserInterest>>>;
  userJobFunctions?: Maybe<Array<Maybe<JobVacancyFunction>>>;
  userSpecializationRoles?: Maybe<Array<JobSpecializationRole>>;
  userUid?: Maybe<Scalars['String']['output']>;
  userVacancyPositions?: Maybe<Array<VacancyPosition>>;
  workPreference?: Maybe<UserWorkPreference>;
};

export type ProfileChecklist = {
  __typename?: 'ProfileChecklist';
  displayName?: Maybe<Scalars['String']['output']>;
  isChecked?: Maybe<Scalars['Boolean']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
};

export enum ProfileChecklistStatus {
  Unchecked = 'UNCHECKED',
  Valid = 'VALID',
  Warning = 'WARNING',
}

export type ProfileChecklistV2 = {
  __typename?: 'ProfileChecklistV2';
  copy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ProfileChecklistStatus>;
};

export type ProfileCompletenessCheckList = {
  __typename?: 'ProfileCompletenessCheckList';
  actionLabel?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  redirectionKey?: Maybe<Scalars['String']['output']>;
  redirectionUrl?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ProfileCompletenessStatusEnum>;
  statusIcon?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum ProfileCompletenessStatusEnum {
  Complete = 'COMPLETE',
  Empty = 'EMPTY',
  Warning = 'WARNING',
}

export type ProfileCreateInput = {
  about?: InputMaybe<Scalars['String']['input']>;
  birthdate?: InputMaybe<Scalars['Date']['input']>;
  educations?: InputMaybe<Array<InputMaybe<EducationCreateInputProfile>>>;
  experiences?: InputMaybe<Array<InputMaybe<ExperienceCreateInputProfile>>>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  links?: InputMaybe<Array<InputMaybe<SupportLinkCreateInputProfile>>>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  nickname?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
  userUid?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileHistory = {
  __typename?: 'ProfileHistory';
  about?: Maybe<Scalars['String']['output']>;
  birthdate?: Maybe<Scalars['Date']['output']>;
  birthdateLocaleStr?: Maybe<Scalars['String']['output']>;
  birthdateMillis?: Maybe<Scalars['Date']['output']>;
  city?: Maybe<Location>;
  cityId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  district?: Maybe<Location>;
  educations?: Maybe<Array<Maybe<Education>>>;
  experiences?: Maybe<Array<Maybe<Experience>>>;
  facebook?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  hasJob?: Maybe<Scalars['Boolean']['output']>;
  hasNoExperience?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  links?: Maybe<Array<Maybe<SupportLink>>>;
  locationId?: Maybe<Scalars['ID']['output']>;
  mediaImageId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profileUrl?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Location>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  skills?: Maybe<Array<Skill>>;
  slug?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userEmailStr?: Maybe<Scalars['String']['output']>;
  userInterests?: Maybe<Array<Maybe<UserInterest>>>;
  userJobFunctions?: Maybe<Array<Maybe<JobVacancyFunction>>>;
  userUid?: Maybe<Scalars['String']['output']>;
  workPreference?: Maybe<UserWorkPreference>;
};

export type ProfileLabel = {
  __typename?: 'ProfileLabel';
  copy?: Maybe<Scalars['String']['output']>;
  textColor?: Maybe<Scalars['String']['output']>;
};

export type ProfilePictureUrlUpdateInput = {
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  mediaImageId?: InputMaybe<Scalars['String']['input']>;
  oldMediaImageId?: InputMaybe<Scalars['String']['input']>;
};

export type ProfilePictureUrlUpdateInputV2 = {
  id: Scalars['ID']['input'];
  mediaImageId?: InputMaybe<Scalars['String']['input']>;
  oldMediaImageId?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileStrength = {
  __typename?: 'ProfileStrength';
  description: Scalars['String']['output'];
  label: ProfileLabel;
  maxStrength: Scalars['Int']['output'];
  strength: Scalars['Int']['output'];
};

export type ProfileUpdateInput = {
  about?: InputMaybe<Scalars['String']['input']>;
  birthdate?: InputMaybe<Scalars['Date']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  hasJob?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
  userUid?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  abandonmentReason: Array<AbandonmentReasonOption>;
  activityFeeds?: Maybe<Feeds>;
  appUpdate?: Maybe<AppVersion>;
  applicationPreview?: Maybe<ApplicationPreview>;
  availableUpdate?: Maybe<UpdateCheck>;
  categoryView?: Maybe<CategoryView>;
  classroom?: Maybe<Classroom>;
  classrooms?: Maybe<Classrooms>;
  communityGroup?: Maybe<CommunityGroup>;
  communityGroupBoards?: Maybe<Array<Maybe<CommunityGroupBoard>>>;
  communityGroupByHomeSectionId?: Maybe<Array<Maybe<CommunityGroup>>>;
  communityGroupHomeSection?: Maybe<Array<Maybe<CommunityGroupHomeSection>>>;
  communityGroupPreview?: Maybe<CommunityGroupPreview>;
  communityGroupRecommendation?: Maybe<CommunityGroupRecommendations>;
  communityGroupRecommendationV2?: Maybe<Array<Maybe<CommunityGroup>>>;
  communityGroupRegistration?: Maybe<CommunityGroupRegistration>;
  communityGroupRegistrationV2?: Maybe<CommunityGroupRegistration>;
  communityGroupRegistrationV3?: Maybe<CommunityGroupRegistrationV2>;
  communityGroupTermConditions?: Maybe<CommunityGroupTermConditions>;
  communityGroups?: Maybe<CommunityGroups>;
  communityGroupsV2?: Maybe<CommunityGroupsV2>;
  communityGroupsV3?: Maybe<CommunityGroupsV2>;
  communityMember?: Maybe<CommunityMember>;
  communityReportCategoryDetailByName?: Maybe<
    Array<Maybe<CommunityReportCategoryDetail>>
  >;
  communityVideoCategories: Array<CommunityVideoCategory>;
  communityVideoV2?: Maybe<CommunityVideoV2>;
  communityVideoV2Comments?: Maybe<CommunityVideoV2Comments>;
  communityVideoV2Jobs?: Maybe<Vacancies>;
  communityVideoV2ReportStatus?: Maybe<CommunityVideoV2ReportStatus>;
  communityVideos?: Maybe<CommunityVideos>;
  communityVideosV2?: Maybe<CommunityVideosV2>;
  company?: Maybe<Company>;
  companyBySlug?: Maybe<Company>;
  companyTab?: Maybe<Array<Maybe<CompanyTab>>>;
  currencies?: Maybe<Array<Maybe<Currency>>>;
  cvReview?: Maybe<CvReview>;
  cvTemplates?: Maybe<CvTemplates>;
  deleteAccImpactContent?: Maybe<DisclaimerContent>;
  deleteAccReasonContent?: Maybe<DisclaimerContent>;
  disclaimerContent?: Maybe<DisclaimerContent>;
  education?: Maybe<Education>;
  educationInstitutions?: Maybe<Array<Maybe<EducationInstitution>>>;
  educationLevels?: Maybe<Array<Maybe<KeyValue>>>;
  educationPrograms?: Maybe<Array<Maybe<EducationProgram>>>;
  employmentTypes?: Maybe<Array<Maybe<EmploymentType>>>;
  epAuthPing?: Maybe<EpPingResponse>;
  epCompany?: Maybe<EpJobCompany>;
  epCompanyAccManager?: Maybe<EpJobCompanyAccManager>;
  epCompanyIndustries?: Maybe<EpJobCompanyIndustries>;
  epCompanyOps?: Maybe<EpCompanyOps>;
  epCompanyOpsSearch: EpCompaniesOps;
  epCompanySizes?: Maybe<Array<EpJobCompanySize>>;
  epDashboardApplicationSummaryCount?: Maybe<EpDashboardApplicationSummaryCount>;
  epDashboardVacancySummaryList?: Maybe<EpDashboardVacancySummaryResult>;
  epDocumentsCertificates?: Maybe<EpScreeningQuestionDocCerts>;
  epEducationInstitutions?: Maybe<EpEducationInstitutions>;
  epEducationLevelDropdown?: Maybe<Array<Maybe<EpEducationLevelKeyValue>>>;
  epEducationLevelFilter?: Maybe<Array<Maybe<EpEducationLevelFilterKeyValue>>>;
  epEducationPrograms?: Maybe<EpEducationPrograms>;
  epExperienceJobFunctions: EpExperienceJobFunctions;
  epExperienceYearDropdown: Array<EpExperienceYearDropdown>;
  epGenderDropdown?: Maybe<Array<Maybe<KeyValue>>>;
  epHello?: Maybe<Scalars['String']['output']>;
  epHelloThere?: Maybe<Scalars['String']['output']>;
  epIdentity?: Maybe<Scalars['String']['output']>;
  epInterviewSessionApplications?: Maybe<EpInterviewSessionApplications>;
  epInterviewSessionById?: Maybe<EpInterviewSession>;
  epInterviewSessionCount?: Maybe<EpInterviewSessionCount>;
  epInterviewSessionDropdown?: Maybe<EpInterviewSessionDropdowns>;
  epInterviewSessionSort?: Maybe<Array<Maybe<KeyValue>>>;
  epInterviewSessionTabs?: Maybe<Array<Maybe<EpInterviewSessionTab>>>;
  epInterviewSessionTypes?: Maybe<Array<Maybe<KeyValue>>>;
  epInterviewSessions?: Maybe<EpInterviewSessions>;
  epInvitationStatusDropdown?: Maybe<Array<EpInvitationStatusDropdownItem>>;
  epJobApplication?: Maybe<EpJobApplication>;
  epJobApplicationCount?: Maybe<EpJobApplicationCountData>;
  epJobApplicationCvDownload?: Maybe<EpJobApplicationProfileExport>;
  epJobApplicationDashboardSummary?: Maybe<EpJobApplicationDashboardSummary>;
  epJobApplicationEpStatusDropdown: Array<Maybe<EpJobApplicationStatus>>;
  epJobApplicationHistoryLabels: Array<Maybe<EpJobApplicationHistoryLabel>>;
  epJobApplicationPrevNext?: Maybe<EpJobApplicationPrevNext>;
  epJobApplicationRejectedReason?: Maybe<Array<Scalars['String']['output']>>;
  epJobApplicationRejectedReasonCategories?: Maybe<
    Array<EpJobApplicationRejectedReasonCategory>
  >;
  epJobApplicationScreening?: Maybe<EpJobApplicationScreening>;
  epJobApplicationSortDropdown?: Maybe<Array<Maybe<KeyValue>>>;
  epJobApplicationSummary?: Maybe<EpJobApplicationSummary>;
  epJobApplications?: Maybe<EpJobApplications>;
  epJobDashboardFilterStatusDropdown?: Maybe<Array<Maybe<KeyValue>>>;
  epJobSkills?: Maybe<JobSkills>;
  epJobSpecializationRoleSkills?: Maybe<JobSpecializationRoleSkills>;
  epJobSpecializationRoles?: Maybe<JobSpecializationRoles>;
  epJobSpecializations?: Maybe<JobSpecializations>;
  epJobVacancies?: Maybe<EpJobVacanciesV2>;
  epJobVacancy?: Maybe<EpJobVacancy>;
  epJobVacancyBenefits?: Maybe<Array<Maybe<KeyValue>>>;
  epJobVacancyCandidate?: Maybe<EpJobVacancyCandidate>;
  epJobVacancyCandidateCount?: Maybe<EpJobVacancyCandidateCount>;
  epJobVacancyCandidatePrevNext?: Maybe<EpJobVacancyCandidatePrevNext>;
  epJobVacancyCandidateSummary?: Maybe<EpJobVacancyCandidateSummary>;
  epJobVacancyCandidates?: Maybe<EpJobVacancyCandidates>;
  epJobVacancyClosedFeedbackTemplates?: Maybe<JobVacancyClosedFeedbackTemplates>;
  epJobVacancyContactTypeDropdown?: Maybe<Array<Maybe<KeyValue>>>;
  epJobVacancyDashboard?: Maybe<EpJobVacancyDashboards>;
  epJobVacancyDashboardSummary?: Maybe<EpJobVacancyDashboardSummary>;
  epJobVacancyDashboardSummaryList?: Maybe<EpJobVacancies>;
  epJobVacancyDistinctSearch?: Maybe<EpJobVacancyDistincts>;
  epJobVacancyDraft?: Maybe<EpJobVacancy>;
  epJobVacancyDraftScreeningQuestions?: Maybe<EpScreeningQuestions>;
  epJobVacancyDrafts?: Maybe<EpJobVacanciesV2>;
  epJobVacancyEthics?: Maybe<Array<Maybe<EpJobVacancyEthic>>>;
  epJobVacancyFunctions?: Maybe<EpJobVacancyFunctions>;
  epJobVacancyLevelDropdown?: Maybe<EpJobLevelDropdowns>;
  epJobVacancyLocationSiteDropdown?: Maybe<EpJobVacancyLocationSites>;
  epJobVacancyPositionNames?: Maybe<EpJobVacancyPositionNames>;
  epJobVacancyRequirementCategorySubMultiselectDropdown?: Maybe<EpJobVacancyRequirementCategorySubMultiselectDropdowns>;
  epJobVacancyScreeningQuestions?: Maybe<EpScreeningQuestions>;
  epJobVacancyScreeningQuestionsFilter?: Maybe<
    Array<EpScreeningQuestionsFilter>
  >;
  /** employer */
  epJobVacancySummary?: Maybe<EpJobVacancySummary>;
  epJobVacancyTierCategory: EpJobVacancyTierCategoryResult;
  epJobVacancyTypeDropdown?: Maybe<EpJobVacancyTypes>;
  epJobVacancyV2?: Maybe<EpJobVacancyV2Result>;
  epLocationAreas: EpLocationAreas;
  epLocationCities?: Maybe<Array<Maybe<EpLocation>>>;
  epLocationDetail: EpLocation;
  epLocationDistrics?: Maybe<Array<Maybe<EpLocation>>>;
  epLocationProvinces?: Maybe<Array<Maybe<EpLocation>>>;
  epLocationSearch?: Maybe<EpLocations>;
  epMenuTabs?: Maybe<Array<Maybe<EpMenuTab>>>;
  epMenus?: Maybe<Array<Maybe<EpMenu>>>;
  epOfferingPackages?: Maybe<EpOfferingPackages>;
  epOpsPing?: Maybe<EpPingResponse>;
  epPackageFeatures?: Maybe<EpPackageFeatures>;
  epPing?: Maybe<EpPingResponse>;
  epScreeningQuestions?: Maybe<EpScreeningQuestions>;
  epSubscriptionPlanStatusDetail: Array<Maybe<EpSubscriptionPlanStatusDetail>>;
  epUser?: Maybe<EpUser>;
  epUserConfig?: Maybe<EpUserConfig>;
  epUserConfigEmailNotificationIntervalDropdown: Array<EpUserConfigEmailNotificationIntervalDropdown>;
  epUserCv?: Maybe<EpUserCv>;
  epUserProfile?: Maybe<EpUserProfile>;
  epUserSkills: EpUserSkills;
  epUserStatus?: Maybe<EpUserStatus>;
  epUserSubscriptionStatus: EpUserSubscriptionStatus;
  epUserSubscriptionTypes: EpUserSubscriptionTypes;
  epUsers: Array<EpUser>;
  epValidateHighlightPrompt?: Maybe<Scalars['Boolean']['output']>;
  epValidateInterviewSessionName?: Maybe<EpValidateInterviewSessionName>;
  epVerifyCode?: Maybe<EpAuthResponse>;
  epVerifyJobVacancyCandidateInvitation: EpInviteJobVacancyCandidateVerification;
  epVerifySelfSignUpCode: EpAuthResponse;
  exam?: Maybe<Exam>;
  examDisplayCategories?: Maybe<Array<Maybe<ExamDisplayCategory>>>;
  examResult?: Maybe<ExamResult>;
  examSkusV2?: Maybe<ExamSummarySku>;
  examTryOutV3?: Maybe<ExamTryout2>;
  experience?: Maybe<Experience>;
  experienceJobFunctions?: Maybe<ExperienceJobFunctions>;
  feed?: Maybe<Feed>;
  feedComment?: Maybe<FeedComment>;
  feedCommentSubs?: Maybe<FeedCommentSubs>;
  feedCommentsV2?: Maybe<FeedComments>;
  feedPollDetailIsVoted?: Maybe<Array<Maybe<FeedPollDetail>>>;
  feedQuizDetailIsAnswered: Array<FeedQuizDetail>;
  feedReportCategories?: Maybe<Array<Maybe<FeedReportCategory>>>;
  feedbackIsAbleToSubmit: Scalars['Boolean']['output'];
  feedsV4?: Maybe<Feeds>;
  freeTrialEligibility: Scalars['Boolean']['output'];
  freeTrialQuota: Scalars['Int']['output'];
  generatedJobDescriptions?: Maybe<GeneratedJobDescriptions>;
  globalConfig?: Maybe<GlobalConfig>;
  /** to get list all Guideline and search */
  guidelines?: Maybe<GuidelinePaging>;
  hello?: Maybe<Scalars['String']['output']>;
  helloCommunity: Scalars['String']['output'];
  helloCommunityDelay: Scalars['String']['output'];
  helloDMS: Scalars['String']['output'];
  helloFeedback: Scalars['String']['output'];
  helloJAS: Scalars['String']['output'];
  helloKLEP: Scalars['String']['output'];
  helloLms: Scalars['String']['output'];
  helloNotification: PingResponse;
  helloSMS: Scalars['String']['output'];
  helloUMS: Scalars['String']['output'];
  helloUnification: Scalars['String']['output'];
  helloUnificationGrpc: Scalars['String']['output'];
  helloUnificationLogin: Scalars['String']['output'];
  helloUnificationSqs: Scalars['String']['output'];
  helloVoyager?: Maybe<Scalars['String']['output']>;
  homeCategories?: Maybe<Array<Maybe<HomeCategory>>>;
  homeCategoriesV2?: Maybe<Array<Maybe<HomeCategory>>>;
  homeCategoriesV3?: Maybe<Array<HomeCategoryV2>>;
  homeCategoryDetails?: Maybe<Array<Maybe<HomeCategoryDetail>>>;
  homeCategoryDetailsView?: Maybe<Array<Maybe<HomeCategoryDetail>>>;
  homeCategoryDetailsViewV2?: Maybe<Array<HomeCategoryDetailV2>>;
  homeCategoryRecommendation?: Maybe<Array<Maybe<HomeCategory>>>;
  homeCategoryRecommendationV2?: Maybe<Array<HomeCategoryV2>>;
  homeCommunity?: Maybe<HomeCommunity>;
  homeCommunityGroupsCoachmarkRecommendation: Array<CommunityGroup>;
  homeFilterSearchRecommendation?: Maybe<HomeFilterSearchRecommendation>;
  homeFiltersAndCategory?: Maybe<HomeFilterAndCategory>;
  homeFiltersAndCategoryV2?: Maybe<HomeFilterAndCategory>;
  homeFooter?: Maybe<JobListerFooterResponse>;
  homeProfileCompleteness?: Maybe<HomeProfileCompleteness>;
  homeProfileCompletenessV2?: Maybe<HomeProfileCompletenessV2>;
  /** @deprecated move to homeViewV2 */
  homeView?: Maybe<HomeView>;
  homeViewV2?: Maybe<HomeViewV2>;
  homepageInterviewInformation?: Maybe<HomepageInterviewInformation>;
  identity?: Maybe<Scalars['String']['output']>;
  interviewDeclineReasons?: Maybe<InterviewDeclineReasons>;
  interviewDetail?: Maybe<InterviewDetailResponse>;
  interviewRescheduleReason?: Maybe<Array<InterviewRescheduleReason>>;
  invitationDeclineReasons?: Maybe<Array<InvitationDeclineReason>>;
  invitationDetail?: Maybe<InvitationResult>;
  invitations?: Maybe<Array<Invitation>>;
  isAbleToSubmitAbandonmentReason: Scalars['Boolean']['output'];
  isAppliedBySlug?: Maybe<JobApplicationByVacancy>;
  jobApplication?: Maybe<JobApplication>;
  jobApplicationFeedbackStatus?: Maybe<Scalars['Boolean']['output']>;
  jobApplicationFeedbackTemplate?: Maybe<ApplicationFeedbackTemplate>;
  jobApplicationFilter?: Maybe<Array<Maybe<JobApplicationFilter>>>;
  jobApplicationFilterV2: JobApplicationFilter;
  jobApplicationFilterV3?: Maybe<Array<FilterType>>;
  jobApplicationLabelDescription?: Maybe<Array<ApplicationLabel>>;
  jobApplicationMessages?: Maybe<JobApplicationMessages>;
  jobApplicationOpenStatus?: Maybe<Scalars['Boolean']['output']>;
  jobApplicationUserFeedbackStatus?: Maybe<UserFeedbackStatus>;
  jobApplications?: Maybe<JobApplications>;
  jobApplicationsV2?: Maybe<JobApplications>;
  jobListingFooterCategory?: Maybe<JobListerFooterResponse>;
  jobListingFooterEducationLevel?: Maybe<JobListerFooterResponse>;
  jobListingFooterGeneric?: Maybe<JobListerFooterResponse>;
  jobListingFooterIndustry?: Maybe<JobListerFooterResponse>;
  jobListingFooterJobType?: Maybe<JobListerFooterResponse>;
  jobListingFooterProvinceCity?: Maybe<JobListerFooterResponse>;
  jobListingFooterRecommendation?: Maybe<JobListerFooterResponse>;
  jobListingFooterSpecializationLocation?: Maybe<JobListerFooterResponse>;
  jobSpecializationRoles?: Maybe<JobSpecializationRoles>;
  jobVacancyFilterByCode?: Maybe<JobVacancyFilter>;
  jobVacancyFilterById?: Maybe<JobVacancyFilter>;
  jobVacancyFunctionsV2?: Maybe<JobVacancyFunctions>;
  lastNotificationStatus?: Maybe<NotificationStatus>;
  locationAreas: LocationAreas;
  locationBySlug?: Maybe<LocationBySlug>;
  locationCities?: Maybe<Array<Maybe<Location>>>;
  locationDistricts?: Maybe<Array<Maybe<Location>>>;
  locationFooter?: Maybe<LocationFooter>;
  locationProvinces?: Maybe<Array<Maybe<Location>>>;
  marketingBanners?: Maybe<Array<Banner>>;
  mediaFile?: Maybe<MediaFile>;
  mediaHello?: Maybe<Scalars['String']['output']>;
  mediaImage?: Maybe<MediaImage>;
  mediaVideo?: Maybe<MediaVideo>;
  mission?: Maybe<Mission>;
  missionClaim: Array<Maybe<MissionClaimForm>>;
  notificationBadge?: Maybe<NotificationBadge>;
  notificationBadgeDomain?: Maybe<Array<Maybe<NotificationBadgeDomain>>>;
  notificationDomains?: Maybe<Array<Maybe<NotificationType>>>;
  notificationDomainsV2?: Maybe<Array<Maybe<NotificationType>>>;
  notificationHistoriesV2?: Maybe<NotificationHistories>;
  notificationHistoriesV3?: Maybe<NotificationHistories>;
  opsApplicationEmploymentTypeDropdown?: Maybe<Array<Maybe<KeyValue>>>;
  opsApplicationFollowUpStatus?: Maybe<OpsFollowUpApplication>;
  opsApplicationLocationDropdown?: Maybe<Array<Maybe<KeyValue>>>;
  opsApplicationStatusDropdown?: Maybe<Array<Maybe<KeyValue>>>;
  opsBannerPlatformDropdown?: Maybe<Array<KeyValue>>;
  opsBannerTypeDropDown?: Maybe<Array<Maybe<KeyValue>>>;
  opsBill?: Maybe<OpsBill>;
  opsBillConfirmation?: Maybe<OpsBillConfirmation>;
  opsBillConfirmations?: Maybe<OpsBillConfirmations>;
  opsBills?: Maybe<OpsBills>;
  opsBoards?: Maybe<Array<Maybe<OpsCommunityBoard>>>;
  opsBulkFreeClassTemplate?: Maybe<Scalars['String']['output']>;
  opsCategories?: Maybe<OpsCategories>;
  opsCategory?: Maybe<OpsCategory>;
  opsCategoryDropdown?: Maybe<Array<Maybe<OpsCategory>>>;
  opsCategoryMain?: Maybe<OpsCategoryMain>;
  opsCategoryMainDropDown?: Maybe<Array<Maybe<OpsCategoryMain>>>;
  opsCategoryMains?: Maybe<OpsCategoryMains>;
  opsCategorySubDropDown?: Maybe<Array<Maybe<OpsCategorySub>>>;
  opsCategorySubs?: Maybe<OpsCategorySubs>;
  opsCheckToken?: Maybe<Scalars['String']['output']>;
  opsClassroom?: Maybe<OpsClassroom>;
  opsClassroomUploadSummaries?: Maybe<OpsClassroomUploadSummaries>;
  opsClassroomUploadSummary?: Maybe<OpsClassroomUploadSummary>;
  opsClassroomUser?: Maybe<OpsClassroomUser>;
  opsClassroomUserDetail?: Maybe<OpsClassroomUserDetail>;
  opsClassroomUserDetails?: Maybe<OpsClassroomUserDetails>;
  opsClassroomUserNewSessions?: Maybe<Array<Maybe<OpsClassroomUserDetail>>>;
  opsClassroomUserSessionSummary?: Maybe<OpsClassroomUserSessionSummary>;
  opsClassroomUsers?: Maybe<OpsClassroomUsers>;
  opsClassrooms?: Maybe<OpsClassrooms>;
  opsCommentReportSummaries?: Maybe<OpsCommentReportSummaries>;
  opsCommentReportSummary?: Maybe<OpsCommentReportSummary>;
  opsCommunityBanCategories: Array<OpsCommunityBanCategory>;
  opsCommunityGroup?: Maybe<OpsCommunityGroup>;
  opsCommunityGroupDropDown?: Maybe<Array<Maybe<OpsCommunityGroup>>>;
  opsCommunityGroups?: Maybe<OpsCommunityGroups>;
  opsCommunityMemberRoles?: Maybe<Array<Maybe<KeyValue>>>;
  opsCommunityMemberUserReport?: Maybe<OpsCommunityMemberUserReport>;
  opsCommunityMemberUserReportSummaries?: Maybe<OpsCommunityMemberUserReportSummaries>;
  opsCommunityMembership?: Maybe<OpsCommunityMembership>;
  opsCommunityMemberships?: Maybe<OpsCommunityMemberships>;
  opsCommunityVideoCategories: Array<OpsCommunityVideoCategory>;
  opsCommunityVideoV2?: Maybe<OpsCommunityVideoV2>;
  opsCommunityVideoV2UserReportSummaries?: Maybe<OpsCommunityVideoV2UserReportSummaries>;
  opsCommunityVideoV2UserReports?: Maybe<OpsCommunityVideoV2UserReports>;
  opsCommunityVideosV2?: Maybe<OpsCommunityVideosV2>;
  opsCompanyDetailVerifiedOrPending?: Maybe<OpsCompanyDetailVerifiedOrPendingResult>;
  opsCompanyPackages?: Maybe<OpsCompanyPackages>;
  opsCompanyPremiumDetail?: Maybe<OpsCompanyPremiumDetailResult>;
  opsCompanyPremiums?: Maybe<OpsCompanyPremiums>;
  opsCompanyTypeDropdown?: Maybe<Array<OpsCompanyTypeDropDown>>;
  opsCompanyVideo?: Maybe<OpsCompanyVideo>;
  opsCompanyVideos?: Maybe<OpsCompanyVideos>;
  opsContentActivationLogs?: Maybe<OpsContentActivationLogs>;
  opsContentExamPackageSummaries?: Maybe<OpsContentExamPackageSummaries>;
  opsContentExamPackages?: Maybe<OpsContentExamPackages>;
  opsContentIsPublishedDropdown: Array<OpsContentIsPublishedDropdown>;
  opsContentSkus: OpsContentSkus;
  opsContentTypeDropdown: Array<OpsContentTypeDropdown>;
  opsContents: OpsContents;
  opsCountries?: Maybe<OpsCountries>;
  opsCountry?: Maybe<OpsCountry>;
  opsCsClassDropDown?: Maybe<Array<Maybe<OpsCsClass>>>;
  opsCsPackageContent?: Maybe<OpsCsPackageDetailContentSummaries>;
  opsCsPackagesContent?: Maybe<OpsCsPackages>;
  opsCurrencies?: Maybe<OpsCurrencies>;
  opsDashboard?: Maybe<OpsDashboard>;
  opsEducationInstitutions?: Maybe<OpsEducationInstitutions>;
  opsEducationLevels?: Maybe<Array<Maybe<KeyValue>>>;
  opsEducationPrograms?: Maybe<OpsEducationPrograms>;
  opsEmploymentTypes?: Maybe<Array<Maybe<KeyValue>>>;
  opsExam?: Maybe<OpsExam>;
  opsExamDisplayCategories?: Maybe<OpsExamDisplayCategories>;
  opsExamDisplayCategoryDropDown?: Maybe<Array<Maybe<OpsExamDisplayCategory>>>;
  opsExamDisplayCategorySubDropDown?: Maybe<
    Array<Maybe<OpsExamDisplayCategorySub>>
  >;
  opsExamDisplayCategorySubs?: Maybe<OpsExamDisplayCategorySubs>;
  opsExamDraft?: Maybe<OpsExamDraft>;
  opsExamDrafts?: Maybe<OpsExamDrafts>;
  opsExamDropDown?: Maybe<Array<Maybe<OpsExam>>>;
  opsExamFreeTrial?: Maybe<OpsExamFreeTrial>;
  opsExamFreeTrials?: Maybe<OpsExamFreeTrials>;
  opsExamPackage?: Maybe<OpsExamPackage>;
  opsExamPackageByOrderNo?: Maybe<OpsExamPackage>;
  opsExamPackageDisplay?: Maybe<OpsExamPackageDisplay>;
  opsExamPackageDisplays?: Maybe<OpsExamPackageDisplays>;
  opsExamPackageDropDown?: Maybe<Array<Maybe<OpsExamPackage>>>;
  opsExamPackageUser?: Maybe<OpsExamPackageUser>;
  opsExamPackageUsers?: Maybe<OpsExamPackageUsers>;
  opsExamPackages?: Maybe<OpsExamPackages>;
  opsExamQuestion?: Maybe<OpsExamQuestion>;
  opsExamQuestions?: Maybe<OpsExamQuestions>;
  opsExamStructure?: Maybe<OpsExamComposition>;
  opsExamUserResult?: Maybe<OpsExamUserResult>;
  opsExamUserResults?: Maybe<OpsExamUserResults>;
  opsExamUserTotal?: Maybe<Scalars['Int']['output']>;
  opsExamUsers?: Maybe<OpsExamUsers>;
  opsExams?: Maybe<OpsExams>;
  opsExperienceJobFunctions?: Maybe<OpsExperienceJobFunctions>;
  opsExperienceYears?: Maybe<Array<Maybe<KeyValue>>>;
  opsFeed?: Maybe<OpsFeed>;
  opsFeedComment?: Maybe<OpsFeedComment>;
  opsFeedComments?: Maybe<OpsFeedComments>;
  opsFeedReportSummaries?: Maybe<OpsFeedReportSummaries>;
  opsFeedReportSummary?: Maybe<OpsFeedReportSummary>;
  opsFeeds?: Maybe<OpsFeeds>;
  opsGenerateQuestions?: Maybe<OpsQuestions>;
  opsGetMenu?: Maybe<OpsMenuList>;
  /** to get Guideline detail */
  opsGuidelineDetail?: Maybe<OpsGuideline>;
  /** to get GuidelineType dropdown */
  opsGuidelineTypeDropdown?: Maybe<Array<Maybe<OpsGuidelineType>>>;
  /** to get list all Guideline and search */
  opsGuidelines?: Maybe<OpsGuidelinePaging>;
  opsHello?: Maybe<Scalars['String']['output']>;
  opsHelloThere?: Maybe<Scalars['String']['output']>;
  opsHelloWait?: Maybe<Scalars['String']['output']>;
  opsHighlightedJob?: Maybe<OpsHighlightedJob>;
  opsHighlightedJobs?: Maybe<OpsHighlightedJobs>;
  opsJobApplications?: Maybe<OpsJobApplications>;
  opsJobCompanies?: Maybe<OpsJobCompanies>;
  opsJobCompany?: Maybe<OpsJobCompany>;
  opsJobCompanyAccManager?: Maybe<OpsJobCompanyAccManager>;
  opsJobCompanyAccManagerCountryCodeDropdown?: Maybe<Array<Maybe<KeyValue>>>;
  opsJobCompanyAccManagerStatusDropdown: Array<KeyValue>;
  opsJobCompanyAccManagerTeamDropdown: Array<KeyValue>;
  opsJobCompanyAccManagers: OpsJobCompanyAccManagers;
  opsJobCompanyDetailLogs?: Maybe<OpsDetailLogs>;
  opsJobCompanyIndustries?: Maybe<OpsJobCompanyIndustries>;
  opsJobCompanyLegalDocumentDropdown?: Maybe<Array<Maybe<KeyValue>>>;
  opsJobCompanyPremium?: Maybe<OpsJobCompanyPremium>;
  opsJobCompanyPremiumRegisterSearch?: Maybe<OpsJobCompanyPremiumRegisterSearchResult>;
  opsJobCompanyPremiumRequest?: Maybe<OpsJobCompanyPremiumRequest>;
  opsJobCompanyPremiumRequests: OpsJobCompanyPremiumRequests;
  opsJobCompanyPremiums: OpsJobCompanyPremiums;
  opsJobCompanySearch?: Maybe<OpsJobCompanySearchResult>;
  opsJobCompanySizeDropdown?: Maybe<Array<Maybe<OpsJobCompanySize>>>;
  opsJobCompanySubscriptionPackages: OpsJobCompanySubscriptionPackages;
  opsJobCompanyTypeDropdown: Array<KeyValue>;
  opsJobCompanyTypes: Array<OpsJobCompanyType>;
  opsJobCompanyUserValidation?: Maybe<Scalars['String']['output']>;
  opsJobCompanyVerification?: Maybe<OpsJobCompanyVerificationResult>;
  opsJobCompanyVerificationCode?: Maybe<Scalars['String']['output']>;
  opsJobCompanyVerificationStatusDropdown?: Maybe<
    Array<OpsJobCompanyVerificationStatus>
  >;
  opsJobCompanyVerifications?: Maybe<OpsJobCompanyVerifications>;
  opsJobKlepCompanies?: Maybe<OpsJobKlepCompanies>;
  opsJobKlepCompanyDraft?: Maybe<OpsJobCompany>;
  opsJobKlepCompanyDrafts?: Maybe<OpsJobCompanies>;
  opsJobKlepCompanyHistoryActions?: Maybe<OpsJobKlepCompanyHistoryActions>;
  opsJobKlepCompanyUserRegistries?: Maybe<OpsJobKlepCompanyUserRegistriesOtp>;
  opsJobKlepCompanyUserRegistryCopyLink?: Maybe<Scalars['String']['output']>;
  opsJobKlepCompanyUserRoleDropdown: Array<OpsJobKlepCompanyUserRoleDropdownItem>;
  opsJobSkillRequirement?: Maybe<OpsJobSkillRequirement>;
  opsJobSkillRequirements?: Maybe<OpsJobSkillRequirements>;
  opsJobSpecializationRoles?: Maybe<JobSpecializationRoles>;
  opsJobSpecializationRolesByKeyword?: Maybe<OpsJobSpecializationRoles>;
  opsJobSpecializations?: Maybe<JobSpecializations>;
  opsJobVacancies?: Maybe<OpsJobVacancies>;
  opsJobVacancy?: Maybe<OpsJobVacancyV2>;
  opsJobVacancyByCode?: Maybe<OpsJobVacancyByCode>;
  opsJobVacancyCategoryDropdown?: Maybe<
    Array<Maybe<OpsJobVacancyRequirementCategory>>
  >;
  opsJobVacancyCategorySub?: Maybe<OpsJobVacancyRequirementCategorySub>;
  opsJobVacancyCategorySubDropdown?: Maybe<
    Array<Maybe<OpsJobVacancyRequirementCategorySub>>
  >;
  opsJobVacancyFunctionDropDowns?: Maybe<Array<Maybe<OpsJobVacancyFunction>>>;
  opsJobVacancyFunctionRequirements?: Maybe<OpsJobVacancyFunctionRequirements>;
  opsJobVacancyFunctions?: Maybe<OpsJobVacancyFunctions>;
  opsJobVacancyHomeSection?: Maybe<Array<Maybe<OpsHomeVacancySection>>>;
  opsJobVacancyLocationSiteDropdown?: Maybe<OpsJobVacancyLocationSites>;
  opsJobVacancyReport: OpsJobVacancyReport;
  opsJobVacancyReportCategoryDropdown: OpsJobVacancyReportCategories;
  opsJobVacancyReportKlepStatusDropdown: Array<KeyValue>;
  opsJobVacancyReportTeamConfirmationStatusDropdown: Array<KeyValue>;
  opsJobVacancyReportUserConfirmationStatusDropdown: Array<KeyValue>;
  opsJobVacancyReports?: Maybe<OpsJobVacancyReports>;
  opsJobVacancyTemplate?: Maybe<Scalars['String']['output']>;
  /** @deprecated drop */
  opsLive?: Maybe<OpsLive>;
  /** @deprecated drop */
  opsLivePublish?: Maybe<OpsLivePublish>;
  /** @deprecated drop */
  opsLiveUser?: Maybe<OpsLiveUser>;
  /** @deprecated drop */
  opsLiveUserDraft?: Maybe<OpsLiveUserDraft>;
  /** @deprecated drop */
  opsLiveUserDrafts?: Maybe<OpsLiveUserDrafts>;
  /** @deprecated drop */
  opsLiveUserTotal?: Maybe<OpsLiveUserTotal>;
  /** @deprecated drop */
  opsLiveUserTotals?: Maybe<OpsLiveUserTotals>;
  /** @deprecated drop */
  opsLiveUsers?: Maybe<OpsLiveUsers>;
  /** @deprecated drop */
  opsLives?: Maybe<OpsLives>;
  opsLocationCities?: Maybe<Array<Maybe<Location>>>;
  opsLocationConfig?: Maybe<OpsLocationConfig>;
  opsLocationDistricts?: Maybe<Array<Maybe<Location>>>;
  opsLocationLevelOption?: Maybe<OpsLocationLevelOptions>;
  opsLocationProvinces?: Maybe<Array<Maybe<Location>>>;
  opsLocationSearchByName?: Maybe<Locations>;
  opsMarketingBanner?: Maybe<OpsMarketingBanner>;
  opsMarketingBanners?: Maybe<OpsMarketingBanners>;
  opsMissions?: Maybe<OpsMissions>;
  opsNews?: Maybe<OpsNewsPaging>;
  opsNewsDetail?: Maybe<OpsNews>;
  opsNotification?: Maybe<OpsMrNotification>;
  opsNotificationMessageCategories: Array<KeyValue>;
  opsNotificationMessageEventGoals: OpsNotificationMessageEventGoals;
  opsNotificationMessageLabels: Array<KeyValue>;
  opsNotificationMessageNameAvailability: Scalars['Boolean']['output'];
  opsNotificationMessagePlatforms: Array<KeyValue>;
  opsNotificationMessageRedirections: Array<KeyValue>;
  opsNotificationMessageSchedules: Array<KeyValue>;
  opsNotificationMessageStatuses: Array<KeyValue>;
  opsNotificationMessageTargetUsers: Array<KeyValue>;
  opsNotificationMessages?: Maybe<OpsNotificationMessages>;
  opsNotificationTarget: NotificationTargets;
  opsNotifications?: Maybe<OpsMrNotifications>;
  opsOfflineProfile?: Maybe<OpsOfflineProfile>;
  opsOfflineProfileDashboard?: Maybe<OpsOfflineProfileDashboard>;
  opsOrder?: Maybe<OpsOrder>;
  /** @deprecated drop */
  opsOrderInquiries?: Maybe<OpsOrderInquiries>;
  /** @deprecated drop */
  opsOrderInquiry?: Maybe<OpsOrderInquiry>;
  /** @deprecated drop */
  opsOrderInquiryProceed?: Maybe<OpsOrderInquiry>;
  opsOrders?: Maybe<OpsOrders>;
  opsPackage?: Maybe<OpsPackage>;
  opsPackageDetail?: Maybe<OpsPackageV2>;
  opsPackageDropDown?: Maybe<Array<Maybe<OpsPackage>>>;
  opsPackageTypeDropDown?: Maybe<Array<Maybe<KeyValue>>>;
  opsPackageTypesDropdown?: Maybe<Array<OpsPackageTypeDropdown>>;
  opsPackages?: Maybe<OpsPackages>;
  opsPackagesDropdown?: Maybe<Array<OpsPackageDropdown>>;
  opsParams?: Maybe<OpsParams>;
  opsPaymentMethod?: Maybe<OpsPaymentMethod>;
  opsPaymentMethods?: Maybe<Array<Maybe<OpsPaymentMethod>>>;
  opsPinUnpinCommunityVideoV2Validation: OpsCommunityVideoV2PinUnpinValidation;
  opsProfileAssignments?: Maybe<OpsProfileAssignments>;
  opsProfileGender?: Maybe<Array<Maybe<KeyValue>>>;
  opsQuestion?: Maybe<OpsQuestion>;
  opsQuestionDraft?: Maybe<OpsQuestionDraft>;
  opsQuestionDrafts?: Maybe<OpsQuestionDrafts>;
  opsQuestions?: Maybe<OpsQuestions>;
  opsReRegisterSummary?: Maybe<OpsCsPackageRegisterSummary>;
  opsRedemptions: OpsRedemptions;
  opsReferralProgram?: Maybe<OpsReferralProgram>;
  opsReferralPrograms?: Maybe<OpsReferralPrograms>;
  opsSegment?: Maybe<OpsSegmentSummary>;
  opsSegmentDivisions: OpsSegmentDivisions;
  opsSegmentJobDescriptionLengths: OpsSegmentJobDescriptionLengths;
  opsSegmentNameAvailability?: Maybe<OpsSegmentNameAvailabilityResult>;
  opsSegmentSubscriptionStatuses: OpsSegmentSubscriptionStatuses;
  opsSegmentTypes: OpsSegmentTypes;
  opsSegmentUserUpdateCount?: Maybe<OpsSegmentUserUpdateCountResult>;
  opsSegments?: Maybe<OpsSegments>;
  opsSession?: Maybe<OpsSession>;
  opsSessionParent?: Maybe<OpsSessions>;
  opsSessions?: Maybe<OpsSessions>;
  opsSkills?: Maybe<OpsUserSkills>;
  opsSkillsV2?: Maybe<OpsSkills>;
  opsSku?: Maybe<OpsSku>;
  opsSkuCategories?: Maybe<OpsSkuCategories>;
  opsSkuCategory?: Maybe<OpsSkuCategory>;
  opsSkuCategoryComponent?: Maybe<OpsSkuCategoryComponent>;
  opsSkuCategoryComponentDropDown?: Maybe<
    Array<Maybe<OpsSkuCategoryComponent>>
  >;
  opsSkuCategoryComponents?: Maybe<OpsSkuCategoryComponents>;
  opsSkuCategoryDropDown?: Maybe<Array<Maybe<OpsSkuCategory>>>;
  opsSkuCategoryForm?: Maybe<Array<Maybe<OpsSkuCategory>>>;
  opsSkus?: Maybe<OpsSkus>;
  opsSubmission?: Maybe<OpsSubmission>;
  opsSubmissions: OpsSubmissions;
  opsSupportLinkCategory?: Maybe<Array<Maybe<OpsSupportLinkCategory>>>;
  opsTeacher?: Maybe<OpsTeacher>;
  opsTeachers?: Maybe<OpsTeachers>;
  opsTestDropDown?: Maybe<Array<Maybe<OpsMsTest>>>;
  opsTestSubDropDown?: Maybe<Array<Maybe<OpsMsTestSub>>>;
  opsTotalUsersBySessionId?: Maybe<Scalars['Int']['output']>;
  opsVacancyHighlightSection?: Maybe<OpsVacancyHighlightSection>;
  opsVacancyHighlightSections?: Maybe<OpsVacancyHighlightSections>;
  opsVerifySkills?: Maybe<UploadVerifiedSkillsResponse>;
  opsVideo?: Maybe<OpsVideo>;
  opsVideos?: Maybe<OpsVideos>;
  opsVoucher?: Maybe<OpsVoucher>;
  opsVouchers?: Maybe<OpsVouchers>;
  opsWalletRedemption?: Maybe<OpsWalletRedemption>;
  opsWalletRedemptions?: Maybe<OpsWalletRedemptions>;
  order?: Maybe<Order>;
  orderSubmitSummary?: Maybe<OrderSubmitSummary>;
  orders?: Maybe<Orders>;
  paymentInstructions?: Maybe<Array<Maybe<PaymentInstruction>>>;
  paymentMethods?: Maybe<Array<PaymentMethodV3>>;
  paymentMethodsV2?: Maybe<Array<Maybe<PaymentMethodGroup>>>;
  profile?: Maybe<Profile>;
  profileBySlug?: Maybe<ProfileHistory>;
  profileCompletenessCheckList?: Maybe<Array<ProfileCompletenessCheckList>>;
  profileHistory?: Maybe<ProfileHistory>;
  profileStrength?: Maybe<ProfileStrength>;
  questionFeedbackCategories?: Maybe<Array<Maybe<QuestionFeedbackCategory>>>;
  questions?: Maybe<Array<Maybe<Question>>>;
  referralHome?: Maybe<ReferralHome>;
  referralLink?: Maybe<CommunityReferralInfo>;
  session?: Maybe<Session>;
  similarJobVacancyV2?: Maybe<Array<Maybe<Vacancy>>>;
  skills?: Maybe<UserSkills>;
  skillsV2?: Maybe<Skills>;
  skuByTypeCategory?: Maybe<SkuGroups>;
  skuTestimonies?: Maybe<SkuTestimonies>;
  spSubscriptionTypes?: Maybe<Array<SpSubscriptionType>>;
  supportLink?: Maybe<SupportLink>;
  supportLinkCategoryDropDown?: Maybe<Array<Maybe<SupportLinkCategory>>>;
  topSectionV2?: Maybe<Array<Maybe<Vacancy>>>;
  topSectionsV2?: Maybe<TopSectionsV2>;
  topSectionsV3?: Maybe<TopSectionsV2>;
  topVacancies?: Maybe<Array<Maybe<Vacancy>>>;
  tryOutHomeUserV2?: Maybe<TryOutHomeV3>;
  tryOutHomeV3?: Maybe<TryOutHomeV3>;
  userCommunities?: Maybe<CommunityGroupsV2>;
  userInterests?: Maybe<UserInterests>;
  userLocationDropdown?: Maybe<Array<Maybe<UserLocationDropdown>>>;
  userLocationPreference?: Maybe<LocationOptions>;
  userOrders?: Maybe<UserOrders>;
  userPreference?: Maybe<Array<Maybe<UserPreference>>>;
  username?: Maybe<Scalars['String']['output']>;
  usernameAvailability?: Maybe<Scalars['Boolean']['output']>;
  vacanciesV2?: Maybe<Vacancies>;
  vacanciesV3?: Maybe<Vacancies>;
  /** consumer */
  vacancy?: Maybe<Vacancy>;
  vacancyBookmarked?: Maybe<Vacancies>;
  vacancyByCompanySlug?: Maybe<Vacancies>;
  vacancyByPreference?: Maybe<Vacancies>;
  vacancyByPreferenceV2?: Maybe<Vacancies>;
  vacancyBySlug?: Maybe<Vacancy>;
  vacancyContactInfo?: Maybe<VacancyContactInformation>;
  vacancyHistoryById?: Maybe<Vacancy>;
  vacancyHistoryBySlug?: Maybe<Vacancy>;
  vacancyInsightBatch: Array<VacancyInsightView>;
  vacancyInsightBatchV2: Array<VacancyInsightViewV2>;
  vacancyInsightByVacancyId?: Maybe<VacancyInsight>;
  vacancyInsightByVacancyIdV2?: Maybe<VacancyInsightV2>;
  vacancyLocationCities?: Maybe<Array<Maybe<Location>>>;
  vacancyLocationDistricts?: Maybe<Array<Maybe<Location>>>;
  vacancyLocationProvinces?: Maybe<Array<Maybe<Location>>>;
  vacancyPositionRecommendations?: Maybe<Array<VacancyPosition>>;
  vacancyPositions?: Maybe<VacancyPositions>;
  vacancyReportCategories?: Maybe<Array<Maybe<VacancyReportCategory>>>;
  vacancyScreening?: Maybe<Array<Maybe<VacancyRequirement>>>;
  vacancyScreeningV2?: Maybe<Array<VacancyScreeningGroup>>;
  vacancySectionDetailsByCodeV3?: Maybe<Vacancies>;
  vacancySectionDetailsByCodeV4?: Maybe<Vacancies>;
  vacancySectionDetailsByHomeSectionId?: Maybe<VacancySectionDetailsDeeplink>;
  vacancySectionDetailsByHomeSectionIdV2?: Maybe<VacancySectionDetailsDeeplink>;
  vacancySectionDetailsByHomeSectionIdV3?: Maybe<Vacancies>;
  vacancySectionDetailsCountByCode?: Maybe<Scalars['BigInt']['output']>;
  vacancySectionDetailsCountByCodeV2?: Maybe<Scalars['BigInt']['output']>;
  vacancySectionDetailsCountById?: Maybe<Scalars['BigInt']['output']>;
  /** @deprecated move to vacancySectionDetailsHighlightV2 */
  vacancySectionDetailsHighlight?: Maybe<
    Array<Maybe<HomeVacancySectionDetail>>
  >;
  vacancySectionDetailsHighlightV2?: Maybe<Array<Maybe<Vacancy>>>;
  vacancySectionDetailsHighlightV3?: Maybe<Array<Maybe<Vacancy>>>;
  vacancySections?: Maybe<Array<Maybe<HomeVacancySection>>>;
  vacancySectionsV2?: Maybe<Array<HomeVacancySection>>;
  validateMembership?: Maybe<CommunityMembership>;
  verifyProfile?: Maybe<UserProfileCompleteness>;
  verifyProfileBeforeApplyV2?: Maybe<UserProfileCompleteness>;
  verifyProfileBeforeApplyV3?: Maybe<UserProfileCompletenessV2>;
  verifyProfileBeforeGenerateCv?: Maybe<Array<ProfileChecklist>>;
  verifyProfileBeforeGenerateCvV2?: Maybe<Array<ProfileChecklistV2>>;
  videoByCompanyId?: Maybe<CompanyVideo>;
  videoPreviewDetail?: Maybe<VideoPreview>;
  videoPreviews?: Maybe<VideoPreviews>;
  walletRedemptions?: Maybe<Array<Maybe<WalletRedemption>>>;
  walletTransactions?: Maybe<Array<Maybe<WalletTransaction>>>;
};

export type QueryActivityFeedsArgs = {
  filter?: InputMaybe<CommonFilter>;
};

export type QueryAppUpdateArgs = {
  versionCode: Scalars['String']['input'];
  versionName: Scalars['String']['input'];
};

export type QueryApplicationPreviewArgs = {
  uniqueId: Scalars['ID']['input'];
};

export type QueryCategoryViewArgs = {
  categoryId: Scalars['ID']['input'];
};

export type QueryClassroomArgs = {
  id: Scalars['ID']['input'];
};

export type QueryClassroomsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCommunityGroupArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCommunityGroupBoardsArgs = {
  communityId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCommunityGroupByHomeSectionIdArgs = {
  communityHomeSectionId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCommunityGroupPreviewArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCommunityGroupRecommendationArgs = {
  filter?: InputMaybe<CommonFilter>;
};

export type QueryCommunityGroupRecommendationV2Args = {
  jobVacancyId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCommunityGroupRegistrationArgs = {
  communityId: Scalars['ID']['input'];
  refereeCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCommunityGroupRegistrationV2Args = {
  communityCode?: InputMaybe<Scalars['String']['input']>;
  communityId?: InputMaybe<Scalars['ID']['input']>;
  refereeCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCommunityGroupRegistrationV3Args = {
  communityCode?: InputMaybe<Scalars['String']['input']>;
  communityId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCommunityGroupTermConditionsArgs = {
  communityId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCommunityGroupsArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCommunityGroupsV2Args = {
  filter?: InputMaybe<CommonFilter>;
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCommunityGroupsV3Args = {
  filter?: InputMaybe<CommonFilter>;
  isHideJoined?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<CommunityGroupsSortBy>;
};

export type QueryCommunityReportCategoryDetailByNameArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCommunityVideoV2Args = {
  communityVideoV2Id: Scalars['ID']['input'];
  isPinnedAllowed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCommunityVideoV2CommentsArgs = {
  communityVideoV2Id?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<CommonFilter>;
  highlightedId?: InputMaybe<Scalars['ID']['input']>;
  lastCommentId?: InputMaybe<Scalars['ID']['input']>;
  parentCommentId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCommunityVideoV2JobsArgs = {
  communityVideoV2Id: Scalars['ID']['input'];
  filter?: InputMaybe<CommonFilter>;
};

export type QueryCommunityVideoV2ReportStatusArgs = {
  communityMemberId: Scalars['ID']['input'];
  videoV2CommentId: Scalars['ID']['input'];
};

export type QueryCommunityVideosArgs = {
  communityVideoCategoryName?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
};

export type QueryCommunityVideosV2Args = {
  communityVideoCategoryId?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>>
  >;
  excludeId?: InputMaybe<Scalars['ID']['input']>;
  filter: CommonFilter;
  isBookmarked?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<CommunityVideosSortBy>;
};

export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCompanyBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryCvTemplatesArgs = {
  isIncludePremium?: InputMaybe<Scalars['Boolean']['input']>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryDeleteAccImpactContentArgs = {
  screenDensity?: InputMaybe<Scalars['String']['input']>;
};

export type QueryDeleteAccReasonContentArgs = {
  screenDensity?: InputMaybe<Scalars['String']['input']>;
};

export type QueryDisclaimerContentArgs = {
  screenDensity?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEducationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEducationInstitutionsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEducationProgramsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpCompanyAccManagerArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEpCompanyIndustriesArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpCompanyOpsArgs = {
  companyId: Scalars['ID']['input'];
};

export type QueryEpCompanyOpsSearchArgs = {
  filter: CommonFilter;
  keyword: Scalars['String']['input'];
};

export type QueryEpCompanySizesArgs = {
  filter?: InputMaybe<CommonFilter>;
};

export type QueryEpDocumentsCertificatesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryEpEducationInstitutionsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryEpEducationProgramsArgs = {
  level?: InputMaybe<EpEducationLevel>;
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryEpExperienceJobFunctionsArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpInterviewSessionApplicationsArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpInterviewSessionByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEpInterviewSessionCountArgs = {
  filter?: InputMaybe<InterviewSessionFilter>;
  vacancyId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEpInterviewSessionDropdownArgs = {
  excludeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  includePersonalAndPassedSessions?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
  vacancyId: Scalars['ID']['input'];
};

export type QueryEpInterviewSessionTabsArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEpInterviewSessionsArgs = {
  filter?: InputMaybe<InterviewSessionFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Scalars['String']['input']>;
  vacancyId: Scalars['ID']['input'];
};

export type QueryEpJobApplicationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEpJobApplicationCountArgs = {
  filter?: InputMaybe<EpJobApplicantFilterInput>;
};

export type QueryEpJobApplicationCvDownloadArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEpJobApplicationPrevNextArgs = {
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  domicile?: InputMaybe<Scalars['ID']['input']>;
  educationInstitutionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  epStatus?: InputMaybe<EpJobApplicationEpStatusEnum>;
  experienceYear?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jobFunctions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobSpecializationRoles?: InputMaybe<Array<Scalars['ID']['input']>>;
  jobVacancyId: Scalars['ID']['input'];
  lastEducationLevel?: InputMaybe<Scalars['String']['input']>;
  lastEducationLevels?: InputMaybe<Array<EpEducationLevelFilter>>;
  locations?: InputMaybe<Array<EpLocationAreaInput>>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  screeningQuestions?: InputMaybe<
    Array<EpJobApplicationScreeningQuestionInput>
  >;
  skills?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  skillsV2?: InputMaybe<Array<Scalars['ID']['input']>>;
  sort?: InputMaybe<Scalars['String']['input']>;
  tab?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpJobApplicationRejectedReasonArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEpJobApplicationScreeningArgs = {
  jobApplicantId: Scalars['ID']['input'];
  screeningQuestions?: InputMaybe<
    Array<InputMaybe<EpJobApplicationScreeningQuestionInput>>
  >;
};

export type QueryEpJobApplicationSummaryArgs = {
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  domicile?: InputMaybe<Scalars['ID']['input']>;
  educationInstitutionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  epStatus?: InputMaybe<EpJobApplicationEpStatusEnum>;
  experienceYear?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  jobFunctions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobSpecializationRoles?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastEducationLevel?: InputMaybe<Scalars['String']['input']>;
  lastEducationLevels?: InputMaybe<Array<EpEducationLevelFilter>>;
  locations?: InputMaybe<Array<EpLocationAreaInput>>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  skills?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  skillsV2?: InputMaybe<Array<Scalars['ID']['input']>>;
  sort?: InputMaybe<Scalars['String']['input']>;
  tab?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpJobApplicationsArgs = {
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  domicile?: InputMaybe<Scalars['ID']['input']>;
  educationInstitutionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  epStatus?: InputMaybe<EpJobApplicationEpStatusEnum>;
  experienceYear?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  gender?: InputMaybe<Scalars['String']['input']>;
  isRestrict?: InputMaybe<Scalars['Boolean']['input']>;
  jobFunctions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobSpecializationRoles?: InputMaybe<Array<Scalars['ID']['input']>>;
  jobVacancyId: Scalars['ID']['input'];
  lastEducationLevel?: InputMaybe<Scalars['String']['input']>;
  lastEducationLevels?: InputMaybe<Array<EpEducationLevelFilter>>;
  locations?: InputMaybe<Array<EpLocationAreaInput>>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  screeningQuestions?: InputMaybe<
    Array<EpJobApplicationScreeningQuestionInput>
  >;
  skills?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  skillsV2?: InputMaybe<Array<Scalars['ID']['input']>>;
  sort?: InputMaybe<Scalars['String']['input']>;
  tab?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpJobSkillsArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpJobSpecializationRoleSkillsArgs = {
  excludeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  jobSpecializationRoleId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
  priority?: InputMaybe<EpJobSpecializationRoleSkillsPriority>;
};

export type QueryEpJobSpecializationRolesArgs = {
  jobSpecializationId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
  positionName?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<EpJobSpecializationRolesPriority>;
};

export type QueryEpJobSpecializationsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  pagination: Pagination;
};

export type QueryEpJobVacanciesArgs = {
  cityId?: InputMaybe<Scalars['ID']['input']>;
  excludeVacancyId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<EpJobVacancyState>;
};

export type QueryEpJobVacancyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEpJobVacancyCandidateArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEpJobVacancyCandidateCountArgs = {
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  educationInstitutionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  educationLevels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  experienceYear?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  invitationStatus?: InputMaybe<Array<Scalars['String']['input']>>;
  jobFunctions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobVacancyId: Scalars['ID']['input'];
  locations?: InputMaybe<Array<EpLocationAreaInput>>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  skills?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sort?: InputMaybe<Scalars['String']['input']>;
  status: EpJobVacancyCandidateStatus;
};

export type QueryEpJobVacancyCandidatePrevNextArgs = {
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  educationInstitutionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  educationLevels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  experienceYear?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invitationStatus?: InputMaybe<Array<Scalars['String']['input']>>;
  jobFunctions?: InputMaybe<Array<Scalars['String']['input']>>;
  jobVacancyId: Scalars['ID']['input'];
  locations?: InputMaybe<Array<EpLocationAreaInput>>;
  matchingScore?: InputMaybe<Scalars['Float']['input']>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  skills?: InputMaybe<Array<Scalars['String']['input']>>;
  sort?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EpJobVacancyCandidateStatus>;
};

export type QueryEpJobVacancyCandidateSummaryArgs = {
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  educationInstitutionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  educationLevels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  experienceYear?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  invitationStatus?: InputMaybe<Array<Scalars['String']['input']>>;
  jobFunctions?: InputMaybe<Array<Scalars['String']['input']>>;
  jobVacancyId: Scalars['ID']['input'];
  locations?: InputMaybe<Array<EpLocationAreaInput>>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  skills?: InputMaybe<Array<Scalars['String']['input']>>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpJobVacancyCandidatesArgs = {
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  educationInstitutionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  educationLevels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  experienceYear?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  gender?: InputMaybe<Scalars['String']['input']>;
  invitationStatus?: InputMaybe<Array<Scalars['String']['input']>>;
  jobFunctions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobVacancyId: Scalars['ID']['input'];
  locations?: InputMaybe<Array<EpLocationAreaInput>>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  skills?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sort?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EpJobVacancyCandidateStatus>;
};

export type QueryEpJobVacancyClosedFeedbackTemplatesArgs = {
  filter?: InputMaybe<CommonFilter>;
};

export type QueryEpJobVacancyDashboardArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryEpJobVacancyDashboardSummaryListArgs = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpJobVacancyDistinctSearchArgs = {
  filter?: InputMaybe<CommonFilter>;
  positionName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpJobVacancyDraftArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEpJobVacancyDraftScreeningQuestionsArgs = {
  draftId: Scalars['ID']['input'];
};

export type QueryEpJobVacancyDraftsArgs = {
  cityId?: InputMaybe<Scalars['ID']['input']>;
  excludeDraftId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<EpJobVacancyState>;
};

export type QueryEpJobVacancyFunctionsArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpJobVacancyPositionNamesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryEpJobVacancyRequirementCategorySubMultiselectDropdownArgs = {
  categorySubName?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  jobFunctionId: Scalars['ID']['input'];
};

export type QueryEpJobVacancyScreeningQuestionsArgs = {
  jobVacancyId: Scalars['ID']['input'];
};

export type QueryEpJobVacancyScreeningQuestionsFilterArgs = {
  jobVacancyId: Scalars['ID']['input'];
};

export type QueryEpJobVacancySummaryArgs = {
  cityId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpJobVacancyTierCategoryArgs = {
  cityId: Scalars['ID']['input'];
  specializationRoleId: Scalars['ID']['input'];
};

export type QueryEpJobVacancyV2Args = {
  id: Scalars['ID']['input'];
};

export type QueryEpLocationAreasArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpLocationCitiesArgs = {
  hasVacancy?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEpLocationDetailArgs = {
  locationId: Scalars['ID']['input'];
};

export type QueryEpLocationDistricsArgs = {
  hasVacancy?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEpLocationProvincesArgs = {
  hasVacancy?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpLocationSearchArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpMenuTabsArgs = {
  domain: EpMenuTabDomain;
};

export type QueryEpSubscriptionPlanStatusDetailArgs = {
  companyId: Scalars['ID']['input'];
};

export type QueryEpUserArgs = {
  registered?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryEpUserCvArgs = {
  cvFileNameForDownload?: InputMaybe<Scalars['String']['input']>;
  jobApplicationId?: InputMaybe<Scalars['ID']['input']>;
  userProfileId: Scalars['ID']['input'];
};

export type QueryEpUserProfileArgs = {
  applicationId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
  screenDensity?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpUserSkillsArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEpUserStatusArgs = {
  email: Scalars['String']['input'];
};

export type QueryEpUserSubscriptionStatusArgs = {
  companyId: Scalars['ID']['input'];
};

export type QueryEpUserSubscriptionTypesArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEpUsersArgs = {
  filter?: InputMaybe<CommonFilter>;
};

export type QueryEpValidateInterviewSessionNameArgs = {
  name: Scalars['String']['input'];
  vacancyId: Scalars['ID']['input'];
};

export type QueryEpVerifyCodeArgs = {
  code: Scalars['String']['input'];
};

export type QueryEpVerifyJobVacancyCandidateInvitationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEpVerifySelfSignUpCodeArgs = {
  code: Scalars['String']['input'];
};

export type QueryExamArgs = {
  id: Scalars['ID']['input'];
};

export type QueryExamDisplayCategoriesArgs = {
  examPackageId: Scalars['ID']['input'];
};

export type QueryExamResultArgs = {
  id: Scalars['ID']['input'];
};

export type QueryExamSkusV2Args = {
  examPackageId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type QueryExamTryOutV3Args = {
  examDisplayCategorySubId: Scalars['ID']['input'];
  examPackageId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<CommonFilter>;
};

export type QueryExperienceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryExperienceJobFunctionsArgs = {
  filter?: InputMaybe<CommonFilter>;
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFeedArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFeedCommentArgs = {
  feedCommentId: Scalars['ID']['input'];
};

export type QueryFeedCommentSubsArgs = {
  feedCommentId: Scalars['ID']['input'];
  filter?: InputMaybe<CommonFilter>;
  hideBlockedComment?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFeedCommentsV2Args = {
  feedId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<CommonFilter>;
  hideBlockedComment?: InputMaybe<Scalars['Boolean']['input']>;
  subCommentLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFeedPollDetailIsVotedArgs = {
  communityMemberId: Scalars['ID']['input'];
  pollId: Scalars['ID']['input'];
};

export type QueryFeedQuizDetailIsAnsweredArgs = {
  communityMemberId: Scalars['ID']['input'];
  quizId: Scalars['ID']['input'];
};

export type QueryFeedbackIsAbleToSubmitArgs = {
  domain: FeedbackDomain;
};

export type QueryFeedsV4Args = {
  communityId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<CommonFilter>;
  hideBlockedFeed?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFreeTrialEligibilityArgs = {
  key: FreeTrialDomain;
};

export type QueryFreeTrialQuotaArgs = {
  key: FreeTrialDomain;
};

export type QueryGeneratedJobDescriptionsArgs = {
  jobSpecializationRoleId: Scalars['ID']['input'];
  positionName: Scalars['String']['input'];
};

export type QueryGlobalConfigArgs = {
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGuidelinesArgs = {
  filter?: InputMaybe<CommonFilter>;
  guidelineTypeId?: InputMaybe<Scalars['ID']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryHelloCommunityArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryHelloCommunityDelayArgs = {
  delay: Scalars['Int']['input'];
};

export type QueryHomeCategoriesArgs = {
  screenDensity?: InputMaybe<Scalars['String']['input']>;
};

export type QueryHomeCategoriesV2Args = {
  screenDensity?: InputMaybe<Scalars['String']['input']>;
};

export type QueryHomeCategoryDetailsArgs = {
  homeCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryHomeCategoryDetailsViewArgs = {
  filter?: InputMaybe<CommonFilter>;
  screenDensity?: InputMaybe<Scalars['String']['input']>;
};

export type QueryHomeCategoryDetailsViewV2Args = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryHomeCategoryRecommendationArgs = {
  screenDensity?: InputMaybe<Scalars['String']['input']>;
};

export type QueryHomeFilterSearchRecommendationArgs = {
  screenDensity?: InputMaybe<Scalars['String']['input']>;
};

export type QueryHomeViewV2Args = {
  cityName?: InputMaybe<Scalars['String']['input']>;
  provinceName?: InputMaybe<Scalars['String']['input']>;
  screenDensity?: InputMaybe<Scalars['String']['input']>;
  userMaxEducationLevel?: InputMaybe<Scalars['String']['input']>;
};

export type QueryHomepageInterviewInformationArgs = {
  screenDensity?: InputMaybe<ScreenDensityEnum>;
};

export type QueryInterviewDetailArgs = {
  applicationId: Scalars['ID']['input'];
};

export type QueryInvitationDetailArgs = {
  invitationUniqueId: Scalars['ID']['input'];
};

export type QueryInvitationsArgs = {
  order?: InputMaybe<InvitationListOrder>;
};

export type QueryIsAppliedBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryJobApplicationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryJobApplicationFeedbackStatusArgs = {
  jobApplicationId: Scalars['ID']['input'];
};

export type QueryJobApplicationFeedbackTemplateArgs = {
  category?: InputMaybe<JobApplicationFeedbackEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryJobApplicationMessagesArgs = {
  jobApplicationId: Scalars['ID']['input'];
};

export type QueryJobApplicationsArgs = {
  filter?: InputMaybe<CommonFilter>;
  filters?: InputMaybe<Array<InputMaybe<SearchCategoryFilter>>>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type QueryJobApplicationsV2Args = {
  filter?: InputMaybe<CommonFilter>;
  filters?: InputMaybe<Array<SearchCategoryFilter>>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryJobListingFooterCategoryArgs = {
  jobSpecializationSlug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryJobListingFooterEducationLevelArgs = {
  educationLevelSlug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryJobListingFooterIndustryArgs = {
  companyIndustrySlug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryJobListingFooterJobTypeArgs = {
  jobTypeSlug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryJobListingFooterProvinceCityArgs = {
  citySlug?: InputMaybe<Scalars['String']['input']>;
  provinceSlug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryJobListingFooterRecommendationArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryJobListingFooterSpecializationLocationArgs = {
  jobSpecializationSlug?: InputMaybe<Scalars['String']['input']>;
  locationSlug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryJobSpecializationRolesArgs = {
  excludeOther?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
  positionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  positionName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryJobVacancyFilterByCodeArgs = {
  code: Scalars['String']['input'];
};

export type QueryJobVacancyFilterByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryJobVacancyFunctionsV2Args = {
  filter?: InputMaybe<CommonFilter>;
  isOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryLocationAreasArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryLocationBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryLocationCitiesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  provinceId: Scalars['ID']['input'];
};

export type QueryLocationDistrictsArgs = {
  cityId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryLocationProvincesArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryMediaFileArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMediaImageArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMediaVideoArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMissionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMissionClaimArgs = {
  missionId: Scalars['ID']['input'];
};

export type QueryNotificationDomainsArgs = {
  screenDensity?: InputMaybe<Scalars['String']['input']>;
};

export type QueryNotificationDomainsV2Args = {
  screenDensity?: InputMaybe<Scalars['String']['input']>;
};

export type QueryNotificationHistoriesV2Args = {
  filter?: InputMaybe<CommonFilter>;
  screenDensity?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryNotificationHistoriesV3Args = {
  domain?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
  screenDensity?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsBillArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsBillConfirmationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsBillConfirmationsArgs = {
  billNo?: InputMaybe<Scalars['String']['input']>;
  className?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  orderRef?: InputMaybe<Scalars['String']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsBillsArgs = {
  billNo?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  filter?: InputMaybe<CommonFilter>;
  orderRef?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsCategoriesArgs = {
  filter?: InputMaybe<CommonFilter>;
  msCategoryMainId?: InputMaybe<Scalars['ID']['input']>;
  msTestId?: InputMaybe<Scalars['ID']['input']>;
  msTestSubId?: InputMaybe<Scalars['ID']['input']>;
  passGradeType?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsCategoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsCategoryDropdownArgs = {
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsCategoryMainArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsCategoryMainDropDownArgs = {
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsCategoryMainsArgs = {
  filter?: InputMaybe<CommonFilter>;
  msTestId?: InputMaybe<Scalars['ID']['input']>;
  msTestSubId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsCategorySubDropDownArgs = {
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsCategorySubsArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  categoryMainId?: InputMaybe<Scalars['ID']['input']>;
  msTestId?: InputMaybe<Scalars['ID']['input']>;
  msTestSubId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsClassroomArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsClassroomUploadSummariesArgs = {
  filter?: InputMaybe<CommonFilter>;
};

export type QueryOpsClassroomUploadSummaryArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsClassroomUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsClassroomUserDetailArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsClassroomUserDetailsArgs = {
  className?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  isBan?: InputMaybe<Scalars['Boolean']['input']>;
  session?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryOpsClassroomUserNewSessionsArgs = {
  from: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
  to: Scalars['Int']['input'];
  total: Scalars['Int']['input'];
};

export type QueryOpsClassroomUserSessionSummaryArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsClassroomUsersArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOpsClassroomsArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  repeatEvery?: InputMaybe<Scalars['String']['input']>;
  repeatOn?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsCommentReportSummariesArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  commentIsBan?: InputMaybe<Scalars['Boolean']['input']>;
  countVote?: InputMaybe<Scalars['Int']['input']>;
  feedCode?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  group?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsCommentReportSummaryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsCommunityGroupArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsCommunityGroupDropDownArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsCommunityGroupsArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  isPublish?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryOpsCommunityMemberUserReportArgs = {
  communityMemberId: Scalars['ID']['input'];
};

export type QueryOpsCommunityMemberUserReportSummariesArgs = {
  filter?: InputMaybe<CommonFilter>;
  reportedMemberEmail?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsCommunityMembershipArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsCommunityMembershipsArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  group?: InputMaybe<Scalars['String']['input']>;
  isBan?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsCommunityVideoV2Args = {
  communityVideoV2Id: Scalars['ID']['input'];
};

export type QueryOpsCommunityVideoV2UserReportSummariesArgs = {
  isBan?: InputMaybe<Scalars['Boolean']['input']>;
  pagination: Pagination;
  reportedMemberEmail?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsCommunityVideoV2UserReportsArgs = {
  pagination: Pagination;
  reportedCommunityMemberId: Scalars['ID']['input'];
};

export type QueryOpsCommunityVideosV2Args = {
  communityVideoCategoryId?: InputMaybe<Scalars['ID']['input']>;
  filter: CommonFilter;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsCompanyDetailVerifiedOrPendingArgs = {
  code: Scalars['String']['input'];
};

export type QueryOpsCompanyPackagesArgs = {
  filter?: InputMaybe<CommonFilter>;
  id: Scalars['ID']['input'];
};

export type QueryOpsCompanyPremiumDetailArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsCompanyPremiumsArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  companyType?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  packageId?: InputMaybe<Scalars['ID']['input']>;
  picName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsCompanyVideoArgs = {
  jobCompanyId: Scalars['ID']['input'];
};

export type QueryOpsCompanyVideosArgs = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  filter: CommonFilter;
};

export type QueryOpsContentActivationLogsArgs = {
  contentCode?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsContentExamPackageSummariesArgs = {
  examPackageId: Scalars['ID']['input'];
  pagination?: InputMaybe<Pagination>;
};

export type QueryOpsContentExamPackagesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryOpsContentSkusArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryOpsContentsArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
  type?: InputMaybe<OpsContentType>;
};

export type QueryOpsCountriesArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsCountryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsCsClassDropDownArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsCsPackageContentArgs = {
  csPackageId: Scalars['ID']['input'];
  pagination?: InputMaybe<Pagination>;
};

export type QueryOpsCsPackagesContentArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryOpsCurrenciesArgs = {
  countryId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsDashboardArgs = {
  date: Scalars['String']['input'];
};

export type QueryOpsEducationInstitutionsArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsEducationProgramsArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsExamArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsExamDisplayCategoriesArgs = {
  filter?: InputMaybe<CommonFilter>;
};

export type QueryOpsExamDisplayCategorySubDropDownArgs = {
  displayCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsExamDisplayCategorySubsArgs = {
  filter?: InputMaybe<CommonFilter>;
};

export type QueryOpsExamDraftArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsExamDraftsArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<CommonFilter>;
  mainCategoryId?: InputMaybe<Scalars['ID']['input']>;
  msTestId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OpsExamDraftStatus>;
  subCategoryId?: InputMaybe<Scalars['ID']['input']>;
  subTestId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsExamDropDownArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsExamFreeTrialArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsExamFreeTrialsArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  isPublish?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsExamPackageArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsExamPackageByOrderNoArgs = {
  orderNo: Scalars['String']['input'];
};

export type QueryOpsExamPackageDisplayArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsExamPackageDisplaysArgs = {
  displayCategoryId?: InputMaybe<Scalars['ID']['input']>;
  displayCategorySubId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsExamPackageUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsExamPackageUsersArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  packageName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsExamPackagesArgs = {
  displayCategoryId?: InputMaybe<Scalars['ID']['input']>;
  displayCategorySubId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsExamQuestionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsExamQuestionsArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  examCode?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  mainCategoryId?: InputMaybe<Scalars['ID']['input']>;
  msTestId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  questionCode?: InputMaybe<Scalars['String']['input']>;
  questionText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subCategoryId?: InputMaybe<Scalars['ID']['input']>;
  subTestId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsExamStructureArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  categoryMainId?: InputMaybe<Scalars['ID']['input']>;
  categorySubId?: InputMaybe<Scalars['ID']['input']>;
  msTestId: Scalars['ID']['input'];
  msTestSubId: Scalars['ID']['input'];
};

export type QueryOpsExamUserResultArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsExamUserResultsArgs = {
  createdAtEnd?: InputMaybe<Scalars['Date']['input']>;
  createdAtStart?: InputMaybe<Scalars['Date']['input']>;
  durationEnd?: InputMaybe<Scalars['Float']['input']>;
  durationStart?: InputMaybe<Scalars['Float']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  examCode?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsExamUsersArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsExamsArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  mainCategoryId?: InputMaybe<Scalars['ID']['input']>;
  msTestId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subCategoryId?: InputMaybe<Scalars['ID']['input']>;
  subTestId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsExperienceJobFunctionsArgs = {
  filter?: InputMaybe<CommonFilter>;
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsFeedArgs = {
  id: Scalars['ID']['input'];
  publishType: Scalars['String']['input'];
};

export type QueryOpsFeedCommentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsFeedCommentsArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  group?: InputMaybe<Scalars['String']['input']>;
  isBan?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOpsFeedReportSummariesArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  countVote?: InputMaybe<Scalars['Int']['input']>;
  feedIsBan?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CommonFilter>;
};

export type QueryOpsFeedReportSummaryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsFeedsArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  fieldType?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  group?: InputMaybe<Scalars['String']['input']>;
  isBan?: InputMaybe<Scalars['Boolean']['input']>;
  isPublish?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsGenerateQuestionsArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  categoryMainId?: InputMaybe<Scalars['ID']['input']>;
  categorySubId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  msTestId?: InputMaybe<Scalars['ID']['input']>;
  msTestSubId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  questionCode?: InputMaybe<Scalars['String']['input']>;
  questionText?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Scalars['String']['input']>;
  type: OpsExamGenerationType;
  unUsed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOpsGuidelineDetailArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsGuidelinesArgs = {
  filter?: InputMaybe<CommonFilter>;
  guidelineTypeId?: InputMaybe<Scalars['ID']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsHelloWaitArgs = {
  delay: Scalars['Int']['input'];
};

export type QueryOpsHighlightedJobArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsHighlightedJobsArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  homeSectionId?: InputMaybe<Scalars['ID']['input']>;
  positionName?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryOpsJobApplicationsArgs = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  positionName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsJobCompaniesArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  companyTypeId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  excludeVerificationStatus?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  jobCompanyIndustryName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  verificationStatus?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsJobCompanyArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsJobCompanyAccManagerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsJobCompanyAccManagersArgs = {
  contactWhatsapp?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsJobCompanyDetailLogsArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<CommonFilter>;
};

export type QueryOpsJobCompanyIndustriesArgs = {
  filter?: InputMaybe<CommonFilter>;
};

export type QueryOpsJobCompanyPremiumArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsJobCompanyPremiumRegisterSearchArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsJobCompanyPremiumRequestArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsJobCompanyPremiumRequestsArgs = {
  accManager?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  currentPackage?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  jobCompanyType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsJobCompanyPremiumsArgs = {
  accManager?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  currentPackage?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsJobCompanySearchArgs = {
  data: OpsJobCompanySearchInput;
};

export type QueryOpsJobCompanySubscriptionPackagesArgs = {
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsJobCompanyUserValidationArgs = {
  email: Scalars['String']['input'];
};

export type QueryOpsJobCompanyVerificationArgs = {
  payload: OpsInputJobCompany;
};

export type QueryOpsJobCompanyVerificationCodeArgs = {
  companyId: Scalars['ID']['input'];
};

export type QueryOpsJobCompanyVerificationStatusDropdownArgs = {
  verificationStatus?: InputMaybe<EpCompanyVerificationStatus>;
};

export type QueryOpsJobCompanyVerificationsArgs = {
  accManager?: InputMaybe<Scalars['String']['input']>;
  accManagerWhatsapp?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  isLegalDocumentAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsJobKlepCompaniesArgs = {
  accManager?: InputMaybe<Scalars['String']['input']>;
  accManagerWhatsapp?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsJobKlepCompanyDraftArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsJobKlepCompanyDraftsArgs = {
  accManager?: InputMaybe<Scalars['String']['input']>;
  accManagerWhatsapp?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsJobKlepCompanyHistoryActionsArgs = {
  domainId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<CommonFilter>;
};

export type QueryOpsJobKlepCompanyUserRegistriesArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<CommonFilter>;
};

export type QueryOpsJobKlepCompanyUserRegistryCopyLinkArgs = {
  input?: InputMaybe<OpsJobKlepCompanyUserRegistryInput>;
};

export type QueryOpsJobSkillRequirementArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsJobSkillRequirementsArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  question?: InputMaybe<Scalars['String']['input']>;
  subCategory?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsJobSpecializationRolesArgs = {
  jobRoleName?: InputMaybe<Scalars['String']['input']>;
  jobSpecializationId: Scalars['ID']['input'];
  pagination?: InputMaybe<Pagination>;
};

export type QueryOpsJobSpecializationRolesByKeywordArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryOpsJobSpecializationsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryOpsJobVacanciesArgs = {
  closedDateEnd?: InputMaybe<Scalars['Date']['input']>;
  closedDateStart?: InputMaybe<Scalars['Date']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  locationSite?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
  positionName?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsJobVacancyArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsJobVacancyByCodeArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsJobVacancyCategorySubArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsJobVacancyCategorySubDropdownArgs = {
  categoryId: Scalars['ID']['input'];
};

export type QueryOpsJobVacancyFunctionRequirementsArgs = {
  jobVacancyFunctionId: Scalars['ID']['input'];
};

export type QueryOpsJobVacancyFunctionsArgs = {
  filter?: InputMaybe<CommonFilter>;
};

export type QueryOpsJobVacancyReportArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsJobVacancyReportsArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  createdAtEnd?: InputMaybe<Scalars['Date']['input']>;
  createdAtStart?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  klepStatus?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  teamConfirmation?: InputMaybe<Scalars['String']['input']>;
  userConfirmation?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsLiveArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsLivePublishArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsLiveUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsLiveUserDraftArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsLiveUserDraftsArgs = {
  filter?: InputMaybe<CommonFilter>;
  liveName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsLiveUserTotalArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsLiveUserTotalsArgs = {
  filter?: InputMaybe<CommonFilter>;
  liveAt?: InputMaybe<Scalars['Date']['input']>;
  liveName?: InputMaybe<Scalars['String']['input']>;
  teacherId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsLiveUsersArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsLivesArgs = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  liveAt?: InputMaybe<Scalars['Date']['input']>;
  teacherId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsLocationCitiesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  provinceId: Scalars['ID']['input'];
};

export type QueryOpsLocationConfigArgs = {
  level: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  pagination: Pagination;
};

export type QueryOpsLocationDistrictsArgs = {
  cityId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsLocationProvincesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsLocationSearchByNameArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsMarketingBannerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsMarketingBannersArgs = {
  filter?: InputMaybe<CommonFilter>;
  isHighlight?: InputMaybe<Scalars['Boolean']['input']>;
  isPublish?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsMissionsArgs = {
  client?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  filter: CommonFilter;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryOpsNewsArgs = {
  filter?: InputMaybe<CommonFilter>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  shortDesc?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsNewsDetailArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsNotificationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsNotificationMessageEventGoalsArgs = {
  filter?: InputMaybe<CommonFilter>;
};

export type QueryOpsNotificationMessageNameAvailabilityArgs = {
  name: Scalars['String']['input'];
};

export type QueryOpsNotificationMessagesArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<OpsNotificationPlatform>;
  publishDate?: InputMaybe<Scalars['String']['input']>;
  schedule?: InputMaybe<OpsNotificationType>;
  status?: InputMaybe<OpsNotificationStatus>;
};

export type QueryOpsNotificationsArgs = {
  filter?: InputMaybe<CommonFilter>;
  publishedAt?: InputMaybe<Scalars['Date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsOfflineProfileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsOfflineProfileDashboardArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  experienceJobFunctions?: InputMaybe<Scalars['String']['input']>;
  experienceYears?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  lastEducationLevel?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsOrderArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsOrderInquiriesArgs = {
  className?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  instalment?: InputMaybe<Scalars['Boolean']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsOrderInquiryArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsOrderInquiryProceedArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsOrdersArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  paymentMethod?: InputMaybe<Scalars['ID']['input']>;
  paymentPlan?: InputMaybe<Scalars['String']['input']>;
  paymentStatus?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsPackageArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsPackageDetailArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsPackageTypesDropdownArgs = {
  hasPurchasedBundle?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOpsPackagesArgs = {
  className?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  isAddon?: InputMaybe<Scalars['Boolean']['input']>;
  isPublish?: InputMaybe<Scalars['Boolean']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsPackagesDropdownArgs = {
  companyType?: InputMaybe<Scalars['String']['input']>;
  packageType?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsParamsArgs = {
  key: Scalars['String']['input'];
};

export type QueryOpsPaymentMethodArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsPinUnpinCommunityVideoV2ValidationArgs = {
  communityVideoV2Id: Scalars['ID']['input'];
};

export type QueryOpsProfileAssignmentsArgs = {
  applicantEmail?: InputMaybe<Scalars['String']['input']>;
  applicantName?: InputMaybe<Scalars['String']['input']>;
  assignedBy?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  jobCompanyName?: InputMaybe<Scalars['String']['input']>;
  jobVacancyCode?: InputMaybe<Scalars['String']['input']>;
  jobVacancyPositionName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsQuestionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsQuestionDraftArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsQuestionDraftsArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<CommonFilter>;
  mainCategoryId?: InputMaybe<Scalars['ID']['input']>;
  msTestId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<OpsQuestionDraftStatus>;
  subCategoryId?: InputMaybe<Scalars['ID']['input']>;
  subTestId?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsQuestionsArgs = {
  approvedEndDate?: InputMaybe<Scalars['Date']['input']>;
  approvedStartDate?: InputMaybe<Scalars['Date']['input']>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  mainCategoryId?: InputMaybe<Scalars['ID']['input']>;
  msTestId?: InputMaybe<Scalars['ID']['input']>;
  subCategoryId?: InputMaybe<Scalars['ID']['input']>;
  subTestId?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsReRegisterSummaryArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsRedemptionsArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  filter: CommonFilter;
  name?: InputMaybe<Scalars['String']['input']>;
  transactionNumber?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsReferralProgramArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsReferralProgramsArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsSegmentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsSegmentNameAvailabilityArgs = {
  name: Scalars['String']['input'];
};

export type QueryOpsSegmentUserUpdateCountArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsSegmentsArgs = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  division?: InputMaybe<Scalars['String']['input']>;
  pagination: Pagination;
  segmentName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsSessionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsSessionParentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsSessionsArgs = {
  className?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  filter?: InputMaybe<CommonFilter>;
  isFileMaterial?: InputMaybe<Scalars['Boolean']['input']>;
  isPostTest?: InputMaybe<Scalars['Boolean']['input']>;
  isPreTest?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  syllabus?: InputMaybe<Scalars['String']['input']>;
  teacherName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsSkillsArgs = {
  filter?: InputMaybe<CommonFilter>;
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsSkillsV2Args = {
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryOpsSkuArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsSkuCategoriesArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsSkuCategoryArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsSkuCategoryComponentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsSkuCategoryComponentDropDownArgs = {
  skuCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsSkuCategoryComponentsArgs = {
  filter?: InputMaybe<CommonFilter>;
};

export type QueryOpsSkusArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  skuCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsSubmissionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsSubmissionsArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  filter: CommonFilter;
  submitDate?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsTeacherArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsTeachersArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsTestSubDropDownArgs = {
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsTotalUsersBySessionIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsVacancyHighlightSectionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsVacancyHighlightSectionsArgs = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  filter: CommonFilter;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOpsVerifySkillsArgs = {
  mediaFileId: Scalars['ID']['input'];
};

export type QueryOpsVideoArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsVideosArgs = {
  filter?: InputMaybe<CommonFilter>;
  host?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  uploadEndDate?: InputMaybe<Scalars['Date']['input']>;
  uploadStartDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryOpsVoucherArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpsVouchersArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  expiryEndDate?: InputMaybe<Scalars['Date']['input']>;
  expiryStartDate?: InputMaybe<Scalars['Date']['input']>;
  filter?: InputMaybe<CommonFilter>;
  isValid?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOpsWalletRedemptionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpsWalletRedemptionsArgs = {
  bankAccNo?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  referralCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOrderSubmitSummaryArgs = {
  orderNo: Scalars['String']['input'];
};

export type QueryOrdersArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPaymentInstructionsArgs = {
  paymentMethodId: Scalars['ID']['input'];
};

export type QueryPaymentMethodsV2Args = {
  channel?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProfileArgs = {
  screenDensity?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProfileBySlugArgs = {
  screenDensity?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

export type QueryProfileHistoryArgs = {
  screenDensity?: InputMaybe<Scalars['String']['input']>;
};

export type QueryQuestionsArgs = {
  examId: Scalars['ID']['input'];
};

export type QueryReferralLinkArgs = {
  communityId: Scalars['ID']['input'];
};

export type QuerySessionArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySimilarJobVacancyV2Args = {
  jobVacancyId: Scalars['ID']['input'];
  locations?: InputMaybe<Array<LocationAreaInput>>;
};

export type QuerySkillsArgs = {
  filter?: InputMaybe<CommonFilter>;
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySkillsV2Args = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};

export type QuerySkuByTypeCategoryArgs = {
  category: Scalars['String']['input'];
  skuType: Scalars['String']['input'];
};

export type QuerySkuTestimoniesArgs = {
  filter?: InputMaybe<CommonFilter>;
};

export type QuerySupportLinkArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTopSectionV2Args = {
  filter?: InputMaybe<CommonFilter>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locations?: InputMaybe<Array<LocationAreaInput>>;
  userMaxEducationLevel?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTopSectionsV2Args = {
  cityName?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  id?: InputMaybe<Scalars['ID']['input']>;
  provinceName?: InputMaybe<Scalars['String']['input']>;
  userMaxEducationLevel?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTopSectionsV3Args = {
  filter?: InputMaybe<CommonFilter>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locations?: InputMaybe<Array<LocationAreaInput>>;
  userMaxEducationLevel?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTopVacanciesArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  locations?: InputMaybe<Array<LocationAreaInput>>;
};

export type QueryTryOutHomeUserV2Args = {
  filter?: InputMaybe<CommonFilter>;
};

export type QueryTryOutHomeV3Args = {
  filter?: InputMaybe<CommonFilter>;
};

export type QueryUserCommunitiesArgs = {
  filter?: InputMaybe<CommonFilter>;
};

export type QueryUserInterestsArgs = {
  filter?: InputMaybe<CommonFilter>;
  screenDensity?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserLocationDropdownArgs = {
  screenDensity?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserLocationPreferenceArgs = {
  filter?: InputMaybe<CommonFilter>;
  hasVacancy?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  screenDensity?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserOrdersArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryUserPreferenceArgs = {
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUsernameAvailabilityArgs = {
  username: Scalars['String']['input'];
};

export type QueryVacanciesV2Args = {
  categories?: InputMaybe<Array<InputMaybe<SearchCategoryFilter>>>;
  cityName?: InputMaybe<Scalars['String']['input']>;
  districtName?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  filters?: InputMaybe<Array<InputMaybe<SearchCategoryFilter>>>;
  haveMisiSeruLimit?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  provinceName?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Array<InputMaybe<SearchCategoryFilter>>>;
};

export type QueryVacanciesV3Args = {
  categories?: InputMaybe<Array<SearchCategoryFilter>>;
  filter?: InputMaybe<CommonFilter>;
  filters?: InputMaybe<Array<SearchCategoryFilter>>;
  haveMisiSeruLimit?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  locations?: InputMaybe<Array<LocationAreaInput>>;
  sortBy?: InputMaybe<Array<SearchCategoryFilter>>;
  userLastEducationLevel?: InputMaybe<Scalars['String']['input']>;
  userLocationCityId?: InputMaybe<Scalars['ID']['input']>;
  userSkillIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryVacancyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryVacancyBookmarkedArgs = {
  filter?: InputMaybe<CommonFilter>;
};

export type QueryVacancyByCompanySlugArgs = {
  companySlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  filter?: InputMaybe<CommonFilter>;
};

export type QueryVacancyByPreferenceArgs = {
  cityName?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  provinceName?: InputMaybe<Scalars['String']['input']>;
  userJobFunctions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userMaxEducationLevel?: InputMaybe<Scalars['String']['input']>;
};

export type QueryVacancyByPreferenceV2Args = {
  filter?: InputMaybe<CommonFilter>;
  locations?: InputMaybe<Array<LocationAreaInput>>;
  userJobFunctions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userMaxEducationLevel?: InputMaybe<Scalars['String']['input']>;
};

export type QueryVacancyBySlugArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryVacancyContactInfoArgs = {
  jobVacancyId: Scalars['ID']['input'];
};

export type QueryVacancyHistoryByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryVacancyHistoryBySlugArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryVacancyInsightBatchArgs = {
  ids: Array<Scalars['ID']['input']>;
  lastEducationLevel: Scalars['String']['input'];
  locationCity: Scalars['String']['input'];
  userSkills: Array<Scalars['String']['input']>;
};

export type QueryVacancyInsightBatchV2Args = {
  ids: Array<Scalars['ID']['input']>;
  lastEducationLevel: Scalars['String']['input'];
  locationCity: Scalars['String']['input'];
  userSkills: Array<Scalars['String']['input']>;
};

export type QueryVacancyInsightByVacancyIdArgs = {
  lastEducationLevel: Scalars['String']['input'];
  locationCity: Scalars['String']['input'];
  userSkills: Array<Scalars['String']['input']>;
  vacancyId: Scalars['ID']['input'];
};

export type QueryVacancyInsightByVacancyIdV2Args = {
  lastEducationLevel: Scalars['String']['input'];
  locationCity: Scalars['String']['input'];
  userSkills: Array<Scalars['String']['input']>;
  vacancyId: Scalars['ID']['input'];
};

export type QueryVacancyLocationCitiesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  provinceId: Scalars['ID']['input'];
};

export type QueryVacancyLocationDistrictsArgs = {
  cityId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryVacancyLocationProvincesArgs = {
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryVacancyPositionRecommendationsArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryVacancyPositionsArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryVacancyScreeningArgs = {
  jobVacancyId: Scalars['ID']['input'];
};

export type QueryVacancyScreeningV2Args = {
  jobVacancyId: Scalars['ID']['input'];
};

export type QueryVacancySectionDetailsByCodeV3Args = {
  code: Scalars['String']['input'];
  filter?: InputMaybe<JobVacancyFilterInput>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryVacancySectionDetailsByCodeV4Args = {
  code: Scalars['String']['input'];
  filter?: InputMaybe<JobVacancyFilterInput>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryVacancySectionDetailsByHomeSectionIdArgs = {
  cityName?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommonFilter>;
  id: Scalars['ID']['input'];
  provinceName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryVacancySectionDetailsByHomeSectionIdV2Args = {
  filter?: InputMaybe<CommonFilter>;
  id: Scalars['ID']['input'];
  locations?: InputMaybe<Array<LocationAreaInput>>;
  userMaxEducationLevel?: InputMaybe<Scalars['String']['input']>;
};

export type QueryVacancySectionDetailsByHomeSectionIdV3Args = {
  filter?: InputMaybe<JobVacancyFilterInput>;
  id: Scalars['ID']['input'];
  pagination?: InputMaybe<Pagination>;
};

export type QueryVacancySectionDetailsCountByCodeArgs = {
  code: Scalars['String']['input'];
  filter?: InputMaybe<JobVacancyFilterInput>;
};

export type QueryVacancySectionDetailsCountByCodeV2Args = {
  code: Scalars['String']['input'];
  filter?: InputMaybe<JobVacancyFilterInput>;
};

export type QueryVacancySectionDetailsCountByIdArgs = {
  filter?: InputMaybe<JobVacancyFilterInput>;
  id: Scalars['ID']['input'];
};

export type QueryVacancySectionDetailsHighlightArgs = {
  cityName?: InputMaybe<Scalars['String']['input']>;
  homeSectionId: Scalars['ID']['input'];
  provinceName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryVacancySectionDetailsHighlightV2Args = {
  cityName?: InputMaybe<Scalars['String']['input']>;
  homeSectionId: Scalars['ID']['input'];
  provinceName?: InputMaybe<Scalars['String']['input']>;
  userMaxEducationLevel?: InputMaybe<Scalars['String']['input']>;
};

export type QueryVacancySectionDetailsHighlightV3Args = {
  homeSectionId: Scalars['ID']['input'];
  locations?: InputMaybe<Array<LocationAreaInput>>;
  userMaxEducationLevel?: InputMaybe<Scalars['String']['input']>;
};

export type QueryVacancySectionsArgs = {
  cityName?: InputMaybe<Scalars['String']['input']>;
  provinceName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryValidateMembershipArgs = {
  communityId: Scalars['ID']['input'];
};

export type QueryVerifyProfileBeforeApplyV2Args = {
  vacancyId: Scalars['ID']['input'];
};

export type QueryVerifyProfileBeforeApplyV3Args = {
  vacancyId: Scalars['ID']['input'];
};

export type QueryVideoByCompanyIdArgs = {
  companyId: Scalars['ID']['input'];
};

export type QueryVideoPreviewDetailArgs = {
  slug: Scalars['String']['input'];
};

export type QueryVideoPreviewsArgs = {
  filter?: InputMaybe<CommonFilter>;
};

export type Question = {
  __typename?: 'Question';
  answers?: Maybe<Array<Maybe<Answer>>>;
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isLatex?: Maybe<Scalars['Boolean']['output']>;
  tags?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type QuestionFeedbackCategory = {
  __typename?: 'QuestionFeedbackCategory';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum QuestionType {
  MultipleChoice = 'MULTIPLE_CHOICE',
  RangeBar = 'RANGE_BAR',
  Text = 'TEXT',
}

export enum QuestionTypeEnum {
  Multiple = 'MULTIPLE',
  Single = 'SINGLE',
}

export type QuickApplyInput = {
  invitationId: Scalars['ID']['input'];
};

export type QuickApplyResponse =
  | QuickApplySuccess
  | QuickApplyVacancyErrorResponse;

export type QuickApplySuccess = {
  __typename?: 'QuickApplySuccess';
  message?: Maybe<Scalars['String']['output']>;
};

export type QuickApplyVacancyErrorResponse = {
  __typename?: 'QuickApplyVacancyErrorResponse';
  errorCode?: Maybe<ApplyVacancyErrorEnum>;
  message?: Maybe<ApplyErrorCopy>;
};

export type ReferralHome = {
  __typename?: 'ReferralHome';
  copy?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  leaderBoard?: Maybe<LeaderBoard>;
  referralSummary?: Maybe<ReferralSummary>;
  termConditionLink?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  walletSummary?: Maybe<WalletSummary>;
};

export type ReferralSummary = {
  __typename?: 'ReferralSummary';
  totalReferral?: Maybe<Scalars['Int']['output']>;
};

export type RescheduleInterviewInput = {
  applicationId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonId: Scalars['ID']['input'];
};

export type RescheduleInterviewInvitationError = {
  __typename?: 'RescheduleInterviewInvitationError';
  errorCode?: Maybe<RescheduleInterviewInvitationErrorEnum>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum RescheduleInterviewInvitationErrorEnum {
  NotFound = 'NOT_FOUND',
  ReasonMaxLength = 'REASON_MAX_LENGTH',
  ReasonRequired = 'REASON_REQUIRED',
  Unknown = 'UNKNOWN',
}

export type RescheduleInterviewInvitationResult =
  | RescheduleInterviewInvitationError
  | RescheduleInterviewInvitationSuccess;

export type RescheduleInterviewInvitationSuccess = {
  __typename?: 'RescheduleInterviewInvitationSuccess';
  rescheduleMessageTemplate?: Maybe<Scalars['String']['output']>;
};

export type ResendApplicationErrorModal = ErrorModal & {
  __typename?: 'ResendApplicationErrorModal';
  content?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ResendApplicationErrorResponse = {
  __typename?: 'ResendApplicationErrorResponse';
  errorCode?: Maybe<ApplyVacancyErrorEnum>;
  message?: Maybe<ResendApplicationErrorModal>;
};

export type ResendApplicationResult =
  | ResendApplicationErrorResponse
  | WhatsappContent;

export type SalaryCreateInput = {
  currencyValue: Scalars['String']['input'];
  period: PeriodEnum;
  salary: Scalars['Int']['input'];
  userProfileId: Scalars['ID']['input'];
};

export type SalaryUpdateInput = {
  currencyValue: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  period: PeriodEnum;
  salary: Scalars['Int']['input'];
  userProfileId: Scalars['ID']['input'];
};

export enum ScreenDensityEnum {
  Hdpi = 'HDPI',
  Mdpi = 'MDPI',
  Web = 'WEB',
  Xhdpi = 'XHDPI',
  Xxhdpi = 'XXHDPI',
  Xxxhdpi = 'XXXHDPI',
}

export enum ScreeningQuestionFilterUiType {
  HorizontalRadioWithLeftLabel = 'HORIZONTAL_RADIO_WITH_LEFT_LABEL',
  HorizontalRadioWithRightLabel = 'HORIZONTAL_RADIO_WITH_RIGHT_LABEL',
  HorizontalRadioWithTopLabel = 'HORIZONTAL_RADIO_WITH_TOP_LABEL',
}

export enum ScreeningQuestionTopic {
  DocCert = 'DOC_CERT',
  Education = 'EDUCATION',
  Industry = 'INDUSTRY',
  Location = 'LOCATION',
  LocationSite = 'LOCATION_SITE',
  Role = 'ROLE',
  Skill = 'SKILL',
}

export type SearchCategoryFilter = {
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SegmentCvInput = {
  isExist: Scalars['Boolean']['input'];
};

export type SegmentEducationLevelInput = {
  data?: InputMaybe<Array<InputSegmentFilterItem>>;
  isExist: Scalars['Boolean']['input'];
};

export type SegmentFilterItem = {
  __typename?: 'SegmentFilterItem';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SegmentJobSpecializationInput = {
  data?: InputMaybe<Array<InputSegmentFilterItem>>;
  isExist: Scalars['Boolean']['input'];
};

export type SegmentLocationInput = {
  data?: InputMaybe<Array<InputSegmentFilterItem>>;
  isExist: Scalars['Boolean']['input'];
  type?: InputMaybe<InputSegmentFilterItem>;
};

export type SegmentNameAvailabilityError = {
  __typename?: 'SegmentNameAvailabilityError';
  code?: Maybe<OpsNameSegmentErrorNum>;
  message?: Maybe<Scalars['String']['output']>;
};

export type SegmentNameAvailabilityResponse = {
  __typename?: 'SegmentNameAvailabilityResponse';
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
};

export type SegmentSkillsInput = {
  data?: InputMaybe<Array<InputSegmentFilterItem>>;
  isExist: Scalars['Boolean']['input'];
};

export type SegmentUserUpdateCountResponse = {
  __typename?: 'SegmentUserUpdateCountResponse';
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
};

export type SegmentUserUpdateCountResponseError = {
  __typename?: 'SegmentUserUpdateCountResponseError';
  code?: Maybe<OpsSegmentErrorEnum>;
  message?: Maybe<Scalars['String']['output']>;
};

export type SegmentWorkExperienceInput = {
  data?: InputMaybe<Array<InputSegmentFilterItem>>;
  isExist: Scalars['Boolean']['input'];
  jobDescriptionTotalWords?: InputMaybe<InputSegmentFilterItem>;
};

export type Session = {
  __typename?: 'Session';
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  exams?: Maybe<Array<Maybe<ExamSession>>>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  fileUrl1?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  isBan?: Maybe<Scalars['Boolean']['output']>;
  isFinished?: Maybe<Scalars['Boolean']['output']>;
  isLive?: Maybe<Scalars['Boolean']['output']>;
  isLiveInApp?: Maybe<Scalars['Boolean']['output']>;
  liveAt?: Maybe<Scalars['String']['output']>;
  liveAtFormatted?: Maybe<Scalars['String']['output']>;
  liveAtLocale?: Maybe<Scalars['String']['output']>;
  liveLink?: Maybe<Scalars['String']['output']>;
  postTestUrl?: Maybe<Scalars['String']['output']>;
  preTestUrl?: Maybe<Scalars['String']['output']>;
  syllabus?: Maybe<Scalars['String']['output']>;
  teacherStr?: Maybe<Scalars['String']['output']>;
  video?: Maybe<Video>;
  videoId?: Maybe<Scalars['ID']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
  waSummaryUrl?: Maybe<Scalars['String']['output']>;
};

export enum SessionType {
  Past = 'PAST',
  Upcoming = 'UPCOMING',
}

export type Sessions = {
  __typename?: 'Sessions';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<Session>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type SetHasNoExperienceErrorCopy = {
  __typename?: 'SetHasNoExperienceErrorCopy';
  content?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum SetHasNoExperienceErrorEnum {
  AlreadyHasExperience = 'ALREADY_HAS_EXPERIENCE',
}

export type SetHasNoExperienceErrorResponse = {
  __typename?: 'SetHasNoExperienceErrorResponse';
  errorCode?: Maybe<SetHasNoExperienceErrorEnum>;
  message?: Maybe<SetHasNoExperienceErrorCopy>;
};

export type SetHasNoExperienceResponse =
  | SetHasNoExperienceErrorResponse
  | SetHasNoExperienceSuccessResponse;

export type SetHasNoExperienceSuccessResponse = {
  __typename?: 'SetHasNoExperienceSuccessResponse';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Skill = {
  __typename?: 'Skill';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Skills = {
  __typename?: 'Skills';
  elements?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Skill>>;
  page?: Maybe<Scalars['Int']['output']>;
};

export type Sku = {
  __typename?: 'Sku';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionArrs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountType?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  installments?: Maybe<Array<Maybe<SkuInstallment>>>;
  isDiscount?: Maybe<Scalars['Boolean']['output']>;
  isFree?: Maybe<Scalars['Boolean']['output']>;
  isGuarantee?: Maybe<Scalars['Boolean']['output']>;
  isInstallment?: Maybe<Scalars['Boolean']['output']>;
  mediaImageId?: Maybe<Scalars['String']['output']>;
  msCurrencyId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  packageType?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceStr?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  validityColor?: Maybe<Scalars['String']['output']>;
  validityPeriod?: Maybe<Scalars['String']['output']>;
  validityPeriodStr?: Maybe<Scalars['String']['output']>;
  validityValue?: Maybe<Scalars['Int']['output']>;
};

export type SkuCart = {
  __typename?: 'SkuCart';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionArrs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  displayName?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  installments?: Maybe<Array<Maybe<SkuInstallmentCart>>>;
  isInstallment?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceStr?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  subTotal?: Maybe<Scalars['Float']['output']>;
  subTotalStr?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type SkuCategory = {
  __typename?: 'SkuCategory';
  description?: Maybe<Scalars['String']['output']>;
  descriptionFormatted?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentCategoryId?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type SkuGroup = {
  __typename?: 'SkuGroup';
  packageType?: Maybe<Scalars['String']['output']>;
  skus?: Maybe<Array<Maybe<Sku>>>;
};

export type SkuGroups = {
  __typename?: 'SkuGroups';
  description?: Maybe<Scalars['String']['output']>;
  descriptionArrs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  displayName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  skus?: Maybe<Array<Maybe<SkuGroup>>>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type SkuInfo = {
  __typename?: 'SkuInfo';
  isExam?: Maybe<Scalars['Boolean']['output']>;
  sku?: Maybe<Sku>;
};

export type SkuInstallment = {
  __typename?: 'SkuInstallment';
  dueDate?: Maybe<Scalars['String']['output']>;
  dueDateStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceStr?: Maybe<Scalars['String']['output']>;
  skuId?: Maybe<Scalars['ID']['output']>;
};

export type SkuInstallmentCart = {
  __typename?: 'SkuInstallmentCart';
  number?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceStr?: Maybe<Scalars['String']['output']>;
};

export type SkuTestimonies = {
  __typename?: 'SkuTestimonies';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<SkuTestimony>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type SkuTestimony = {
  __typename?: 'SkuTestimony';
  id: Scalars['ID']['output'];
  image?: Maybe<MediaImage>;
  imageId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  position: Scalars['String']['output'];
  testimony: Scalars['String']['output'];
};

export type SkuTryOut = {
  __typename?: 'SkuTryOut';
  code?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Skus = {
  __typename?: 'Skus';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<Sku>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type SocialMedia = {
  __typename?: 'SocialMedia';
  facebook?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  linkedid?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
};

export type SocialMediaUpdateInput = {
  facebook?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  instagram?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
};

export type SpSubscriptionPlan = {
  __typename?: 'SpSubscriptionPlan';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  entitlementIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  expiredAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  purchasedAt?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  spSubscriptionType?: Maybe<SpSubscriptionType>;
  spSubscriptionTypeId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type SpSubscriptionType = {
  __typename?: 'SpSubscriptionType';
  asset?: Maybe<SpSubscriptionTypeAsset>;
  color?: Maybe<Scalars['String']['output']>;
  ctaLabel?: Maybe<Scalars['String']['output']>;
  ctaUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDefaultSelected?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<SpSubscriptionTypeLabel>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceStr?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['ID']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  promotionalPrice?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  validityPeriod?: Maybe<Scalars['String']['output']>;
  validityValue?: Maybe<Scalars['Int']['output']>;
};

export type SpSubscriptionTypeAsset = {
  __typename?: 'SpSubscriptionTypeAsset';
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  spSubscriptionTypeId?: Maybe<Scalars['ID']['output']>;
};

export type SpSubscriptionTypeLabel = {
  __typename?: 'SpSubscriptionTypeLabel';
  color?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  key?: Maybe<SpSubscriptionTypeLabelEnum>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum SpSubscriptionTypeLabelEnum {
  MostEconomical = 'MOST_ECONOMICAL',
  MostPopular = 'MOST_POPULAR',
}

export type StatusBadge = {
  __typename?: 'StatusBadge';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  textColor?: Maybe<Scalars['String']['output']>;
};

export type SubmitAppReviewInput = {
  rating?: InputMaybe<Scalars['Int']['input']>;
  review?: InputMaybe<Scalars['String']['input']>;
};

export enum SubmitTypeEnum {
  Allowed = 'ALLOWED',
  Disallowed = 'DISALLOWED',
}

export type SubmitWorkPreferenceV3Error = {
  __typename?: 'SubmitWorkPreferenceV3Error';
  errorCode?: Maybe<SubmitWorkPreferenceV3ErrorEnum>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum SubmitWorkPreferenceV3ErrorEnum {
  InvalidSize = 'INVALID_SIZE',
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN',
}

export type SubmitWorkPreferenceV3Response = {
  __typename?: 'SubmitWorkPreferenceV3Response';
  isSuccess: Scalars['Boolean']['output'];
};

export type SubmitWorkPreferenceV3Result =
  | SubmitWorkPreferenceV3Error
  | SubmitWorkPreferenceV3Response;

export type SupportLink = {
  __typename?: 'SupportLink';
  id?: Maybe<Scalars['ID']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  supportLinkCategory?: Maybe<SupportLinkCategory>;
  supportLinkCategoryId?: Maybe<Scalars['ID']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type SupportLinkCategory = {
  __typename?: 'SupportLinkCategory';
  asset?: Maybe<SupportLinkCategoryAsset>;
  displayName?: Maybe<Scalars['String']['output']>;
  hint?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  rules?: Maybe<Array<Maybe<SupportLinkCategoryRule>>>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  tips?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type SupportLinkCategoryAsset = {
  __typename?: 'SupportLinkCategoryAsset';
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  supportLinkCategoryId?: Maybe<Scalars['ID']['output']>;
};

export type SupportLinkCategoryRule = {
  __typename?: 'SupportLinkCategoryRule';
  errorMessage?: Maybe<Scalars['String']['output']>;
  type?: Maybe<SupportLinkCategoryRuleType>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum SupportLinkCategoryRuleType {
  MaxChar = 'MAX_CHAR',
  MinChar = 'MIN_CHAR',
  Regex = 'REGEX',
}

export type SupportLinkCreateInput = {
  link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  userProfileId: Scalars['ID']['input'];
};

export type SupportLinkCreateInputProfile = {
  link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SupportLinkCreateInputV2 = {
  link: Scalars['String']['input'];
  supportLinkCategoryId: Scalars['ID']['input'];
  userProfileId: Scalars['ID']['input'];
};

export type SupportLinkUpdateInput = {
  id: Scalars['ID']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SupportLinkUpdateInputV2 = {
  id: Scalars['ID']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  supportLinkCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type TopSectionsV2 = {
  __typename?: 'TopSectionsV2';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  homeSectionId?: Maybe<Scalars['ID']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<Vacancy>>>;
  page?: Maybe<Scalars['Int']['output']>;
  sectionDisplayName?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type TryOutHome = {
  __typename?: 'TryOutHome';
  displays?: Maybe<Array<Maybe<ExamDisplayCategory>>>;
  examPackages?: Maybe<Array<Maybe<ExamPackage>>>;
  /** @deprecated drop, replace with examPackages */
  headers?: Maybe<Array<Maybe<SkuTryOut>>>;
};

export type TryOutHomeDisplaysArgs = {
  examPackageId?: InputMaybe<Scalars['ID']['input']>;
};

export type TryOutHomeV2 = {
  __typename?: 'TryOutHomeV2';
  examPackages?: Maybe<Array<Maybe<ExamPackage>>>;
};

export type TryOutHomeV3 = {
  __typename?: 'TryOutHomeV3';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<ExamPackage>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum UnbanCommunityVideoV2UserErrorEnum {
  MemberNotFound = 'MEMBER_NOT_FOUND',
  UserNotBanned = 'USER_NOT_BANNED',
}

export type UnbanCommunityVideoV2UserErrorResponse = {
  __typename?: 'UnbanCommunityVideoV2UserErrorResponse';
  code?: Maybe<UnbanCommunityVideoV2UserErrorEnum>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UnbanCommunityVideoV2UserResponse = {
  __typename?: 'UnbanCommunityVideoV2UserResponse';
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateCheck = {
  __typename?: 'UpdateCheck';
  buttonCopy?: Maybe<Scalars['String']['output']>;
  currentVersion?: Maybe<Scalars['String']['output']>;
  isAvailableUpdate?: Maybe<Scalars['Boolean']['output']>;
  mainCopy?: Maybe<Scalars['String']['output']>;
  newVersion?: Maybe<Scalars['String']['output']>;
  titleCopy?: Maybe<Scalars['String']['output']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type UploadFiles = {
  __typename?: 'UploadFiles';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<UploadFile>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type UploadImage = {
  __typename?: 'UploadImage';
  key?: Maybe<Scalars['ID']['output']>;
  originalImageUrl?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['ID']['output']>;
};

export type UploadImages = {
  __typename?: 'UploadImages';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<UploadImage>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type UploadUserCvInput = {
  mediaId?: InputMaybe<Scalars['ID']['input']>;
};

export type UploadVerifiedSkillsResponse = {
  __typename?: 'UploadVerifiedSkillsResponse';
  countFailed?: Maybe<Scalars['Int']['output']>;
  countSuccess?: Maybe<Scalars['Int']['output']>;
  countUnverified?: Maybe<Scalars['Int']['output']>;
  countVerified?: Maybe<Scalars['Int']['output']>;
  failed?: Maybe<Array<EpVerifiedSkill>>;
  result?: Maybe<Array<EpVerifiedSkill>>;
  success?: Maybe<Array<EpVerifiedSkill>>;
};

export type UserAttribute = {
  __typename?: 'UserAttribute';
  descriptionComplete?: Maybe<Scalars['String']['output']>;
  descriptionDefault?: Maybe<Scalars['String']['output']>;
  descriptionIncomplete?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  displayTextButton?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
  isInfo?: Maybe<Scalars['Boolean']['output']>;
  isMandatory?: Maybe<Scalars['Boolean']['output']>;
  isVerifyV1?: Maybe<Scalars['Boolean']['output']>;
  isVerifyV2?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  point?: Maybe<Scalars['Int']['output']>;
  target?: Maybe<Scalars['String']['output']>;
  warningAttribute?: Maybe<Scalars['String']['output']>;
};

export type UserAttributeUserProfile = {
  __typename?: 'UserAttributeUserProfile';
  id?: Maybe<Scalars['ID']['output']>;
  index?: Maybe<Scalars['String']['output']>;
  infoStr?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isCompleteness?: Maybe<Scalars['Boolean']['output']>;
  isInfo?: Maybe<Scalars['Boolean']['output']>;
  isWarning?: Maybe<Scalars['Boolean']['output']>;
  userAttribute?: Maybe<UserAttribute>;
  userAttributeId?: Maybe<Scalars['ID']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
  warningStr?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type UserCv = {
  __typename?: 'UserCv';
  id?: Maybe<Scalars['ID']['output']>;
  isSelected?: Maybe<Scalars['Boolean']['output']>;
  mediaFile?: Maybe<MediaFile>;
  mediaId?: Maybe<Scalars['String']['output']>;
  uploadDateStr?: Maybe<Scalars['String']['output']>;
};

export type UserCvs = {
  __typename?: 'UserCvs';
  generatedCvs?: Maybe<Array<UserCv>>;
  uploadedCvs?: Maybe<Array<UserCv>>;
};

export type UserFeedbackCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isSatisfied?: InputMaybe<Scalars['Boolean']['input']>;
  referenceId: Scalars['ID']['input'];
};

export type UserFeedbackStatus = {
  __typename?: 'UserFeedbackStatus';
  status?: Maybe<Scalars['Boolean']['output']>;
};

export type UserInterest = {
  __typename?: 'UserInterest';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isSelected?: Maybe<Scalars['Boolean']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
};

export type UserInterests = {
  __typename?: 'UserInterests';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  limitSelection?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<UserInterest>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type UserLeaderBoard = {
  __typename?: 'UserLeaderBoard';
  emailStr?: Maybe<Scalars['String']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type UserLocationDropdown = {
  __typename?: 'UserLocationDropdown';
  description?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  mediaId?: Maybe<Scalars['String']['output']>;
  mediaImage?: Maybe<MediaImage>;
  screenDensity?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type UserOrder = {
  __typename?: 'UserOrder';
  createdAtStr?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoiceUrl?: Maybe<Scalars['String']['output']>;
  item?: Maybe<UserOrderItem>;
  status?: Maybe<UserOrderStatusEnum>;
  statusBadge?: Maybe<StatusBadge>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  totalPriceStr?: Maybe<Scalars['String']['output']>;
};

export type UserOrderItem = {
  __typename?: 'UserOrderItem';
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceStr?: Maybe<Scalars['String']['output']>;
};

export enum UserOrderStatusEnum {
  Expired = 'EXPIRED',
  Paid = 'PAID',
  Pending = 'PENDING',
}

export type UserOrders = {
  __typename?: 'UserOrders';
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<UserOrder>>;
  page?: Maybe<Scalars['Int']['output']>;
};

export type UserPreference = {
  __typename?: 'UserPreference';
  dataType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  resetDate?: Maybe<Scalars['Date']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  loginProvider?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
};

export type UserProfileCompleteness = {
  __typename?: 'UserProfileCompleteness';
  attributes?: Maybe<Array<Maybe<UserAttributeUserProfile>>>;
  copy?: Maybe<Scalars['String']['output']>;
  sectionName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<VerifyProfileStatus>;
};

export type UserProfileCompletenessV2 = {
  __typename?: 'UserProfileCompletenessV2';
  attributes?: Maybe<Array<Maybe<UserAttributeUserProfile>>>;
  copy?: Maybe<Scalars['String']['output']>;
  previewProfile?: Maybe<PreviewProfileBeforeApply>;
  sectionName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<VerifyProfileStatus>;
};

export type UserSalary = {
  __typename?: 'UserSalary';
  currencyId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  period?: Maybe<PeriodEnum>;
  salary?: Maybe<Scalars['Int']['output']>;
  salaryStr?: Maybe<Scalars['String']['output']>;
  userProfileId?: Maybe<Scalars['ID']['output']>;
};

export type UserSkills = {
  __typename?: 'UserSkills';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  limitSelection?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<KeyValue>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export enum UserType {
  Cons = 'CONS',
  Klep = 'KLEP',
  Ops = 'OPS',
}

export type UserVacancyRestriction = {
  __typename?: 'UserVacancyRestriction';
  events?: Maybe<Array<Maybe<Event>>>;
  list?: Maybe<Array<UserVacancyRestrictionDetail>>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UserVacancyRestrictionDetail = {
  __typename?: 'UserVacancyRestrictionDetail';
  copy?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
};

export type UserWorkPreference = {
  __typename?: 'UserWorkPreference';
  isAvailableForRelocation?: Maybe<Scalars['Boolean']['output']>;
  isAvailableForRelocationStr?: Maybe<Scalars['String']['output']>;
  jobWorkLocationSiteKey?: Maybe<Scalars['String']['output']>;
  jobWorkLocationSiteStr?: Maybe<Scalars['String']['output']>;
  userLocationPreferenceStr?: Maybe<Scalars['String']['output']>;
};

export type Vacancies = {
  __typename?: 'Vacancies';
  cityId?: Maybe<Scalars['ID']['output']>;
  cityName?: Maybe<Scalars['String']['output']>;
  copy?: Maybe<Scalars['String']['output']>;
  districtId?: Maybe<Scalars['ID']['output']>;
  districtName?: Maybe<Scalars['String']['output']>;
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  homeSectionId?: Maybe<Scalars['ID']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<Vacancy>>>;
  page?: Maybe<Scalars['Int']['output']>;
  provinceId?: Maybe<Scalars['ID']['output']>;
  provinceName?: Maybe<Scalars['String']['output']>;
  sectionDisplayName?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type Vacancy = {
  __typename?: 'Vacancy';
  benefits?: Maybe<Array<Maybe<VacancyBenefit>>>;
  city?: Maybe<Location>;
  cityId?: Maybe<Scalars['ID']['output']>;
  cityStr?: Maybe<Scalars['String']['output']>;
  closeDate?: Maybe<Scalars['Date']['output']>;
  closeDateStr?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  companyStr?: Maybe<Scalars['String']['output']>;
  contactDetail?: Maybe<Scalars['String']['output']>;
  contactDetailLink?: Maybe<Scalars['String']['output']>;
  contactType?: Maybe<Scalars['String']['output']>;
  copy?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  deferredLinkApp?: Maybe<Scalars['String']['output']>;
  deferredLinkWeb?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Location>;
  districtId?: Maybe<Scalars['ID']['output']>;
  districtStr?: Maybe<Scalars['String']['output']>;
  educationLevel?: Maybe<Scalars['String']['output']>;
  educationLevelStr?: Maybe<Scalars['String']['output']>;
  formattedDescription?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  genderStr?: Maybe<Scalars['String']['output']>;
  googleType?: Maybe<Scalars['String']['output']>;
  hasScreeningQuestion?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  infoStr?: Maybe<Scalars['String']['output']>;
  insight?: Maybe<VacancyInsight>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isBookmarked?: Maybe<Scalars['Boolean']['output']>;
  isClosed?: Maybe<Scalars['Boolean']['output']>;
  isHighlighted?: Maybe<Scalars['Boolean']['output']>;
  isPremium?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  jobCompanyId?: Maybe<Scalars['ID']['output']>;
  jobFunction?: Maybe<Scalars['String']['output']>;
  jobFunctionId?: Maybe<Scalars['ID']['output']>;
  jobLevelStr?: Maybe<Scalars['String']['output']>;
  jobPostingContent?: Maybe<Scalars['String']['output']>;
  jobRole?: Maybe<JobRole>;
  jobSpecialization?: Maybe<JobSpecialization>;
  /** @deprecated switch to jobFunction */
  jobVacancyFunction?: Maybe<Scalars['String']['output']>;
  /** @deprecated switch to jobFunctionId */
  jobVacancyFunctionId?: Maybe<Scalars['ID']['output']>;
  labelGroups?: Maybe<Array<Maybe<VacancyLabel>>>;
  locationId?: Maybe<Scalars['ID']['output']>;
  locationSite?: Maybe<Scalars['String']['output']>;
  locationSiteStr?: Maybe<Scalars['String']['output']>;
  maxAge?: Maybe<Scalars['Int']['output']>;
  maxAgeStr?: Maybe<Scalars['String']['output']>;
  minExperience?: Maybe<Scalars['Int']['output']>;
  minExperienceStr?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  positionName?: Maybe<Scalars['String']['output']>;
  postedDateStr?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  province?: Maybe<Location>;
  provinceId?: Maybe<Scalars['ID']['output']>;
  provinceStr?: Maybe<Scalars['String']['output']>;
  requirementStr?: Maybe<Scalars['String']['output']>;
  requirements?: Maybe<Array<Maybe<VacancyRequirement>>>;
  restrictions?: Maybe<JobVacancyRestrictions>;
  salaryLowerBound?: Maybe<Scalars['Float']['output']>;
  salaryLowerBoundStr?: Maybe<Scalars['String']['output']>;
  salaryUpperBound?: Maybe<Scalars['Float']['output']>;
  salaryUpperBoundStr?: Maybe<Scalars['String']['output']>;
  shareLink?: Maybe<Scalars['String']['output']>;
  /** @deprecated move to VacancyRequirement */
  skillStr?: Maybe<Scalars['String']['output']>;
  skillTags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** @deprecated move to VacancyRequirement */
  skills?: Maybe<Array<Maybe<Skill>>>;
  slug?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  typeStr?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedAtStr?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userVacancyRestriction?: Maybe<UserVacancyRestriction>;
  vacancyCount?: Maybe<Scalars['Int']['output']>;
  vacancyCountStr?: Maybe<Scalars['String']['output']>;
  validThrough?: Maybe<Scalars['Date']['output']>;
  viewCount?: Maybe<Scalars['Int']['output']>;
  workingDayEnd?: Maybe<Scalars['String']['output']>;
  workingDayEndStr?: Maybe<Scalars['String']['output']>;
  workingDayStart?: Maybe<Scalars['String']['output']>;
  workingDayStartStr?: Maybe<Scalars['String']['output']>;
  workingDayStr?: Maybe<Scalars['String']['output']>;
  workingHourEnd?: Maybe<Scalars['String']['output']>;
  workingHourEndStr?: Maybe<Scalars['String']['output']>;
  workingHourStart?: Maybe<Scalars['String']['output']>;
  workingHourStartStr?: Maybe<Scalars['String']['output']>;
  workingHourStr?: Maybe<Scalars['String']['output']>;
};

export type VacancyBenefit = {
  __typename?: 'VacancyBenefit';
  copy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type VacancyContactInformation = {
  __typename?: 'VacancyContactInformation';
  contactDetail?: Maybe<Scalars['String']['output']>;
  contactDetailLink?: Maybe<Scalars['String']['output']>;
  contactType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isKlep?: Maybe<Scalars['Boolean']['output']>;
  isTp?: Maybe<Scalars['Boolean']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  isWorkingHour?: Maybe<Scalars['Boolean']['output']>;
};

export type VacancyInsight = {
  __typename?: 'VacancyInsight';
  list?: Maybe<Array<VacancyInsightDetail>>;
  subTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  vacancyId?: Maybe<Scalars['ID']['output']>;
};

export type VacancyInsightAsset = {
  __typename?: 'VacancyInsightAsset';
  imageUrl?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
};

export type VacancyInsightDetail = {
  __typename?: 'VacancyInsightDetail';
  copy?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<VacancyInsightAsset>;
  key?: Maybe<Scalars['String']['output']>;
};

export type VacancyInsightDetailIconArgs = {
  screenDensity?: InputMaybe<ScreenDensityEnum>;
};

export enum VacancyInsightProfileMatch {
  GoodMatch = 'GOOD_MATCH',
  Match = 'MATCH',
  NotMatch = 'NOT_MATCH',
}

export type VacancyInsightSummary = {
  __typename?: 'VacancyInsightSummary';
  copy?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<VacancyInsightAsset>;
  key?: Maybe<Scalars['String']['output']>;
};

export type VacancyInsightSummaryIconArgs = {
  screenDensity?: InputMaybe<ScreenDensityEnum>;
};

export type VacancyInsightV2 = {
  __typename?: 'VacancyInsightV2';
  applicantEducation?: Maybe<VacancyInsightSummary>;
  applicantExperience?: Maybe<VacancyInsightSummary>;
  profileMatch?: Maybe<VacancyInsightSummary>;
  profileRank?: Maybe<VacancyInsightSummary>;
  totalApplicant?: Maybe<VacancyInsightSummary>;
  vacancyId: Scalars['ID']['output'];
};

export type VacancyInsightView = {
  __typename?: 'VacancyInsightView';
  list: Array<KeyValue>;
  vacancyId: Scalars['ID']['output'];
};

export type VacancyInsightViewV2 = {
  __typename?: 'VacancyInsightViewV2';
  profileMatch?: Maybe<Scalars['String']['output']>;
  profileRank?: Maybe<Scalars['String']['output']>;
  totalApplicant?: Maybe<Scalars['String']['output']>;
  vacancyId: Scalars['ID']['output'];
};

export type VacancyKeyword = {
  __typename?: 'VacancyKeyword';
  asset?: Maybe<VacancyKeywordAsset>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  screenDensity?: Maybe<Scalars['String']['output']>;
};

export type VacancyKeywordAsset = {
  __typename?: 'VacancyKeywordAsset';
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  jobVacancyMarCategoryId?: Maybe<Scalars['ID']['output']>;
  screenDensity?: Maybe<Scalars['ID']['output']>;
};

export type VacancyLabel = {
  __typename?: 'VacancyLabel';
  copy?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  textColor?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type VacancyPosition = {
  __typename?: 'VacancyPosition';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type VacancyPositions = {
  __typename?: 'VacancyPositions';
  elements?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<VacancyPosition>>;
  page?: Maybe<Scalars['Int']['output']>;
};

export type VacancyReportCategory = {
  __typename?: 'VacancyReportCategory';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type VacancyReportSubmitInput = {
  jobVacancyId: Scalars['ID']['input'];
  jobVacancyReportCategoryId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type VacancyReqCategory = {
  __typename?: 'VacancyReqCategory';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type VacancyRequirement = {
  __typename?: 'VacancyRequirement';
  id?: Maybe<Scalars['ID']['output']>;
  isLoadQuestion?: Maybe<Scalars['Boolean']['output']>;
  jobVacancyId?: Maybe<Scalars['ID']['output']>;
  jobVacancyReqCategorySubId?: Maybe<Scalars['ID']['output']>;
  questions?: Maybe<Array<Maybe<VacancyRequirementQuestion>>>;
  vacancyReqCategory?: Maybe<VacancyReqCategory>;
};

export type VacancyRequirementAnswer = {
  __typename?: 'VacancyRequirementAnswer';
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idx?: Maybe<Scalars['Int']['output']>;
  jobVacancyReqQuestionId?: Maybe<Scalars['ID']['output']>;
};

export type VacancyRequirementQuestion = {
  __typename?: 'VacancyRequirementQuestion';
  answers?: Maybe<Array<Maybe<VacancyRequirementAnswer>>>;
  code?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  jobVacancyReqCategorySubId?: Maybe<Scalars['ID']['output']>;
};

export type VacancyScreeningAnswerInput = {
  answers: Array<KeyValueInput>;
  questionId: Scalars['ID']['input'];
};

export type VacancyScreeningGroup = {
  __typename?: 'VacancyScreeningGroup';
  alertMsg?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  options?: Maybe<Array<KeyValue>>;
  questions?: Maybe<Array<VacancyScreeningQuestion>>;
  section?: Maybe<Scalars['String']['output']>;
  submitType?: Maybe<SubmitTypeEnum>;
  title?: Maybe<Scalars['String']['output']>;
  toolTipMsg?: Maybe<Scalars['String']['output']>;
  type?: Maybe<QuestionTypeEnum>;
};

export type VacancyScreeningQuestion = {
  __typename?: 'VacancyScreeningQuestion';
  key: Scalars['ID']['output'];
  optionType?: Maybe<OptionTypeEnum>;
  title?: Maybe<Scalars['String']['output']>;
};

export type VacancyScreeningQuestionSubmitInput = {
  answer: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type VacancyScreeningRequirementSubmitInput = {
  jobVacancyReqCategorySubId: Scalars['ID']['input'];
  questions?: InputMaybe<
    Array<InputMaybe<VacancyScreeningQuestionSubmitInput>>
  >;
};

export type VacancyScreeningResult = {
  __typename?: 'VacancyScreeningResult';
  pass?: Maybe<Scalars['Boolean']['output']>;
};

export type VacancyScreeningSubmitInput = {
  jobVacancyId: Scalars['ID']['input'];
  requirements?: InputMaybe<
    Array<InputMaybe<VacancyScreeningRequirementSubmitInput>>
  >;
};

export type VacancyScreeningSubmitInputV2 = {
  details: Array<VacancyScreeningAnswerInput>;
  jobVacancyId: Scalars['ID']['input'];
};

export type VacancySectionDetailsDeeplink = {
  __typename?: 'VacancySectionDetailsDeeplink';
  cityId?: Maybe<Scalars['ID']['output']>;
  cityName?: Maybe<Scalars['String']['output']>;
  copy?: Maybe<Scalars['String']['output']>;
  districtId?: Maybe<Scalars['ID']['output']>;
  districtName?: Maybe<Scalars['String']['output']>;
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  homeSectionId?: Maybe<Scalars['ID']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<Vacancy>>>;
  page?: Maybe<Scalars['Int']['output']>;
  provinceId?: Maybe<Scalars['ID']['output']>;
  provinceName?: Maybe<Scalars['String']['output']>;
  sectionDisplayName?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type VerifyPreviewPageCodeError = {
  __typename?: 'VerifyPreviewPageCodeError';
  errorCode: VerifyPreviewPageCodeErrorEnum;
  message: Scalars['String']['output'];
};

export enum VerifyPreviewPageCodeErrorEnum {
  InvalidUniqueId = 'INVALID_UNIQUE_ID',
  UncaughtError = 'UNCAUGHT_ERROR',
  VerifyFailed = 'VERIFY_FAILED',
}

export type VerifyPreviewPageCodeResult =
  | VerifyPreviewPageCodeError
  | VerifyPreviewPageCodeSuccess;

export type VerifyPreviewPageCodeSuccess = {
  __typename?: 'VerifyPreviewPageCodeSuccess';
  generatedToken: Scalars['String']['output'];
};

export type VerifyProfileStatus = {
  __typename?: 'VerifyProfileStatus';
  age?: Maybe<Scalars['Boolean']['output']>;
  education?: Maybe<Scalars['Boolean']['output']>;
  experience?: Maybe<Scalars['Boolean']['output']>;
  gender?: Maybe<Scalars['Boolean']['output']>;
  profileData?: Maybe<Scalars['Boolean']['output']>;
};

export type Video = {
  __typename?: 'Video';
  quality?: Maybe<VideoQuality>;
  video?: Maybe<VideoInfo>;
};

export type VideoInfo = {
  __typename?: 'VideoInfo';
  description?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  tags?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type VideoPreview = {
  __typename?: 'VideoPreview';
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  video: MediaVideo;
  videoId: Scalars['ID']['output'];
};

export type VideoPreviews = {
  __typename?: 'VideoPreviews';
  elements?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<VideoPreview>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type VideoQuality = {
  __typename?: 'VideoQuality';
  hd1080?: Maybe<VideoQualityInfo>;
  ld320?: Maybe<VideoQualityInfo>;
  md480?: Maybe<VideoQualityInfo>;
  md720?: Maybe<VideoQualityInfo>;
};

export type VideoQualityInfo = {
  __typename?: 'VideoQualityInfo';
  expiredSeconds?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type VotePollResult = {
  __typename?: 'VotePollResult';
  poll?: Maybe<Array<Maybe<FeedPollDetail>>>;
  quiz?: Maybe<Array<Maybe<FeedQuizDetail>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type VoucherApplied = {
  __typename?: 'VoucherApplied';
  amount?: Maybe<Scalars['Float']['output']>;
  amountStr?: Maybe<Scalars['String']['output']>;
  voucherCode?: Maybe<Scalars['String']['output']>;
};

export type WalletRedemption = {
  __typename?: 'WalletRedemption';
  amount?: Maybe<Scalars['Float']['output']>;
  bankAccName?: Maybe<Scalars['String']['output']>;
  bankAccNo?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  paymentDate?: Maybe<Scalars['String']['output']>;
  paymentDateStr?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  receiptUrl?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type WalletRedemptionUserInfo = {
  __typename?: 'WalletRedemptionUserInfo';
  bankAccName?: Maybe<Scalars['String']['output']>;
  bankAccNo?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type WalletSummary = {
  __typename?: 'WalletSummary';
  referralCode?: Maybe<Scalars['String']['output']>;
  referralLink?: Maybe<Scalars['String']['output']>;
  referralVoucherCode?: Maybe<Scalars['String']['output']>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  totalAmountStr?: Maybe<Scalars['String']['output']>;
  totalPayOut?: Maybe<Scalars['Float']['output']>;
  totalPayOutStr?: Maybe<Scalars['String']['output']>;
};

export type WalletTransaction = {
  __typename?: 'WalletTransaction';
  amount?: Maybe<Scalars['Float']['output']>;
  amountReferenceId?: Maybe<Scalars['ID']['output']>;
  amountStr?: Maybe<Scalars['String']['output']>;
  createdAtLocale?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  originReferenceId?: Maybe<Scalars['ID']['output']>;
  referenceStr?: Maybe<Scalars['String']['output']>;
  skuStr?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['ID']['output']>;
};

export type WhatsappContent = {
  __typename?: 'WhatsappContent';
  whatsappText: Scalars['String']['output'];
};

export type Whiteboard = {
  __typename?: 'Whiteboard';
  name?: Maybe<Scalars['String']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  roomToken?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
};

export type WorkPreferenceInput = {
  isAvailableForRelocation?: InputMaybe<Scalars['Boolean']['input']>;
  jobFunctionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  jobWorkLocationSiteKey?: InputMaybe<Scalars['ID']['input']>;
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type WorkPreferenceInputV2 = {
  employmentTypeKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  isAvailableForRelocation?: InputMaybe<Scalars['Boolean']['input']>;
  jobSpecializationRoleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  jobWorkLocationSiteKey?: InputMaybe<Scalars['ID']['input']>;
  locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  positionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type WorkPreferenceInputV3 = {
  employmentTypeKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  isAvailableForRelocation?: InputMaybe<Scalars['Boolean']['input']>;
  jobSpecializationRoleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  jobWorkLocationSiteKey?: InputMaybe<Scalars['ID']['input']>;
  locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  positionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  salaryAmount?: InputMaybe<Scalars['Int']['input']>;
  userInterestIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SuggestLocationInput = {
  suggestedLocation: Scalars['String']['input'];
};

export type JobApplicationCountQueryVariables = Exact<{
  filter?: InputMaybe<EpJobApplicantFilterInput>;
}>;

export type JobApplicationCountQuery = {
  __typename?: 'Query';
  jobApplicationCount?: {
    __typename?: 'EpJobApplicationCountData';
    menuId?: string | null;
    countStr?: string | null;
    count?: any | null;
  } | null;
};

export type JobApplicationSummaryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  tab?: InputMaybe<Scalars['String']['input']>;
  epStatus?: InputMaybe<EpJobApplicationEpStatusEnum>;
  lastEducationLevels?: InputMaybe<
    Array<EpEducationLevelFilter> | EpEducationLevelFilter
  >;
  educationInstitutionIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  domicile?: InputMaybe<Scalars['ID']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  locations?: InputMaybe<Array<EpLocationAreaInput> | EpLocationAreaInput>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  experienceYear?: InputMaybe<Scalars['String']['input']>;
  jobSpecializationRoles?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  skillsV2?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type JobApplicationSummaryQuery = {
  __typename?: 'Query';
  jobApplicationSummary?: {
    __typename?: 'EpJobApplicationSummary';
    total?: number | null;
    accepted?: number | null;
    saved?: number | null;
    withdrawn?: number | null;
    rejected?: number | null;
    onProgress?: number | null;
    pending?: number | null;
    interview?: number | null;
  } | null;
};

export type JobApplicationsQueryVariables = Exact<{
  filter?: InputMaybe<CommonFilter>;
  epStatus?: InputMaybe<EpJobApplicationEpStatusEnum>;
  jobVacancyId: Scalars['ID']['input'];
  lastEducationLevels?: InputMaybe<
    Array<EpEducationLevelFilter> | EpEducationLevelFilter
  >;
  educationInstitutionIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  domicile?: InputMaybe<Scalars['ID']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  experienceYear?: InputMaybe<Scalars['String']['input']>;
  jobSpecializationRoles?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  skillsV2?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  tab?: InputMaybe<Scalars['String']['input']>;
  locations?: InputMaybe<Array<EpLocationAreaInput> | EpLocationAreaInput>;
  isRestrict?: InputMaybe<Scalars['Boolean']['input']>;
  screeningQuestions?: InputMaybe<
    | Array<EpJobApplicationScreeningQuestionInput>
    | EpJobApplicationScreeningQuestionInput
  >;
}>;

export type JobApplicationsQuery = {
  __typename?: 'Query';
  jobApplications?: {
    __typename?: 'EpJobApplications';
    elements?: number | null;
    limit?: number | null;
    page?: number | null;
    size?: number | null;
    state?: EpJobApplicationEmptyState | null;
    list?: Array<{
      __typename?: 'EpJobApplication';
      id?: string | null;
      userProfileId?: string | null;
      isInvited?: boolean | null;
      jobVacancyId?: string | null;
      matchingResult?: number | null;
      name?: string | null;
      gender?: string | null;
      phoneNumber?: string | null;
      locationId?: string | null;
      status?: string | null;
      companyStatus?: string | null;
      lastEducationLevel?: string | null;
      startEducationYear?: number | null;
      endEducationYear?: number | null;
      lastJobDescription?: string | null;
      startLastJobYear?: number | null;
      endLastJobYear?: number | null;
      cityName?: string | null;
      provinceName?: string | null;
      districtName?: string | null;
      createdAt?: any | null;
      isRead?: boolean | null;
      expectedSalaryStr?: string | null;
      haveScreeningQuestionAnswers?: boolean | null;
      screeningQuestionResultStr?: string | null;
      isFullScoreScreeningQuestion?: boolean | null;
      userLastEducation?: {
        __typename?: 'EpEducation';
        periodStr?: string | null;
        educationLevel?: string | null;
        educationInstitution?: {
          __typename?: 'EpEducationInstitution';
          id?: string | null;
          name?: string | null;
        } | null;
        educationProgram?: {
          __typename?: 'EpEducationProgram';
          id?: string | null;
          name?: string | null;
        } | null;
      } | null;
      userProfile?: {
        __typename?: 'EpUserProfile';
        imageUrl?: string | null;
        age?: number | null;
        experiencesYearStr?: string | null;
        companyNote?: string | null;
        lastExperience?: {
          __typename?: 'EpExperience';
          name?: string | null;
          companyName?: string | null;
          periodStr?: string | null;
          employmentType?: {
            __typename?: 'EpEmploymentType';
            value?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type JobApplicationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type JobApplicationQuery = {
  __typename?: 'Query';
  jobApplication?: {
    __typename?: 'EpJobApplication';
    id?: string | null;
    userProfileId?: string | null;
    jobVacancyId?: string | null;
    name?: string | null;
    gender?: string | null;
    phoneNumber?: string | null;
    locationId?: string | null;
    status?: string | null;
    companyNote?: string | null;
    companyStatus?: string | null;
    lastEducationLevel?: string | null;
    startEducationYear?: number | null;
    endEducationYear?: number | null;
    lastJobDescription?: string | null;
    startLastJobYear?: number | null;
    endLastJobYear?: number | null;
    cityName?: string | null;
    provinceName?: string | null;
    createdAt?: any | null;
    isMasked?: boolean | null;
    isInvited?: boolean | null;
    isRead?: boolean | null;
    expectedSalaryStr?: string | null;
    matchingResult?: number | null;
    userLastEducation?: {
      __typename?: 'EpEducation';
      educationLevel?: string | null;
      educationInstitution?: {
        __typename?: 'EpEducationInstitution';
        id?: string | null;
        name?: string | null;
      } | null;
      educationProgram?: {
        __typename?: 'EpEducationProgram';
        id?: string | null;
        name?: string | null;
      } | null;
    } | null;
    userProfile?: {
      __typename?: 'EpUserProfile';
      id?: string | null;
      name?: string | null;
      age?: number | null;
      experiencesYearStr?: string | null;
      userEmail?: string | null;
      phoneNumber?: string | null;
      isContactRestricted: boolean;
      nickname?: string | null;
      gender?: string | null;
      genderStr?: string | null;
      imageUrl?: string | null;
      about?: string | null;
      isPrivate?: boolean | null;
      birthdate?: any | null;
      locationId?: string | null;
      companyStatus?: string | null;
      companyNote?: string | null;
      updatedAt?: any | null;
      updatedAtStr?: string | null;
      educations?: Array<{
        __typename?: 'EpEducation';
        id?: string | null;
        userProfileId?: string | null;
        educationLevel?: string | null;
        description?: string | null;
        startYear?: string | null;
        endYear?: string | null;
        startMonth?: string | null;
        endMonth?: string | null;
        periodStr?: string | null;
        educationInstitution?: {
          __typename?: 'EpEducationInstitution';
          id?: string | null;
          name?: string | null;
        } | null;
        educationProgram?: {
          __typename?: 'EpEducationProgram';
          id?: string | null;
          name?: string | null;
        } | null;
      } | null> | null;
      experiences?: Array<{
        __typename?: 'EpExperience';
        periodStr?: string | null;
        name?: string | null;
        skills?: string | null;
        userProfileId?: string | null;
        startYear?: string | null;
        jobFunction?: string | null;
        endYear?: string | null;
        id?: string | null;
        employmentTypeStr?: string | null;
        description?: string | null;
        companyName?: string | null;
        jvSpecializationRoleId?: string | null;
        jvRole?: string | null;
      } | null> | null;
      skills?: Array<{
        __typename?: 'EpUserSkillV2';
        displayName?: string | null;
        id?: string | null;
        name?: string | null;
      } | null> | null;
      links?: Array<{
        __typename?: 'EpSupportLink';
        id?: string | null;
        userProfileId?: string | null;
        name?: string | null;
        link?: string | null;
        supportLinkCategory?: {
          __typename?: 'EpSupportLinkCategory';
          id?: string | null;
          name?: string | null;
          displayName?: string | null;
          prefix?: string | null;
          asset?: {
            __typename?: 'EpSupportLinkCategoryAsset';
            id?: string | null;
            imageUrl?: string | null;
          } | null;
        } | null;
      } | null> | null;
      district?: {
        __typename?: 'EpLocation';
        id?: string | null;
        parentId?: string | null;
        name?: string | null;
        level?: number | null;
      } | null;
      city?: {
        __typename?: 'EpLocation';
        id?: string | null;
        parentId?: string | null;
        name?: string | null;
        level?: number | null;
      } | null;
      province?: {
        __typename?: 'EpLocation';
        id?: string | null;
        parentId?: string | null;
        name?: string | null;
        level?: number | null;
      } | null;
      cv?: { __typename?: 'MediaFile'; url?: string | null } | null;
    } | null;
  } | null;
};

export type UpdateBulkJobApplicationCompanyStatusMutationVariables = Exact<{
  data?: InputMaybe<EpUpdateBulkJobApplicationCompanyStatusInput>;
}>;

export type UpdateBulkJobApplicationCompanyStatusMutation = {
  __typename?: 'Mutation';
  updateBulkJobApplicationCompanyStatus?: {
    __typename?: 'MutationResponse';
    isSuccess: boolean;
    error?: string | null;
    warning?: string | null;
    data?: string | null;
  } | null;
};

export type UpdateBulkJobApplicationCompanyStatusV2MutationVariables = Exact<{
  data?: InputMaybe<EpUpdateBulkJobApplicationCompanyStatusInput>;
}>;

export type UpdateBulkJobApplicationCompanyStatusV2Mutation = {
  __typename?: 'Mutation';
  updateBulkJobApplicationCompanyStatusV2?: {
    __typename?: 'EpUpdateBulkJobApplicationCompanyStatusResponse';
    isSuccess: boolean;
    error?: string | null;
    warning?: string | null;
    data?: {
      __typename?: 'EpUpdateBulkJobApplicationCompanyStatusData';
      message?: string | null;
      countSuccess?: number | null;
      countFailed?: number | null;
      result?: Array<{
        __typename?: 'EpJobApplication';
        id?: string | null;
        status?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type JobApplicationSortDropdownQueryVariables = Exact<{
  [key: string]: never;
}>;

export type JobApplicationSortDropdownQuery = {
  __typename?: 'Query';
  jobApplicationSortDropdown?: Array<{
    __typename?: 'KeyValue';
    key?: string | null;
    value?: string | null;
  } | null> | null;
};

export type UpdateJobApplicationNoteMutationVariables = Exact<{
  data?: InputMaybe<EpJobApplicationNoteInput>;
}>;

export type UpdateJobApplicationNoteMutation = {
  __typename?: 'Mutation';
  updateJobApplicationNote?: {
    __typename?: 'MutationResponse';
    isSuccess: boolean;
    error?: string | null;
    data?: string | null;
  } | null;
};

export type JobApplicationEpStatusDropdownQueryVariables = Exact<{
  [key: string]: never;
}>;

export type JobApplicationEpStatusDropdownQuery = {
  __typename?: 'Query';
  jobApplicationEpStatusDropdown: Array<{
    __typename?: 'EpJobApplicationStatus';
    key?: EpJobApplicationEpStatusEnum | null;
    value?: string | null;
  } | null>;
};

export type UpdateJobApplicationStatusAndRemarksMutationVariables = Exact<{
  data: EpJobApplicationStatusAndRemarksUpdateInput;
}>;

export type UpdateJobApplicationStatusAndRemarksMutation = {
  __typename?: 'Mutation';
  updateJobApplicationStatusAndRemarks?: {
    __typename?: 'EpJobApplicationRemarks';
    isSuccess: boolean;
    error?: string | null;
    data?: {
      __typename?: 'EpJobApplicationRemarksData';
      note?: string | null;
    } | null;
  } | null;
};

export type JobApplicationHistoryLabelsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type JobApplicationHistoryLabelsQuery = {
  __typename?: 'Query';
  jobApplicationHistoryLabels: Array<{
    __typename?: 'EpJobApplicationHistoryLabel';
    label?: string | null;
    displayName?: string | null;
    textColor?: string | null;
    bgColor?: string | null;
  } | null>;
};

export type JobApplicationPrevNextQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  jobVacancyId: Scalars['ID']['input'];
  epStatus?: InputMaybe<EpJobApplicationEpStatusEnum>;
  tab?: InputMaybe<Scalars['String']['input']>;
  lastEducationLevels?: InputMaybe<
    Array<EpEducationLevelFilter> | EpEducationLevelFilter
  >;
  educationInstitutionIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  gender?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  locations?: InputMaybe<Array<EpLocationAreaInput> | EpLocationAreaInput>;
  jobSpecializationRoles?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  skillsV2?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  domicile?: InputMaybe<Scalars['ID']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  experienceYear?: InputMaybe<Scalars['String']['input']>;
  screeningQuestions?: InputMaybe<
    | Array<EpJobApplicationScreeningQuestionInput>
    | EpJobApplicationScreeningQuestionInput
  >;
}>;

export type JobApplicationPrevNextQuery = {
  __typename?: 'Query';
  jobApplicationPrevNext?: {
    __typename?: 'EpJobApplicationPrevNext';
    prevId?: string | null;
    id?: string | null;
    nextId?: string | null;
  } | null;
};

export type JobApplicationRejectedReasonCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type JobApplicationRejectedReasonCategoriesQuery = {
  __typename?: 'Query';
  jobApplicationRejectedReasonCategories?: Array<{
    __typename?: 'EpJobApplicationRejectedReasonCategory';
    key: string;
    value: string;
    disabledKeys?: Array<string> | null;
    reasonTitle?: string | null;
    reasonDescription?: string | null;
    reasonType: string;
  }> | null;
};

export type JobApplicationRejectedReasonsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type JobApplicationRejectedReasonsQuery = {
  __typename?: 'Query';
  jobApplicationRejectedReasons?: Array<string> | null;
};

export type UserCvQueryVariables = Exact<{
  userProfileId: Scalars['ID']['input'];
  cvFileNameForDownload?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserCvQuery = {
  __typename?: 'Query';
  userCv?: {
    __typename?: 'EpUserCv';
    id?: string | null;
    userProfileIdStr?: string | null;
    cvStatus?: EpUserCvStatus | null;
    updatedAtStr?: string | null;
    cv?: { __typename?: 'MediaFile'; url?: string | null } | null;
  } | null;
};

export type UpdateJobApplicationIsReadMutationVariables = Exact<{
  data?: InputMaybe<EpUpdateJobApplicationIsRead>;
}>;

export type UpdateJobApplicationIsReadMutation = {
  __typename?: 'Mutation';
  updateJobApplicationIsRead?: {
    __typename?: 'MutationResponse';
    isSuccess: boolean;
    error?: string | null;
    warning?: string | null;
    data?: string | null;
  } | null;
};

export type JobApplicationScreeningQueryVariables = Exact<{
  jobApplicantId: Scalars['ID']['input'];
  screeningQuestions?: InputMaybe<
    | Array<InputMaybe<EpJobApplicationScreeningQuestionInput>>
    | InputMaybe<EpJobApplicationScreeningQuestionInput>
  >;
}>;

export type JobApplicationScreeningQuery = {
  __typename?: 'Query';
  jobApplicationScreening?: {
    __typename?: 'EpJobApplicationScreening';
    noticeStr?: string | null;
    resultStr?: string | null;
    isFullScore?: boolean | null;
    data?: Array<{
      __typename?: 'EpJobApplicationScreeningData';
      id?: string | null;
      title?: string | null;
      topic: JobApplicationScreeningTopic;
      values?: Array<{
        __typename?: 'EpJobApplicationScreeningDataValue';
        answer?: string | null;
        id?: string | null;
        isMatch?: boolean | null;
        question?: string | null;
        requirement?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type VerifyCodeQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type VerifyCodeQuery = {
  __typename?: 'Query';
  verifyCode?: {
    __typename?: 'EpAuthResponse';
    isSuccess: boolean;
    error?: string | null;
    warning?: string | null;
    data?: {
      __typename?: 'EpAuth';
      authCode?: string | null;
      email?: string | null;
      status?: EpVerifyCodeStatus | null;
    } | null;
  } | null;
};

export type SignupMutationVariables = Exact<{
  data: EpUserSignUpInput;
}>;

export type SignupMutation = {
  __typename?: 'Mutation';
  signup: {
    __typename?: 'MutationResponse';
    isSuccess: boolean;
    error?: string | null;
    data?: string | null;
  };
};

export type SelfSignupMutationVariables = Exact<{
  data: EpUserSignUpInput;
}>;

export type SelfSignupMutation = {
  __typename?: 'Mutation';
  selfSignup: {
    __typename?: 'EpSignUpResponse';
    isSuccess: boolean;
    error?: string | null;
    data?: { __typename?: 'EpSignUpResult'; id?: string | null } | null;
  };
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type ForgotPasswordMutation = {
  __typename?: 'Mutation';
  forgotPassword?: {
    __typename?: 'MutationResponse';
    isSuccess: boolean;
    error?: string | null;
    data?: string | null;
  } | null;
};

export type ForgotPasswordSubmissionMutationVariables = Exact<{
  data: EpUserForgotPasswordInput;
}>;

export type ForgotPasswordSubmissionMutation = {
  __typename?: 'Mutation';
  forgotPasswordSubmission?: {
    __typename?: 'MutationResponse';
    isSuccess: boolean;
    error?: string | null;
    data?: string | null;
  } | null;
};

export type ResetPasswordMutationVariables = Exact<{
  data: EpUserResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword?: {
    __typename?: 'MutationResponse';
    isSuccess: boolean;
    error?: string | null;
    data?: string | null;
  } | null;
};

export type ResendSignUpLinkMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ResendSignUpLinkMutation = {
  __typename?: 'Mutation';
  resendSignUpLink: {
    __typename?: 'EpResendSignUpLinkResponse';
    isSuccess: boolean;
    error?: string | null;
    warning?: string | null;
    data?: { __typename?: 'EpSignUpResult'; id?: string | null } | null;
  };
};

export type VerifySelfSignUpCodeQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type VerifySelfSignUpCodeQuery = {
  __typename?: 'Query';
  verifySelfSignUpCode: {
    __typename?: 'EpAuthResponse';
    isSuccess: boolean;
    error?: string | null;
    warning?: string | null;
    data?: {
      __typename?: 'EpAuth';
      id?: string | null;
      authCode?: string | null;
      email?: string | null;
      status?: EpVerifyCodeStatus | null;
    } | null;
  };
};

export type JobVacancyCandidatesQueryVariables = Exact<{
  filter?: InputMaybe<CommonFilter>;
  status?: InputMaybe<EpJobVacancyCandidateStatus>;
  jobVacancyId: Scalars['ID']['input'];
  educationLevels?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  educationInstitutionIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  gender?: InputMaybe<Scalars['String']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  locations?: InputMaybe<Array<EpLocationAreaInput> | EpLocationAreaInput>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  experienceYear?: InputMaybe<Scalars['String']['input']>;
  jobFunctions?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  skills?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  invitationStatus?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type JobVacancyCandidatesQuery = {
  __typename?: 'Query';
  jobVacancyCandidates?: {
    __typename?: 'EpJobVacancyCandidates';
    elements?: number | null;
    page?: number | null;
    hasNext?: boolean | null;
    hasPrev?: boolean | null;
    state?: EpJobVacancyCandidateEmptyState | null;
    list?: Array<{
      __typename?: 'EpJobVacancyCandidate';
      id?: string | null;
      jobCompanyId?: string | null;
      jobVacancyId?: string | null;
      jobApplicationId?: string | null;
      invitationStatus?: EpInvitationStatus | null;
      invitedAt?: string | null;
      matchingResult?: number | null;
      userProfileId?: string | null;
      expectedSalaryStr?: string | null;
      userLastEducation?: {
        __typename?: 'EpEducation';
        periodStr?: string | null;
        educationLevel?: string | null;
        educationInstitution?: {
          __typename?: 'EpEducationInstitution';
          id?: string | null;
          name?: string | null;
        } | null;
        educationProgram?: {
          __typename?: 'EpEducationProgram';
          id?: string | null;
          name?: string | null;
        } | null;
      } | null;
      userProfile?: {
        __typename?: 'EpUserProfile';
        name?: string | null;
        imageUrl?: string | null;
        age?: number | null;
        experiencesYearStr?: string | null;
        city?: { __typename?: 'EpLocation'; name?: string | null } | null;
        district?: { __typename?: 'EpLocation'; name?: string | null } | null;
      } | null;
      userLastExperience?: {
        __typename?: 'EpExperience';
        name?: string | null;
        companyName?: string | null;
        periodStr?: string | null;
        employmentTypeStr?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type JobVacancyCandidateSummaryQueryVariables = Exact<{
  jobVacancyId: Scalars['ID']['input'];
  educationLevels?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  educationInstitutionIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  gender?: InputMaybe<Scalars['String']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  locations?: InputMaybe<Array<EpLocationAreaInput> | EpLocationAreaInput>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  experienceYear?: InputMaybe<Scalars['String']['input']>;
  jobFunctions?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  skills?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  invitationStatus?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type JobVacancyCandidateSummaryQuery = {
  __typename?: 'Query';
  jobVacancyCandidateSummary?: {
    __typename?: 'EpJobVacancyCandidateSummary';
    total?: number | null;
    available?: number | null;
    invitationSent?: number | null;
    notInterested?: number | null;
  } | null;
};

export type JobVacancyCandidateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type JobVacancyCandidateQuery = {
  __typename?: 'Query';
  jobVacancyCandidate?: {
    __typename?: 'EpJobVacancyCandidate';
    id?: string | null;
    jobCompanyId?: string | null;
    jobVacancyId?: string | null;
    jobApplicationId?: string | null;
    invitationStatus?: EpInvitationStatus | null;
    invitedAt?: string | null;
    matchingResult?: number | null;
    matchingScore?: number | null;
    userProfileId?: string | null;
    expectedSalaryStr?: string | null;
    status?: EpJobVacancyCandidateStatus | null;
    userProfile?: {
      __typename?: 'EpUserProfile';
      id?: string | null;
      name?: string | null;
      age?: number | null;
      experiencesYearStr?: string | null;
      userEmail?: string | null;
      nickname?: string | null;
      gender?: string | null;
      genderStr?: string | null;
      imageUrl?: string | null;
      about?: string | null;
      phoneNumber?: string | null;
      isPrivate?: boolean | null;
      birthdate?: any | null;
      locationId?: string | null;
      companyStatus?: string | null;
      companyNote?: string | null;
      updatedAt?: any | null;
      updatedAtStr?: string | null;
      educations?: Array<{
        __typename?: 'EpEducation';
        id?: string | null;
        userProfileId?: string | null;
        educationLevel?: string | null;
        description?: string | null;
        startYear?: string | null;
        endYear?: string | null;
        startMonth?: string | null;
        endMonth?: string | null;
        periodStr?: string | null;
        educationInstitution?: {
          __typename?: 'EpEducationInstitution';
          id?: string | null;
          name?: string | null;
        } | null;
        educationProgram?: {
          __typename?: 'EpEducationProgram';
          id?: string | null;
          name?: string | null;
        } | null;
      } | null> | null;
      experiences?: Array<{
        __typename?: 'EpExperience';
        periodStr?: string | null;
        name?: string | null;
        skills?: string | null;
        userProfileId?: string | null;
        startYear?: string | null;
        jobFunction?: string | null;
        endYear?: string | null;
        id?: string | null;
        employmentTypeStr?: string | null;
        description?: string | null;
        companyName?: string | null;
        jvSpecializationRoleId?: string | null;
        jvRole?: string | null;
      } | null> | null;
      skills?: Array<{
        __typename?: 'EpUserSkillV2';
        displayName?: string | null;
        id?: string | null;
        name?: string | null;
      } | null> | null;
      links?: Array<{
        __typename?: 'EpSupportLink';
        id?: string | null;
        userProfileId?: string | null;
        name?: string | null;
        link?: string | null;
        supportLinkCategory?: {
          __typename?: 'EpSupportLinkCategory';
          id?: string | null;
          name?: string | null;
          displayName?: string | null;
          prefix?: string | null;
          asset?: {
            __typename?: 'EpSupportLinkCategoryAsset';
            id?: string | null;
            imageUrl?: string | null;
          } | null;
        } | null;
      } | null> | null;
      district?: {
        __typename?: 'EpLocation';
        id?: string | null;
        parentId?: string | null;
        name?: string | null;
        level?: number | null;
      } | null;
      city?: {
        __typename?: 'EpLocation';
        id?: string | null;
        parentId?: string | null;
        name?: string | null;
        level?: number | null;
      } | null;
      province?: {
        __typename?: 'EpLocation';
        id?: string | null;
        parentId?: string | null;
        name?: string | null;
        level?: number | null;
      } | null;
      cv?: { __typename?: 'MediaFile'; url?: string | null } | null;
    } | null;
    userLastEducation?: {
      __typename?: 'EpEducation';
      educationLevel?: string | null;
      educationProgram?: {
        __typename?: 'EpEducationProgram';
        name?: string | null;
      } | null;
    } | null;
    userLastExperience?: {
      __typename?: 'EpExperience';
      name?: string | null;
      companyName?: string | null;
      periodStr?: string | null;
      employmentTypeStr?: string | null;
    } | null;
  } | null;
};

export type JobVacancyCandidatePrevNextQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  matchingScore?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<EpJobVacancyCandidateStatus>;
  jobVacancyId: Scalars['ID']['input'];
  educationLevels?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  educationInstitutionIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  gender?: InputMaybe<Scalars['String']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  locations?: InputMaybe<Array<EpLocationAreaInput> | EpLocationAreaInput>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  experienceYear?: InputMaybe<Scalars['String']['input']>;
  jobFunctions?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  skills?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  invitationStatus?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type JobVacancyCandidatePrevNextQuery = {
  __typename?: 'Query';
  jobVacancyCandidatePrevNext?: {
    __typename?: 'EpJobVacancyCandidatePrevNext';
    id?: string | null;
    prevId?: string | null;
    nextId?: string | null;
  } | null;
};

export type DeleteJobVacancyCandidateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteJobVacancyCandidateMutation = {
  __typename?: 'Mutation';
  deleteJobVacancyCandidate?: {
    __typename?: 'MutationResponse';
    isSuccess: boolean;
    data?: string | null;
    error?: string | null;
  } | null;
};

export type VerifyJobVacancyCandidateInvitationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type VerifyJobVacancyCandidateInvitationQuery = {
  __typename?: 'Query';
  verifyJobVacancyCandidateInvitation: {
    __typename?: 'EpInviteJobVacancyCandidateVerification';
    templateStr?: string | null;
    status?: EpInviteJobVacancyCandidateVerificationStatus | null;
    jobApplicationId?: string | null;
    copyStr?: string | null;
  };
};

export type JobVacancyCandidateCountQueryVariables = Exact<{
  jobVacancyId: Scalars['ID']['input'];
  educationLevels?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  educationInstitutionIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  gender?: InputMaybe<Scalars['String']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  locations?: InputMaybe<Array<EpLocationAreaInput> | EpLocationAreaInput>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  experienceYear?: InputMaybe<Scalars['String']['input']>;
  jobFunctions?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  skills?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  status: EpJobVacancyCandidateStatus;
  invitationStatus?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type JobVacancyCandidateCountQuery = {
  __typename?: 'Query';
  jobVacancyCandidateCount?: {
    __typename?: 'EpJobVacancyCandidateCount';
    total?: number | null;
  } | null;
};

export type InviteJobVacancyCandidateMutationVariables = Exact<{
  data: EpInviteJobVacancyCandidateInput;
}>;

export type InviteJobVacancyCandidateMutation = {
  __typename?: 'Mutation';
  inviteJobVacancyCandidate: {
    __typename?: 'MutationResponse';
    isSuccess: boolean;
    data?: string | null;
    error?: string | null;
  };
};

export type JobSpecializationsQueryVariables = Exact<{
  pagination: Pagination;
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type JobSpecializationsQuery = {
  __typename?: 'Query';
  jobSpecializations?: {
    __typename?: 'JobSpecializations';
    list?: Array<{
      __typename?: 'JobSpecialization';
      id?: string | null;
      name?: string | null;
      displayName?: string | null;
    } | null> | null;
  } | null;
};

export type JobSpecializationRolesQueryVariables = Exact<{
  jobSpecializationId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
  priority?: InputMaybe<EpJobSpecializationRolesPriority>;
  positionName?: InputMaybe<Scalars['String']['input']>;
}>;

export type JobSpecializationRolesQuery = {
  __typename?: 'Query';
  jobSpecializationRoles?: {
    __typename?: 'JobSpecializationRoles';
    elements?: number | null;
    hasNextPage?: boolean | null;
    hasPrevPage?: boolean | null;
    limit?: number | null;
    page?: number | null;
    size?: number | null;
    list?: Array<{
      __typename?: 'JobSpecializationRole';
      id?: string | null;
      displayName?: string | null;
      groupBy?: EpJobSpecializationRolesGroupBy | null;
      groupByStr?: string | null;
      isRecommended?: boolean | null;
      name?: string | null;
      jobRole?: {
        __typename?: 'JobRole';
        displayName?: string | null;
        id?: string | null;
        jobSpecializationId?: string | null;
        name?: string | null;
      } | null;
      jobSpecialization?: {
        __typename?: 'JobSpecialization';
        displayName?: string | null;
        id?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type JobSpecializationRoleSkillsQueryVariables = Exact<{
  excludeIds?: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  jobSpecializationRoleId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
  priority?: InputMaybe<EpJobSpecializationRoleSkillsPriority>;
}>;

export type JobSpecializationRoleSkillsQuery = {
  __typename?: 'Query';
  jobSpecializationRoleSkills?: {
    __typename?: 'JobSpecializationRoleSkills';
    elements?: number | null;
    hasNextPage?: boolean | null;
    hasPrevPage?: boolean | null;
    limit?: number | null;
    page?: number | null;
    size?: number | null;
    list?: Array<{
      __typename?: 'JobSkill';
      displayName?: string | null;
      groupBy?: EpJobSpecializationRoleSkillsGroupBy | null;
      groupByStr?: string | null;
      id?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type JobVacancyLevelDropdownQueryVariables = Exact<{
  [key: string]: never;
}>;

export type JobVacancyLevelDropdownQuery = {
  __typename?: 'Query';
  jobVacancyLevelDropdown?: {
    __typename?: 'EpJobLevelDropdowns';
    list?: Array<{
      __typename?: 'KeyValue';
      key?: string | null;
      value?: string | null;
    } | null> | null;
  } | null;
};

export type JobVacancyTypeDropdownQueryVariables = Exact<{
  [key: string]: never;
}>;

export type JobVacancyTypeDropdownQuery = {
  __typename?: 'Query';
  jobVacancyTypeDropdown?: {
    __typename?: 'EpJobVacancyTypes';
    list?: Array<{
      __typename?: 'KeyValue';
      key?: string | null;
      value?: string | null;
    } | null> | null;
  } | null;
};

export type JobVacancyLocationSiteDropdownQueryVariables = Exact<{
  [key: string]: never;
}>;

export type JobVacancyLocationSiteDropdownQuery = {
  __typename?: 'Query';
  jobVacancyLocationSiteDropdown?: {
    __typename?: 'EpJobVacancyLocationSites';
    list?: Array<{
      __typename?: 'JobVacancyLocationSitesKeyValue';
      key?: string | null;
      value?: string | null;
      description?: string | null;
    } | null> | null;
  } | null;
};

export type JobVacancyContactTypeDropdownQueryVariables = Exact<{
  [key: string]: never;
}>;

export type JobVacancyContactTypeDropdownQuery = {
  __typename?: 'Query';
  jobVacancyContactTypeDropdown?: Array<{
    __typename?: 'KeyValue';
    value?: string | null;
    key?: string | null;
  } | null> | null;
};

export type EducationLevelDropdownQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EducationLevelDropdownQuery = {
  __typename?: 'Query';
  educationLevelDropdown?: Array<{
    __typename?: 'EpEducationLevelKeyValue';
    key?: EpEducationLevel | null;
    value?: string | null;
  } | null> | null;
};

export type EpEducationProgramsQueryVariables = Exact<{
  level?: InputMaybe<EpEducationLevel>;
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
}>;

export type EpEducationProgramsQuery = {
  __typename?: 'Query';
  educationPrograms?: {
    __typename?: 'EpEducationPrograms';
    elements?: number | null;
    hasNextPage?: boolean | null;
    hasPrevPage?: boolean | null;
    limit?: number | null;
    page?: number | null;
    size?: number | null;
    list?: Array<{
      __typename?: 'EpEducationProgram';
      id?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type EducationInstitutionsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
}>;

export type EducationInstitutionsQuery = {
  __typename?: 'Query';
  educationInstitutions?: {
    __typename?: 'EpEducationInstitutions';
    elements?: number | null;
    hasNextPage?: boolean | null;
    hasPrevPage?: boolean | null;
    limit?: number | null;
    page?: number | null;
    size?: number | null;
    list?: Array<{
      __typename?: 'EpEducationInstitution';
      id?: string | null;
      name?: string | null;
      displayName?: string | null;
      rank?: number | null;
    } | null> | null;
  } | null;
};

export type GenderDropdownQueryVariables = Exact<{ [key: string]: never }>;

export type GenderDropdownQuery = {
  __typename?: 'Query';
  genderDropdown?: Array<{
    __typename?: 'KeyValue';
    key?: string | null;
    value?: string | null;
  } | null> | null;
};

export type LocationProvincesQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  hasVacancy?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type LocationProvincesQuery = {
  __typename?: 'Query';
  locationProvinces?: Array<{
    __typename?: 'EpLocation';
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type LocationCitiesQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  hasVacancy?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type LocationCitiesQuery = {
  __typename?: 'Query';
  locationCities?: Array<{
    __typename?: 'EpLocation';
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type LocationDistricsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  hasVacancy?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type LocationDistricsQuery = {
  __typename?: 'Query';
  locationDistrics?: Array<{
    __typename?: 'EpLocation';
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type LocationDetailQueryVariables = Exact<{
  locationId: Scalars['ID']['input'];
}>;

export type LocationDetailQuery = {
  __typename?: 'Query';
  locationCityDetail: {
    __typename?: 'EpLocation';
    id?: string | null;
    parentId?: string | null;
    name?: string | null;
    level?: number | null;
    hasVacancy?: boolean | null;
  };
};

export type CompanySizesQueryVariables = Exact<{ [key: string]: never }>;

export type CompanySizesQuery = {
  __typename?: 'Query';
  companySizes?: Array<{
    __typename?: 'EpJobCompanySize';
    id?: string | null;
    name?: string | null;
    size?: string | null;
  }> | null;
};

export type CompanyIndustriesQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyIndustriesQuery = {
  __typename?: 'Query';
  companyIndustries?: {
    __typename?: 'EpJobCompanyIndustries';
    list?: Array<{
      __typename?: 'EpJobCompanyIndustry';
      id?: string | null;
      name?: string | null;
    }> | null;
  } | null;
};

export type UsersQueryVariables = Exact<{ [key: string]: never }>;

export type UsersQuery = {
  __typename?: 'Query';
  users: Array<{
    __typename?: 'EpUser';
    username?: string | null;
    companyId?: string | null;
  }>;
};

export type UserConfigEmailNotificationIntervalDropdownQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserConfigEmailNotificationIntervalDropdownQuery = {
  __typename?: 'Query';
  userConfigEmailNotificationIntervalDropdown: Array<{
    __typename?: 'EpUserConfigEmailNotificationIntervalDropdown';
    key: string;
    value: string;
    description: string;
  }>;
};

export type ExperienceYearDropdownQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ExperienceYearDropdownQuery = {
  __typename?: 'Query';
  experienceYearDropdown: Array<{
    __typename?: 'EpExperienceYearDropdown';
    key?: EpExperienceLevel | null;
    value?: string | null;
  }>;
};

export type ExperienceJobFunctionsQueryVariables = Exact<{
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExperienceJobFunctionsQuery = {
  __typename?: 'Query';
  experienceJobFunctions: {
    __typename?: 'EpExperienceJobFunctions';
    elements?: number | null;
    page?: number | null;
    list?: Array<{
      __typename?: 'EpExperienceJobFunction';
      key?: string | null;
      value?: string | null;
    } | null> | null;
  };
};

export type UserSkillsQueryVariables = Exact<{
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserSkillsQuery = {
  __typename?: 'Query';
  userSkills: {
    __typename?: 'EpUserSkills';
    elements?: number | null;
    page?: number | null;
    list?: Array<{
      __typename?: 'EpUserSkill';
      key?: string | null;
      value?: string | null;
    } | null> | null;
  };
};

export type LocationAreasQueryVariables = Exact<{
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type LocationAreasQuery = {
  __typename?: 'Query';
  locationAreas: {
    __typename?: 'EpLocationAreas';
    page?: number | null;
    elements?: number | null;
    list: Array<{
      __typename?: 'EpLocationArea';
      name: string;
      displayName: string;
      type: EpLocationAreaType;
    }>;
  };
};

export type JobVacancyBenefitsQueryVariables = Exact<{ [key: string]: never }>;

export type JobVacancyBenefitsQuery = {
  __typename?: 'Query';
  jobVacancyBenefits?: Array<{
    __typename?: 'KeyValue';
    key?: string | null;
    value?: string | null;
  } | null> | null;
};

export type MenuTabsQueryVariables = Exact<{
  domain: EpMenuTabDomain;
}>;

export type MenuTabsQuery = {
  __typename?: 'Query';
  menuTabs?: Array<{
    __typename?: 'EpMenuTab';
    id: string;
    parentId?: string | null;
    domain: string;
    name?: string | null;
    displayName?: string | null;
    textColor?: string | null;
    bgColor?: string | null;
    isLock?: boolean | null;
    iconUrl?: string | null;
    message?: string | null;
    isDisplayLink?: boolean | null;
    badgeLabel?: string | null;
    nextLevelMenuTab?: Array<{
      __typename?: 'EpMenuTab';
      id: string;
      parentId?: string | null;
      domain: string;
      name?: string | null;
      displayName?: string | null;
      textColor?: string | null;
      bgColor?: string | null;
      countTextColor?: string | null;
      countBgColor?: string | null;
      description?: string | null;
      tooltipDescription?: string | null;
    } | null> | null;
  } | null> | null;
};

export type DocumentsCertificatesQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type DocumentsCertificatesQuery = {
  __typename?: 'Query';
  documentsCertificates?: {
    __typename?: 'EpScreeningQuestionDocCerts';
    elements?: number | null;
    hasNextPage?: boolean | null;
    hasPrevPage?: boolean | null;
    limit?: number | null;
    page?: number | null;
    size?: number | null;
    list?: Array<{
      __typename?: 'EpScreeningQuestionDocCert';
      id?: string | null;
      name?: string | null;
      displayName?: string | null;
    } | null> | null;
  } | null;
};

export type JobVacancyPositionNamesQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
}>;

export type JobVacancyPositionNamesQuery = {
  __typename?: 'Query';
  jobVacancyPositionNames?: {
    __typename?: 'EpJobVacancyPositionNames';
    elements?: number | null;
    hasNextPage?: boolean | null;
    hasPrevPage?: boolean | null;
    isSuccess?: boolean | null;
    limit?: number | null;
    page?: number | null;
    size?: number | null;
    list?: Array<{
      __typename?: 'EpJobVacancyPositionName';
      id?: string | null;
      name?: string | null;
      displayName?: string | null;
    } | null> | null;
  } | null;
};

export type InvitationStatusDropdownQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InvitationStatusDropdownQuery = {
  __typename?: 'Query';
  invitationStatusDropdown?: Array<{
    __typename?: 'EpInvitationStatusDropdownItem';
    id: string;
    name: string;
  }> | null;
};

export type InterviewSessionDropdownQueryVariables = Exact<{
  excludeIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  includePersonalAndPassedSessions?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
  vacancyId: Scalars['ID']['input'];
}>;

export type InterviewSessionDropdownQuery = {
  __typename?: 'Query';
  interviewSessionDropdown?: {
    __typename?: 'EpInterviewSessionDropdowns';
    elements?: number | null;
    hasNextPage?: boolean | null;
    hasPrevPage?: boolean | null;
    limit?: number | null;
    page?: number | null;
    size?: number | null;
    list?: Array<{
      __typename?: 'EpInterviewSessionDropdown';
      endTimeStr: string;
      id: string;
      name: string;
      numberOfApplicants: number;
      recruiterMessage?: string | null;
      recruiterPhoneNumber?: string | null;
      schedule: string;
      sizeType: EpInterviewSessionSizeType;
      startTimeStr: string;
      type: EpInterviewSessionType;
      vacancyId: string;
      offlineDetail?: {
        __typename?: 'EpInterviewSessionOfflineDetail';
        address: string;
        url?: string | null;
      } | null;
      onlineDetail?: {
        __typename?: 'EpInterviewSessionOnlineDetail';
        password?: string | null;
        url: string;
      } | null;
    } | null> | null;
  } | null;
};

export type CompanyQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'EpJobCompany';
    id?: string | null;
    name?: string | null;
    code?: string | null;
    companyType?: string | null;
    accManager?: string | null;
    displayName?: string | null;
    description?: string | null;
    contactPhone?: string | null;
    contactWhatsapp?: string | null;
    contactEmail?: string | null;
    contactWeblink?: string | null;
    foundedYear?: number | null;
    nib?: string | null;
    legalDocumentUrl?: string | null;
    logoUrl?: string | null;
    postalCode?: number | null;
    address?: string | null;
    verificationStatus?: EpCompanyVerificationStatus | null;
    verificationStatusStr?: string | null;
    verificationStatusNotice?: string | null;
    verificationStatusDisplayStr?: string | null;
    logo?: {
      __typename?: 'MediaImage';
      url?: string | null;
      id: string;
    } | null;
    legalDocument?: {
      __typename?: 'MediaFile';
      url?: string | null;
      id: string;
    } | null;
    jobCompanyIndustry?: {
      __typename?: 'EpJobCompanyIndustry';
      id?: string | null;
      name?: string | null;
    } | null;
    jobCompanySize?: {
      __typename?: 'EpJobCompanySize';
      id?: string | null;
      name?: string | null;
      size?: string | null;
    } | null;
    locationProvince?: {
      __typename?: 'EpLocation';
      id?: string | null;
      name?: string | null;
    } | null;
    locationCity?: {
      __typename?: 'EpLocation';
      id?: string | null;
      name?: string | null;
    } | null;
    locationDistrict?: {
      __typename?: 'EpLocation';
      id?: string | null;
      name?: string | null;
    } | null;
  } | null;
};

export type CompanyAccManagerQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CompanyAccManagerQuery = {
  __typename?: 'Query';
  companyAccManager?: {
    __typename?: 'EpJobCompanyAccManager';
    accManager?: string | null;
    accManagerWhatsapp?: string | null;
    accManagerCopy?: string | null;
    accManagerDashboardCopy?: string | null;
    accManagerErrorCopy?: string | null;
    accManagerRegisterCopy?: string | null;
    accManagerRegisterCopyV2?: string | null;
  } | null;
};

export type UpdateCompanyMutationVariables = Exact<{
  payload?: InputMaybe<EpInputUpdateCompany>;
}>;

export type UpdateCompanyMutation = {
  __typename?: 'Mutation';
  updateCompany?: {
    __typename?: 'MutationResponse';
    data?: string | null;
    error?: string | null;
  } | null;
};

export type RegisterCompanyV2MutationVariables = Exact<{
  payload?: InputMaybe<EpRegisterCompanyInputV2>;
}>;

export type RegisterCompanyV2Mutation = {
  __typename?: 'Mutation';
  registerCompanyV2?:
    | {
        __typename?: 'EpRegisterCompanyV2Error';
        message: string;
        typeError: EpRegisterCompanyV2ErrorEnum;
      }
    | {
        __typename?: 'EpRegisterCompanyV2Response';
        id: string;
        name?: string | null;
      }
    | null;
};

export type DashboardApplicationSummaryCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type DashboardApplicationSummaryCountQuery = {
  __typename?: 'Query';
  dashboardApplicationSummaryCount?:
    | {
        __typename?: 'EpDashboardApplicationSummaryError';
        message?: string | null;
        typeError?: EpDashboardApplicationSummaryErrorEnum | null;
      }
    | {
        __typename?: 'EpDashboardApplicationSummaryResponse';
        totalNew?: number | null;
        totalPending?: number | null;
      }
    | null;
};

export type DashboardVacancySummaryListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type DashboardVacancySummaryListQuery = {
  __typename?: 'Query';
  dashboardVacancySummaryList?:
    | {
        __typename?: 'EpDashboardVacancySummaryError';
        message?: string | null;
        typeError?: EpDashboardVacancySummaryErrorEnum | null;
      }
    | {
        __typename?: 'EpDashboardVacancySummaryResponse';
        onProgress?: Array<{
          __typename?: 'EpDashboardVacancySummaryPayload';
          code?: string | null;
          createdAt?: any | null;
          id: string;
          locationId: string;
          positionName?: string | null;
          totalApplication?: number | null;
          locationCity?: {
            __typename?: 'EpLocation';
            hasVacancy?: boolean | null;
            id?: string | null;
            level?: number | null;
            name?: string | null;
            parentId?: string | null;
          } | null;
          locationProvince?: {
            __typename?: 'EpLocation';
            hasVacancy?: boolean | null;
            id?: string | null;
            level?: number | null;
            name?: string | null;
            parentId?: string | null;
          } | null;
        }> | null;
        pending?: Array<{
          __typename?: 'EpDashboardVacancySummaryPayload';
          code?: string | null;
          createdAt?: any | null;
          id: string;
          locationId: string;
          positionName?: string | null;
          totalApplication?: number | null;
          locationCity?: {
            __typename?: 'EpLocation';
            hasVacancy?: boolean | null;
            id?: string | null;
            level?: number | null;
            name?: string | null;
            parentId?: string | null;
          } | null;
          locationProvince?: {
            __typename?: 'EpLocation';
            hasVacancy?: boolean | null;
            id?: string | null;
            level?: number | null;
            name?: string | null;
            parentId?: string | null;
          } | null;
        }> | null;
      }
    | null;
};

export type ValidateInterviewSessionNameQueryVariables = Exact<{
  name: Scalars['String']['input'];
  vacancyId: Scalars['ID']['input'];
}>;

export type ValidateInterviewSessionNameQuery = {
  __typename?: 'Query';
  validateInterviewSessionName?: {
    __typename?: 'EpValidateInterviewSessionName';
    code: EpValidateInterviewSessionNameCode;
    message?: string | null;
  } | null;
};

export type EpCreateInterviewInvitationMutationVariables = Exact<{
  input: EpCreateInterviewInvitationInput;
}>;

export type EpCreateInterviewInvitationMutation = {
  __typename?: 'Mutation';
  createInterviewInvitation?:
    | {
        __typename?: 'EpCreateInterviewInvitationError';
        message?: string | null;
        typeError?: EpSetInterviewInvitationError | null;
      }
    | {
        __typename?: 'EpCreateInterviewInvitationSuccess';
        message?: string | null;
        title?: string | null;
      }
    | null;
};

export type EpUpdateInterviewInvitationMutationVariables = Exact<{
  input: EpUpdateInterviewInvitationInput;
}>;

export type EpUpdateInterviewInvitationMutation = {
  __typename?: 'Mutation';
  updateInterviewInvitation?:
    | {
        __typename?: 'EpUpdateInterviewInvitationError';
        message?: string | null;
        typeError?: EpSetInterviewInvitationError | null;
      }
    | {
        __typename?: 'EpUpdateInterviewInvitationSuccess';
        message?: string | null;
        title?: string | null;
      }
    | null;
};

export type InterviewSessionsQueryVariables = Exact<{
  vacancyId: Scalars['ID']['input'];
  filter?: InputMaybe<InterviewSessionFilter>;
  sort?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
}>;

export type InterviewSessionsQuery = {
  __typename?: 'Query';
  interviewSessions?: {
    __typename?: 'EpInterviewSessions';
    elements?: number | null;
    hasNextPage?: boolean | null;
    hasPrevPage?: boolean | null;
    limit?: number | null;
    page?: number | null;
    size?: number | null;
    state?: EpInterviewSessionState | null;
    list?: Array<{
      __typename?: 'EpInterviewSession';
      endTimeStr?: string | null;
      id?: string | null;
      name?: string | null;
      schedule?: string | null;
      startTimeStr?: string | null;
      type?: EpInterviewSessionType | null;
      statistic?: Array<{
        __typename?: 'EpInterviewStatistic';
        count?: number | null;
        displayName?: string | null;
        status?: EpInterviewSessionStatus | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type InterviewSessionSortQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InterviewSessionSortQuery = {
  __typename?: 'Query';
  interviewSessionSort?: Array<{
    __typename?: 'KeyValue';
    key?: string | null;
    value?: string | null;
  } | null> | null;
};

export type InterviewSessionTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InterviewSessionTypesQuery = {
  __typename?: 'Query';
  interviewSessionTypes?: Array<{
    __typename?: 'KeyValue';
    key?: string | null;
    value?: string | null;
  } | null> | null;
};

export type InterviewSessionCountQueryVariables = Exact<{
  filter?: InputMaybe<InterviewSessionFilter>;
  vacancyId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type InterviewSessionCountQuery = {
  __typename?: 'Query';
  interviewSessionCount?: {
    __typename?: 'EpInterviewSessionCount';
    count: number;
  } | null;
};

export type InterviewSessionApplicationsQueryVariables = Exact<{
  keyword?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
}>;

export type InterviewSessionApplicationsQuery = {
  __typename?: 'Query';
  interviewSessionApplications?: {
    __typename?: 'EpInterviewSessionApplications';
    elements?: number | null;
    hasNextPage?: boolean | null;
    hasPrevPage?: boolean | null;
    limit?: number | null;
    page?: number | null;
    size?: number | null;
    state?: EpInterviewSessionApplicationState | null;
    list?: Array<{
      __typename?: 'EpInterviewSessionApplication';
      application?: {
        __typename?: 'EpJobApplication';
        id?: string | null;
        interestedStatus?: JobApplicationPreviewInterestEnum | null;
        isAccepted?: boolean | null;
        isInteviewed?: boolean | null;
        jobVacancyId?: string | null;
        name?: string | null;
        status?: string | null;
        districtName?: string | null;
        cityName?: string | null;
        provinceName?: string | null;
        createdAt?: any | null;
        matchingResult?: number | null;
        haveScreeningQuestionAnswers?: boolean | null;
        userProfile?: {
          __typename?: 'EpUserProfile';
          age?: number | null;
          imageUrl?: string | null;
        } | null;
      } | null;
      interviewInvitation?: {
        __typename?: 'EpInterviewInvitation';
        id?: string | null;
        invitedAt?: string | null;
        status?: EpInterviewSessionStatus | null;
        statusStr?: string | null;
      } | null;
      session?: {
        __typename?: 'EpInterviewSession';
        id?: string | null;
        endTimeStr?: string | null;
        name?: string | null;
        schedule?: string | null;
        startTimeStr?: string | null;
        sizeType?: EpInterviewSessionSizeType | null;
        type?: EpInterviewSessionType | null;
        statistic?: Array<{
          __typename?: 'EpInterviewStatistic';
          count?: number | null;
          displayName?: string | null;
          status?: EpInterviewSessionStatus | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type InterviewSessionTabsQueryVariables = Exact<{
  keyword?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type InterviewSessionTabsQuery = {
  __typename?: 'Query';
  interviewSessionTabs?: Array<{
    __typename?: 'EpInterviewSessionTab';
    displayName?: string | null;
    key?: EpInterviewSessionStatus | null;
    name?: string | null;
    numberOfApplicants?: number | null;
  } | null> | null;
};

export type InterviewSessionByIdQueryVariables = Exact<{
  epInterviewSessionByIdId: Scalars['ID']['input'];
}>;

export type InterviewSessionByIdQuery = {
  __typename?: 'Query';
  interviewSessionById?: {
    __typename?: 'EpInterviewSession';
    id?: string | null;
    name?: string | null;
    schedule?: string | null;
    startTimeStr?: string | null;
    type?: EpInterviewSessionType | null;
    sizeType?: EpInterviewSessionSizeType | null;
    vacancy?: {
      __typename?: 'EpJobVacancy';
      id?: string | null;
      code?: string | null;
      positionName?: string | null;
      createdAt?: any | null;
      closeDate?: any | null;
    } | null;
  } | null;
};

export type CreateMediaFileMutationVariables = Exact<{
  file: MediaFileUploadInput;
}>;

export type CreateMediaFileMutation = {
  __typename?: 'Mutation';
  createMediaFile?: {
    __typename?: 'MediaFileUpload';
    uploadUrl: string;
    file: {
      __typename?: 'MediaFile';
      id: string;
      name: string;
      access: MediaAccess;
    };
  } | null;
};

export type CreateMediaImageMutationVariables = Exact<{
  image: MediaImageUploadInput;
}>;

export type CreateMediaImageMutation = {
  __typename?: 'Mutation';
  createMediaImage?: {
    __typename?: 'MediaImageUpload';
    uploadUrl: string;
    image: {
      __typename?: 'MediaImage';
      id: string;
      name: string;
      access: MediaAccess;
    };
  } | null;
};

export type MediaFileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MediaFileQuery = {
  __typename?: 'Query';
  mediaFile?: {
    __typename?: 'MediaFile';
    id: string;
    name: string;
    access: MediaAccess;
    url?: string | null;
    createdAt: any;
  } | null;
};

export type MediaImageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MediaImageQuery = {
  __typename?: 'Query';
  mediaImage?: {
    __typename?: 'MediaImage';
    id: string;
    name: string;
    access: MediaAccess;
    url?: string | null;
    thumbnailUrl?: string | null;
    createdAt: any;
  } | null;
};

export type MenusQueryVariables = Exact<{ [key: string]: never }>;

export type MenusQuery = {
  __typename?: 'Query';
  menus?: Array<{
    __typename?: 'EpMenu';
    id?: string | null;
    name?: string | null;
    displayName?: string | null;
    path?: string | null;
    roleAccess?: {
      __typename?: 'EpRoleAccess';
      id?: string | null;
      canCreate?: boolean | null;
      canRead?: boolean | null;
      canUpdate?: boolean | null;
      canApprove?: boolean | null;
      canDownload?: boolean | null;
    } | null;
    additionalRoleAccess?: Array<{
      __typename?: 'EpAdditionalRoleAccess';
      id?: string | null;
      keyParam?: string | null;
      value?: boolean | null;
    } | null> | null;
  } | null> | null;
};

export type EducationLevelFilterQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EducationLevelFilterQuery = {
  __typename?: 'Query';
  educationLevelFilter?: Array<{
    __typename?: 'EpEducationLevelFilterKeyValue';
    key?: EpEducationLevelFilter | null;
    value?: string | null;
  } | null> | null;
};

export type UserQueryVariables = Exact<{ [key: string]: never }>;

export type UserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'EpUser';
    companyId?: string | null;
    username?: string | null;
    displayName?: string | null;
    phoneNumber?: string | null;
    registrationStatus?: EpUserRegistrationStatus | null;
    roleStr?: string | null;
    roleId?: string | null;
    createdAt?: any | null;
    companyName?: string | null;
  } | null;
};

export type UserConfigQueryVariables = Exact<{ [key: string]: never }>;

export type UserConfigQuery = {
  __typename?: 'Query';
  userConfig?: {
    __typename?: 'EpUserConfig';
    vacancyReportSummaryPeriod?: EpVacancyReportSummaryPeriod | null;
  } | null;
};

export type UpdateUserConfigMutationVariables = Exact<{
  data: EpUserConfigUpdateInput;
}>;

export type UpdateUserConfigMutation = {
  __typename?: 'Mutation';
  updateUserConfig: {
    __typename?: 'MutationResponse';
    data?: string | null;
    isSuccess: boolean;
    warning?: string | null;
    error?: string | null;
  };
};

export type UpdateUserMutationVariables = Exact<{
  data: EpUserUpdateInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'MutationResponse';
    data?: string | null;
    isSuccess: boolean;
    warning?: string | null;
    error?: string | null;
  };
};

export type UserSubscriptionStatusQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type UserSubscriptionStatusQuery = {
  __typename?: 'Query';
  userSubscriptionStatus: {
    __typename?: 'EpUserSubscriptionStatus';
    packageId?: string | null;
    packageStr?: string | null;
    currentPackageStr?: string | null;
    expiryDate?: any | null;
    refreshDate?: any | null;
    isValid?: boolean | null;
    isDisplayInfo?: boolean | null;
    isPremium?: boolean | null;
    info?: string | null;
    offerPackageUpgrade?: boolean | null;
    errors?: string | null;
    freeTrialDuration?: number | null;
    freeTrialStatus?: EpFreeTrialStatus | null;
    isFreeTrial?: boolean | null;
    packageEnum?: EpUserSubscriptionPlanTypeEnum | null;
    companyId?: string | null;
    status?: {
      __typename?: 'EpUserSubscriptionStatusDetail';
      jobPromoteMax?: number | null;
    } | null;
    jobPromoteStatus?: {
      __typename?: 'EpJobPromoteStatus';
      jobPromoteCount?: number | null;
      jobPromoteExceeded?: boolean | null;
    } | null;
  };
};

export type UserSubscriptionTypesQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type UserSubscriptionTypesQuery = {
  __typename?: 'Query';
  userSubscriptionTypes: {
    __typename?: 'EpUserSubscriptionTypes';
    list: Array<{
      __typename?: 'EpUserSubscriptionType';
      id: string;
      name?: string | null;
      description?: string | null;
      isRecommended?: boolean | null;
      isSelected?: boolean | null;
      btnCopy?: string | null;
      btnConfirmationCopy?: string | null;
      confirmationAsset?: string | null;
      confirmationTitleCopy?: string | null;
      confirmationBodyCopy?: string | null;
      subscriptionTypeItems: Array<string | null>;
    } | null>;
  };
};

export type OfferingPackagesQueryVariables = Exact<{ [key: string]: never }>;

export type OfferingPackagesQuery = {
  __typename?: 'Query';
  offeringPackages?: {
    __typename?: 'EpOfferingPackages';
    list: Array<{
      __typename?: 'EpOfferingPackage';
      btnCopy: string;
      featureItems: Array<string>;
      featureTitle: string;
      id: string;
      isPopular: boolean;
      packageDescription: string;
      packageName: string;
    }>;
  } | null;
};

export type RequestUserSubscriptionTypeMutationVariables = Exact<{
  companyId: Scalars['ID']['input'];
  subscriptionTypeId: Scalars['ID']['input'];
}>;

export type RequestUserSubscriptionTypeMutation = {
  __typename?: 'Mutation';
  requestUserSubscriptionType: {
    __typename?: 'MutationResponse';
    data?: string | null;
    isSuccess: boolean;
    warning?: string | null;
    error?: string | null;
  };
};

export type SubscriptionPlanStatusDetailQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type SubscriptionPlanStatusDetailQuery = {
  __typename?: 'Query';
  subscriptionPlanStatusDetail: Array<{
    __typename?: 'EpSubscriptionPlanStatusDetail';
    title?: string | null;
    description?: string | null;
    isLimit?: boolean | null;
  } | null>;
};

export type PackageFeaturesQueryVariables = Exact<{ [key: string]: never }>;

export type PackageFeaturesQuery = {
  __typename?: 'Query';
  packageFeatures?: {
    __typename?: 'EpPackageFeatures';
    list: Array<{
      __typename?: 'EpPackageFeature';
      description: string;
      expiry?: string | null;
      packageName: string;
    }>;
  } | null;
};

export type UserStatusQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type UserStatusQuery = {
  __typename?: 'Query';
  userStatus?: {
    __typename?: 'EpUserStatus';
    status?: EpUserStatusEnum | null;
  } | null;
};

export type JobVacanciesQueryVariables = Exact<{
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<EpJobVacancyState>;
  cityId?: InputMaybe<Scalars['ID']['input']>;
  excludeVacancyId?: InputMaybe<Scalars['ID']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
}>;

export type JobVacanciesQuery = {
  __typename?: 'Query';
  jobVacancies?: {
    __typename?: 'EpJobVacanciesV2';
    page?: number | null;
    elements?: number | null;
    hasPrevPage?: boolean | null;
    hasNextPage?: boolean | null;
    list?: Array<{
      __typename?: 'EpJobVacancy';
      id?: string | null;
      totalCandidateRecommendation?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      closeDate?: any | null;
      closeDateStr?: string | null;
      positionName?: string | null;
      code?: string | null;
      isPublished?: boolean | null;
      isHighlighted?: boolean | null;
      isReposted?: boolean | null;
      highlightedExpiredAt?: any | null;
      highlightedExpiredAtStr?: string | null;
      highlightedStatus?: EpHighlightedStatus | null;
      webLink?: string | null;
      state?: EpJobVacancyState | null;
      locationCity?: {
        __typename?: 'EpLocation';
        id?: string | null;
        name?: string | null;
      } | null;
      jobApplicationStatistic?: {
        __typename?: 'EpJobApplicationStatistic';
        totalAccepted?: number | null;
        totalInterview?: number | null;
        totalOnProgress?: number | null;
        totalPending?: number | null;
        totalRejected?: number | null;
      } | null;
      tierCategory?: {
        __typename?: 'EpJobVacancyTierCategoryResponse';
        id?: JobVacancyTierCategoryEnum | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type JobVacancyDraftsQueryVariables = Exact<{
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<EpJobVacancyState>;
  cityId?: InputMaybe<Scalars['ID']['input']>;
  excludeDraftId?: InputMaybe<Scalars['ID']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
}>;

export type JobVacancyDraftsQuery = {
  __typename?: 'Query';
  jobVacancyDrafts?: {
    __typename?: 'EpJobVacanciesV2';
    page?: number | null;
    elements?: number | null;
    hasPrevPage?: boolean | null;
    hasNextPage?: boolean | null;
    list?: Array<{
      __typename?: 'EpJobVacancy';
      id?: string | null;
      totalCandidateRecommendation?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      closeDate?: any | null;
      closeDateStr?: string | null;
      positionName?: string | null;
      code?: string | null;
      isPublished?: boolean | null;
      isHighlighted?: boolean | null;
      isReposted?: boolean | null;
      highlightedExpiredAt?: any | null;
      highlightedExpiredAtStr?: string | null;
      highlightedStatus?: EpHighlightedStatus | null;
      webLink?: string | null;
      state?: EpJobVacancyState | null;
      locationCity?: {
        __typename?: 'EpLocation';
        id?: string | null;
        name?: string | null;
      } | null;
      jobApplicationStatistic?: {
        __typename?: 'EpJobApplicationStatistic';
        totalAccepted?: number | null;
        totalInterview?: number | null;
        totalOnProgress?: number | null;
        totalPending?: number | null;
        totalRejected?: number | null;
      } | null;
      tierCategory?: {
        __typename?: 'EpJobVacancyTierCategoryResponse';
        id?: JobVacancyTierCategoryEnum | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type JobVacancySearchAltQueryVariables = Exact<{
  filter?: InputMaybe<CommonFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  excludeVacancyId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type JobVacancySearchAltQuery = {
  __typename?: 'Query';
  jobVacancies?: {
    __typename?: 'EpJobVacanciesV2';
    elements?: number | null;
    limit?: number | null;
    page?: number | null;
    size?: number | null;
    list?: Array<{
      __typename?: 'EpJobVacancy';
      id?: string | null;
      positionName?: string | null;
      code?: string | null;
      createdAt?: any | null;
      closeDate?: any | null;
      locationCity?: {
        __typename?: 'EpLocation';
        id?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type JobVacancySummaryQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  cityId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type JobVacancySummaryQuery = {
  __typename?: 'Query';
  jobVacancySummary?: {
    __typename?: 'EpJobVacancySummary';
    total?: number | null;
    totalClosed?: number | null;
    totalOnHold?: number | null;
    totalOpen?: number | null;
    totalDraft?: number | null;
  } | null;
};

export type JobVacancyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type JobVacancyQuery = {
  __typename?: 'Query';
  jobVacancy?:
    | {
        __typename?: 'EpJobVacancy';
        id?: string | null;
        positionName?: string | null;
        code?: string | null;
        description?: string | null;
        formattedDescription?: string | null;
        state?: EpJobVacancyState | null;
        status?: string | null;
        contactType?: string | null;
        contactDetail?: string | null;
        hrEmail?: string | null;
        vacancyCount?: number | null;
        closeDate?: any | null;
        webLink?: string | null;
        type?: string | null;
        typeStr?: string | null;
        jobLevel?: string | null;
        jobLevelStr?: string | null;
        locationSite?: string | null;
        locationSiteStr?: string | null;
        jobVacancyFunctionId?: string | null;
        workingDayStartStr?: string | null;
        workingDayStart?: number | null;
        workingDayEndStr?: string | null;
        workingDayEnd?: number | null;
        workingHourStartStr?: string | null;
        workingHourStart?: number | null;
        workingHourEndStr?: string | null;
        workingHourEnd?: number | null;
        salaryLowerBoundStr?: string | null;
        salaryLowerBound?: number | null;
        salaryUpperBoundStr?: string | null;
        salaryUpperBound?: number | null;
        educationLevel?: string | null;
        educationLevelStr?: string | null;
        minExperience?: number | null;
        maxAge?: number | null;
        minAge?: number | null;
        gender?: string | null;
        genderStr?: string | null;
        note?: string | null;
        createdAt?: any | null;
        isPublished?: boolean | null;
        isHighlighted?: boolean | null;
        isOpenForFreshGraduate?: boolean | null;
        isOpenForAllEducationPrograms?: boolean | null;
        totalPending?: number | null;
        totalOnProgress?: number | null;
        totalAccepted?: number | null;
        totalRejected?: number | null;
        benefits?: Array<{
          __typename?: 'KeyValue';
          key?: string | null;
          value?: string | null;
        } | null> | null;
        educationProgramPreferences?: Array<{
          __typename?: 'EpEducationProgramPreference';
          id?: string | null;
          idx?: number | null;
          name?: string | null;
        }> | null;
        locationProvince?: {
          __typename?: 'EpLocation';
          name?: string | null;
          id?: string | null;
        } | null;
        locationCity?: {
          __typename?: 'EpLocation';
          name?: string | null;
          id?: string | null;
        } | null;
        locationDistrict?: {
          __typename?: 'EpLocation';
          name?: string | null;
          id?: string | null;
        } | null;
        jobFunction?: {
          __typename?: 'EpJobVacancyFunction';
          displayName?: string | null;
          id?: string | null;
        } | null;
        jobRole?: {
          __typename?: 'EpJobRole';
          id?: string | null;
          name?: string | null;
          displayName?: string | null;
        } | null;
        jobSpecialization?: {
          __typename?: 'EpJobSpecialization';
          id?: string | null;
          name?: string | null;
          displayName?: string | null;
        } | null;
        skillsV2?: Array<{
          __typename?: 'EpSkill';
          displayName?: string | null;
          id?: string | null;
          idx?: number | null;
          isVerified?: boolean | null;
          name?: string | null;
        } | null> | null;
        requirements?: Array<{
          __typename?: 'EpVacancyRequirement';
          id?: string | null;
          jobVacancyReqCategorySubId?: string | null;
          isAdditional?: boolean | null;
          priority?: number | null;
          vacancyReqCategorySub?: {
            __typename?: 'EpJobVacancyRequirementCategorySub';
            id?: string | null;
            displayName?: string | null;
            jobVacancyRequirementCategoryId?: string | null;
            name?: string | null;
          } | null;
        } | null> | null;
        restrictions?: {
          __typename?: 'EpJobVacancyRestrictions';
          city?: boolean | null;
          educationLevel?: boolean | null;
        } | null;
        tierCategory?: {
          __typename?: 'EpJobVacancyTierCategoryResponse';
          id?: JobVacancyTierCategoryEnum | null;
          name?: string | null;
        } | null;
      }
    | {
        __typename?: 'EpJobVacancyError';
        message?: string | null;
        typeError?: EpJobVacancyErrorEnum | null;
      }
    | null;
};

export type JobVacancyScreeningQuestionsQueryVariables = Exact<{
  jobVacancyId: Scalars['ID']['input'];
}>;

export type JobVacancyScreeningQuestionsQuery = {
  __typename?: 'Query';
  jobVacancyScreeningQuestions?: {
    __typename?: 'EpScreeningQuestions';
    list?: Array<{
      __typename?: 'EpScreeningQuestion';
      id: string;
      idx: number;
      topic: ScreeningQuestionTopic;
      title?: string | null;
      description?: string | null;
      question?: string | null;
      answer?: Array<string | null> | null;
      value?: Array<{
        __typename?: 'EpScreeningQuestionValue';
        id: string;
        idx: number;
        name?: string | null;
        displayName?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type OpenJobVacancyMutationVariables = Exact<{
  jobVacancyId: Scalars['ID']['input'];
}>;

export type OpenJobVacancyMutation = {
  __typename?: 'Mutation';
  openJobVacancy?:
    | {
        __typename?: 'EpJobVacancyGeneratedLink';
        closeDate?: any | null;
        code?: string | null;
        createdAt?: any | null;
        positionName?: string | null;
        webLink?: string | null;
        jobPublished?: {
          __typename?: 'JobPublished';
          current?: number | null;
          hasExceeded?: boolean | null;
          max?: number | null;
        } | null;
      }
    | {
        __typename?: 'EpOpenJobVacancyError';
        message?: string | null;
        typeError?: EpOpenJobVacancyErrorEnum | null;
      }
    | null;
};

export type OpenJobVacancyV3MutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OpenJobVacancyV3Mutation = {
  __typename?: 'Mutation';
  openJobVacancyV3:
    | {
        __typename?: 'EpJobVacancyV3Response';
        closeDate?: any | null;
        code?: string | null;
        createdAt?: any | null;
        positionName?: string | null;
        webLink?: string | null;
      }
    | {
        __typename?: 'EpOpenJobVacancyV3Error';
        message?: string | null;
        typeError?: EpOpenJobVacancyV3ErrorEnum | null;
      };
};

export type OnHoldJobVacancyV2MutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OnHoldJobVacancyV2Mutation = {
  __typename?: 'Mutation';
  onHoldJobVacancyV2:
    | { __typename?: 'EpJobVacancyV3IdResponse'; id?: string | null }
    | {
        __typename?: 'EpOnHoldJobVacancyV2Error';
        message?: string | null;
        typeError?: EpOnHoldJobVacancyV2ErrorEnum | null;
      };
};

export type CloseJobVacancyV2MutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CloseJobVacancyV2Mutation = {
  __typename?: 'Mutation';
  closeJobVacancyV2:
    | {
        __typename?: 'EpCloseJobVacancyV2Error';
        message?: string | null;
        typeError?: EpCloseJobVacancyV2ErrorEnum | null;
      }
    | { __typename?: 'EpJobVacancyV3IdResponse'; id?: string | null };
};

export type CreateJobVacancyMutationVariables = Exact<{
  data: EpJobVacancyCreateInput;
}>;

export type CreateJobVacancyMutation = {
  __typename?: 'Mutation';
  createJobVacancy:
    | {
        __typename?: 'EpCreateJobVacancyError';
        message?: string | null;
        typeError?: EpCreateJobVacancyErrorEnum | null;
      }
    | {
        __typename?: 'EpJobVacancyGeneratedLink';
        closeDate?: any | null;
        code?: string | null;
        createdAt?: any | null;
        positionName?: string | null;
        webLink?: string | null;
        jobPublished?: {
          __typename?: 'JobPublished';
          current?: number | null;
          hasExceeded?: boolean | null;
          max?: number | null;
        } | null;
      };
};

export type CreateJobVacancyV3MutationVariables = Exact<{
  data: EpJobVacancyCreateInput;
}>;

export type CreateJobVacancyV3Mutation = {
  __typename?: 'Mutation';
  createJobVacancyV3:
    | {
        __typename?: 'EpCreateJobVacancyV3Error';
        message?: string | null;
        typeError?: EpCreateJobVacancyV3ErrorEnum | null;
      }
    | {
        __typename?: 'EpJobVacancyV3Response';
        closeDate?: any | null;
        code?: string | null;
        createdAt?: any | null;
        positionName?: string | null;
        webLink?: string | null;
      };
};

export type UpdateJobVacancyMutationVariables = Exact<{
  data: EpJobVacancyUpdateInput;
}>;

export type UpdateJobVacancyMutation = {
  __typename?: 'Mutation';
  updateJobVacancy:
    | {
        __typename?: 'EpJobVacancyGeneratedLink';
        closeDate?: any | null;
        code?: string | null;
        createdAt?: any | null;
        positionName?: string | null;
        webLink?: string | null;
        jobPublished?: {
          __typename?: 'JobPublished';
          current?: number | null;
          hasExceeded?: boolean | null;
          max?: number | null;
        } | null;
      }
    | {
        __typename?: 'EpUpdateJobVacancyError';
        message?: string | null;
        typeError?: EpUpdateJobVacancyErrorEnum | null;
      };
};

export type UpdateJobVacancyV3MutationVariables = Exact<{
  data: EpJobVacancyUpdateInput;
}>;

export type UpdateJobVacancyV3Mutation = {
  __typename?: 'Mutation';
  updateJobVacancyV3:
    | {
        __typename?: 'EpJobVacancyV3Response';
        closeDate?: any | null;
        code?: string | null;
        createdAt?: any | null;
        positionName?: string | null;
        webLink?: string | null;
      }
    | {
        __typename?: 'EpUpdateJobVacancyV3Error';
        message?: string | null;
        typeError?: EpUpdateJobVacancyV3ErrorEnum | null;
      };
};

export type RepostJobVacancyMutationVariables = Exact<{
  data: EpJobVacancyUpdateInput;
}>;

export type RepostJobVacancyMutation = {
  __typename?: 'Mutation';
  repostJobVacancy:
    | {
        __typename?: 'EpJobVacancyV3Response';
        closeDate?: any | null;
        code?: string | null;
        createdAt?: any | null;
        positionName?: string | null;
        webLink?: string | null;
      }
    | {
        __typename?: 'EpRepostJobVacancyError';
        message?: string | null;
        typeError?: EpRepostJobVacancyErrorEnum | null;
      };
};

export type CreateJobVacancyDraftMutationVariables = Exact<{
  input?: InputMaybe<EpJobVacancyCreateDraftInput>;
}>;

export type CreateJobVacancyDraftMutation = {
  __typename?: 'Mutation';
  createJobVacancyDraft?:
    | {
        __typename?: 'EpCreateJobVacancyDraftError';
        message?: string | null;
        typeError?: EpCreateJobVacancyDraftErrorEnum | null;
      }
    | {
        __typename?: 'EpCreateJobVacancyDraftSuccess';
        code?: string | null;
        message?: string | null;
      }
    | null;
};

export type ActivateJobVacancyHighlightV2MutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ActivateJobVacancyHighlightV2Mutation = {
  __typename?: 'Mutation';
  activateJobVacancyHighlightV2:
    | {
        __typename?: 'EpActivateJobVacancyHighlightV2Error';
        message?: string | null;
        typeError?: EpActivateJobVacancyHighlightV2ErrorEnum | null;
      }
    | { __typename?: 'EpJobVacancyV3IdResponse'; id?: string | null };
};

export type DeactivateJobVacancyHighlightV2MutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeactivateJobVacancyHighlightV2Mutation = {
  __typename?: 'Mutation';
  deactivateJobVacancyHighlightV2:
    | {
        __typename?: 'EpDeactivateJobVacancyHighlightV2Error';
        message?: string | null;
        typeError?: EpDeactivateJobVacancyHighlightV2ErrorEnum | null;
      }
    | { __typename?: 'EpJobVacancyV3IdResponse'; id?: string | null };
};

export type JobVacancyDistinctSearchQueryVariables = Exact<{
  filter?: InputMaybe<CommonFilter>;
  positionName?: InputMaybe<Scalars['String']['input']>;
}>;

export type JobVacancyDistinctSearchQuery = {
  __typename?: 'Query';
  jobVacancyDistinctSearch?: {
    __typename?: 'EpJobVacancyDistincts';
    elements?: number | null;
    limit?: number | null;
    page?: number | null;
    list?: Array<string | null> | null;
  } | null;
};

export type JobVacancyClosedFeedbackTemplatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type JobVacancyClosedFeedbackTemplatesQuery = {
  __typename?: 'Query';
  jobVacancyClosedFeedbackTemplates?: {
    __typename?: 'JobVacancyClosedFeedbackTemplates';
    list?: Array<{
      __typename?: 'JobVacancyClosedFeedbackTemplate';
      id?: string | null;
      inputType?: JobVacancyClosedFeedbackTemplateEnum | null;
      content?: string | null;
    } | null> | null;
  } | null;
};

export type CreateJobVacancyClosedFeedbackMutationVariables = Exact<{
  payload?: InputMaybe<EpJobVacancyClosedFeedbackInput>;
}>;

export type CreateJobVacancyClosedFeedbackMutation = {
  __typename?: 'Mutation';
  createJobVacancyClosedFeedback?: {
    __typename?: 'MutationResponse';
    isSuccess: boolean;
  } | null;
};

export type ScreeningQuestionsQueryVariables = Exact<{ [key: string]: never }>;

export type ScreeningQuestionsQuery = {
  __typename?: 'Query';
  screeningQuestions?: {
    __typename?: 'EpScreeningQuestions';
    list?: Array<{
      __typename?: 'EpScreeningQuestion';
      id: string;
      idx: number;
      topic: ScreeningQuestionTopic;
      title?: string | null;
      description?: string | null;
      question?: string | null;
      answer?: Array<string | null> | null;
      value?: Array<{
        __typename?: 'EpScreeningQuestionValue';
        id: string;
        idx: number;
        name?: string | null;
        displayName?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type JobVacancyScreeningQuestionsFilterQueryVariables = Exact<{
  jobVacancyId: Scalars['ID']['input'];
}>;

export type JobVacancyScreeningQuestionsFilterQuery = {
  __typename?: 'Query';
  jobVacancyScreeningQuestionsFilter?: Array<{
    __typename?: 'EpScreeningQuestionsFilter';
    description?: string | null;
    id?: string | null;
    isRequired?: boolean | null;
    title?: string | null;
    topic: ScreeningQuestionTopic;
    uiType?: string | null;
    choices?: Array<{
      __typename?: 'EpScreeningQuestionsFilterData';
      key?: string | null;
      value?: string | null;
    }> | null;
    entries?: Array<{
      __typename?: 'EpScreeningQuestionsFilterData';
      key?: string | null;
      value?: string | null;
    }> | null;
  }> | null;
};

export type SyncPublishedJobVacancyMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SyncPublishedJobVacancyMutation = {
  __typename?: 'Mutation';
  syncPublishedJobVacancy?:
    | {
        __typename?: 'EpSyncPublishedJobVacancyError';
        message?: string | null;
        typeError?: EpSyncPublishedJobVacancyErrorEnum | null;
      }
    | {
        __typename?: 'EpSyncPublishedJobVacancyResponse';
        jobPublished?: {
          __typename?: 'JobPublished';
          current?: number | null;
          hasExceeded?: boolean | null;
          max?: number | null;
        } | null;
      }
    | null;
};

export type SubmitContactedPhoneNumberMutationVariables = Exact<{
  packageId: Scalars['ID']['input'];
  phoneNumber: Scalars['String']['input'];
}>;

export type SubmitContactedPhoneNumberMutation = {
  __typename?: 'Mutation';
  submitContactedPhoneNumber?:
    | {
        __typename?: 'EpSubmitContactedPhoneNumberResponse';
        packageId?: string | null;
      }
    | {
        __typename?: 'EpSubmitContactedPhoneNumberResponseError';
        message?: string | null;
        typeError?: EpSubmitContactedPhoneNumberResponseErrorEnum | null;
      }
    | null;
};

export const JobApplicationCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobApplicationCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EpJobApplicantFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobApplicationCount' },
            name: { kind: 'Name', value: 'epJobApplicationCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'menuId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countStr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationCountQuery,
  JobApplicationCountQueryVariables
>;
export const JobApplicationSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobApplicationSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tab' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'epStatus' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EpJobApplicationEpStatusEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastEducationLevels' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EpEducationLevelFilter' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'educationInstitutionIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'domicile' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ageMin' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ageMax' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locations' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EpLocationAreaInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'minSalary' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'maxSalary' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'experienceYear' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobSpecializationRoles' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skillsV2' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobApplicationSummary' },
            name: { kind: 'Name', value: 'epJobApplicationSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tab' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tab' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'epStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'epStatus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastEducationLevels' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastEducationLevels' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'educationInstitutionIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'educationInstitutionIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domicile' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'domicile' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gender' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gender' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ageMin' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ageMin' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ageMax' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ageMax' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locations' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'locations' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minSalary' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'minSalary' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'maxSalary' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'maxSalary' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'experienceYear' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'experienceYear' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobSpecializationRoles' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobSpecializationRoles' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skillsV2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skillsV2' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'saved' } },
                { kind: 'Field', name: { kind: 'Name', value: 'withdrawn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rejected' } },
                { kind: 'Field', name: { kind: 'Name', value: 'onProgress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interview' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationSummaryQuery,
  JobApplicationSummaryQueryVariables
>;
export const JobApplicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobApplications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CommonFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'epStatus' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EpJobApplicationEpStatusEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobVacancyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastEducationLevels' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EpEducationLevelFilter' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'educationInstitutionIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'domicile' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ageMin' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ageMax' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'minSalary' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'maxSalary' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'experienceYear' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobSpecializationRoles' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skillsV2' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tab' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locations' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EpLocationAreaInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isRestrict' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'screeningQuestions' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'EpJobApplicationScreeningQuestionInput',
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobApplications' },
            name: { kind: 'Name', value: 'epJobApplications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'epStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'epStatus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobVacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobVacancyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastEducationLevels' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastEducationLevels' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'educationInstitutionIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'educationInstitutionIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domicile' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'domicile' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gender' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gender' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ageMin' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ageMin' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ageMax' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ageMax' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minSalary' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'minSalary' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'maxSalary' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'maxSalary' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'experienceYear' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'experienceYear' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobSpecializationRoles' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobSpecializationRoles' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skillsV2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skillsV2' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locations' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'locations' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tab' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tab' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isRestrict' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isRestrict' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'screeningQuestions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'screeningQuestions' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userProfileId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isInvited' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobVacancyId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'matchingResult' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userLastEducation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'periodStr' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'educationLevel' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'educationInstitution',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'educationProgram' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastEducationLevel' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startEducationYear' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endEducationYear' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastJobDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastJobDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startLastJobYear' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endLastJobYear' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cityName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'provinceName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'districtName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'age' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'experiencesYearStr',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyNote' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastExperience' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'companyName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'employmentType',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'periodStr' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isInvited' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isRead' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expectedSalaryStr' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'haveScreeningQuestionAnswers',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'screeningQuestionResultStr',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'isFullScoreScreeningQuestion',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationsQuery,
  JobApplicationsQueryVariables
>;
export const JobApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobApplication' },
            name: { kind: 'Name', value: 'epJobApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userProfileId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobVacancyId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyNote' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyStatus' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLastEducation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'educationLevel' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'educationInstitution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'educationProgram' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastEducationLevel' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'startEducationYear' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'endEducationYear' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastJobDescription' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastJobDescription' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'startLastJobYear' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'endLastJobYear' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cityName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provinceName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isMasked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInvited' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'experiencesYearStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isContactRestricted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nickname' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'genderStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'about' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isPrivate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'birthdate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyNote' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAtStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'educations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userProfileId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'educationLevel' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'educationInstitution',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'educationProgram' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startYear' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endYear' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startMonth' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endMonth' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'periodStr' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'experiences' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'periodStr' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skills' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userProfileId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startYear' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobFunction' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endYear' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'employmentTypeStr',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyName' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'jvSpecializationRoleId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jvRole' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skills' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'links' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userProfileId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'link' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'supportLinkCategory',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'displayName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'prefix' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'asset' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'imageUrl',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'district' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'level' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'city' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'level' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'province' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'level' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cv' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isRead' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInvited' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'expectedSalaryStr' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'matchingResult' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobApplicationQuery, JobApplicationQueryVariables>;
export const UpdateBulkJobApplicationCompanyStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBulkJobApplicationCompanyStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'EpUpdateBulkJobApplicationCompanyStatusInput',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'updateBulkJobApplicationCompanyStatus',
            },
            name: {
              kind: 'Name',
              value: 'epUpdateBulkJobApplicationCompanyStatus',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'warning' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBulkJobApplicationCompanyStatusMutation,
  UpdateBulkJobApplicationCompanyStatusMutationVariables
>;
export const UpdateBulkJobApplicationCompanyStatusV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBulkJobApplicationCompanyStatusV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'EpUpdateBulkJobApplicationCompanyStatusInput',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'updateBulkJobApplicationCompanyStatusV2',
            },
            name: {
              kind: 'Name',
              value: 'epUpdateBulkJobApplicationCompanyStatusV2',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'warning' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countSuccess' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countFailed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBulkJobApplicationCompanyStatusV2Mutation,
  UpdateBulkJobApplicationCompanyStatusV2MutationVariables
>;
export const JobApplicationSortDropdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobApplicationSortDropdown' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobApplicationSortDropdown' },
            name: { kind: 'Name', value: 'epJobApplicationSortDropdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationSortDropdownQuery,
  JobApplicationSortDropdownQueryVariables
>;
export const UpdateJobApplicationNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateJobApplicationNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EpJobApplicationNoteInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'updateJobApplicationNote' },
            name: { kind: 'Name', value: 'epUpdateJobApplicationNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateJobApplicationNoteMutation,
  UpdateJobApplicationNoteMutationVariables
>;
export const JobApplicationEpStatusDropdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobApplicationEpStatusDropdown' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobApplicationEpStatusDropdown' },
            name: { kind: 'Name', value: 'epJobApplicationEpStatusDropdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationEpStatusDropdownQuery,
  JobApplicationEpStatusDropdownQueryVariables
>;
export const UpdateJobApplicationStatusAndRemarksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateJobApplicationStatusAndRemarks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'EpJobApplicationStatusAndRemarksUpdateInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'updateJobApplicationStatusAndRemarks',
            },
            name: {
              kind: 'Name',
              value: 'epUpdateJobApplicationStatusAndRemarks',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateJobApplicationStatusAndRemarksMutation,
  UpdateJobApplicationStatusAndRemarksMutationVariables
>;
export const JobApplicationHistoryLabelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobApplicationHistoryLabels' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobApplicationHistoryLabels' },
            name: { kind: 'Name', value: 'epJobApplicationHistoryLabels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bgColor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationHistoryLabelsQuery,
  JobApplicationHistoryLabelsQueryVariables
>;
export const JobApplicationPrevNextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobApplicationPrevNext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobVacancyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'epStatus' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EpJobApplicationEpStatusEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tab' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastEducationLevels' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EpEducationLevelFilter' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'educationInstitutionIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locations' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EpLocationAreaInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobSpecializationRoles' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skillsV2' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'domicile' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ageMin' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ageMax' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'minSalary' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'maxSalary' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'experienceYear' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'screeningQuestions' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'EpJobApplicationScreeningQuestionInput',
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobApplicationPrevNext' },
            name: { kind: 'Name', value: 'epJobApplicationPrevNext' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'epStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'epStatus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobVacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobVacancyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastEducationLevels' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastEducationLevels' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'educationInstitutionIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'educationInstitutionIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domicile' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'domicile' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gender' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gender' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ageMin' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ageMin' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ageMax' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ageMax' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locations' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'locations' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minSalary' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'minSalary' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'maxSalary' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'maxSalary' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'experienceYear' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'experienceYear' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobSpecializationRoles' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobSpecializationRoles' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skillsV2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skillsV2' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tab' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tab' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'screeningQuestions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'screeningQuestions' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'prevId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationPrevNextQuery,
  JobApplicationPrevNextQueryVariables
>;
export const JobApplicationRejectedReasonCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobApplicationRejectedReasonCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'jobApplicationRejectedReasonCategories',
            },
            name: {
              kind: 'Name',
              value: 'epJobApplicationRejectedReasonCategories',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'disabledKeys' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reasonTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reasonDescription' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reasonType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationRejectedReasonCategoriesQuery,
  JobApplicationRejectedReasonCategoriesQueryVariables
>;
export const JobApplicationRejectedReasonsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobApplicationRejectedReasons' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobApplicationRejectedReasons' },
            name: { kind: 'Name', value: 'epJobApplicationRejectedReason' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationRejectedReasonsQuery,
  JobApplicationRejectedReasonsQueryVariables
>;
export const UserCvDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userCv' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userProfileId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cvFileNameForDownload' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'userCv' },
            name: { kind: 'Name', value: 'epUserCv' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userProfileId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userProfileId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cvFileNameForDownload' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cvFileNameForDownload' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userProfileIdStr' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cv' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cvStatus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedAtStr' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserCvQuery, UserCvQueryVariables>;
export const UpdateJobApplicationIsReadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateJobApplicationIsRead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EpUpdateJobApplicationIsRead' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'updateJobApplicationIsRead' },
            name: { kind: 'Name', value: 'epUpdateJobApplicationIsRead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'warning' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateJobApplicationIsReadMutation,
  UpdateJobApplicationIsReadMutationVariables
>;
export const JobApplicationScreeningDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobApplicationScreening' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobApplicantId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'screeningQuestions' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'EpJobApplicationScreeningQuestionInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobApplicationScreening' },
            name: { kind: 'Name', value: 'epJobApplicationScreening' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobApplicantId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobApplicantId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'screeningQuestions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'screeningQuestions' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'topic' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'answer' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isMatch' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'requirement' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'noticeStr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'resultStr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFullScore' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationScreeningQuery,
  JobApplicationScreeningQueryVariables
>;
export const VerifyCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'verifyCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'verifyCode' },
            name: { kind: 'Name', value: 'epVerifyCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'code' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'authCode' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'warning' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyCodeQuery, VerifyCodeQueryVariables>;
export const SignupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'signup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EpUserSignUpInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'signup' },
            name: { kind: 'Name', value: 'epSignUp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignupMutation, SignupMutationVariables>;
export const SelfSignupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'selfSignup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EpUserSignUpInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'selfSignup' },
            name: { kind: 'Name', value: 'epSelfSignUp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SelfSignupMutation, SelfSignupMutationVariables>;
export const ForgotPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'forgotPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'forgotPassword' },
            name: { kind: 'Name', value: 'epForgotPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const ForgotPasswordSubmissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'forgotPasswordSubmission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EpUserForgotPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'forgotPasswordSubmission' },
            name: { kind: 'Name', value: 'epForgotPasswordSubmission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ForgotPasswordSubmissionMutation,
  ForgotPasswordSubmissionMutationVariables
>;
export const ResetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EpUserResetPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'resetPassword' },
            name: { kind: 'Name', value: 'epResetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const ResendSignUpLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resendSignUpLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'resendSignUpLink' },
            name: { kind: 'Name', value: 'epResendSignUpLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'warning' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResendSignUpLinkMutation,
  ResendSignUpLinkMutationVariables
>;
export const VerifySelfSignUpCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'verifySelfSignUpCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'verifySelfSignUpCode' },
            name: { kind: 'Name', value: 'epVerifySelfSignUpCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'code' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'authCode' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'warning' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifySelfSignUpCodeQuery,
  VerifySelfSignUpCodeQueryVariables
>;
export const JobVacancyCandidatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancyCandidates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CommonFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EpJobVacancyCandidateStatus' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobVacancyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'educationLevels' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'educationInstitutionIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ageMin' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ageMax' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locations' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EpLocationAreaInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'minSalary' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'maxSalary' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'experienceYear' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobFunctions' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skills' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invitationStatus' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancyCandidates' },
            name: { kind: 'Name', value: 'epJobVacancyCandidates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobVacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobVacancyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'educationLevels' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'educationLevels' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'educationInstitutionIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'educationInstitutionIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gender' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gender' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ageMin' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ageMin' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ageMax' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ageMax' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locations' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'locations' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minSalary' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'minSalary' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'maxSalary' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'maxSalary' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'experienceYear' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'experienceYear' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobFunctions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobFunctions' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skills' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skills' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invitationStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invitationStatus' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPrev' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobCompanyId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobVacancyId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobApplicationId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invitationStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invitedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'matchingResult' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userProfileId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expectedSalaryStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userLastEducation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'periodStr' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'educationLevel' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'educationInstitution',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'educationProgram' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'age' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'experiencesYearStr',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'district' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userLastEducation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'educationLevel' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'educationProgram' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userLastExperience' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'periodStr' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'employmentTypeStr',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancyCandidatesQuery,
  JobVacancyCandidatesQueryVariables
>;
export const JobVacancyCandidateSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancyCandidateSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobVacancyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'educationLevels' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'educationInstitutionIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ageMin' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ageMax' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locations' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EpLocationAreaInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'minSalary' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'maxSalary' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'experienceYear' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobFunctions' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skills' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invitationStatus' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancyCandidateSummary' },
            name: { kind: 'Name', value: 'epJobVacancyCandidateSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobVacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobVacancyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'educationLevels' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'educationLevels' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'educationInstitutionIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'educationInstitutionIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gender' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gender' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ageMin' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ageMin' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ageMax' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ageMax' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locations' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'locations' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minSalary' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'minSalary' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'maxSalary' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'maxSalary' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'experienceYear' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'experienceYear' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobFunctions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobFunctions' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skills' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skills' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invitationStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invitationStatus' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'available' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitationSent' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notInterested' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancyCandidateSummaryQuery,
  JobVacancyCandidateSummaryQueryVariables
>;
export const JobVacancyCandidateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancyCandidate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancyCandidate' },
            name: { kind: 'Name', value: 'epJobVacancyCandidate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobCompanyId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobVacancyId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitationStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'invitedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'matchingResult' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'matchingScore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userProfileId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'expectedSalaryStr' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'experiencesYearStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nickname' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'genderStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'about' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isPrivate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'birthdate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyNote' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAtStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'educations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userProfileId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'educationLevel' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'educationInstitution',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'educationProgram' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startYear' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endYear' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startMonth' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endMonth' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'periodStr' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'experiences' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'periodStr' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skills' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userProfileId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startYear' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobFunction' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endYear' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'employmentTypeStr',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyName' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'jvSpecializationRoleId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jvRole' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skills' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'links' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userProfileId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'link' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'supportLinkCategory',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'displayName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'prefix' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'asset' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'imageUrl',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'district' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'level' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'city' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'level' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'province' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'level' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cv' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLastEducation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'educationLevel' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'educationProgram' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLastExperience' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'periodStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'employmentTypeStr' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'matchingResult' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancyCandidateQuery,
  JobVacancyCandidateQueryVariables
>;
export const JobVacancyCandidatePrevNextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancyCandidatePrevNext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matchingScore' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EpJobVacancyCandidateStatus' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobVacancyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'educationLevels' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'educationInstitutionIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ageMin' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ageMax' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locations' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EpLocationAreaInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'minSalary' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'maxSalary' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'experienceYear' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobFunctions' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skills' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invitationStatus' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancyCandidatePrevNext' },
            name: { kind: 'Name', value: 'epJobVacancyCandidatePrevNext' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'matchingScore' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'matchingScore' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobVacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobVacancyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'educationLevels' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'educationLevels' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'educationInstitutionIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'educationInstitutionIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gender' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gender' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ageMin' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ageMin' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ageMax' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ageMax' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locations' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'locations' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minSalary' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'minSalary' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'maxSalary' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'maxSalary' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'experienceYear' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'experienceYear' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobFunctions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobFunctions' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skills' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skills' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invitationStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invitationStatus' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prevId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancyCandidatePrevNextQuery,
  JobVacancyCandidatePrevNextQueryVariables
>;
export const DeleteJobVacancyCandidateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteJobVacancyCandidate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'deleteJobVacancyCandidate' },
            name: { kind: 'Name', value: 'epDeleteJobVacancyCandidate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteJobVacancyCandidateMutation,
  DeleteJobVacancyCandidateMutationVariables
>;
export const VerifyJobVacancyCandidateInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'verifyJobVacancyCandidateInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'verifyJobVacancyCandidateInvitation',
            },
            name: {
              kind: 'Name',
              value: 'epVerifyJobVacancyCandidateInvitation',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'templateStr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'copyStr' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyJobVacancyCandidateInvitationQuery,
  VerifyJobVacancyCandidateInvitationQueryVariables
>;
export const JobVacancyCandidateCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancyCandidateCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobVacancyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'educationLevels' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'educationInstitutionIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ageMin' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ageMax' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locations' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'EpLocationAreaInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'minSalary' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'maxSalary' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'experienceYear' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobFunctions' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skills' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EpJobVacancyCandidateStatus' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invitationStatus' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancyCandidateCount' },
            name: { kind: 'Name', value: 'epJobVacancyCandidateCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobVacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobVacancyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'educationLevels' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'educationLevels' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'educationInstitutionIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'educationInstitutionIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gender' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gender' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ageMin' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ageMin' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ageMax' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ageMax' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locations' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'locations' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minSalary' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'minSalary' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'maxSalary' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'maxSalary' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'experienceYear' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'experienceYear' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobFunctions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobFunctions' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skills' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skills' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invitationStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invitationStatus' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancyCandidateCountQuery,
  JobVacancyCandidateCountQueryVariables
>;
export const InviteJobVacancyCandidateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'inviteJobVacancyCandidate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EpInviteJobVacancyCandidateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'inviteJobVacancyCandidate' },
            name: { kind: 'Name', value: 'epInviteJobVacancyCandidate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InviteJobVacancyCandidateMutation,
  InviteJobVacancyCandidateMutationVariables
>;
export const JobSpecializationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobSpecializations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Pagination' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobSpecializations' },
            name: { kind: 'Name', value: 'epJobSpecializations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobSpecializationsQuery,
  JobSpecializationsQueryVariables
>;
export const JobSpecializationRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobSpecializationRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobSpecializationId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EpJobSpecializationRolesPriority' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'positionName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobSpecializationRoles' },
            name: { kind: 'Name', value: 'epJobSpecializationRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobSpecializationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobSpecializationId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priority' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'priority' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'positionName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'positionName' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupByStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isRecommended' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobRole' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'jobSpecializationId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobSpecialization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPrevPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobSpecializationRolesQuery,
  JobSpecializationRolesQueryVariables
>;
export const JobSpecializationRoleSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobSpecializationRoleSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobSpecializationRoleId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priority' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'EpJobSpecializationRoleSkillsPriority',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobSpecializationRoleSkills' },
            name: { kind: 'Name', value: 'epJobSpecializationRoleSkills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'excludeIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobSpecializationRoleId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobSpecializationRoleId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priority' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'priority' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupByStr' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPrevPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobSpecializationRoleSkillsQuery,
  JobSpecializationRoleSkillsQueryVariables
>;
export const JobVacancyLevelDropdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancyLevelDropdown' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancyLevelDropdown' },
            name: { kind: 'Name', value: 'epJobVacancyLevelDropdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancyLevelDropdownQuery,
  JobVacancyLevelDropdownQueryVariables
>;
export const JobVacancyTypeDropdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancyTypeDropdown' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancyTypeDropdown' },
            name: { kind: 'Name', value: 'epJobVacancyTypeDropdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancyTypeDropdownQuery,
  JobVacancyTypeDropdownQueryVariables
>;
export const JobVacancyLocationSiteDropdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancyLocationSiteDropdown' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancyLocationSiteDropdown' },
            name: { kind: 'Name', value: 'epJobVacancyLocationSiteDropdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancyLocationSiteDropdownQuery,
  JobVacancyLocationSiteDropdownQueryVariables
>;
export const JobVacancyContactTypeDropdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancyContactTypeDropdown' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancyContactTypeDropdown' },
            name: { kind: 'Name', value: 'epJobVacancyContactTypeDropdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancyContactTypeDropdownQuery,
  JobVacancyContactTypeDropdownQueryVariables
>;
export const EducationLevelDropdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'educationLevelDropdown' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'educationLevelDropdown' },
            name: { kind: 'Name', value: 'epEducationLevelDropdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EducationLevelDropdownQuery,
  EducationLevelDropdownQueryVariables
>;
export const EpEducationProgramsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'epEducationPrograms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'level' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EpEducationLevel' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'educationPrograms' },
            name: { kind: 'Name', value: 'epEducationPrograms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'level' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'level' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPrevPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EpEducationProgramsQuery,
  EpEducationProgramsQueryVariables
>;
export const EducationInstitutionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'educationInstitutions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'educationInstitutions' },
            name: { kind: 'Name', value: 'epEducationInstitutions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPrevPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EducationInstitutionsQuery,
  EducationInstitutionsQueryVariables
>;
export const GenderDropdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'genderDropdown' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'genderDropdown' },
            name: { kind: 'Name', value: 'epGenderDropdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenderDropdownQuery, GenderDropdownQueryVariables>;
export const LocationProvincesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'locationProvinces' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hasVacancy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'locationProvinces' },
            name: { kind: 'Name', value: 'epLocationProvinces' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hasVacancy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'hasVacancy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LocationProvincesQuery,
  LocationProvincesQueryVariables
>;
export const LocationCitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'locationCities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'parentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hasVacancy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'locationCities' },
            name: { kind: 'Name', value: 'epLocationCities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'parentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hasVacancy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'hasVacancy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocationCitiesQuery, LocationCitiesQueryVariables>;
export const LocationDistricsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'locationDistrics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'parentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hasVacancy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'locationDistrics' },
            name: { kind: 'Name', value: 'epLocationDistrics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'parentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hasVacancy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'hasVacancy' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LocationDistricsQuery,
  LocationDistricsQueryVariables
>;
export const LocationDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'locationDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'locationCityDetail' },
            name: { kind: 'Name', value: 'epLocationDetail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'locationId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasVacancy' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocationDetailQuery, LocationDetailQueryVariables>;
export const CompanySizesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'companySizes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'companySizes' },
            name: { kind: 'Name', value: 'epCompanySizes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '99' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanySizesQuery, CompanySizesQueryVariables>;
export const CompanyIndustriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'companyIndustries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'companyIndustries' },
            name: { kind: 'Name', value: 'epCompanyIndustries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '99' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyIndustriesQuery,
  CompanyIndustriesQueryVariables
>;
export const UsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'users' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'users' },
            name: { kind: 'Name', value: 'epUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;
export const UserConfigEmailNotificationIntervalDropdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'userConfigEmailNotificationIntervalDropdown',
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'userConfigEmailNotificationIntervalDropdown',
            },
            name: {
              kind: 'Name',
              value: 'epUserConfigEmailNotificationIntervalDropdown',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserConfigEmailNotificationIntervalDropdownQuery,
  UserConfigEmailNotificationIntervalDropdownQueryVariables
>;
export const ExperienceYearDropdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'experienceYearDropdown' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'experienceYearDropdown' },
            name: { kind: 'Name', value: 'epExperienceYearDropdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExperienceYearDropdownQuery,
  ExperienceYearDropdownQueryVariables
>;
export const ExperienceJobFunctionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'experienceJobFunctions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CommonFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'experienceJobFunctions' },
            name: { kind: 'Name', value: 'epExperienceJobFunctions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExperienceJobFunctionsQuery,
  ExperienceJobFunctionsQueryVariables
>;
export const UserSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CommonFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'userSkills' },
            name: { kind: 'Name', value: 'epUserSkills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserSkillsQuery, UserSkillsQueryVariables>;
export const LocationAreasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'locationAreas' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CommonFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'locationAreas' },
            name: { kind: 'Name', value: 'epLocationAreas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocationAreasQuery, LocationAreasQueryVariables>;
export const JobVacancyBenefitsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancyBenefits' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancyBenefits' },
            name: { kind: 'Name', value: 'epJobVacancyBenefits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancyBenefitsQuery,
  JobVacancyBenefitsQueryVariables
>;
export const MenuTabsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'menuTabs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'domain' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EpMenuTabDomain' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'menuTabs' },
            name: { kind: 'Name', value: 'epMenuTabs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domain' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'domain' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bgColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLock' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isDisplayLink' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'badgeLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextLevelMenuTab' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'domain' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'textColor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bgColor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countTextColor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countBgColor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tooltipDescription' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MenuTabsQuery, MenuTabsQueryVariables>;
export const DocumentsCertificatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'documentsCertificates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'documentsCertificates' },
            name: { kind: 'Name', value: 'epDocumentsCertificates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPrevPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DocumentsCertificatesQuery,
  DocumentsCertificatesQueryVariables
>;
export const JobVacancyPositionNamesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancyPositionNames' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancyPositionNames' },
            name: { kind: 'Name', value: 'epJobVacancyPositionNames' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPrevPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancyPositionNamesQuery,
  JobVacancyPositionNamesQueryVariables
>;
export const InvitationStatusDropdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'invitationStatusDropdown' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'invitationStatusDropdown' },
            name: { kind: 'Name', value: 'epInvitationStatusDropdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InvitationStatusDropdownQuery,
  InvitationStatusDropdownQueryVariables
>;
export const InterviewSessionDropdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'interviewSessionDropdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includePersonalAndPassedSessions' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keyword' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vacancyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'interviewSessionDropdown' },
            name: { kind: 'Name', value: 'epInterviewSessionDropdown' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'excludeIds' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'includePersonalAndPassedSessions',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'includePersonalAndPassedSessions',
                  },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keyword' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'keyword' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vacancyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPrevPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endTimeStr' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numberOfApplicants' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'offlineDetail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onlineDetail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'password' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recruiterMessage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recruiterPhoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'schedule' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sizeType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startTimeStr' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vacancyId' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InterviewSessionDropdownQuery,
  InterviewSessionDropdownQueryVariables
>;
export const CompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'company' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'company' },
            name: { kind: 'Name', value: 'epCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'legalDocument' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accManager' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactPhone' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactWhatsapp' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactWeblink' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'foundedYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nib' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'legalDocumentUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'verificationStatus' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'verificationStatusStr' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'verificationStatusNotice' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'verificationStatusDisplayStr' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobCompanyIndustry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobCompanySize' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'locationProvince' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'locationCity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'locationDistrict' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyQuery, CompanyQueryVariables>;
export const CompanyAccManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'companyAccManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'companyAccManager' },
            name: { kind: 'Name', value: 'epCompanyAccManager' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accManager' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accManagerWhatsapp' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accManagerCopy' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accManagerDashboardCopy' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accManagerErrorCopy' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accManagerRegisterCopy' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accManagerRegisterCopyV2' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyAccManagerQuery,
  CompanyAccManagerQueryVariables
>;
export const UpdateCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'payload' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EpInputUpdateCompany' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'updateCompany' },
            name: { kind: 'Name', value: 'epUpdateCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'payload' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;
export const RegisterCompanyV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'registerCompanyV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'payload' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EpRegisterCompanyInputV2' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'registerCompanyV2' },
            name: { kind: 'Name', value: 'epRegisterCompanyV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'payload' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpRegisterCompanyV2Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpRegisterCompanyV2Response',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterCompanyV2Mutation,
  RegisterCompanyV2MutationVariables
>;
export const DashboardApplicationSummaryCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'dashboardApplicationSummaryCount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'dashboardApplicationSummaryCount' },
            name: { kind: 'Name', value: 'epDashboardApplicationSummaryCount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpDashboardApplicationSummaryResponse',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalNew' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPending' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpDashboardApplicationSummaryError',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardApplicationSummaryCountQuery,
  DashboardApplicationSummaryCountQueryVariables
>;
export const DashboardVacancySummaryListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'dashboardVacancySummaryList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'dashboardVacancySummaryList' },
            name: { kind: 'Name', value: 'epDashboardVacancySummaryList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpDashboardVacancySummaryResponse',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onProgress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locationCity' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasVacancy' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'level' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locationProvince' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasVacancy' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'level' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'positionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalApplication' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pending' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locationCity' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasVacancy' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'level' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locationProvince' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasVacancy' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'level' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'positionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalApplication' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpDashboardVacancySummaryError',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardVacancySummaryListQuery,
  DashboardVacancySummaryListQueryVariables
>;
export const ValidateInterviewSessionNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'validateInterviewSessionName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vacancyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'validateInterviewSessionName' },
            name: { kind: 'Name', value: 'epValidateInterviewSessionName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vacancyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ValidateInterviewSessionNameQuery,
  ValidateInterviewSessionNameQueryVariables
>;
export const EpCreateInterviewInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EpCreateInterviewInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EpCreateInterviewInvitationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'createInterviewInvitation' },
            name: { kind: 'Name', value: 'epCreateInterviewInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpCreateInterviewInvitationError',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpCreateInterviewInvitationSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EpCreateInterviewInvitationMutation,
  EpCreateInterviewInvitationMutationVariables
>;
export const EpUpdateInterviewInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EpUpdateInterviewInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EpUpdateInterviewInvitationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'updateInterviewInvitation' },
            name: { kind: 'Name', value: 'epUpdateInterviewInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpUpdateInterviewInvitationError',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpUpdateInterviewInvitationSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EpUpdateInterviewInvitationMutation,
  EpUpdateInterviewInvitationMutationVariables
>;
export const InterviewSessionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'interviewSessions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vacancyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InterviewSessionFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'interviewSessions' },
            name: { kind: 'Name', value: 'epInterviewSessions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vacancyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPrevPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endTimeStr' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'schedule' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startTimeStr' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statistic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InterviewSessionsQuery,
  InterviewSessionsQueryVariables
>;
export const InterviewSessionSortDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'interviewSessionSort' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'interviewSessionSort' },
            name: { kind: 'Name', value: 'epInterviewSessionSort' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InterviewSessionSortQuery,
  InterviewSessionSortQueryVariables
>;
export const InterviewSessionTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'interviewSessionTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'interviewSessionTypes' },
            name: { kind: 'Name', value: 'epInterviewSessionTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InterviewSessionTypesQuery,
  InterviewSessionTypesQueryVariables
>;
export const InterviewSessionCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'interviewSessionCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InterviewSessionFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vacancyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'interviewSessionCount' },
            name: { kind: 'Name', value: 'epInterviewSessionCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vacancyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InterviewSessionCountQuery,
  InterviewSessionCountQueryVariables
>;
export const InterviewSessionApplicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'interviewSessionApplications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keyword' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sessionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'interviewSessionApplications' },
            name: { kind: 'Name', value: 'epInterviewSessionApplications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keyword' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'keyword' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sessionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPrevPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'application' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'interestedStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isAccepted' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isInteviewed' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobVacancyId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'districtName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cityName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'provinceName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'matchingResult' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'haveScreeningQuestionAnswers',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userProfile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'age' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrl' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interviewInvitation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'invitedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusStr' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'session' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endTimeStr' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'schedule' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startTimeStr' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sizeType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statistic' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'displayName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InterviewSessionApplicationsQuery,
  InterviewSessionApplicationsQueryVariables
>;
export const InterviewSessionTabsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'interviewSessionTabs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keyword' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sessionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'interviewSessionTabs' },
            name: { kind: 'Name', value: 'epInterviewSessionTabs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keyword' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'keyword' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sessionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfApplicants' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InterviewSessionTabsQuery,
  InterviewSessionTabsQueryVariables
>;
export const InterviewSessionByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'interviewSessionById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'epInterviewSessionByIdId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'interviewSessionById' },
            name: { kind: 'Name', value: 'epInterviewSessionById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'epInterviewSessionByIdId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'schedule' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'startTimeStr' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sizeType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vacancy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'positionName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closeDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InterviewSessionByIdQuery,
  InterviewSessionByIdQueryVariables
>;
export const CreateMediaFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMediaFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MediaFileUploadInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMediaFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'access' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'uploadUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMediaFileMutation,
  CreateMediaFileMutationVariables
>;
export const CreateMediaImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMediaImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'image' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MediaImageUploadInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMediaImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'image' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'image' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'access' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'uploadUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMediaImageMutation,
  CreateMediaImageMutationVariables
>;
export const MediaFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'mediaFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'access' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MediaFileQuery, MediaFileQueryVariables>;
export const MediaImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'mediaImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'access' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thumbnailUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MediaImageQuery, MediaImageQueryVariables>;
export const MenusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'menus' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'menus' },
            name: { kind: 'Name', value: 'epMenus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roleAccess' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'canCreate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'canRead' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'canUpdate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'canApprove' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'canDownload' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionalRoleAccess' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyParam' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MenusQuery, MenusQueryVariables>;
export const EducationLevelFilterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'educationLevelFilter' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'educationLevelFilter' },
            name: { kind: 'Name', value: 'epEducationLevelFilter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EducationLevelFilterQuery,
  EducationLevelFilterQueryVariables
>;
export const UserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'user' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'user' },
            name: { kind: 'Name', value: 'epUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'registrationStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'roleStr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserQuery, UserQueryVariables>;
export const UserConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userConfig' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'userConfig' },
            name: { kind: 'Name', value: 'epUserConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vacancyReportSummaryPeriod' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserConfigQuery, UserConfigQueryVariables>;
export const UpdateUserConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EpUserConfigUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'updateUserConfig' },
            name: { kind: 'Name', value: 'epUpdateUserConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'warning' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserConfigMutation,
  UpdateUserConfigMutationVariables
>;
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EpUserUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'updateUser' },
            name: { kind: 'Name', value: 'epUpdateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'warning' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const UserSubscriptionStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userSubscriptionStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'userSubscriptionStatus' },
            name: { kind: 'Name', value: 'epUserSubscriptionStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'packageId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'packageStr' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentPackageStr' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refreshDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isValid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isDisplayInfo' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offerPackageUpgrade' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'status' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobPromoteMax' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freeTrialDuration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freeTrialStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isFreeTrial' } },
                { kind: 'Field', name: { kind: 'Name', value: 'packageEnum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobPromoteStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobPromoteCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobPromoteExceeded' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserSubscriptionStatusQuery,
  UserSubscriptionStatusQueryVariables
>;
export const UserSubscriptionTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userSubscriptionTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'userSubscriptionTypes' },
            name: { kind: 'Name', value: 'epUserSubscriptionTypes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isRecommended' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isSelected' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'btnCopy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'btnConfirmationCopy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmationAsset' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmationTitleCopy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmationBodyCopy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscriptionTypeItems' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserSubscriptionTypesQuery,
  UserSubscriptionTypesQueryVariables
>;
export const OfferingPackagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'offeringPackages' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'offeringPackages' },
            name: { kind: 'Name', value: 'epOfferingPackages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'btnCopy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featureItems' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featureTitle' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isPopular' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packageDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packageName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OfferingPackagesQuery,
  OfferingPackagesQueryVariables
>;
export const RequestUserSubscriptionTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestUserSubscriptionType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subscriptionTypeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'requestUserSubscriptionType' },
            name: { kind: 'Name', value: 'epRequestUserSubscriptionType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionTypeId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subscriptionTypeId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'warning' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestUserSubscriptionTypeMutation,
  RequestUserSubscriptionTypeMutationVariables
>;
export const SubscriptionPlanStatusDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'subscriptionPlanStatusDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subscriptionPlanStatusDetail' },
            name: { kind: 'Name', value: 'epSubscriptionPlanStatusDetail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLimit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscriptionPlanStatusDetailQuery,
  SubscriptionPlanStatusDetailQueryVariables
>;
export const PackageFeaturesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'packageFeatures' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'packageFeatures' },
            name: { kind: 'Name', value: 'epPackageFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expiry' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packageName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PackageFeaturesQuery,
  PackageFeaturesQueryVariables
>;
export const UserStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'userStatus' },
            name: { kind: 'Name', value: 'epUserStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserStatusQuery, UserStatusQueryVariables>;
export const JobVacanciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CommonFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EpJobVacancyState' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cityId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeVacancyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancies' },
            name: { kind: 'Name', value: 'epJobVacancies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'state' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeVacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'excludeVacancyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPrevPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'totalCandidateRecommendation',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closeDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closeDateStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'positionName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isPublished' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isHighlighted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isReposted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'highlightedExpiredAt' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'highlightedExpiredAtStr',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'highlightedStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webLink' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationCity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'jobApplicationStatistic',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalAccepted' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalInterview' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalOnProgress' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPending' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalRejected' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tierCategory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobVacanciesQuery, JobVacanciesQueryVariables>;
export const JobVacancyDraftsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancyDrafts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CommonFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EpJobVacancyState' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cityId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeDraftId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancyDrafts' },
            name: { kind: 'Name', value: 'epJobVacancyDrafts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cityId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'state' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeDraftId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'excludeDraftId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPrevPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'totalCandidateRecommendation',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closeDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closeDateStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'positionName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isPublished' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isHighlighted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isReposted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'highlightedExpiredAt' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'highlightedExpiredAtStr',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'highlightedStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webLink' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationCity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'jobApplicationStatistic',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalAccepted' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalInterview' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalOnProgress' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalPending' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalRejected' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tierCategory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancyDraftsQuery,
  JobVacancyDraftsQueryVariables
>;
export const JobVacancySearchAltDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancySearchAlt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CommonFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeVacancyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancies' },
            name: { kind: 'Name', value: 'epJobVacancies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeVacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'excludeVacancyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'positionName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closeDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationCity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancySearchAltQuery,
  JobVacancySearchAltQueryVariables
>;
export const JobVacancySummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancySummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cityId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancySummary' },
            name: { kind: 'Name', value: 'epJobVacancySummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalClosed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalOnHold' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalOpen' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalDraft' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancySummaryQuery,
  JobVacancySummaryQueryVariables
>;
export const JobVacancyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancy' },
            name: { kind: 'Name', value: 'epJobVacancyV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpJobVacancy' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'positionName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formattedDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'benefits' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contactType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contactDetail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hrEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vacancyCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closeDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webLink' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobLevel' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobLevelStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationSite' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationSiteStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobVacancyFunctionId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workingDayStartStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workingDayStart' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workingDayEndStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workingDayEnd' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workingHourStartStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workingHourStart' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workingHourEndStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workingHourEnd' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salaryLowerBoundStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salaryLowerBound' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salaryUpperBoundStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salaryUpperBound' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'educationLevel' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'educationLevelStr' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minExperience' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxAge' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minAge' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'genderStr' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isPublished' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isHighlighted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isOpenForFreshGraduate' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'isOpenForAllEducationPrograms',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'educationProgramPreferences',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'idx' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationProvince' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationCity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationDistrict' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobFunction' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobRole' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobSpecialization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skillsV2' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'idx' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isVerified' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalPending' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalOnProgress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalAccepted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalRejected' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requirements' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'jobVacancyReqCategorySubId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isAdditional' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'priority' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'vacancyReqCategorySub',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'displayName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'jobVacancyRequirementCategoryId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'restrictions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'educationLevel' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skillsV2' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'idx' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tierCategory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpJobVacancyError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobVacancyQuery, JobVacancyQueryVariables>;
export const JobVacancyScreeningQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancyScreeningQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobVacancyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancyScreeningQuestions' },
            name: { kind: 'Name', value: 'epJobVacancyScreeningQuestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobVacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobVacancyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'idx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'topic' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'question' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answer' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'value' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'idx' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancyScreeningQuestionsQuery,
  JobVacancyScreeningQuestionsQueryVariables
>;
export const OpenJobVacancyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'openJobVacancy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobVacancyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'openJobVacancy' },
            name: { kind: 'Name', value: 'epOpenJobVacancyV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobVacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobVacancyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpJobVacancyGeneratedLink' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closeDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobPublished' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasExceeded' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'max' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'positionName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webLink' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpOpenJobVacancyError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OpenJobVacancyMutation,
  OpenJobVacancyMutationVariables
>;
export const OpenJobVacancyV3Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'openJobVacancyV3' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'openJobVacancyV3' },
            name: { kind: 'Name', value: 'epOpenJobVacancyV3' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobVacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpJobVacancyV3Response' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closeDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'positionName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webLink' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpOpenJobVacancyV3Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OpenJobVacancyV3Mutation,
  OpenJobVacancyV3MutationVariables
>;
export const OnHoldJobVacancyV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'onHoldJobVacancyV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'onHoldJobVacancyV2' },
            name: { kind: 'Name', value: 'epOnHoldJobVacancyV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobVacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpJobVacancyV3IdResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpOnHoldJobVacancyV2Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OnHoldJobVacancyV2Mutation,
  OnHoldJobVacancyV2MutationVariables
>;
export const CloseJobVacancyV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'closeJobVacancyV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'closeJobVacancyV2' },
            name: { kind: 'Name', value: 'epCloseJobVacancyV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobVacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpCloseJobVacancyV2Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpJobVacancyV3IdResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CloseJobVacancyV2Mutation,
  CloseJobVacancyV2MutationVariables
>;
export const CreateJobVacancyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createJobVacancy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EpJobVacancyCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'createJobVacancy' },
            name: { kind: 'Name', value: 'epCreateJobVacancyV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpJobVacancyGeneratedLink' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closeDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobPublished' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasExceeded' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'max' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'positionName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webLink' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpCreateJobVacancyError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateJobVacancyMutation,
  CreateJobVacancyMutationVariables
>;
export const CreateJobVacancyV3Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createJobVacancyV3' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EpJobVacancyCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'createJobVacancyV3' },
            name: { kind: 'Name', value: 'epCreateJobVacancyV3' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpCreateJobVacancyV3Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpJobVacancyV3Response' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closeDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'positionName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateJobVacancyV3Mutation,
  CreateJobVacancyV3MutationVariables
>;
export const UpdateJobVacancyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateJobVacancy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EpJobVacancyUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'updateJobVacancy' },
            name: { kind: 'Name', value: 'epUpdateJobVacancyV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpJobVacancyGeneratedLink' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closeDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobPublished' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasExceeded' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'max' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'positionName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webLink' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpUpdateJobVacancyError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateJobVacancyMutation,
  UpdateJobVacancyMutationVariables
>;
export const UpdateJobVacancyV3Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateJobVacancyV3' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EpJobVacancyUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'updateJobVacancyV3' },
            name: { kind: 'Name', value: 'epUpdateJobVacancyV3' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpJobVacancyV3Response' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closeDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'positionName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webLink' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpUpdateJobVacancyV3Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateJobVacancyV3Mutation,
  UpdateJobVacancyV3MutationVariables
>;
export const RepostJobVacancyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'repostJobVacancy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EpJobVacancyUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'repostJobVacancy' },
            name: { kind: 'Name', value: 'epRepostJobVacancy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpJobVacancyV3Response' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closeDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'positionName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webLink' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpRepostJobVacancyError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RepostJobVacancyMutation,
  RepostJobVacancyMutationVariables
>;
export const CreateJobVacancyDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createJobVacancyDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EpJobVacancyCreateDraftInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'createJobVacancyDraft' },
            name: { kind: 'Name', value: 'epCreateJobVacancyDraft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpCreateJobVacancyDraftSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpCreateJobVacancyDraftError',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateJobVacancyDraftMutation,
  CreateJobVacancyDraftMutationVariables
>;
export const ActivateJobVacancyHighlightV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'activateJobVacancyHighlightV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'activateJobVacancyHighlightV2' },
            name: { kind: 'Name', value: 'epActivateJobVacancyHighlightV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobVacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpActivateJobVacancyHighlightV2Error',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpJobVacancyV3IdResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActivateJobVacancyHighlightV2Mutation,
  ActivateJobVacancyHighlightV2MutationVariables
>;
export const DeactivateJobVacancyHighlightV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deactivateJobVacancyHighlightV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'deactivateJobVacancyHighlightV2' },
            name: { kind: 'Name', value: 'epDeactivateJobVacancyHighlightV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobVacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpDeactivateJobVacancyHighlightV2Error',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EpJobVacancyV3IdResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeactivateJobVacancyHighlightV2Mutation,
  DeactivateJobVacancyHighlightV2MutationVariables
>;
export const JobVacancyDistinctSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancyDistinctSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CommonFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'positionName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancyDistinctSearch' },
            name: { kind: 'Name', value: 'epJobVacancyDistinctSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'positionName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'positionName' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'elements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'list' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancyDistinctSearchQuery,
  JobVacancyDistinctSearchQueryVariables
>;
export const JobVacancyClosedFeedbackTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancyClosedFeedbackTemplates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'jobVacancyClosedFeedbackTemplates' },
            name: {
              kind: 'Name',
              value: 'epJobVacancyClosedFeedbackTemplates',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inputType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancyClosedFeedbackTemplatesQuery,
  JobVacancyClosedFeedbackTemplatesQueryVariables
>;
export const CreateJobVacancyClosedFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createJobVacancyClosedFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'payload' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EpJobVacancyClosedFeedbackInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'createJobVacancyClosedFeedback' },
            name: { kind: 'Name', value: 'epCreateJobVacancyClosedFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'payload' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateJobVacancyClosedFeedbackMutation,
  CreateJobVacancyClosedFeedbackMutationVariables
>;
export const ScreeningQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'screeningQuestions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'screeningQuestions' },
            name: { kind: 'Name', value: 'epScreeningQuestions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'idx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'topic' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'question' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answer' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'value' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'idx' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScreeningQuestionsQuery,
  ScreeningQuestionsQueryVariables
>;
export const JobVacancyScreeningQuestionsFilterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobVacancyScreeningQuestionsFilter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobVacancyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'jobVacancyScreeningQuestionsFilter',
            },
            name: {
              kind: 'Name',
              value: 'epJobVacancyScreeningQuestionsFilter',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobVacancyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobVacancyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'choices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'topic' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uiType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobVacancyScreeningQuestionsFilterQuery,
  JobVacancyScreeningQuestionsFilterQueryVariables
>;
export const SyncPublishedJobVacancyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'syncPublishedJobVacancy' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'syncPublishedJobVacancy' },
            name: { kind: 'Name', value: 'epSyncPublishedJobVacancy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpSyncPublishedJobVacancyError',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpSyncPublishedJobVacancyResponse',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobPublished' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasExceeded' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'max' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SyncPublishedJobVacancyMutation,
  SyncPublishedJobVacancyMutationVariables
>;
export const SubmitContactedPhoneNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'submitContactedPhoneNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'packageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'submitContactedPhoneNumber' },
            name: { kind: 'Name', value: 'epSubmitContactedPhoneNumber' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'packageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'packageId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phoneNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phoneNumber' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpSubmitContactedPhoneNumberResponse',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packageId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EpSubmitContactedPhoneNumberResponseError',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeError' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitContactedPhoneNumberMutation,
  SubmitContactedPhoneNumberMutationVariables
>;
