import { useMemo } from 'react';
import { useAppSelector } from '../use-store';

import { PACKAGE } from '~/utils/constants';

const usePremium = () => {
  const realtimeData = useAppSelector((state) => state.realtimeData);

  const currentPackage =
    realtimeData?.packageByCompanyId?.data?.currentHighestPackageEnum ?? '';

  const isPremium = useMemo(() => {
    return !!realtimeData.packageByCompanyId.data?.isPremium;
  }, [realtimeData]);

  const hasJobPromoteQuotaLeft = useMemo(() => {
    const current = realtimeData.highlightedByCompanyId.data?.current ?? 0;
    const max = realtimeData.highlightedByCompanyId.data?.max ?? 0;
    return current < max;
  }, [realtimeData]);

  const isFreePlusNosSTD = [
    `${PACKAGE.FREE_PLUS}`,
    `${PACKAGE.NOS_STD}`,
  ].includes(currentPackage);

  const hasCRE = !!realtimeData?.packageByCompanyId?.data?.hasCRE;

  return { isPremium, isFreePlusNosSTD, hasJobPromoteQuotaLeft, hasCRE };
};

export default usePremium;
