import { Box, brandColors, Stack, Typography } from '@kitalulus/web-ui-kit';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AuthLayoutCopyRight from '~/components/AuthLayoutV2/AuthLayoutCopyRight';
import LoadingButton from '~/components/LoadingButton/LoadingButton';
import Navbar from '~/components/Navbar';
import SubscriptionPlanV2 from '~/components/SubscriptionPlanV2';
import { useRealtimeData } from '~/hooks/firebase/use-realtime-data';
import { useSignOut } from '~/hooks/use-auth';
import { useAppDispatch } from '~/hooks/use-store';
import { useUserOnboarding } from '~/hooks/use-user-onboarding';
import {
  useOfferingPackages,
  useUserSubscriptionRequest,
} from '~/hooks/use-user-subscription';
import { setSnackbar } from '~/store/views/snackbar-slice';
import {
  EpOfferingPackage,
  EpUserSubscriptionType,
} from '~/types/graphql/graphql';
import { MIXPANEL_ACCOUNT_FAQ_LINK_POSITION } from '~/utils/constants/mixpanel';
import * as mpe from '~/utils/mixpanel';

const SubscriptionOffer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { handleSignOut } = useSignOut();
  const { packages, isLoading } = useOfferingPackages();
  const { handleRequest } = useUserSubscriptionRequest();
  const { handleNextOnboarding } = useUserOnboarding();
  useRealtimeData();

  const onSkip = useCallback(async () => {
    mpe.onBoardingPremiumSelect({
      packageOption: 'Free',
    });
    dispatch(
      setSnackbar({
        layout: 'dashboard',
        severity: 'success',
        message: t('company:companyRegistrationSuccess'),
      }),
    );

    // and end up the onboarding
    handleNextOnboarding();
  }, []);

  const onSelect = useCallback(
    async (
      plan: EpUserSubscriptionType | EpOfferingPackage,
      onClose: () => void,
    ) => {
      const res = await handleRequest(plan.id, {
        type: 'self-registration-success',
        layout: 'dashboard',
      });
      if (!res?.isSuccess) return;

      // on success selecting subscription package
      // close the confirmation modal
      // and end up the onboarding
      onClose();
      handleNextOnboarding();
    },
    [handleRequest],
  );

  const title = useMemo(() => {
    return t('subscription:subscriptionOnboardingTitleV2', {
      highlighted: `<span>${t(
        'subscription:subscriptionOnboardingHighlightedV2',
      )}</span>`,
    });
  }, []);

  const footer = useMemo(() => {
    return t('subscription:footer:onboardingFooterSubtitleV2', {
      highlighted: `<b>${t('common:free')}!</b>`,
    });
  }, []);

  const ctaLabel = useMemo(() => {
    return t('subscription:footer:onboardingFooterButtonV2');
  }, []);

  return (
    <Stack>
      <Navbar
        buttons={[
          {
            key: 'signout',
            children: t('common:backHome'),
            onClick: () => handleSignOut(),
            sx: { textTransform: 'none' },
          },
        ]}
      />
      <Box pt={10}>
        <Stack spacing={6} px={0}>
          {/* Header section */}
          <Stack
            alignItems="center"
            textAlign="center"
            position="relative"
            spacing={1}
            overflow="hidden"
            px={{ xs: 4, md: 8 }}
            pb={4}
          >
            <Typography
              component="div"
              variant="h3"
              sx={{
                maxWidth: '700px',
                span: { color: brandColors.primary[500] },
              }}
            >
              <div
                style={{ whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </Typography>
            <Typography variant="body1" color="text.primary">
              {t('subscription:subscriptionOnboardingSubtitleV2')}
            </Typography>

            {/* Blobs */}
            <Box
              display={{ xs: 'none', lg: 'block' }}
              component="img"
              src="/blobs/small-24-dots.svg"
              position="absolute"
              left="100px"
              top="20px"
            />

            <Box
              display={{ xs: 'none', lg: 'block' }}
              component="img"
              src="/blobs/gradient-orange-dot.svg"
              position="absolute"
              right="-35px"
              bottom="0"
            />
          </Stack>

          {/* Subscription plan list */}
          <Box position="relative" pb={5} sx={{ overflowX: 'hidden' }}>
            <SubscriptionPlanV2
              plans={packages}
              isLoading={isLoading}
              onPlanSelect={onSelect}
              pageLocation="onBoarding"
            />

            {/* Blobs */}
            <Box
              display={{ xs: 'none', lg: 'block' }}
              component="img"
              src="/blobs/gradient-blue-dot.svg"
              position="absolute"
              top="15px"
              left="0"
            />

            <Box
              display={{ xs: 'none', lg: 'block' }}
              component="img"
              src="/blobs/small-24-dots.svg"
              position="absolute"
              right="-70px"
              bottom="250px"
            />

            <Box
              display={{ xs: 'none', lg: 'block' }}
              component="img"
              src="/blobs/gradient-blue-dot.svg"
              position="absolute"
              bottom="30px"
              right="0"
            />
          </Box>

          {/* Footer section */}
          <Stack>
            {/* Free trial banner */}
            <Stack
              alignItems="center"
              spacing={2}
              bgcolor="#F8FCFF"
              px={{ xs: 4, md: 0 }}
              py={8}
            >
              <Stack textAlign="center" spacing={1}>
                <Typography variant="h4">
                  {t('subscription:footer:onboardingFooterTitle')}
                </Typography>
                <Typography component="div" variant="body1">
                  <div dangerouslySetInnerHTML={{ __html: footer }} />
                </Typography>
              </Stack>
              <LoadingButton
                variant="contained"
                onClick={onSkip}
                data-test-id="btnSubscriptionOfferCTA"
                size="large"
              >
                {ctaLabel}
              </LoadingButton>
            </Stack>

            {/* Copyright section */}
            <Box py={4} textAlign="center">
              <AuthLayoutCopyRight
                helpLink={{
                  linkPosition:
                    MIXPANEL_ACCOUNT_FAQ_LINK_POSITION.SUBSCRIPTION_OFFER_DIALOG,
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default SubscriptionOffer;
