import { useLocation } from 'react-router-dom';
import { useFeatureFlag } from './firebase/use-feature-flag';
import { useAppSelector } from './use-store';
import { useMemo } from 'react';

const useBanner = () => {
  const location = useLocation();
  const isFreePlanActive = useFeatureFlag('free_plan');
  const bannerFreeTrial = useAppSelector((state) => state.bannerFreeTrial);
  const banner = useAppSelector((state) => state.banner);
  const realtimeData = useAppSelector((state) => state.realtimeData);

  const packageByCompanyIdData = realtimeData.packageByCompanyId.data;
  const hasAlmostExpired = packageByCompanyIdData?.hasAlmostExpired;

  const isInDashboard = location.pathname === '/dashboard';

  const showBanner = useMemo(() => {
    return isInDashboard && hasAlmostExpired;
  }, [isInDashboard, hasAlmostExpired]);

  const useNewBanner = isFreePlanActive;

  const bannerHeight = useNewBanner ? banner.height : bannerFreeTrial.height;

  return { useNewBanner, bannerHeight, showBanner };
};

export default useBanner;
