import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

import {
  MUTATION_OPEN_JOB_VACANCY,
  MUTATION_ON_HOLD_JOB_VACANCY_V2,
  MUTATION_CLOSED_JOB_VACANCY_V2,
  MUTATION_OPEN_JOB_VACANCY_V3,
} from '~/graphql/vacancy';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { Nullable } from '~/types';
import {
  CloseJobVacancyV2Mutation,
  CloseJobVacancyV2MutationVariables,
  OnHoldJobVacancyV2Mutation,
  OnHoldJobVacancyV2MutationVariables,
  OpenJobVacancyMutation,
  OpenJobVacancyMutationVariables,
  OpenJobVacancyV3Mutation,
  OpenJobVacancyV3MutationVariables,
} from '~/types/graphql/graphql';
import { useAppDispatch } from './use-store';

export const useOpenJobVacancy = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Nullable<string>>();

  const [mutateOpenJobVacancy] = useMutation<
    OpenJobVacancyMutation,
    OpenJobVacancyMutationVariables
  >(MUTATION_OPEN_JOB_VACANCY);

  const [mutateOpenJobVacancyV3] = useMutation<
    OpenJobVacancyV3Mutation,
    OpenJobVacancyV3MutationVariables
  >(MUTATION_OPEN_JOB_VACANCY_V3);

  const showError = (message: string) => {
    dispatch(
      setSnackbar({
        layout: 'dashboard',
        severity: 'error',
        message: message,
      }),
    );
  };
  const handleOpenJobVacancy = useCallback(
    async (vacancyId: string) => {
      try {
        setIsLoading(true);
        return await mutateOpenJobVacancy({
          variables: { jobVacancyId: vacancyId },
        });
      } catch (err: any) {
        setError(err.message);
        showError(err.message);
      } finally {
        setIsLoading(false);
      }
    },
    [mutateOpenJobVacancy],
  );

  const handleOpenJobVacancyV3 = useCallback(
    async (vacancyId: string) => {
      try {
        setIsLoading(true);
        const { data } = await mutateOpenJobVacancyV3({
          variables: { id: vacancyId },
        });
        return data;
      } catch (err: any) {
        setError(err.message);
        showError(err.message);
      } finally {
        setIsLoading(false);
      }
    },
    [mutateOpenJobVacancy],
  );

  return { handleOpenJobVacancy, handleOpenJobVacancyV3, isLoading, error };
};

export const useOnHoldJobVacancy = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Nullable<string>>();

  const [mutateOnHoldJobVacancyV2] = useMutation<
    OnHoldJobVacancyV2Mutation,
    OnHoldJobVacancyV2MutationVariables
  >(MUTATION_ON_HOLD_JOB_VACANCY_V2);

  const handleOnHoldJobVacancy = useCallback(async (vacancyId: string) => {
    try {
      setIsLoading(true);
      const { data } = await mutateOnHoldJobVacancyV2({
        variables: { id: vacancyId },
      });
      return data;
    } catch (err: any) {
      setError(err.message);
      dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'error',
          message: err.message,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { handleOnHoldJobVacancy, isLoading, error };
};

export const useCloseJobVacancy = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Nullable<string>>();

  const [mutateCloseJobVacancyV2] = useMutation<
    CloseJobVacancyV2Mutation,
    CloseJobVacancyV2MutationVariables
  >(MUTATION_CLOSED_JOB_VACANCY_V2);

  const handleCloseJobVacancy = useCallback(async (vacancyId: string) => {
    try {
      setIsLoading(true);
      const { data } = await mutateCloseJobVacancyV2({
        variables: { id: vacancyId },
      });
      return data;
    } catch (err: any) {
      setError(err.message);
      dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'error',
          message: err.message,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { handleCloseJobVacancy, isLoading, error };
};
